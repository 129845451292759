import React from 'react';
import './dropdown.scss';
import { DropdownMenu } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { UncontrolledTooltip } from 'reactstrap';

// Import Icons
import Edit from '@material-ui/icons/Edit';
import Sync from '@material-ui/icons/Sync';
import Delete from '@material-ui/icons/Delete';
import People from '@material-ui/icons/People';
import History from '@material-ui/icons/History';
import Visibility from '@material-ui/icons/Visibility';

// Application imports;
import AppContext from '../../../../components/AppContext';
import Document from '../../../../api/Document';

// Imports css;
import 'react-confirm-alert/src/react-confirm-alert.css';

interface Props extends RouteComponentProps {
  isAttached: number;
  documentUuid: string;
  media: any;
  deletePermission: boolean;
  linkOpenHandler: Function;
  updateListData: Function;
  viewRevisionHandler: Function;
  viewRolesHandler: Function;
}

interface State {}

class DocumentDropdown extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  documentDeleteConfirmation = (documentUuid: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this document?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteDocument(documentUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  deleteDocument = (documentUuid: string) => {
    const { activeAccountUUID } = this.context;

    // Request to server;
    Document.deleteDocument(activeAccountUUID, documentUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.updateListData();
        } else {
          toast.error(response.data.messages[0][0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <>
        <DropdownMenu right className="w200">
          <ol className="dropdown-menu__lists profile--menu">
            <li>
              <button
                onClick={() => this.props.linkOpenHandler(this.props.media)}
                id="InlineView"
              >
                <Visibility fontSize="large" className="icons" />
                {/* View */}
                <UncontrolledTooltip placement="top" target="InlineView">
                  View
                </UncontrolledTooltip>
              </button>
            </li>
            {this.props.isAttached === 0 && (
              <>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={() =>
                      this.props.history.push(`/document/edit/${this.props.documentUuid}`)
                    }
                    id="InlineEdit"
                  >
                    <Edit fontSize="large" className="icons" />
                    <UncontrolledTooltip placement="top" target="InlineEdit">
                      Edit
                    </UncontrolledTooltip>
                    {/* Edit */}
                  </button>
                </li>
                {!this.props.deletePermission ? (
                  <>
                    <li className="dropdown-divider" />
                    <li>
                      <button
                        className="text--error"
                        onClick={() =>
                          this.documentDeleteConfirmation(this.props.documentUuid)
                        }
                        id="InlineDelete"
                      >
                        <Delete fontSize="large" className="icons text--error" />
                        <UncontrolledTooltip placement="top" target="InlineDelete">
                          Delete
                        </UncontrolledTooltip>
                        {/* Delete */}
                      </button>
                    </li>
                  </>
                ) : (
                  ''
                )}
              </>
            )}
            {this.props.isAttached === 1 && (
              <>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={() =>
                      this.props.history.push(
                        `/document/revision/${this.props.documentUuid}`
                      )
                    }
                    id="InlineRevision"
                  >
                    <Sync fontSize="large" className="icons" />
                    <UncontrolledTooltip placement="top" target="InlineRevision">
                      Create Revision
                    </UncontrolledTooltip>
                    {/* Create Revision */}
                  </button>
                </li>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={() =>
                      this.props.viewRevisionHandler(this.props.documentUuid)
                    }
                    id="InlineViewRevision"
                  >
                    <History fontSize="large" className="icons" />
                    <UncontrolledTooltip placement="top" target="InlineViewRevision">
                      View Revisions
                    </UncontrolledTooltip>
                    {/* View Revision */}
                  </button>
                </li>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={() => this.props.viewRolesHandler(this.props.documentUuid)}
                    id="InlineRoles"
                  >
                    <People fontSize="large" className="icons" />
                    <UncontrolledTooltip placement="top" target="InlineRoles">
                      Roles
                    </UncontrolledTooltip>
                    {/* Roles */}
                  </button>
                </li>
              </>
            )}
          </ol>
        </DropdownMenu>
      </>
    );
  }
}

export default withRouter(DocumentDropdown);
