import React, { ChangeEvent } from 'react';
import { Container, Row, Col, Table, Input } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import EditProfileModal from './EditProfileModal';
import EditPasswordModal from './EditPasswordModal';
import AppContext from '../../../components/AppContext';
import { toast } from 'react-toastify';
import ContentLoader from 'react-content-loader';

// Application Imports
import appConfig from '../../../config';
import LocalStorage from '../../../helpers/LocalStorage';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './profile-settings.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

//import static assets
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
// import ProfileCorporateAvatar from '../../../assets/images/No-Corporate-Image.jpg';

interface Props extends RouteComponentProps {}

interface State {
  modalEditProfile: boolean;
  modalEditProfileImage: string;
  modalChangePassword: boolean;
  profileImageUrl: string;
  emailPersonalNotificationCompliance: boolean;
  emailNotificationCompliance: boolean;
  emailNotificationActivity: boolean;
  showLoader: boolean;
}

class ProfileSettings extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalEditProfile: false,
      modalEditProfileImage: '',
      modalChangePassword: false,
      emailPersonalNotificationCompliance: false,
      emailNotificationCompliance: false,
      emailNotificationActivity: false,
      profileImageUrl: '',
      showLoader: true,
    };
  }

  componentDidMount() {
    document.title = `My Profile Settings ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch user detail;
    this.getUserDetails();
  }

  getUserDetails = () => {
    // set loader to loading;
    this.setState({
      showLoader: true,
    });

    const postData = {
      view: 'profile-setting',
    };
    axiosInstance
      .post('/user/get-user-details', postData)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            emailPersonalNotificationCompliance:
              response.data.data.notification_personal_compliance_updates,
            emailNotificationActivity:
              response.data.data.notification_company_activity_updates,
            emailNotificationCompliance:
              response.data.data.notification_company_compliance_updates,
            profileImageUrl: response.data.data.image_url,
          });
        } else {
          this.setState({
            showLoader: false,
          });
          toast.error('Unable to retrieve details. Please try again.', {
            autoClose: 4000,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
        });
      });
  };

  updateProfileImg = (imgURL: string) => {
    this.setState({
      profileImageUrl: imgURL,
    });
  };

  toggleEditProfileModal = () => {
    this.setState({
      modalEditProfile: !this.state.modalEditProfile,
      modalEditProfileImage: this.state.profileImageUrl,
    });
  };

  toggleEditPasswordModal = () => {
    this.setState({
      modalChangePassword: !this.state.modalChangePassword,
    });
  };

  handleEmailNotification = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Prepare post data to send init;
    let postData = {};
    let stateKey = {} as any;

    // set request data if clicked on compliance update;
    if (e.target.name === 'notification_personal_compliance_updates') {
      postData = {
        notification_type: e.target.name,
        notification_value: e.target.checked ? 1 : 0,
      };
      stateKey['emailPersonalNotificationCompliance'] = e.target.checked ? true : false;
    }

    // set request data if clicked on compliance update;
    if (e.target.name === 'notification_company_compliance_updates') {
      postData = {
        notification_type: e.target.name,
        notification_value: e.target.checked ? 1 : 0,
      };
      stateKey['emailNotificationCompliance'] = e.target.checked ? true : false;
    }

    // set request data if clicked on activity update;
    if (e.target.name === 'notification_company_activity_updates') {
      postData = {
        notification_type: e.target.name,
        notification_value: e.target.checked ? 1 : 0,
      };
      stateKey['emailNotificationActivity'] = e.target.checked ? true : false;
    }

    this.setState(stateKey);

    //sending a post request to the server
    axiosInstance
      .post('/user/update-notifications', postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          /*toast.success('Notification settings updated successfully.', {
            autoClose: 4000,
          });*/
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 4000,
          });
        }
      });
  };

  render() {
    const { user } = this.context;
    const activeAccount = LocalStorage.getActiveAccount();
    const isCorporateAccount = LocalStorage.isCurrentAccountCorporate();
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} sm={9} md={8} lg={7} className="col-centered">
              <div className="Profile--section Profile--section-sm">
                {!this.state.showLoader ? (
                  <div>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--25">
                      <h2 className="u-heading--medium u-heading--medium-sm">
                        My Profile Settings
                      </h2>
                    </div>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                      <h3 className="u-heading--xsmall u-heading--xsmall-sm">Profile</h3>
                    </div>
                    <div
                      className={`profile--block ${
                        isCorporateAccount ? `corporate` : `personal`
                      }`}
                    >
                      {isCorporateAccount ? (
                        <div className="profile--avatar__wrap">
                          <div className="profile--avatar size80">
                            <img
                              src={
                                this.state.profileImageUrl
                                  ? this.state.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="profile--avatar size80">
                          <img
                            src={
                              this.state.profileImageUrl
                                ? this.state.profileImageUrl
                                : ProfileAvatar
                            }
                            alt="Profile"
                          />
                        </div>
                      )}

                      <div className="profile--details">
                        <div className="table-responsive u-mb--30">
                          <Table>
                            <tbody>
                              <tr>
                                <td className="NameColoumn">First Name</td>
                                <td className="DetailColoumn">
                                  <strong>{user.firstName}</strong>
                                </td>
                              </tr>
                              <tr>
                                <td>Last Name</td>
                                <td>
                                  <strong>{user.lastName}</strong>
                                </td>
                              </tr>
                              {LocalStorage.hasCorporateAccount() && (
                                <tr>
                                  <td>Role</td>
                                  <td>
                                    <strong>{activeAccount.user_role}</strong>
                                  </td>
                                </tr>
                              )}
                              <tr>
                                <td colSpan={2} align={'right'}>
                                  <div className="ml-auto u-flexed u-align-center u-justify-end">
                                    <button
                                      type="button"
                                      onClick={this.toggleEditProfileModal}
                                      className="c-button c-button--primary c-button-radius c-button-inline c-button-sm u-upper"
                                    >
                                      EDIT PROFILE
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>

                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                      <h3 className="u-heading--xsmall u-heading--xsmall-sm">Password</h3>
                    </div>
                    <div className="table-responsive u-mb--30">
                      <Table>
                        <tbody>
                          <tr>
                            <td>Change the password for your account</td>
                            <td>
                              <div className="ml-auto u-flexed u-align-center u-justify-end">
                                <button
                                  type="button"
                                  onClick={this.toggleEditPasswordModal}
                                  className="c-button c-button--primary c-button-radius c-button-inline c-button-sm u-upper"
                                >
                                  CHANGE PASSWORD
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    {!LocalStorage.hasCorporateViewAccount() &&
                      !(
                        LocalStorage.hasCorporateAccount() &&
                        LocalStorage.hasManagerRole()
                      ) && (
                        <>
                          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                            <h3 className="u-heading--xsmall u-heading--xsmall-sm">
                              Email Notifcations
                            </h3>
                          </div>
                          <div className="table-responsive">
                            <Table className="emailtable">
                              <tbody>
                                <tr>
                                  <td>
                                    <div>
                                      Preferred email<br></br>
                                      <small className="u-mt--5 w320">
                                        All notifications and updates for compliance
                                        activity will be sent to this email address
                                      </small>
                                    </div>
                                  </td>
                                  <td align={'right'}>
                                    <strong>{user.email}</strong>
                                    <div className="ml-auto u-flexed u-align-center u-justify-end u-mt--15">
                                      <button
                                        type="button"
                                        onClick={this.toggleEditProfileModal}
                                        className="c-button c-button--primary c-button-radius c-button-inline c-button-sm u-upper"
                                      >
                                        edit address
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                                <tr>
                                  <td colSpan={2}>
                                    {/*!LocalStorage.hasCorporateAccount()*/ true && (
                                      <div className="button-set u-mb--20">
                                        <label className="start">
                                          <Input
                                            type="checkbox"
                                            name="notification_personal_compliance_updates"
                                            id="notification_personal_compliance_updates"
                                            onChange={this.handleEmailNotification}
                                            checked={
                                              this.state
                                                .emailPersonalNotificationCompliance
                                            }
                                          />
                                          <span className="eds-checked">
                                            <span className="check"></span>
                                          </span>{' '}
                                          Compliance Updates<br></br>
                                          <small className="grey_sh">
                                            When my documentation is expiring soon
                                          </small>
                                        </label>
                                      </div>
                                    )}
                                    {LocalStorage.hasCorporateAccount() && (
                                      <>
                                        <div className="button-set u-mb--20">
                                          <label className="start">
                                            <Input
                                              type="checkbox"
                                              name="notification_company_compliance_updates"
                                              id="notification_company_compliance_updates"
                                              onChange={this.handleEmailNotification}
                                              checked={
                                                this.state.emailNotificationCompliance
                                              }
                                            />
                                            <span className="eds-checked">
                                              <span className="check"></span>
                                            </span>{' '}
                                            Team Compliance Updates<br></br>
                                            <small className="grey_sh">
                                              When my team members documentation is
                                              expiring soon
                                            </small>
                                          </label>
                                        </div>
                                        {/*
                                      <div className="button-set">
                                        <label className="start">
                                          <Input
                                            type="checkbox"
                                            name="notification_company_activity_updates"
                                            id="notification_company_activity_updates"
                                            onChange={this.handleEmailNotification}
                                            checked={this.state.emailNotificationActivity}
                                          />
                                          <span className="eds-checked">
                                            <span className="check"></span>
                                          </span>{' '}
                                          Activity Updates<br></br>
                                          <small className="grey_sh">
                                            Team members accepting invites
                                          </small>
                                        </label>
                                      </div>
                                      */}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </>
                      )}
                  </div>
                ) : (
                  <ContentLoader type="instagram" />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <EditProfileModal
          isOpen={this.state.modalEditProfile}
          toggle={this.toggleEditProfileModal}
          updateProfileImg={this.updateProfileImg}
          profileImageUrl={this.state.modalEditProfileImage}
        />
        <EditPasswordModal
          isOpen={this.state.modalChangePassword}
          toggle={this.toggleEditPasswordModal}
        />
      </>
    );
  }
}

export default ProfileSettings;
