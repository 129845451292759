import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';

//Application Imports
import { accountType } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';

// Static Assets
import UploadGraphic from '../../../assets/images/upload.jpg';
import Backup from '@material-ui/icons/Backup';

interface Props {
  showPreviewItem?: boolean;
}

class BlankDocumentScreen extends React.PureComponent<Props, {}> {
  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    return (
      <Container fluid={false} className="u-pb--50 u-pb--50-sm">
        <Row>
          <Col xs={12} md={6} lg={5} className="col-centered">
            <div
              className={
                'upload--section upload--section-sm text-center ' +
                (activeAccount.account_type === accountType.corporate ? 'u-mt--0' : '')
              }
            >
              <div className="upload--graphic upload--graphic-sm u-mb--60 u-mb--60-sm">
                <img src={UploadGraphic} alt="Upload" className="img-fluid" />
              </div>
              <div className="page-head page-head-sm center column noborder">
                <h2 className="u-heading--xsmall u-heading--xsmall-sm">
                  Time to upload some documents!
                </h2>
                <p className="text-center fs--13">
                  You currently have no documents uploaded, to start <br></br>
                  uploading them click the button below
                </p>
              </div>
              {!this.props.showPreviewItem && (
                <Link
                  to="/document/add"
                  className="c-button c-button--primary c-button-radius c-button-inline size--xs has-icon--before"
                >
                  <Backup fontSize="small" className="icons u-mr--5" />
                  Upload
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BlankDocumentScreen;
