import React from 'react';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';

interface Props extends RouteComponentProps {
  children?: React.ReactElement;
  exact?: boolean;
  path?: string;
}

interface State {}

class Layout404 extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    let dataBg = 'purple';
    if (
      matchPath(this.props.match.path, { path: '/*' }) ||
      matchPath(this.props.match.path, { path: '/*' })
    ) {
      dataBg = 'white';
    }

    return (
      <>
        <div className="wrapper wrapper-full-page" data-bg={dataBg}>
          <div className="main-panel">{this.props.children}</div>
        </div>
      </>
    );
  }
}

export default withRouter(Layout404);
