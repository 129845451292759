import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Import CSS
import './sidebar.scss';

interface Props extends RouteComponentProps {}

interface State {}

class Sidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <aside className="owl--sidebar d-none d-md-flex d-lg-flex d-xl-flex">
          <header className="owl--sidebar__heading d-flex align-items-center">
            {/* Members{' '}
            <button
              onClick={() => this.props.history.push('/corporate/member')}
              type="button"
              className="c-button c-button--primary c-button--primary-bordered c-button-inline c-button-xs"
            >
              + Member
            </button> */}
          </header>

          <ul className="team--lists">
            <li
              className={`team--lists__items ${
                this.props.location.pathname === '/corporate/team/member'
                  ? `is--selected`
                  : ``
              }`}
            >
              <Link to="/corporate/team/member" color="primary">
                All Personnel
              </Link>
            </li>
          </ul>
        </aside>
      </>
    );
  }
}

export default withRouter(Sidebar);
