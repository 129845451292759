import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { RouteComponentProps, Link as RouteLink, withRouter } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';

//Application Imports
import appConfig from '../../../config';
import { linkedAs, accountType } from '../../../helpers/Constant';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import Preview from '../../../api/Preview';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import DocumentList from '../documents-upload/DocumentList';
import NoDataMsg from '../profile-preview/NoDataMsg';
import Helper from '../../../helpers/Helpers';
import BlankScreen from './BlankScreen';
import LocalStorage from '../../../helpers/LocalStorage';
import AppContext from '../../../components/AppContext';
import ShareProfileModal from '../../../layout/includes/Header/ShareProfileModal';
import DocumentPreviewLightbox from '../../common/documents-upload/DocumentPreviewLightbox';

// Import CSS
import './team-share-view.scss';
import './team-share.scss';
import './team-member-lists.scss';
import './team-member-landing.scss';

// Import Icons
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Edit from '@material-ui/icons/Edit';
import ScreenShareIcon from '@material-ui/icons/ScreenShare';
import Search from '@material-ui/icons/Search';

// Static Includes
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface RouteInfo {
  uuid: string;
  accountUuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  area?: string;
}

interface State {
  showLoader: boolean;
  projectManagersObj: Array<any>;
  employeeList: Array<any>;
  subcontractorList: Array<any>;
  documentHeadingList: Array<any>;
  employeeDocument: any;
  documentList: any;
  projectDetail: any;
  companyDetail: any;
  startDate: Date | null;
  loadErrorMessage: string | null;
  copied: boolean;
  showEditControl: boolean;
  prevPath: any;
  isEditState: boolean;
  isShareModalOpen: boolean;
  isLinkOpen: boolean;
  imageArray: Array<any>;
}

class ProjectPreview extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      projectManagersObj: [],
      employeeList: [],
      subcontractorList: [],
      documentHeadingList: [],
      employeeDocument: {},
      documentList: null,
      projectDetail: {},
      companyDetail: {},
      startDate: null,
      loadErrorMessage: null,
      copied: false,
      showEditControl: true,
      prevPath: '/corporate/project',
      isEditState: false,
      isShareModalOpen: false,
      imageArray: [],
      isLinkOpen: false,
    };
  }

  shareProfileModalHandler = () => {
    this.setState({
      isShareModalOpen: !this.state.isShareModalOpen,
    });
  };

  formatNumber = (phoneNumberString: any) => {
    var cleaned: any = ('' + phoneNumberString).replace(/\D/g, '');
    var match;

    match = cleaned.match(/^(1|)?(\d{4})(\d{3})(\d{3})$/);

    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '', match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
  };

  filterWorkerList = (dataArray: any, type: string) => {
    let dataList = [];

    if (type === linkedAs.employee && dataArray.length > 0) {
      dataList = dataArray.filter((data: any) => {
        return data.linkedAs === linkedAs.employee;
      });
    }

    if (type === linkedAs.subcontractor && dataArray.length > 0) {
      dataList = dataArray.filter((data: any) => {
        return data.linkedAs === linkedAs.subcontractor;
      });
    }
    return dataList;
  };

  getPreviewImageArray = (
    employeeAccountUuid: string,
    roleDocumentUuid: string,
    openLightBox: boolean
  ) => {
    let employeeDocument = this.state.employeeDocument[employeeAccountUuid];
    if (employeeDocument && employeeDocument[roleDocumentUuid] && employeeDocument[roleDocumentUuid].media.length > 0) {
      if (openLightBox) {
        this.documentPreviewHandler(employeeDocument[roleDocumentUuid].media);
        return;
      }
      return employeeDocument[roleDocumentUuid].media;
    }

    return null;
  };

  fetchProjectData = () => {
    const projectUuid = this.props.match.params.uuid;
    const accountUuid = this.props.match.params.accountUuid;
    const { activeAccountUUID } = this.context;

    Preview.getProject(projectUuid, accountUuid, activeAccountUUID).then(
      (response: any) => {
        if (response.data.success === 1) {
          let responseDate = response.data.data.project_detail.start_date;
          this.setState({
            showLoader: false,
            projectManagersObj: response.data.data.project_detail.managers,
            projectDetail: response.data.data.project_detail,
            companyDetail: response.data.data.company_detail,
            startDate: responseDate === null ? null : new Date(responseDate),
            employeeList: this.filterWorkerList(
              response.data.data.assigned_list,
              linkedAs.employee
            ),
            subcontractorList: this.filterWorkerList(
              response.data.data.assigned_list,
              linkedAs.subcontractor
            ),
            documentList: response.data.data.document_list,
            documentHeadingList: response.data.data.role_document_list,
            employeeDocument: response.data.data.account_documents,
          });
        } else {
          this.setState({
            showLoader: false,
            loadErrorMessage: response.data.messages[0],
          });
        }
      }
    );
  };

  getDocumentExpiry = (
    accountUuid: string,
    roleDocumentUuid: string,
    formatted: boolean = true
  ) => {
    const employeeDocuments = this.state.employeeDocument[accountUuid];
    if (employeeDocuments && employeeDocuments[roleDocumentUuid]) {
      return formatted
        ? Helper.getDocumentExpiryDate(
            employeeDocuments[roleDocumentUuid].document_expiry
          )
        : employeeDocuments[roleDocumentUuid].document_expiry;
    }
    return '-';
  };

  calculateExpiry = (
    accountUuid: string,
    roleDocumentUuid: string,
    getTdClassName: boolean,
    columnIndex: number
  ) => {
    const date = this.getDocumentExpiry(accountUuid, roleDocumentUuid, false);
    let className = columnIndex === 0 ? 'second-cell' : '';
    if (date === null || date === '-') {
      return getTdClassName ? className : '';
    }

    const docExpiryStatus = Helper.getDocumentExpiryStatus(date);
    const daysDiff = Helper.getDateDiff(date);

    if (docExpiryStatus === 'expired') {
      if (getTdClassName) {
        return 'column--expired';
      } else {
        return (
          <div className="ml-auto text--error u-flexed u-align-center">
            Expired
            <Close fontSize="small" className="icons text--error u-ml--5" />
          </div>
        );
      }
    } else if (docExpiryStatus === 'warning') {
      if (getTdClassName) {
        return 'column--warning';
      } else {
        return <small className="ml-auto text--warning">Due in {daysDiff} days</small>;
      }
    } else {
      if (getTdClassName) {
        return '';
      } else {
        return (
          <div className="ml-auto">
            <Done fontSize="small" className="icons text--green" />
          </div>
        );
      }
    }
  };

  getDocumentExpiryWithHtml = (
    accountUuid: string,
    roleDocumentUuid: string,
    getTdClassName: boolean,
    columnIndex: number
  ) => {
    const employeeDocuments = this.state.employeeDocument[accountUuid];
    let formattedCalculateDate: any;
    let simpleCalculateDate: any;
    if (employeeDocuments && employeeDocuments[roleDocumentUuid]) {
      formattedCalculateDate = Helper.getDocumentExpiryDate(
        employeeDocuments[roleDocumentUuid].document_expiry
      );

      simpleCalculateDate = employeeDocuments[roleDocumentUuid].document_expiry;
      const docExpiryStatus = Helper.getDocumentExpiryStatus(simpleCalculateDate);
      const daysDiff = Helper.getDateDiff(simpleCalculateDate);

      if (simpleCalculateDate === null || simpleCalculateDate === '-') {
        formattedCalculateDate = 'N/A';
      }

      if (docExpiryStatus === 'expired') {
        if (getTdClassName) {
          return 'column--expired';
        } else {
          return (
            <div className="u-flexed u-align-center u-bordered">
              <div className="date text--error">
                {formattedCalculateDate}
                <br></br>
                <span className="u-bold">Expired</span>
              </div>
              <div className="ml-auto">
                <Close fontSize="small" className="icons text--error u-ml--5" />
              </div>
            </div>
          );
        }
      } else if (docExpiryStatus === 'warning') {
        if (getTdClassName) {
          return 'column--warning';
        } else {
          return (
            <div className="u-flexed u-align-center u-bordered">
              <div className="date">
                {formattedCalculateDate}
                <br></br>
                <span className="u-bold text--warning">Due in {daysDiff} days</span>
              </div>
              <div className="ml-auto d-none">
                <Close fontSize="small" className="icons text--warning u-ml--5" />
              </div>
            </div>
          );
        }
      } else {
        if (getTdClassName) {
          return '';
        } else {
          return (
            <div className="u-flexed u-align-center">
              <div className="date">{formattedCalculateDate}</div>
              <div className="ml-auto">
                <Done fontSize="small" className="icons text--green" />
              </div>
            </div>
          );
        }
      }
    }

    let className = columnIndex === 0 ? 'second-cell' : '';
    if (simpleCalculateDate === null || simpleCalculateDate === '-') {
      return getTdClassName ? className : '';
    }

    return '-';
  };

  getTableRow = (currentIndex: number, employeeList: any) => {
    const finalIndex = 1 + currentIndex;
    if (finalIndex < employeeList.length) {
      return (
        <tr className="noStyle">
          <td colSpan={this.state.documentHeadingList.length + 1}></td>
        </tr>
      );
    }
  };

  shareLinkHandler = () => {
    this.setState({ copied: true });
    setTimeout(() => {
      this.setState({ copied: false });
    }, 5000);
  };

  documentPreviewHandler = (images?: Array<any>) => {
    this.setState({
      isLinkOpen: !this.state.isLinkOpen,
      imageArray: images ? images : [],
    });
  };

  getFormattedDate = (date: any) => {
    var day = date.getDate() + '';
    var month = date.getMonth() + 1 + '';
    var year = date.getFullYear() + '';

    day = this.checkZero(day);
    month = this.checkZero(month);
    year = this.checkZero(year);

    return day + '/' + month + '/' + year;
  };

  checkZero = (data: string) => {
    if (data.length === 1) {
      data = '0' + data;
    }
    return data;
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.match.params.accountUuid !== prevProps.match.params.accountUuid) {
      this.fetchProjectData();
    }
  }

  componentDidMount() {
    document.title = `Project Preview ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    this.fetchProjectData();

    // check the router state for prev path;
    const prevPath: any = this.props.location.state;
    if (prevPath) {
      if (prevPath.prevPath === '/corporate/invite/project') {
        this.setState({
          showEditControl: false,
        });
      }
      if (prevPath.prevPath === '/corporate/project') {
        this.setState({
          prevPath: prevPath.prevPath,
          isEditState: true,
        });
      }
      this.setState({
        prevPath: prevPath.prevPath,
      });
    }
  }

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    const { isLoggedIn } = this.context;
    const projectDetails = this.state.projectDetail;
    const isSubcontractorView = this.props.match.params.accountUuid === undefined ? 0 : 1;
    const { isEditState } = this.state;
    return (
      <>
        {this.state.showLoader ? (
          <div className="u-ml--20">
            <Loader />
          </div>
        ) : (
          <>
            {this.state.loadErrorMessage !== null ? (
              <BlankScreen message={this.state.loadErrorMessage} />
            ) : (
              <div className="myteams--section myteams--section-sm sideBarEnabled">
                <Container fluid={true}>
                  <div className="row flex-xl-nowrap">
                    <div className="owl--content fullwidth">
                      {isLoggedIn &&
                      activeAccount.account_type === accountType.corporate ? (
                        <header className="owl--content__heading d-flex align-items-center">
                          <div className="u-flexed u-align-center fs--15">
                            <Button
                              className="c-button--Back fs--16 text--black u-mr--20"
                              onClick={() =>
                                this.props.history.push({
                                  pathname: this.state.prevPath,
                                  state: { isEditState },
                                })
                              }
                            >
                              <i className="arrow left u-mr--10" /> Projects
                            </Button>
                          </div>
                          <div className="ml-auto u-flexed u-align-center d-none d-sm-flex">
                            {!LocalStorage.hasManagerRole() && (
                              <>
                                <RouteLink
                                  to={{
                                    pathname: `/corporate/project/edit/${projectDetails.uuid}#addMember`,
                                    state: { prevPath: this.props.location.pathname },
                                  }}
                                  color="secondary"
                                  className="c-button c-button--primary c-button-xs"
                                >
                                  + Member
                                </RouteLink>
                                {this.state.showEditControl && (
                                  <RouteLink
                                    to={{
                                      pathname: `/corporate/project/edit/${projectDetails.uuid}`,
                                      state: { prevPath: this.props.location.pathname },
                                    }}
                                    color="secondary"
                                    className="btn btn--search c-button"
                                  >
                                    <Edit fontSize="small" className="icons u-mr--5" />
                                    Edit
                                  </RouteLink>
                                )}
                              </>
                            )}
                            <div className="copied--block u-ml--10">
                              <CopyToClipboard
                                text={`${appConfig.BASE_URL}project/${projectDetails.uuid}`}
                                onCopy={this.shareProfileModalHandler}
                              >
                                <button
                                  color="secondary"
                                  type="button"
                                  className="btn btn--search c-button"
                                >
                                  <ScreenShareIcon
                                    fontSize="small"
                                    className="icons u-mr--5"
                                  />
                                  Share
                                </button>
                              </CopyToClipboard>
                              {this.state.copied ? (
                                <span className="clipboard">Link copied!</span>
                              ) : null}
                            </div>
                          </div>
                        </header>
                      ) : (
                        (this.props.match.path !== '/project/:uuid' ||
                          this.state.prevPath === '/links/project') && (
                          <header className="owl--content__heading d-flex align-items-center">
                            <div className="u-flexed u-align-center fs--15">
                              <Button
                                className="c-button--Back fs--16 text--black u-mr--20"
                                onClick={() => this.props.history.goBack()}
                              >
                                <i className="arrow left u-mr--10" /> Back
                              </Button>
                            </div>
                          </header>
                        )
                      )}
                      <header className="owl--content__heading d-flex align-items-center hAuto">
                        <div className="myprofile--details myprofile--details-sm u-mt--10 u-mb--0">
                          <div className="myprofile--details__inner myprofile--details__inner-sm">
                            <div className="myprofile--avatar myprofile--avatar-sm">
                              <img
                                src={
                                  this.state.companyDetail.logoImageUrl
                                    ? this.state.companyDetail.logoImageUrl
                                    : ProfileAvatar
                                }
                                width="55"
                                alt="Avatar"
                              />
                            </div>
                            <div className="myprofile--excerpt myprofile--excerpt-sm">
                              <h3 className="user--name user--name-sm">
                                {this.state.companyDetail.company_name}
                                {/**
                                <small>#{this.state.companyDetail.CRN_number}</small>
                                 */}
                              </h3>
                              <p className="fs--16 u-mb--0">
                                #{this.state.companyDetail.CRN_number}
                              </p>
                            </div>
                          </div>
                          {isLoggedIn && (
                            <div className="ml-auto">
                              <button
                                onClick={() =>
                                  this.props.history.push(
                                    `/account/${this.state.companyDetail.uuid}`
                                  )
                                }
                                className="c-button c-button--white c-button-xs"
                              >
                                View Profile
                              </button>
                            </div>
                          )}
                        </div>
                      </header>

                      <div className="board--helper u-flex-direction-column mobile-nowrap noBorder">
                        <div className="d-flex fullwidth">
                          <div className="member--details">
                            <h2 className="member--name member--name-sm">
                              {projectDetails.project_name}
                            </h2>
                            <p className="date">
                              Estimated start date:{' '}
                              <strong>
                                {this.state.startDate === null
                                  ? 'No Date'
                                  : this.getFormattedDate(this.state.startDate)}
                              </strong>
                            </p>
                          </div>
                        </div>
                        {!isSubcontractorView && (
                          <Container fluid={false} className="fullwidth noGutter">
                            {this.state.projectManagersObj.map((data: any) => (
                              <div className="row" key={data.uuid}>
                                <div className="col-12 col-lg-6 col-md-7 col-sm-12">
                                  <div className="member--block styled">
                                    <div className="u-flexed u-align-center">
                                      <div className="avatar--40">
                                        <img
                                          src={
                                            data.profileImageUrl !== null
                                              ? data.profileImageUrl
                                              : ProfileAvatar
                                          }
                                          width="40"
                                          alt="Profile Avatar"
                                        />
                                      </div>
                                      <div className="members--profile__details">
                                        <p className="members--name">{data.label}</p>
                                        <p className="members--position">
                                          Project Manager
                                        </p>
                                      </div>
                                    </div>
                                    <div className="ml-auto">
                                      <a
                                        href={
                                          data.contactNumber !== null
                                            ? 'tel: ' + data.contactNumber
                                            : '#'
                                        }
                                        className="links--primary"
                                      >
                                        {this.formatNumber(data.contactNumber)}
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </Container>
                        )}
                      </div>

                      <Container fluid={false} className="fullwidth noGutter padd20">
                        <div className="row">
                          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                            <div className="board--wrapper u-mb--50 u-mb--50-sm">
                              {this.state.employeeList.length > 0 && (
                                <div
                                  className="board--container styled"
                                  style={{
                                    width: 'calc(100vw - 220px)',
                                    maxWidth: 'calc(100vw - 220px)',
                                  }}
                                >
                                  <div
                                    className={`board--container__wrapper ${
                                      this.state.documentHeadingList.length > 6
                                        ? ``
                                        : `column--6`
                                    }`}
                                  >
                                    <table>
                                      <thead>
                                        <tr>
                                          <th className="fixed h110">
                                            Team Onsite ({this.state.employeeList.length})
                                          </th>
                                          {this.state.documentHeadingList.map(
                                            (data: any, key: number) => (
                                              <th
                                                className={`${
                                                  key === 0 ? `second-cell ` : `w170`
                                                }`}
                                                key={data.role_document_uuid}
                                              >
                                                {data.document_title}
                                              </th>
                                            )
                                          )}
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.state.employeeList.map(
                                          (employee: any, employeeKey: number) => (
                                            <React.Fragment key={employee.uuid}>
                                              <tr key={employee.uuid}>
                                                <th
                                                  className={`fixed ${GetAccountDocumentStatusClassName(
                                                    employee.accountDocumentStatus,
                                                    false
                                                  )}`}
                                                >
                                                  <div className="u-flexed u-align-center">
                                                    <div className="avatar--40">
                                                      <img
                                                        src={
                                                          employee.profileImageUrl !==
                                                          null
                                                            ? employee.profileImageUrl
                                                            : ProfileAvatar
                                                        }
                                                        width="40"
                                                        alt="Profile Avatar"
                                                      />
                                                    </div>
                                                    <div className="members--details">
                                                      <p className="members--name">
                                                        {employee.name}
                                                      </p>
                                                      <p className="members--position">
                                                        Employee
                                                      </p>
                                                    </div>

                                                    {/* <div className="column--controls">
                                                    <button
                                                      className="c-button c-button-xs"
                                                      id="ViewProfile"
                                                      onClick={() =>
                                                        this.props.history.push({
                                                          pathname: `/account/${employee.accountUuid}`,
                                                        })
                                                      }
                                                    >
                                                      <Visibility
                                                        fontSize="small"
                                                        className="icons"
                                                      />
                                                    </button>
                                                    <UncontrolledTooltip
                                                      placement="top"
                                                      target="ViewProfile"
                                                    >
                                                      View Profile
                                                    </UncontrolledTooltip>
                                                  </div> */}
                                                  </div>
                                                </th>

                                                {this.state.documentHeadingList.map(
                                                  (
                                                    documentHeadingData: any,
                                                    documentHeadingIndex: number
                                                  ) => (
                                                    <td
                                                      className={`${this.getDocumentExpiryWithHtml(
                                                        employee['accountUuid'],
                                                        documentHeadingData[
                                                          'role_document_uuid'
                                                        ],
                                                        true,
                                                        documentHeadingIndex
                                                      )}`}
                                                      key={documentHeadingIndex}
                                                    >
                                                      {this.getDocumentExpiryWithHtml(
                                                        employee['accountUuid'],
                                                        documentHeadingData[
                                                          'role_document_uuid'
                                                        ],
                                                        false,
                                                        documentHeadingIndex
                                                      )}
                                                      {this.getPreviewImageArray(
                                                        employee['accountUuid'],
                                                        documentHeadingData[
                                                          'role_document_uuid'
                                                        ],
                                                        false
                                                      ) && (
                                                        <div className="absolute--links">
                                                          <button
                                                            type="button"
                                                            className="button white md"
                                                            onClick={() =>
                                                              this.getPreviewImageArray(
                                                                employee['accountUuid'],
                                                                documentHeadingData[
                                                                  'role_document_uuid'
                                                                ],
                                                                true
                                                              )
                                                            }
                                                          >
                                                            <Search
                                                              fontSize="large"
                                                              className="icons"
                                                            />
                                                          </button>
                                                        </div>
                                                      )}
                                                    </td>
                                                  )
                                                )}
                                              </tr>
                                              {this.getTableRow(
                                                employeeKey,
                                                this.state.employeeList
                                              )}
                                            </React.Fragment>
                                          )
                                        )}

                                        <tr className="noStyle">
                                          <td
                                            colSpan={
                                              this.state.documentHeadingList.length + 1
                                            }
                                          ></td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              )}

                              <div className="board--container board--bottom fullwidth">
                                <Container fluid={false} className="fullwidth noGutter">
                                  {this.state.subcontractorList.length > 0 && (
                                    <Row className="u-mb--30">
                                      <Col xs={12} md={6} lg={6} className="">
                                        <h4 className="fs--15">Sub-contractors</h4>
                                        {this.state.subcontractorList.map(
                                          (data: any) =>
                                            data.linkedAs === linkedAs.subcontractor && (
                                              <div
                                                className={`section--subcordinator ${GetAccountDocumentStatusClassName(
                                                  data.accountDocumentStatus,
                                                  false
                                                )}`}
                                                key={data.uuid}
                                              >
                                                <div className="u-flexed u-align-center">
                                                  <div className="avatar--40">
                                                    <img
                                                      src={
                                                        data.profileImageUrl !== null
                                                          ? data.profileImageUrl
                                                          : ProfileAvatar
                                                      }
                                                      width="40"
                                                      alt="Profile Avatar"
                                                    />
                                                  </div>
                                                  <div className="members--details">
                                                    <p className="members--name">
                                                      {data.name}
                                                    </p>
                                                    <p className="members--position">
                                                      Sub contractor
                                                    </p>
                                                  </div>
                                                </div>
                                                <div className="ml-auto u-flexed u-align-center mobile-mt--10">
                                                  {data.documentsStats.allDocumentsOk ===
                                                    1 && (
                                                    <Done
                                                      fontSize="small"
                                                      className="icons text--green"
                                                    />
                                                  )}
                                                  <RouteLink
                                                    to={
                                                      this.props.area === 'corporateSide'
                                                        ? `/corporate/project-view/${projectDetails.uuid}/${data.accountUuid}`
                                                        : `/project/${projectDetails.uuid}/${data.accountUuid}`
                                                    }
                                                    className="btn--profile"
                                                  >
                                                    {data.accountType ===
                                                    accountType.soletrader
                                                      ? 'View Documents'
                                                      : 'View Members & Documents'}
                                                    <ChevronRightIcon
                                                      fontSize="large"
                                                      className="icons u-ml--5"
                                                    />
                                                  </RouteLink>
                                                </div>
                                              </div>
                                            )
                                        )}
                                      </Col>
                                    </Row>
                                  )}

                                  {this.state.documentList !== null ? (
                                    <div className="project-section-subContractor">
                                      <DocumentList
                                        documentList={this.state.documentList}
                                        updateListData={() => {}}
                                        showEditElements={false}
                                      />
                                    </div>
                                  ) : (
                                    <NoDataMsg />
                                  )}
                                </Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Container>
                    </div>
                  </div>
                </Container>
              </div>
            )}
            <ShareProfileModal
              isOpen={this.state.isShareModalOpen}
              toggle={this.shareProfileModalHandler}
              isProjectScreen={true}
              projectUrl={`${appConfig.BASE_URL}project/${projectDetails.uuid}`}
              projectUuid={projectDetails.uuid}
            />
            {this.state.isLinkOpen && (
              <DocumentPreviewLightbox
                imageArray={this.state.imageArray}
                linkOpenHandler={this.documentPreviewHandler}
              />
            )}
          </>
        )}
      </>
    );
  }
}

export default withRouter(ProjectPreview);
