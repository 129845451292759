import React from 'react';
import { Row, Col, Modal, ModalBody, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import scrollToComponent from 'react-scroll-to-component';

// Application imports
import LinkMember from '../../../api/LinkMember';
import AppContext from '../../../components/AppContext';
import SelectOptions from '../../../components/UI/SelectOptions';
import AlertError from '../../../layout/includes/Alert/Error';

// Import CSS
import './invite.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

interface Props {
  userAccountUuid: string;
  roleUuid: string;
  isOpen: boolean;
  toggle: Function;
  updateMemberListStatus: Function;
  rolesOption: any;
}

interface State {
  errorMessages: Array<string> | string;
  formSubmitted: boolean;
  selectRoleUuid: string;
}

class ChangeRoleModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessages: '',
      formSubmitted: false,
      selectRoleUuid: '',
    };
  }

  onChangeRole = (e: any) => {
    this.setState({
      selectRoleUuid: e.target.value,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;

    this.setState({
      errorMessages: '',
      formSubmitted: true,
    });

    // request to server;
    LinkMember.changeAccountRole(
      activeAccountUUID,
      this.state.selectRoleUuid,
      this.props.userAccountUuid
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.props.updateMemberListStatus(
            this.props.userAccountUuid,
            this.state.selectRoleUuid
          );
          this.props.toggle();
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--large modal--large-md"
        backdrop={true}
      >
        <ModalBody className="plr--60 plr--60-sm" style={{ maxHeight: 'inherit' }}>
          <button
            className="close"
            onClick={() => this.props.toggle()}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body--inner pt--30">
            <header className="modal-body--header modal-body--header-sm center">
              <h3 className="title">Change Role</h3>
            </header>
            <AvForm className="form-horizontal" onValidSubmit={this.submitHandler}>
              <AlertError
                ref={(div: any) => {
                  this.alertErrorRef = div;
                }}
                message={this.state.errorMessages}
              />
              {this.props.roleUuid && (
                <AvField
                  type="select"
                  name="role"
                  label="Role"
                  placeholder="Please select Role"
                  onChange={this.onChangeRole}
                  defaultValue={this.props.roleUuid}
                  required
                  errorMessage="Please select Role"
                >
                  <SelectOptions
                    data={this.props.rolesOption}
                    placeholder="Please select Role..."
                  />
                </AvField>
              )}

              <Row className="u-flexed u-align-center u-justify-center text-center">
                <Col xs={12} md={12} lg={12}>
                  <Button
                    type="submit"
                    disabled={this.state.formSubmitted}
                    className={`btn-radius btn btn-primary u-upper ${
                      this.state.formSubmitted ? `inlineloading` : ``
                    }`}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default ChangeRoleModal;
