import React from 'react';
import { toast } from 'react-toastify';

// Application Imports;
import Layout from './Layout';
import MemberBlankScreen from './MemberBlankScreen';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import TeamMember from '../../../api/TeamMember';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import TeamMemberList from './TeamMemberList';
import { linkedAs } from '../../../helpers/Constant';

// Import Css;
import './team-member-landing.scss';

interface Props {}

interface State {
  showLoader: boolean;
  showBlankScreen: boolean;
  documentHeadingList: Array<any>;
  employeeList: Array<any>;
  subcontractorList: Array<any>;
  accountList: Array<any>;
  userRoledocumentMapping: Array<any>;
  allAccounts: Array<any>;
  approvedMemberList: Array<any>;
  needApprovalMemberList: Array<any>; //Employee Documents which needs approval
  pendingMemberList: Array<any>; //Employees Awaiting Approval to connect
  expiringMemberList: Array<any>;
}

class TeamMemberLanding extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      showBlankScreen: true,
      allAccounts: [],
      employeeList: [],
      subcontractorList: [],
      accountList: [],
      userRoledocumentMapping: [],
      documentHeadingList: [],
      approvedMemberList: [],
      needApprovalMemberList: [],
      pendingMemberList: [],
      expiringMemberList: [],
    };
  }

  getAccountList = (accountIds: any, accountList: any) => {
    let accountListArray: any = [];

    const accountDataArray = Object.entries(accountList);
    for (let [key, value] of accountDataArray) {
      for (let i = 0; i < accountIds.length; i++) {
        if (accountIds[i] === key) {
          accountListArray.push(value);
        }
      }
    }
    return accountListArray;
  };

  filterAccountTypeData = (accountListArray: any, type: string) => {
    let filterdArray: any = [];
    const accountDataArray: any = Object.entries(accountListArray);

    for (let i = 0; i < accountDataArray.length; i++) {
      if (accountDataArray[i][1].account_data.linkedAs === type) {
        filterdArray.push(accountDataArray[i][1]);
      }
    }
    return filterdArray;
  };

  getDocumentListArray = (documentArray: any) => {
    let docHeadingArray: any = [];
    const docDataArray: any = Object.entries(documentArray);
    for (let [key, value] of docDataArray) {
      docHeadingArray.push({ uuid: key, title: value });
    }
    return docHeadingArray;
  };

  fetchMemberList = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    TeamMember.getTeamMembers(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        const checkBlankScreenShowStatus =
          response.data.data.okAccountsUuids.length > 0 ||
          response.data.data.alertAccountsUuids.length > 0 ||
          response.data.data.pendingAccountUuids.length > 0 ||
          response.data.data.pendingAccounts.length > 0
            ? false
            : true;
        this.setState({
          showLoader: false,
          showBlankScreen: checkBlankScreenShowStatus,
          approvedMemberList: this.getAccountList(
            response.data.data.okAccountsUuids,
            response.data.data.accounts
          ),
          needApprovalMemberList: this.getAccountList(
            response.data.data.pendingAccountUuids,
            response.data.data.accounts
          ),
          pendingMemberList: response.data.data.pendingAccounts,
          expiringMemberList: this.getAccountList(
            response.data.data.alertAccountsUuids,
            response.data.data.accounts
          ),
          documentHeadingList: this.getDocumentListArray(
            response.data.data.uniqueRoleDocuments
          ),
          userRoledocumentMapping: response.data.data.userRoleDocumentMapping,
          accountList: response.data.data.accounts,
          employeeList: this.filterAccountTypeData(
            response.data.data.accounts,
            linkedAs.employee
          ),
          subcontractorList: this.filterAccountTypeData(
            response.data.data.accounts,
            linkedAs.subcontractor
          ),
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Personnel ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch team member list;
    this.fetchMemberList();
  }

  render() {
    return (
      <>
        <Layout>
          <div className="owl--content">
            {this.state.showLoader ? (
              <div className="invite--section invite--section-sm text-center">
                <Loader />
              </div>
            ) : this.state.showBlankScreen ? (
              <MemberBlankScreen />
            ) : (
              <TeamMemberList
                employeeList={this.state.employeeList}
                subcontractorList={this.state.subcontractorList}
                userRoledocumentMapping={this.state.userRoledocumentMapping}
                accountList={this.state.accountList}
                documentHeadingList={this.state.documentHeadingList}
                approvedList={this.state.approvedMemberList}
                needApprovalList={this.state.needApprovalMemberList}
                pendingList={this.state.pendingMemberList}
                expiringList={this.state.expiringMemberList}
              />
            )}
          </div>
        </Layout>
      </>
    );
  }
}

export default TeamMemberLanding;
