import React from 'react';
import {
  RouteComponentProps,
  matchPath,
  withRouter,
  Link,
  NavLink,
} from 'react-router-dom';

// Import CSS
import './mobile-menu.scss';

import { NavItem } from 'reactstrap';
import Backup from '@material-ui/icons/Backup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScreenShare from '@material-ui/icons/ScreenShare';

//Application Imports
import AppContext from '../../../components/AppContext';
import { accountType } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';
import ShareProfileModal from '../Header/ShareProfileModal';

interface Props extends RouteComponentProps {}

interface State {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
  shareModalOpen: boolean;
}

class MobileMenu extends React.Component<Props, State> {
  static contextType = AppContext;
  private navsidebarToggle = React.createRef<HTMLButtonElement>();
  constructor(props: Props) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
      shareModalOpen: false,
    };
  }

  closeNavSidebar = () => {
    document.documentElement.classList.toggle('nav--open');
    let element: any = document.getElementById('buttonToggle');
    if (element) {
      element.classList.toggle('toggled');
    }
  };

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
  };
  logout = () => {
    const { logout } = this.context;
    logout();

    this.props.history.push('/');
  };

  openShareModal = () => {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
    document.documentElement.classList.remove('nav--open');
    let element: any = document.getElementById('buttonToggle');
    if (element) {
      element.classList.remove('toggled');
    }
  };

  render() {
    const activeAccount = LocalStorage.getActiveAccount();

    const { isLoggedIn } = this.context;
    return (
      <>
        <div className="widget__menuArea">
          <div className="widget__menuArea__content">
            <div className="widget__menuArea__content--body">
              <div className="widget__menuArea__content--body--scroll">
                {isLoggedIn ? (
                  <>
                    {activeAccount.account_type === accountType.corporateView ? (
                      <nav className="responsive--navigation d-none d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <ul className="responsive--navigation__list">
                          <NavItem
                            className={`${
                              this.props.location.pathname === '/corporate/search'
                                ? `is--active`
                                : ``
                            }`}
                          >
                            <NavLink
                              className="nav-link"
                              to="/corporate/search"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Search a Profile
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={`${
                              this.props.location.pathname ===
                              '/corporate_viewonly/profile'
                                ? `is--active`
                                : ``
                            }`}
                          >
                            <NavLink
                              className="nav-link"
                              to="/corporate_viewonly/profile"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Company Profile
                            </NavLink>
                          </NavItem>
                        </ul>
                      </nav>
                    ) : activeAccount.account_type === accountType.corporate ? (
                      <nav className="responsive--navigation d-none d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <ul className="responsive--navigation__list">
                          <NavItem
                            className={
                              matchPath(this.props.match.path, {
                                path: '/corporate/team/member',
                              })
                                ? `is--active`
                                : ``
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/corporate/team/member"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Personnel
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              this.props.location.pathname ===
                                '/corporate/invite/project' ||
                              this.props.location.pathname ===
                                '/corporate/favourite/project' ||
                              this.props.location.pathname === '/corporate/project' ||
                              matchPath(this.props.match.path, {
                                path: '/corporate/project-view/:uuid',
                              })
                                ? `is--active`
                                : ``
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/corporate/project"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Projects
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              this.props.location.pathname === '/company/profile'
                                ? 'is--active'
                                : this.props.location.pathname === '/documentation'
                                ? 'is--active'
                                : ''
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/company/profile"
                              onClick={() => this.closeNavSidebar()}
                            >
                              COMPANY PROFILE & DOCUMENTS
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              this.props.location.pathname === '/corporate/search/profile'
                                ? 'is--active'
                                : ''
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/corporate/search/profile"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Search A profile / subcontractor
                            </NavLink>
                          </NavItem>
                        </ul>
                      </nav>
                    ) : (
                      <nav className="responsive--navigation d-none d-block d-sm-block d-md-none d-lg-none d-xl-none">
                        <ul className="responsive--navigation__list">
                          <NavItem
                            className={
                              this.props.location.pathname === '/company/profile' ||
                              this.props.location.pathname === '/personal/profile'
                                ? 'is--active'
                                : ''
                            }
                          >
                            {activeAccount.account_type === accountType.soletrader ? (
                              <NavLink
                                className="nav-link"
                                to="/company/profile"
                                onClick={() => this.closeNavSidebar()}
                              >
                                Company Profile
                              </NavLink>
                            ) : (
                              <NavLink
                                className="nav-link"
                                to="/personal/profile"
                                onClick={() => this.closeNavSidebar()}
                              >
                                My Profile
                              </NavLink>
                            )}
                          </NavItem>
                          <NavItem
                            className={
                              this.props.location.pathname === '/documentation' ||
                              matchPath(this.props.match.path, {
                                path: '/document',
                              })
                                ? 'is--active'
                                : ''
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/documentation"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Documentation
                            </NavLink>
                          </NavItem>
                          <NavItem
                            className={
                              this.props.location.pathname === '/links'
                                ? 'is--active'
                                : ''
                            }
                          >
                            <NavLink
                              className="nav-link"
                              to="/links"
                              onClick={() => this.closeNavSidebar()}
                            >
                              Links
                            </NavLink>
                          </NavItem>
                        </ul>
                      </nav>
                    )}
                    <nav className="responsive--navigation">
                      <ul className="responsive--navigation__list">
                        {activeAccount.account_type !== accountType.corporate &&
                        activeAccount.account_type !== accountType.corporateView ? (
                          <NavItem className="mobile--nav-item">
                            <Link
                              to="/document/add"
                              className="nav-link"
                              onClick={() => this.closeNavSidebar()}
                            >
                              <Backup fontSize="small" className="icons u-mr--5"></Backup>
                              Upload
                            </Link>
                            <Link
                              to="/profile/preview"
                              className="nav-link"
                              onClick={() => this.closeNavSidebar()}
                            >
                              <VisibilityIcon
                                fontSize="small"
                                className="icons u-mr--5"
                              ></VisibilityIcon>
                              Preview
                            </Link>
                            <button
                              type="button"
                              className="nav-link fullwidth"
                              onClick={this.openShareModal}
                            >
                              <ScreenShare
                                fontSize="small"
                                className="icons u-mr--5"
                              ></ScreenShare>
                              Share
                            </button>
                            <ShareProfileModal
                              isOpen={this.state.shareModalOpen}
                              toggle={this.openShareModal}
                            />
                          </NavItem>
                        ) : (
                          ''
                        )}
                      </ul>
                    </nav>
                  </>
                ) : (
                  <div className="c-header__additional--info c-header__additional--info-sm">
                    <div>
                      {matchPath(this.props.match.path, { path: '/signup' }) ||
                      matchPath(this.props.match.path, {
                        path: '/auth/forgot-password',
                      }) ? (
                        <>
                          <span className="d-none d-sm-inline-flex u-mr--5">
                            Already a customer?
                          </span>
                          <Link to="/auth/login">Sign in &gt;</Link>
                        </>
                      ) : (
                        <>
                          <span className="d-none d-sm-inline-flex u-mr--5">
                            Create an account?
                          </span>
                          <a href="/#Pricing" rel="noopener noreferrer">
                            Sign up &gt;
                          </a>
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(MobileMenu);
