import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { toast } from 'react-toastify';

// Application Import
import axiosInstance from '../../utils/AppAxios';

// Import CSS
import '../auth/login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {
  email?: string;
  accountType?: string;
  hideMailContent?: boolean;
}

interface State {
  formSubmitted: boolean;
}

class SignupThankMsg extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      formSubmitted: false,
    };
  }
  sendConfirmationMail = () => {
    this.setState({
      formSubmitted: true,
    });
    // preparing data to send;
    const data = {
      email: this.props.email,
    };

    //sending a resend mail request to the server
    axiosInstance
      .post('/signup/send-welcome-email', data)
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          toast.success('Mail has been sent successfully.', {
            autoClose: 4000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 4000,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };
  render() {
    return (
      <Card className="card card-sm">
        <div className="card--header card--header-sm u-mb--10">
          <h2 className="card--title card--title-sm">
            We have sent you a unique Customer Reference Number
          </h2>
        </div>
        <div className="card--body card--body-sm">
          <div className="u-flexed u-flex-direction-column u-align-center u-justify-center text-center">
            <p className="fs--15 dark-gray u-mb--20">
              We have {this.props.hideMailContent ? 'sent message' : 'emailed'} your
              unique CRN to{' '}
              <a href={`mailto:${this.props.email}`} className="links--primary">
                {this.props.email}
              </a>
              . You can use this number to sign in or to send to others to find you.
            </p>

            {this.props.hideMailContent ? (
              ''
            ) : (
              <p className="fs--15 dark-gray u-mb--20">
                Didn’t receive an email?{' '}
                <button
                  type="button"
                  onClick={this.sendConfirmationMail}
                  className="links--primary"
                >
                  Resend
                </button>
              </p>
            )}
            <Link
              to="/auth/login"
              className={`c-button c-button--primary c-button-w210 c-button-radius ${
                this.state.formSubmitted ? `inlineloading` : ``
              }`}
            >
              GO TO LOGIN
            </Link>
          </div>
        </div>
      </Card>
    );
  }
}

export default withRouter(SignupThankMsg);
