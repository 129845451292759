import React from 'react';
import {
  Row,
  Col,
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import ReactFilestack from 'filestack-react';
import { toast } from 'react-toastify';

//Application Imports
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import { accountType } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';
import AlertError from '../../../layout/includes/Alert/Error';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './profile-settings.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

// Static Assets
import Backup from '@material-ui/icons/Backup';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
// import ProfileCorporateAvatar from '../../../assets/images/No-Corporate-Image.jpg';

interface Props {
  isOpen: boolean;
  toggle: Function;
  updateProfileImg: Function;
  profileImageUrl: string;
}

interface FileStackProps {
  onPick: () => void;
}

interface State {
  imageUrl: string;
  mediaDetails: object;
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
}

class EditProfileModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      mediaDetails: {},
      formSubmitted: false,
      alertErrorMessage: '',
      imageUrl: '',
    };
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.state.imageUrl === '' && this.props.profileImageUrl !== '') {
      this.setState({
        imageUrl: this.props.profileImageUrl,
      });
    }
  }

  getImageURL = (urlArray: any) => {
    if (urlArray.filesUploaded.length > 0) {
      this.setState({
        imageUrl: LocalStorage.getFilestackSecureUrl(urlArray.filesUploaded[0].url),
        mediaDetails: urlArray.filesUploaded[0],
      });
    }
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { updateUser } = this.context;
    //Set loader to true;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    // Prepare the JSON data for request to server;
    const profileDataUpdate = {
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      media_details: JSON.stringify(this.state.mediaDetails),
    };

    //sending a post request to the server
    axiosInstance
      .post('/user/edit-profile', profileDataUpdate)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          updateUser(values);

          // update the value on profile page;
          if (this.state.imageUrl !== '') {
            this.props.updateProfileImg(this.state.imageUrl);
          }

          this.props.toggle();

          toast.success('Your profile is updated successfully.', {
            autoClose: 2000,
          });
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  render() {
    const { user } = this.context;
    const filestackLSObject = LocalStorage.getFilestackObject();
    return (
      <Modal
        id="UserEditProfile"
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md"
        backdrop={true}
      >
        <ModalHeader className="center u-border--btm0" toggle={() => this.props.toggle()}>
          Profile
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm">
          <AlertError message={this.state.alertErrorMessage} />
          <AvForm
            className="form-horizontal u-mt--0"
            onValidSubmit={this.submitHandler}
            autoComplete="off"
          >
            <AvField
              name="firstName"
              id="firstName"
              label="First Name"
              required
              errorMessage="Please First name"
              value={user.firstName}
            />
            <AvField
              name="lastName"
              id="lastName"
              label="Last Name"
              required
              errorMessage="Please Last name"
              value={user.lastName}
            />
            <AvGroup>
              <Label htmlFor="email">Email Address (this is your login email)</Label>
              <span className="fullwidth fs--14">
                All notifications and updates for compliance activity will be sent to this
                email address
              </span>
              <AvInput name="email" id="email" value={user.email} required />
              <AvFeedback>Please enter a valid email address</AvFeedback>
            </AvGroup>

            {user.accountType === accountType.corporate && (
              <div className="table-responsive">
                <Table className="emailtable">
                  <tbody>
                    <tr>
                      <td>Role</td>
                      <td className="last-child">
                        <strong>TO BE DONE</strong>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            )}

            <div className="profile--upload profile--upload-md u-pt--0 u-mb--30">
              <div className="profile--thumbnail profile--thumbnail-md">
                <img
                  src={this.state.imageUrl ? this.state.imageUrl : ProfileAvatar}
                  width="150"
                  alt="Profile Avatar"
                />
              </div>
              <div className="profile--controls profile--controls-md">
                <p>Profile photo (optional)</p>
                <ReactFilestack
                  apikey={appConfig.FILESTACK_API_KEY}
                  actionOptions={{
                    accept: 'image/*',
                  }}
                  clientOptions={{
                    security: {
                      policy: filestackLSObject.policy,
                      signature: filestackLSObject.signature,
                    },
                  }}
                  customRender={({ onPick }: FileStackProps) => (
                    <button
                      type="button"
                      className="btn-upload u-flexed u-align-center"
                      onClick={onPick}
                    >
                      <Backup fontSize="large" className="icons u-mr--5"></Backup> Upload
                      file
                    </button>
                  )}
                  onSuccess={(res: any) => this.getImageURL(res)}
                />
              </div>
            </div>

            <Row className="row u-mt--25 u-mb--15">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="u-flexed u-align-center u-justify-center"
              >
                <Button
                  id="submit"
                  type="submit"
                  disabled={this.state.formSubmitted ? true : false}
                  className={`c-button c-button--primary c-button-w210 c-button-radius ${
                    this.state.formSubmitted ? `inlineloading` : ``
                  }`}
                >
                  Save changes
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    );
  }
}

export default EditProfileModal;
