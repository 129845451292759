import React from 'react';

import { Table, Modal, ModalHeader, ModalBody } from 'reactstrap';

interface Props {
  isOpen: boolean;
  toggle: Function;
}

interface State {}

class FeeBreakupModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        backdrop={true}
        id="FeeBreakupModal"
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md pricing--modal"
      >
        <ModalHeader
          className="start plr--30 plr--30-sm u-border--btm0 u-pt--25"
          toggle={() => this.props.toggle()}
        >
          Fee Breakup
        </ModalHeader>
        <ModalBody className="plr--30 plr--30-sm" style={{ maxHeight: 'inherit' }}>
          <div className="modal-body--inner pt--30 u-mb--0">
            <div className="table-responsive u-mb--0">
              <Table className="table-bordered u-mb--0">
                <thead>
                  <tr>
                    <th className="EmployeeColumn">Number of employees</th>
                    <th className="AFeeColumn">Annual Fee</th>
                    <th className="MFeeColumn">Monthly Fee</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>1-5</td>
                    <td>$150.00</td>
                    <td>$12.50</td>
                  </tr>
                  <tr>
                    <td>6-10</td>
                    <td>$800.00</td>
                    <td>$67.00</td>
                  </tr>
                  <tr>
                    <td>11-20</td>
                    <td>$2,000.00</td>
                    <td>$167.00</td>
                  </tr>
                  <tr>
                    <td>21-40</td>
                    <td>$3,000.00</td>
                    <td>$250.00</td>
                  </tr>
                  <tr>
                    <td>41-60</td>
                    <td>$6,000.00</td>
                    <td>$500.00</td>
                  </tr>
                  <tr>
                    <td>61-100</td>
                    <td>$10,000.00</td>
                    <td>$834.00</td>
                  </tr>
                  <tr>
                    <td>101-150</td>
                    <td>$15,000.00</td>
                    <td>$1,250.00</td>
                  </tr>
                  <tr>
                    <td>151-200</td>
                    <td>$20,000.00</td>
                    <td>$1,667.00</td>
                  </tr>
                  <tr>
                    <td>201+</td>
                    <td colSpan={2}>
                      <a
                        href="mailto:hello@owlcds.com.au"
                        className="button
                          button--primary button-xs
                          button-inlineflex"
                      >
                        Get Quote
                      </a>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default FeeBreakupModal;
