import React, { MouseEvent } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import {
  ListGroup,
  ListGroupItem,
  Button,
  Dropdown,
  DropdownToggle,
  Input,
} from 'reactstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports
import AppContext from '../../../components/AppContext';
import ProfileDropdown from './ProfileDropDownMenu/ProfileDropdownmenu';
import ViewLinkedDocumentModal from '../../common/account-links/ViewLinkedDocumentModal';
import LinkMember from '../../../api/LinkMember';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import RejectDocumentModal from '../../common/account-links/RejectDocumentModal';

// Import Icons
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Search from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';

// Import CSS
import './member-list.scss';
import './teams.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets;
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  memberList: Array<any>;
  filterMemberList: Function;
  resetMemberFilter: Function;
  pendingMemberList: Array<any>;
  roles: Array<any>;
  toggle: Function;
  updateMemberListStatus: Function;
  updatePendingMemberList: Function;
  reloadData: Function;
  deleteFromMemberList: Function;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

interface State {
  dropdownOpen: Array<boolean>;
  filterValue: string;
  viewDocumentModalOpen: boolean;
  openRejectDocumentModal: boolean;
  previewDocumentList: Array<any>;
  viewDocumentModalArea: string;
  rejectUserAccountDocumentUuid: string;
}

class MemberList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  typingTimeout: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.typingTimeout = setTimeout(() => '', 1000);
    this.state = {
      dropdownOpen: [],
      filterValue: '',
      viewDocumentModalOpen: false,
      openRejectDocumentModal: false,
      previewDocumentList: [],
      viewDocumentModalArea: 'corporatePendingMemberView',
      rejectUserAccountDocumentUuid: '',
    };
  }

  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  confirmDelete = (userAccountUuid: string, e: React.MouseEvent<HTMLButtonElement>) => {
    const elementButton = e.currentTarget;

    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this linked member account?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteLinkedAccount(elementButton, userAccountUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  deleteLinkedAccount = (elementButton: HTMLButtonElement, userAccountUuid: string) => {
    const { activeAccountUUID } = this.context;

    elementButton.classList.toggle('inlineloading');

    // request to server;
    LinkMember.deleteUnlinkedAccount(activeAccountUUID, userAccountUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.deleteFromMemberList(userAccountUuid);
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
        }
      })
      .then(() => {
        elementButton.classList.toggle('inlineloading');
      });
  };

  confirmLinkUnlink = (
    userAccountUuid: string,
    unlink: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const elementButton = e.currentTarget;
    if (unlink) {
      confirmAlert({
        title: 'Confirm',
        message: 'Do you really want to unlink this member account?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              this.changeAccountStatus(elementButton, userAccountUuid, unlink),
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      this.changeAccountStatus(elementButton, userAccountUuid, unlink);
    }
  };

  changeAccountStatus = (
    elementButton: HTMLButtonElement,
    userAccountUuid: string,
    unlink: boolean
  ) => {
    const { activeAccountUUID } = this.context;

    elementButton.classList.add('inlineloading');
    elementButton.innerText = '';

    // request to server;
    LinkMember.updateAccountStatus(activeAccountUUID, userAccountUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.updateMemberListStatus(userAccountUuid);
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
          elementButton.classList.remove('inlineloading');
          elementButton.innerText = unlink ? 'Unlink Member' : 'Link Member';
        }
      })
      .catch((err) => {
        elementButton.classList.remove('inlineloading');
        elementButton.innerText = unlink ? 'Unlink Member' : 'Link Member';
      });
  };

  approvalInviteConfirm = (
    e: MouseEvent<HTMLButtonElement>,
    pendingInviteUuid: string
  ) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to approve the member?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.approveInvite(elementButton, pendingInviteUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  approveInvite = (elementButton: HTMLButtonElement, pendingInviteUuid: string) => {
    const { activeAccountUUID } = this.context;
    elementButton.classList.toggle('inlineloading');
    const prevButtonText = elementButton.innerText;
    elementButton.innerText = '';

    // request to server;
    LinkMember.approveCorporatePendingInvite(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.reloadData();
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  cancelInviteConfirm = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to cancel invitation to this member?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.cancelPendingInvitation(elementButton, pendingInviteUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  cancelPendingInvitation = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    elementButton.classList.toggle('inlineloading');
    const prevButtonText = elementButton.innerText;
    elementButton.innerText = '';

    // request to server;
    LinkMember.cancelPendingInvite(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.updatePendingMemberList(pendingInviteUuid);
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  resendInvitation = (
    e: React.MouseEvent<HTMLButtonElement>,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;

    let elementButton = e.currentTarget;
    elementButton.innerText = 'Processing..';

    // request to server;
    LinkMember.resendInvitation(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        elementButton.innerText = 'Resend';
        if (response.data.success === 1) {
          toast.success('Invitation sent successfully.', {
            autoClose: 2000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        elementButton.innerText = 'Resend';
      });
  };

  onChangeFilterValue = (e: any) => {
    this.typingTimeout = setTimeout(this.filterMemberListFunc, 500);

    this.setState({
      filterValue: e.target.value,
    });
  };

  filterMemberListFunc = () => {
    this.props.filterMemberList(this.state.filterValue);
  };

  previewDocumentModal = (
    userAccountUuid: string,
    getSubmittedData?: boolean,
    fetchData?: boolean
  ) => {
    const { activeAccountUUID } = this.context;
    if (!this.state.viewDocumentModalOpen) {
      // button loading state;
      let buttonElement: any = document.getElementById(`preview_${userAccountUuid}`);
      if (buttonElement) {
        buttonElement.classList.toggle('inlineloading');
      }
      if (fetchData) {
        if (getSubmittedData) {
          // request to server;
          LinkMember.getLinkedMemberSubmittedDocuments(activeAccountUUID, userAccountUuid)
            .then((response) => {
              if (response.data.success === 1) {
                this.setState({
                  previewDocumentList: response.data.data.documents,
                  viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
                  viewDocumentModalArea: 'corporateLinkedMemberView',
                });
              } else {
                toast.error(response.data.messages[0], {
                  autoClose: 3000,
                });
              }
            })
            .then(() => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            });
        } else {
          // request to server;
          LinkMember.getAwaitingPendingInviteSubmittedDocuments(
            activeAccountUUID,
            userAccountUuid
          )
            .then((response) => {
              if (response.data.success === 1) {
                this.setState({
                  previewDocumentList: response.data.data.documents,
                  viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
                  viewDocumentModalArea: 'corporatePendingMemberView',
                });
              } else {
                toast.error(response.data.messages[0], {
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            })
            .then(() => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            });
        }
      } else {
        this.props.reloadData();
      }
    } else {
      this.setState({
        viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
      });
    }
  };

  rejectMemberSubmittedDocument = (
    closeAllModal: boolean = false,
    userAccountDocumentUuid: string = ''
  ) => {
    this.setState({
      openRejectDocumentModal: !this.state.openRejectDocumentModal,
      rejectUserAccountDocumentUuid: userAccountDocumentUuid,
    });
  };

  componentDidMount() {
    let currentLocation = window.location.href;
    const hasCommentAnchor = currentLocation.includes('#');
    if (hasCommentAnchor) {
      const anchorCommentId = `${currentLocation.substring(
        currentLocation.indexOf('#') + 1
      )}`;
      const anchorComment = document.getElementById(anchorCommentId);
      if (anchorComment) {
        anchorComment.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  render() {
    return (
      <>
        <div className="myteams--section myteams--section-sm">
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
            <h2 className="u-heading--medium u-heading--medium-sm">Linked Personnel</h2>
          </div>
          <ul className="admin--stats admin--stats-sm">
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">{this.props.activeMemberCount}</div>
              <div className="desc desc-sm">Linked Active Personnel</div>
            </li>
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">
                {this.props.pendingMemberList.length}
              </div>
              <div className="desc desc-sm">Pending Personnel Invites</div>
            </li>
          </ul>

          <div className="portlet">
            <div className="portlet--body">
              <div className="portlet--body__wrapper">
                <div className="portlet--search size--300">
                  <Input
                    name="filterValue"
                    id="filterValue"
                    className="form--fields"
                    placeholder="Search"
                    onChange={this.onChangeFilterValue}
                  />
                  <button className="portlet--search__btn" type="button">
                    <Search
                      fontSize="large"
                      className="icons"
                      onClick={this.filterMemberListFunc}
                    />
                  </button>
                </div>
                <div className="ml-auto">
                  <Button
                    type="button"
                    onClick={() => this.props.toggle()}
                    className="btn-radius btn-w160 btn btn-primary"
                  >
                    + Invite Employee
                  </Button>
                </div>
              </div>

              <div className="profile--links">
                <ListGroup>
                  <ListGroupItem className="head">
                    <div className="NameColumn">Name</div>
                    <div className="RoleColumn">Role</div>
                  </ListGroupItem>
                  {this.props.activeMemberCount > 0 ? (
                    this.props.memberList.map(
                      (data: any, key: number) =>
                        data.status === 1 && (
                          <ListGroupItem
                            key={data.uuid}
                            className={GetAccountDocumentStatusClassName(
                              data.accountDocumentStatus
                            )}
                          >
                            <div className="NameColumn">
                              <div className="avatar--40">
                                <img
                                  src={
                                    data.profileImageUrl
                                      ? data.profileImageUrl
                                      : ProfileAvatar
                                  }
                                  alt="Profile"
                                />
                              </div>
                              <div className="list-group--details">
                                <p className="user--name">{data.name}</p>
                                <p className="user--email">{data.email}</p>
                              </div>
                            </div>
                            <div className="RoleColumn">
                              <>{data.role}</>
                            </div>
                            <div className="ActivityColumn">
                              <Link
                                to={{
                                  pathname: `/corporate/member-projects/${data.accountUuid}`,
                                  state: { userName: data.name },
                                }}
                              >
                                <span className="badge badge-primary text-dark u-ml--5">
                                  {data.totalAssignedProjects
                                    ? data.totalAssignedProjects
                                    : 0}{' '}
                                  Project
                                </span>
                              </Link>
                              <div className="ml-auto">
                                <div className="deny u-flexed u-align-center">
                                  <Dropdown
                                    className="members--dropdown"
                                    isOpen={this.state.dropdownOpen[key]}
                                    toggle={() => this.toggleDropdown(key)}
                                  >
                                    <DropdownToggle color="primary">
                                      <MoreHoriz
                                        fontSize="large"
                                        className="icons"
                                        style={{ margin: '0' }}
                                      />
                                    </DropdownToggle>
                                    <ProfileDropdown
                                      accountData={data}
                                      accountUuid={data.accountUuid}
                                      userAccountUuid={data.uuid}
                                      roleUuid={data.roleUuid}
                                      changeAccountStatus={this.confirmLinkUnlink}
                                      updateMemberListStatus={
                                        this.props.updateMemberListStatus
                                      }
                                      roles={this.props.roles}
                                      documentModalHandler={this.previewDocumentModal}
                                    />
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        )
                    )
                  ) : (
                    <ListGroupItem>
                      <p>No active linked members found</p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </div>

          {this.props.unlinkedMemberCount > 0 && (
            <div className="profile--links u-mt--35">
              <h3 className="title">Link History</h3>
              <ListGroup>
                {this.props.memberList.map(
                  (data: any) =>
                    data.status === 0 && (
                      <ListGroupItem className="head" key={data.uuid}>
                        <div className="NameColumn">
                          <div className="avatar--40">
                            <img
                              src={
                                data.profileImageUrl
                                  ? data.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="list-group--details">
                            <p className="user--name">{data.name}</p>
                            <p className="user--email">{data.email}</p>
                          </div>
                        </div>
                        <div className="RoleColumn">
                          <>{data.role}</>
                        </div>
                        <div className="ActivityColumn">
                          <div className="ml-auto">
                            <div className="deny u-flexed u-align-center">
                              <button
                                type="button"
                                color="link"
                                id={`preview_${data.uuid}`}
                                className={`c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90`}
                                onClick={() =>
                                  this.previewDocumentModal(data.uuid, true, true)
                                }
                              >
                                <Visibility fontSize="small" className="icons u-mr--5" />
                                View
                              </button>

                              <button
                                type="button"
                                onClick={(e) =>
                                  this.confirmLinkUnlink(data.uuid, false, e)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Re-Link
                              </button>

                              <button
                                type="button"
                                onClick={(e) => this.confirmDelete(data.uuid, e)}
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    )
                )}
              </ListGroup>
            </div>
          )}
          {this.props.pendingMemberList.length > 0 && (
            <div className="profile--links u-mt--35" id="pendingInvites">
              <h3 className="title">Pending Invites</h3>
              <ListGroup>
                {this.props.pendingMemberList.map((data: any) => (
                  <ListGroupItem className="head" key={data.uuid}>
                    <div className="NameColumn">
                      <div className="avatar--40">
                        <img
                          src={
                            data.profileImageUrl ? data.profileImageUrl : ProfileAvatar
                          }
                          alt="Profile"
                        />
                      </div>
                      <div className="list-group--details">
                        <p className="user--name">{data.name}</p>
                        <p className="user--email">{data.email}</p>
                      </div>
                    </div>
                    <div className="RoleColumn">
                      <>{data.role}</>
                    </div>
                    <div className="ActivityColumn">
                      <div className="ml-auto">
                        <div className="deny u-flexed u-align-center">
                          {data.status === 0 && (
                            <>
                              <button
                                type="button"
                                id={`${data.uuid}resend`}
                                onClick={(e) => this.resendInvitation(e, data.uuid)}
                                className="links--primary fs--15 u-mr--10"
                              >
                                Resend
                              </button>

                              <button
                                type="button"
                                onClick={(e) => this.cancelInviteConfirm(e, data.uuid)}
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Cancel Invite
                              </button>
                            </>
                          )}
                          {data.status === 1 && (
                            <>
                              <button
                                type="button"
                                color="link"
                                id={`preview_${data.uuid}`}
                                className={`c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90`}
                                onClick={() =>
                                  this.previewDocumentModal(data.uuid, false, true)
                                }
                              >
                                <Visibility fontSize="small" className="icons u-mr--5" />
                                View
                              </button>
                              <button
                                type="button"
                                onClick={(e) => this.approvalInviteConfirm(e, data.uuid)}
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Approve
                              </button>
                              <button
                                onClick={(e) =>
                                  this.rejectMemberSubmittedDocument(false, data.uuid)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Reject
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          )}
        </div>
        <ViewLinkedDocumentModal
          area={this.state.viewDocumentModalArea}
          isOpen={this.state.viewDocumentModalOpen}
          toggle={this.previewDocumentModal}
          documentList={this.state.previewDocumentList}
          getAccountDocumentTypeOptions={() => {}}
          userAccountUuid=""
        />
        <RejectDocumentModal
          area="pendingReject"
          userAccountDocumentUuid={this.state.rejectUserAccountDocumentUuid}
          isOpen={this.state.openRejectDocumentModal}
          toggle={this.rejectMemberSubmittedDocument}
        />
      </>
    );
  }
}

export default withRouter(MemberList);
