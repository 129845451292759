import Api from './Api';

export default class Team extends Api {
  static getTeamMembers = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/team/get-team-members-summary', postData);
  };

  static getMemberDocuments = async (
    accountUuid: string,
    memberAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      account_uuid: memberAccountUuid,
    };

    return await Api.postRequest('/team/get-member-documents', postData);
  };
}
