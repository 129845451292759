import React from 'react';
import { toast } from 'react-toastify';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import appConfig from '../../../config';
import DocumentList from '../documents-upload/DocumentList';
import BlankDocumentScreen from '../documents-upload/BlankDocumentScreen';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AccountDetail from './AccountDetail';
import NoDataMsg from './NoDataMsg';
import LocalStorage from '../../../helpers/LocalStorage';
import { accountType } from '../../../helpers/Constant';
import Breadcrumb from './Breadcrumb';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

import VisibilityIcon from '@material-ui/icons/Visibility';

// Import CSS
import './profile-preview.scss';

interface Props extends RouteComponentProps {}

interface State {
  showLoader: boolean;
  documentList: any;
  accountData: any;
  documentListShow: boolean;
}

class ProfilePreview extends React.Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      documentList: {},
      accountData: {},
      documentListShow: false,
    };
  }

  fetchDocuments = () => {
    const { activeAccountUUID } = this.context;

    // prepare uuid for the request;
    const postData = {
      uuid: activeAccountUUID,
      search_account_uuid: activeAccountUUID,
    };

    // send request to server and get data from server;
    axiosInstance.post('/account/preview', postData).then((response: AxiosResponse) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          documentList:
            response.data.data.document_list !== null
              ? response.data.data.document_list
              : {},
          documentListShow: response.data.data.document_list !== null ? true : false,
          accountData: response.data.data.account,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Preview ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // Fetch the user/account document;
    this.fetchDocuments();
  }

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    return (
      <>
        {activeAccount.account_type === accountType.corporate && <Breadcrumb />}
        <div className={`u-pb--50 u-pb--50-sm`}>
          {activeAccount.account_type !== accountType.corporate && (
            <div className="preview--mode preview--mode-sm">
              <VisibilityIcon fontSize="small" className="icons u-mr--5" />
              Preview Mode
            </div>
          )}

          {!this.state.showLoader ? (
            <>
              {this.state.accountData !== null ? (
                <>
                  <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
                    <AccountDetail accountData={this.state.accountData} />
                  </Container>
                  {this.state.documentListShow ? (
                    <DocumentList
                      documentList={this.state.documentList}
                      updateListData={() => {}}
                      showEditElements={false}
                    />
                  ) : (
                    <NoDataMsg />
                  )}
                </>
              ) : (
                <BlankDocumentScreen showPreviewItem={false} />
              )}
            </>
          ) : (
            <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
              <Row>
                <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
                  <Loader />
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(ProfilePreview);
