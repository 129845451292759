const dev = {
  BASE_URL: 'http://appdev.owlcds.com.au:3000/',
  apiGateway: {
    BASE_URL: 'http://apidev.owlcds.com.au/v1',
  },
  ENABLE_SENTRY: false,
};

const prod = {
  BASE_URL: 'https://www.owlcds.com.au/',
  apiGateway: {
    BASE_URL: 'https://api.owlcds.com.au/v1',
  },
  ENABLE_SENTRY: true,
};

const uat = {
  BASE_URL: 'https://uat.owlcds.com.au/',
  apiGateway: {
    BASE_URL: 'https://api-uat.owlcds.com.au/v1',
  },
  ENABLE_SENTRY: true,
};

const config =
  process.env.REACT_APP_STAGE === 'production'
    ? prod
    : process.env.REACT_APP_STAGE === 'uat'
    ? uat
    : dev;

export default {
  // Add common config values here
  REACT_APP_STAGE: process.env.REACT_APP_STAGE.trim(),
  MAX_ATTACHMENT_SIZE: 5000000,
  APPLICATION_NAME: 'OWLCDS',
  DOCUMENT_TITLE_POSTFIX: ' | OWLCDS',
  LOCAL_STORAGE_NAME: 'AppLocalState',
  PRIVACY_POLICY_URL:
    process.env.REACT_APP_STAGE === 'production'
      ? 'https://www.owlcds.com.au/privacy.pdf'
      : process.env.REACT_APP_STAGE === 'uat'
      ? 'https://uat.owlcds.com.au/privacy.pdf'
      : 'http://appdev.owlcds.com.au:3000/privacy.pdf',
  TERMS_CONDITIONS_URL:
    process.env.REACT_APP_STAGE === 'production'
      ? 'https://www.owlcds.com.au/terms.pdf'
      : process.env.REACT_APP_STAGE === 'uat'
      ? 'https://uat.owlcds.com.au/terms.pdf'
      : 'http://appdev.owlcds.com.au:3000/terms.pdf',
  TERMS_OF_USE_URL:
    process.env.REACT_APP_STAGE === 'production'
      ? 'https://www.owlcds.com.au/terms_of_use.pdf'
      : process.env.REACT_APP_STAGE === 'uat'
      ? 'https://uat.owlcds.com.au/terms_of_use.pdf'
      : 'http://appdev.owlcds.com.au:3000/terms_of_use.pdf',
  WEBSITE_DISCLAIMER_URL:
    process.env.REACT_APP_STAGE === 'production'
      ? 'https://www.owlcds.com.au/website_disclaimer.pdf'
      : process.env.REACT_APP_STAGE === 'uat'
      ? 'https://uat.owlcds.com.au/website_disclaimer.pdf'
      : 'http://appdev.owlcds.com.au:3000/website_disclaimer.pdf',
  FILESTACK_API_KEY: process.env.REACT_APP_FILESTACK_KEY,
  CRYPTR_SECRET_KEY: process.env.REACT_APP_CRYPTR_SECRET,
  GA_ID: process.env.REACT_APP_TRACKING_ID || '',
  MAINTENANCE_MODE: process.env.REACT_APP_MAINTENANCE_MODE || false,
  INTERCOM_APP_ID: process.env.REACT_APP_INTERCOM_APP_ID,
  SENTRY_DSN: 'https://5a03b07211af43c78e9dfc7e789348c6@o420030.ingest.sentry.io/5337507',
  ...config,
};
