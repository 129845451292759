import React from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';

// Static Assets
import PersonalPricing from '../../../assets/images/PersonalPricing.svg';
import SoleTraderPricing from '../../../assets/images/SoleTraderPricing.svg';
import CorperatePricing from '../../../assets/images/CorperatePricing.svg';
import ViewProfile from '../../../assets/images/ViewProfile.svg';

// Import Icons
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import Visibility from '@material-ui/icons/Visibility';

import { Container, Row, Col, Table } from 'reactstrap';
import { UncontrolledTooltip } from 'reactstrap';

// Import Modals
import FeeBreakupModal from '../../common/fee-breakup/FeeBreakupModal';

interface SectionPricingProps {}
interface SectionPricingState {
  isOpen: boolean;
  handleToggle: boolean;
  FeeBreakupModal: boolean;
}

class SectionPricing extends React.Component<SectionPricingProps, SectionPricingState> {
  constructor(props: SectionPricingProps) {
    super(props);
    this.state = {
      isOpen: false,
      handleToggle: false,
      FeeBreakupModal: false,
    };
  }
  toggle = () => {
    if (this.state.isOpen) {
      this.setState({});
    } else {
      this.setState({});
    }
  };
  toggleFeeBreakupModal = () => {
    this.setState({
      FeeBreakupModal: !this.state.FeeBreakupModal,
    });
  };

  render() {
    return (
      <>
        <section
          className="section--pricing section--pricing-sm"
          data-bg={'purple2'}
          id="Pricing"
        >
          <div className="price--table">
            <Container fluid={false}>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <ReactWOW animation="fadeInLeft" delay="300ms">
                    <div className="table-responsive">
                      <Table>
                        <thead>
                          <tr>
                            <th className="">
                              <div className="">
                                <div className="pricing--head__content u-noPadd">
                                  <div className="price--head">
                                    <h2 className="u-heading__exlarge u-heading__exlarge-sm">
                                      <strong>Plans &amp; Pricing</strong>
                                    </h2>
                                    <p className="fs--18 fs--18-sm">
                                      Choose the plan that works for you and start your
                                      free trial today.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="w260">
                              <div className="">
                                <div className="page-head page-head-sm noborder start h110">
                                  <picture className="g150 h110">
                                    <img
                                      src={PersonalPricing}
                                      alt="Personal"
                                      className="img-fluid"
                                    />
                                  </picture>
                                </div>

                                <div className="pricing--head__content">
                                  <div className="price--head">
                                    <h3>Personal</h3>
                                    <p>Manage your own compliance</p>
                                  </div>

                                  <div className="price--tag">
                                    <span className="price">
                                      <span className="sign">$</span>
                                      <span className="currency fs--60">11</span>
                                    </span>
                                    <span className="month">Annually</span>
                                  </div>
                                  <div className="price--btn">
                                    <Link
                                      to="/signup/personal"
                                      className="button
                                        button--white
                                        button-inlineflex"
                                    >
                                      Get Started
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="w260">
                              <div className="">
                                <div className="page-head page-head-sm noborder start h110">
                                  <picture className="g150 h110">
                                    <img
                                      src={SoleTraderPricing}
                                      alt="Personal"
                                      className="img-fluid"
                                    />
                                  </picture>
                                </div>
                                <div className="pricing--head__content">
                                  <div className="price--head">
                                    <h3>Sole Trader</h3>
                                    <p>Manage your business' compliance</p>
                                  </div>

                                  <div className="price--tag">
                                    <span className="price">
                                      <span className="sign">$</span>
                                      <span className="currency fs--60">50</span>
                                    </span>
                                    <span className="month">Annually</span>
                                  </div>
                                  <div className="price--btn">
                                    <Link
                                      to="/signup/soletrader"
                                      className="button
                                        button--white
                                        button-inlineflex"
                                    >
                                      Get Started
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </th>
                            <th className="w260">
                              <div className="">
                                <div className="page-head page-head-sm noborder start h110">
                                  <picture className="g150 h110">
                                    <img
                                      src={CorperatePricing}
                                      alt="Sole Trader"
                                      className="img-fluid"
                                    />
                                  </picture>
                                </div>
                                <div className="pricing--head__content">
                                  <div className="price--head">
                                    <h3>Corporate</h3>
                                    <p>
                                      Manage your employees' &amp; subcontractors'
                                      compliance
                                    </p>
                                  </div>

                                  <div className="price--tag u-flexed">
                                    <div className="team--size">
                                      <span className="fs--10">Team size</span>
                                      <button
                                        onClick={this.toggleFeeBreakupModal}
                                        className="team--count"
                                        id="UncontrolledTooltipExample"
                                      >
                                        <Visibility fontSize="small" className="icons" />
                                      </button>
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="UncontrolledTooltipExample"
                                      >
                                        Fee Breakup
                                      </UncontrolledTooltip>
                                    </div>
                                    <div className="price--corporate">
                                      <span className="price">
                                        <span
                                          className="currency fs--40"
                                          onClick={this.toggleFeeBreakupModal}
                                        >
                                          Price
                                        </span>
                                      </span>
                                      <span className="month">Guide</span>
                                    </div>
                                  </div>
                                  <div className="price--btn">
                                    <Link
                                      to="/signup/corporate"
                                      className="button
                                        button--white
                                        button-inlineflex"
                                    >
                                      Get Started
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Highly secure
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      <strong>Organised Work Life</strong> has taken all
                                      the necessary precautions to ensure all data is
                                      completely safe and secure, including storing all
                                      data onshore in Australia.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Unlimited data storage
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Store as many certificates, licenses, and compliance
                                      documents as you want or need.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Expiry date alerts
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      <strong>Get reminders sent</strong> straight to your
                                      inbox 60, 30 and 14 days before document expiry
                                      dates.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Summary profile
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Create your personalised individual, business, or
                                      team <strong>Summary Profile</strong>
                                      to share with others. Use the privacy toggles to
                                      choose what you do and don’t want to share on your
                                      profile.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Share profile
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Share your <strong>Summary Profile</strong>
                                      with others, for example, potential employers,
                                      current employers, regulators, or clients, so they
                                      can see your certificates, licences and other
                                      compliance documents as needed.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                View profiles
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      View the profiles of suppliers, employees, teams,
                                      and individuals that are shared with you.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Activity Log
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Every single transaction made on your account,
                                      including viewing your profile, is tracked and
                                      recorded in your Activity Log; this ensures the
                                      platform has a high level of self-governance.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Directory listing
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <h4>For Sole Traders &amp; Companies </h4>
                                    <p className="title">
                                      Your business profile can be listed in our
                                      Compliance Business Directory. The Directory is
                                      available to the general public so they can find and
                                      hire businesses who have the certifications,
                                      licences, or other compliance requirements they want
                                      or need.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>Business Profile</td>
                            <td>Company Profile</td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Create Roles
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <h4>For Corporates</h4>
                                    <p className="title">
                                      Customise your business portal to suit your
                                      organisational structure. You can:
                                    </p>
                                    <ol>
                                      <li>
                                        Create and name roles/positions Select the
                                        compliance requirements for each role/position,
                                        for example,
                                        <ul>
                                          <li>Education</li>
                                          <li>Security checks</li>
                                          <li>Licence</li>
                                          <li>Registrations</li>
                                          <li>Other</li>
                                        </ul>
                                      </li>
                                      <li>
                                        Allocate permissions to managerial or supervisory
                                        roles.
                                      </li>
                                    </ol>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Link Employees and subcontractors
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Invite employees and subcontractors to link their
                                      documents to meet role requirements.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>

                          <tr>
                            <td>
                              <div className="u-flexed u-align-center">
                                Create Project/Teams
                                <div className="popover--wrapper">
                                  <button className="u-ml--5 button--help">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 20 20"
                                    >
                                      <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                                    </svg>
                                  </button>
                                  <div className="popover__content">
                                    <div className="triangle-with-shadow"></div>
                                    <p className="title">
                                      Create teams by department, site, or location. Add
                                      permissions to allow managers to monitor their staff
                                      only.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Close fontSize="small" className="icons" />
                            </td>
                            <td>
                              <Done fontSize="small" className="icons" />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </ReactWOW>

                  <ReactWOW animation="fadeInRight" delay="400ms">
                    <div className="price--cta price--cta-sm">
                      <picture className="g105">
                        <img
                          src={ViewProfile}
                          alt="View profile only"
                          className="img-fluid"
                        />
                      </picture>
                      <div className="price--cta--details">
                        <h5>
                          View profile only <small>THIS PLAN IS FREE</small>
                        </h5>
                        <p>
                          Can only view personal, company and team profiles if provided
                          with their customer reference number.
                        </p>
                      </div>
                      <div className="ml-auto text-center">
                        {/* <p className="fs--14 text-black u-mb--5">
                          <strong>Limited to 50 views</strong>
                        </p> */}
                        <button
                          type="button"
                          className="button button--primary button-inlineflex button-w190"
                          onClick={() =>
                            (window.location.href = '/signup/corporate_viewonly')
                          }
                        >
                          GET STARTED
                        </button>
                      </div>
                    </div>
                  </ReactWOW>
                  {/**
                  <ReactWOW animation="fadeInLeft" delay="500ms">
                    <div className="price--cta price--cta-sm">
                      <picture className="g105">
                        <img src={Suppliers} alt="Suppliers" className="img-fluid" />
                      </picture>
                      <div className="price--cta--details">
                        <h5>
                          Suppliers <small>THIS PLAN IS FREE</small>
                        </h5>
                        <p>Upload completed training certifcatons and assign to users.</p>
                      </div>
                      <div className="ml-auto text-center">
                        <button
                          type="button"
                          className="button button--primary button-inlineflex button-w190"
                        >
                          GET STARTED
                        </button>
                      </div>
                    </div>
                  </ReactWOW>
                  */}
                </Col>
              </Row>
            </Container>
          </div>
        </section>
        <FeeBreakupModal
          isOpen={this.state.FeeBreakupModal}
          toggle={this.toggleFeeBreakupModal}
        />
      </>
    );
  }
}

export default SectionPricing;
