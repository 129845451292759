import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Static Sections
import SectionHome from './SectionHome';
import SectionFeatures from './SectionFeatures';
import SectionCompliance from './SectionCompliance';
import SectionCorporate from './SectionCorporate';
import SectionHowItWorks from './SectionHowItWorks';
import SectionPerfectFit from './SectionPerfectFit';
import SectionPricing from './SectionPricing';
import SectionTry4Free from './SectionTry4Free';

interface Props extends RouteComponentProps {}

class Home extends React.Component<Props, {}> {
  componentDidMount() {
    document.title =
      "Don't get caught out with compliance | Compliance management platform";
  }

  render() {
    return (
      <>
        <div className="main-page">
          {/* Home Section */}
          <SectionHome />

          {/* Features Section */}
          <SectionFeatures />

          {/* Compliance Section */}
          <SectionCompliance />

          {/* Corporate Section */}
          <SectionCorporate />

          {/* How It Works Section */}
          <SectionHowItWorks />

          {/* Perfect Fit Section */}
          <SectionPerfectFit />

          {/* Pricing Section */}
          <SectionPricing />

          {/* Try4Free Section */}
          <SectionTry4Free />
        </div>
      </>
    );
  }
}

export default Home;
