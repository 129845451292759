import React from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import scrollToComponent from 'react-scroll-to-component';

//Application Imports
import appConfig from '../../../config';
import AddCompanyPayment from './AddCompanyPayment';
import AppContext from '../../../components/AppContext';
import SignupTerms from '../../signup/SignupTerms';
import AuthPageWrapper from '../../common/AuthPageWrapper';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';
import AlertError from '../../../layout/includes/Alert/Error';

// Import CSS
import '../../auth/login.scss';
import './addcompany.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {}

interface State {
  formSubmitted: boolean;
  showCreditCard: boolean;
  alertErrorMessage: Array<string> | string;
  email: string;
  showThankDivMsg: boolean;
}

class AddCompany extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.alertErrorRef = React.createRef();
    this.state = {
      formSubmitted: false,
      showCreditCard: false,
      alertErrorMessage: '',
      email: '',
      showThankDivMsg: false,
    };
  }

  updateMsgDivState = () => {
    this.setState({
      showThankDivMsg: true,
      showCreditCard: false,
    });
  };

  prevStep = () => {
    this.setState({
      showThankDivMsg: false,
      showCreditCard: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    // loader set to true and error message set to blank;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    //preparing post data
    const createCompanyData = {
      company_name: values.company_name,
      email: values.email,
      company_abnacn: values.company_abnacn,
    };

    //setting the email for thank you screen
    this.setState({ email: values.email });

    //storing data for step 2
    localStorage.setItem('newCompanyData', JSON.stringify(createCompanyData));

    //sending a post request to the server
    axiosInstance
      .post('/user/validate-add-company', createCompanyData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          this.setState({
            showCreditCard: true,
          });

          //setting the email for thank you screen
          this.setState({ email: values.email });

          //storing data for step 2
          localStorage.setItem('newCompanyData', JSON.stringify(createCompanyData));
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });

          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  componentDidMount() {
    document.title = `New Company ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    let localSignupData = localStorage.getItem('newCompanyData');
    let getData;
    if (localSignupData !== null) {
      getData = JSON.parse(localSignupData);
    }

    const model = { ...getData };
    const { user } = this.context;
    return (
      <AuthPageWrapper>
        {!this.state.showCreditCard && !this.state.showThankDivMsg && (
          <Card className="card card-sm u-mt--20">
            <div className="card--header card--header-sm u-mb--0 u-pb--0">
              <h2 className="card--title card--title-sm">Add a company</h2>
            </div>
            <div className="card--body card--body-sm">
              <AlertError
                ref={(div: any) => {
                  this.alertErrorRef = div;
                }}
                message={this.state.alertErrorMessage}
              />
              <div className="choose--plan choose--plan-sm text-center">
                <div className="choose--plan__inner choose--plan__inner-sm">
                  <span className="active--plan">$55 - Annually</span>{' '}
                </div>
              </div>

              <AvForm
                className="form-horizontal"
                autoComplete="off"
                onValidSubmit={this.submitHandler}
                model={model}
              >
                <AvField
                  name="company_name"
                  id="company_name"
                  label="Company name"
                  required
                  errorMessage="Please enter Company name"
                />

                <AvField
                  name="email"
                  type="email"
                  label="Work email"
                  required
                  errorMessage="Please enter a valid Email address"
                  validate={{
                    email: {
                      value: true,
                      errorMessage: 'Please enter a valid Email address',
                    },
                  }}
                />
                <AvField
                  name="company_abnacn"
                  label="ABN"
                  required
                  minLength={9}
                  maxLength={15}
                  validate={{
                    pattern: {
                      value: '^[0-9\\s]+$',
                      errorMessage: 'You can enter only numbers',
                    },
                  }}
                  errorMessage="Please enter a valid ABN"
                />

                <Row className="u-mt--20 u-mb--20">
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className="u-flexed u-align-center u-justify-center"
                  >
                    <Button
                      color="primary"
                      disabled={this.state.formSubmitted ? true : false}
                      className={`btn-radius ${
                        this.state.formSubmitted ? `inlineloading` : ``
                      }`}
                      id="submit"
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>

                <SignupTerms />
              </AvForm>
            </div>
          </Card>
        )}
        {this.state.showCreditCard && (
          <AddCompanyPayment
            accountType={user.accountType}
            updateMsgDivState={this.updateMsgDivState}
            prevStep={this.prevStep}
          />
        )}
        {this.state.showThankDivMsg && (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--10">
              <h2 className="card--title card--title-sm">
                We have sent you a unique Customer CRN
              </h2>
            </div>
            <div className="card--body card--body-sm">
              <div className="u-flexed u-flex-direction-column u-align-center u-justify-center text-center">
                <p className="fs--15 dark-gray u-mb--20">
                  We have emailed your unique Customer CRN to{' '}
                  <a href={`mailto:${this.state.email}`} className="links--primary">
                    {this.state.email}
                  </a>
                  . You can use send this number others to find you.
                </p>
              </div>
            </div>
          </Card>
        )}
      </AuthPageWrapper>
    );
  }
}

export default AddCompany;
