import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import appConfig from '../../../config';
import DocumentList from '../documents-upload/DocumentList';
import BlankScreen from './BlankScreen';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AccountDetail from '../profile-preview/AccountDetail';
import NoDataMsg from '../profile-preview/NoDataMsg';
import Breadcrumb from './Breadcrumb';
import Preview from '../../../api/Preview';

// Import Axios instance;
import { AxiosResponse } from 'axios';

// Import CSS
import '../profile-preview/profile-preview.scss';

interface RouteInfo {
  accountUuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {}

interface State {
  showLoader: boolean;
  documentList: any;
  accountData: any;
  documentListShow: boolean;
  message: string;
}

class AccountPreview extends React.Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      documentList: {},
      accountData: {},
      documentListShow: false,
      message: '',
    };
  }

  fetchAccountDetail = () => {
    const { activeAccountUUID } = this.context;
    let accountUuid = this.props.match.params.accountUuid;
    // send request to server and get data from server;
    Preview.getAccountDetail(activeAccountUUID, accountUuid).then(
      (response: AxiosResponse) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            documentList:
              response.data.data.document_list !== null
                ? response.data.data.document_list
                : {},
            documentListShow: response.data.data.document_list !== null ? true : false,
            accountData: response.data.data.account,
          });
        } else {
          this.setState({
            showLoader: false,
            accountData: null,
            message: response.data.messages[0],
          });
        }
      }
    );
  };

  componentDidMount() {
    document.title = `Preview ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // Fetch the user/account document;
    this.fetchAccountDetail();
  }

  render() {
    const { isLoggedIn } = this.context;
    return (
      <>
        {isLoggedIn && <Breadcrumb area="accountPreview" />}

        <div className="u-pb--50 u-pb--50-sm">
          {!this.state.showLoader ? (
            <>
              {this.state.accountData !== null ? (
                <div className="account-preview-public">
                  <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
                    <AccountDetail accountData={this.state.accountData} />
                  </Container>
                  {this.state.documentListShow ? (
                    <DocumentList
                      documentList={this.state.documentList}
                      updateListData={() => {}}
                      showEditElements={false}
                    />
                  ) : (
                    <NoDataMsg />
                  )}
                </div>
              ) : (
                <BlankScreen message={this.state.message} />
              )}
            </>
          ) : (
            <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
              <Row>
                <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
                  <Loader />
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(AccountPreview);
