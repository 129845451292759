import React from 'react';
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

// Import Includes
import Header from './includes/Header/Header';
import MobileMenu from './includes/Menu/MobileMenu';

interface Props extends RouteComponentProps {}

class Layout extends React.PureComponent<Props, {}> {
  render() {
    let dataBg = 'white';
    if (
      matchPath(this.props.match.path, { path: '/auth' }) ||
      matchPath(this.props.match.path, { path: '/signup' }) ||
      matchPath(this.props.match.path, { path: '/', exact: true })
    ) {
      dataBg = 'purple';
    }
    if (
      matchPath(this.props.match.path, { path: '/corporate' }) ||
      matchPath(this.props.match.path, { path: '/profile/settings' })
    ) {
      dataBg = 'grey';
    }
    return (
      <div>
        <div className="wrapper wrapper-full-page" data-bg={dataBg}>
          <div className="MobileSidebar">
            <MobileMenu {...this.props} />
          </div>
          <div className="main-panel">
            <Header {...this.props} />
            {this.props.children}
          </div>
        </div>
        <div className="loading" style={{ display: 'none' }}>
          <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1" />
            <div className="sk-cube sk-cube2" />
            <div className="sk-cube sk-cube3" />
            <div className="sk-cube sk-cube4" />
            <div className="sk-cube sk-cube5" />
            <div className="sk-cube sk-cube6" />
            <div className="sk-cube sk-cube7" />
            <div className="sk-cube sk-cube8" />
            <div className="sk-cube sk-cube9" />
          </div>
        </div>
        <ToastContainer />
      </div>
    );
  }
}

export default withRouter(Layout);
