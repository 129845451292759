import React from 'react';
import { Table, Label, Input } from 'reactstrap';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import AppContext from '../../../components/AppContext';
import { toast } from 'react-toastify';

// Import Api's functions;
import Roles from '../../../api/Roles';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';

// Import CSS
import './role.scss';
import './role-add.scss';

// Import Icons;
import CheckBox from '@material-ui/icons/CheckBox';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

interface Props extends RouteComponentProps {
  roleList: any;
}

interface State {}

class RoleList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  activeToggler = (id: string) => {
    const { activeAccountUUID } = this.context;

    Roles.updateActiveStatus(activeAccountUUID, id)
      .then((response) => {
        if (response.data.success === 1) {
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 2000,
          });
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div className="myteams--section myteams--section-sm editrole">
        <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
          <h2 className="u-heading--medium u-heading--medium-sm">Roles</h2>
          <div className="page-head--actions">
            <Link
              to="/corporate/role/add"
              className="c-button c-button-primary c-button-inlineflex c-button-radius c-button-sm c-button-sm-h35 u-upper"
            >
              + Create new role
            </Link>
          </div>
        </div>
        <div className="Role--Table">
          <div className="Role--Table--Left">
            <Table className="table-bordered">
              <thead>
                <tr>
                  <th className="ActiveColumn">
                    <span>Active</span>
                  </th>
                  <th className="RoleColumn">
                    <span>Role</span>
                  </th>
                  <th className="AppliedColumn text-center">
                    <span>Applied</span>
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
          <div className="Role--Table--Right noScroll">
            <Table className="table-bordered">
              <thead>
                <tr>
                  <th className="RequiredColumn">
                    {' '}
                    <span>Required Documents</span>
                  </th>
                </tr>
              </thead>
            </Table>
          </div>
        </div>
        <div className="Role--Table last">
          <div className="Role--Table--Left">
            <Table className="">
              <tbody>
                {this.props.roleList.length > 0
                  ? this.props.roleList.map((data: any) => (
                      <tr key={data.uuid}>
                        <td className="ActiveColumn">
                          <Label check className="custom-toggle--label">
                            <div className="custom-toggle">
                              <Input
                                type="checkbox"
                                defaultChecked={data.is_active}
                                onChange={() => this.activeToggler(data.uuid)}
                              />
                              <span className="custom-toggle-slider" />
                            </div>
                          </Label>
                        </td>

                        <td className="RoleColumn">
                          <Link to={`/corporate/role/edit/${data.uuid}`}>
                            <div className="fs--15">{data.title}</div>
                            <div className="fs--13 grey">
                              {data.industry}, {data.position}
                            </div>
                          </Link>
                        </td>

                        <td className="AppliedColumn text-center">{data.applied}</td>
                      </tr>
                    ))
                  : ''}
              </tbody>
            </Table>
          </div>
          <div className="Role--Table--Right scrollable">
            <div className="table-responsive">
              <Table className="">
                <tbody>
                  <tr>
                    <td className="RequiredColumn no-padd">
                      <table>
                        <tbody>
                          {this.props.roleList.length > 0
                            ? this.props.roleList.map((data: any) => (
                                <tr key={data.uuid}>
                                  {data.documents.length > 0 ? (
                                    data.documents.map((docData: any) => (
                                      <td
                                        className={`h120 ${GetAccountDocumentStatusClassName(
                                          docData.accountDocumentStatus,
                                          true
                                        )}`}
                                        key={docData.uuid}
                                      >
                                        <div className="details">
                                          <div className="text">{docData.title}</div>
                                          <div className="u-pt--5 u-flexed u-align-center">
                                            {docData.is_optional ? (
                                              <CheckBox
                                                fontSize="large"
                                                className="icons text--primary"
                                              />
                                            ) : (
                                              ''
                                            )}
                                          </div>
                                        </div>
                                      </td>
                                    ))
                                  ) : (
                                    <td className="h120">
                                      <div className="details">
                                        <MoreHorizIcon
                                          fontSize="small"
                                          className="icons"
                                        />
                                      </div>
                                    </td>
                                  )}
                                </tr>
                              ))
                            : ''}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(RoleList);
