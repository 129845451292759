import Api from './Api';

export default class User extends Api {
  static getDetails = async (screen: string): Promise<any> => {
    const postData = {
      view: screen,
    };

    return await Api.postRequest('/user/get-user-details', postData);
  };

  static updatePersonalProfile = async (postData: any): Promise<any> => {
    return await Api.postRequest('/user/update-personal-profile', postData);
  };
}
