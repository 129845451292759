import React from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormGroup,
} from 'reactstrap';

// Application Imports;
import AppContext from '../../components/AppContext';
import AlertError from '../../layout/includes/Alert/Error';
import DocumentList from '../common/documents-upload/DocumentList';
import BlankScreen from './BlankScreen';
import appConfig from '../../config';
import AccountDetail from '../common/profile-preview/AccountDetail';
import NoDataMsg from '../common/profile-preview/NoDataMsg';

// Axios
import axiosInstance from '../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import Css;
import './server-profile.scss';

interface Props {}

interface State {
  crn: string;
  crnError: boolean;
  formSubmitted: boolean;
  documentList: any;
  accountData: any;
  documentListShow: boolean;
  errorMessages: Array<string> | string;
}

class SearchProfile extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      crn: '',
      crnError: false,
      formSubmitted: false,
      documentList: {},
      accountData: null,
      documentListShow: false,
      errorMessages: '',
    };
  }

  crnChangeHandler = (e: any) => {
    this.setState({
      crn: e.target.value,
      crnError: false,
    });
  };

  handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { activeAccountUUID } = this.context;
    this.setState({
      crnError: false,
      formSubmitted: true,
      errorMessages: '',
      documentList: {},
      documentListShow: false,
      accountData: null,
    });

    if (this.state.crn.trim() === null || this.state.crn.trim() === '') {
      this.setState({
        crnError: true,
        formSubmitted: false,
      });
      return;
    }

    const postData = {
      uuid: activeAccountUUID,
      crn: this.state.crn,
    };

    // server request;
    axiosInstance
      .post('/search/search-by-crn', postData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.setState({
            documentList:
              response.data.data.document_list !== null
                ? response.data.data.document_list
                : {},
            documentListShow: response.data.data.document_list !== null ? true : false,
            accountData: response.data.data.account,
          });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  componentDidMount() {
    document.title = `Search Profile ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <div>
        <div className="profile--search u-mb--25">
          <Container fluid={true} className="container-fluid-sm">
            <Row className="u-flexed u-align-center u-justify-center">
              <Col xs={12} sm={12} md={6} lg={6} className="col-centered">
                <Row>
                  <Col xs={10} sm={10} md={8} lg={8} className="col-centered"></Col>
                  <Col xs={2} sm={2} md={4} lg={4} className="col-centered"></Col>
                </Row>
                <AlertError message={this.state.errorMessages} />
                <Form className="form-horizontal" onSubmit={this.handleFormSubmit}>
                  <FormGroup
                    className={`form-group u-mb--0 ${
                      this.state.crnError ? `form-group--has-error` : ``
                    }`}
                  >
                    <label htmlFor="CRM">Customer Reference Number (CRN)</label>
                    <InputGroup>
                      <Input
                        name="crn"
                        id="crn"
                        placeholder="Customer Reference Number"
                        className="form-control"
                        value={this.state.crn}
                        onChange={this.crnChangeHandler}
                      />
                      <InputGroupAddon addonType="append">
                        <Button
                          color="primary"
                          disabled={this.state.formSubmitted ? true : false}
                          className={`${this.state.formSubmitted ? `inlineloading` : ``}`}
                          id="submit"
                        >
                          Search
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>

                    <span className="help-block">Please enter CRN</span>
                  </FormGroup>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        {!this.state.errorMessages &&
          (!this.state.documentListShow && this.state.accountData === null ? (
            <BlankScreen />
          ) : (
            <>
              <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
                <AccountDetail accountData={this.state.accountData} />
              </Container>
              {this.state.documentListShow ? (
                <DocumentList
                  showEditElements={false}
                  documentList={this.state.documentList}
                  updateListData={() => {}}
                />
              ) : (
                <NoDataMsg />
              )}
            </>
          ))}
      </div>
    );
  }
}

export default SearchProfile;
