import React, { MouseEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports
import AppContext from '../../../components/AppContext';
import Account from '../../../api/Account';

// Import CSS
import '../members/member-list.scss';
import '../members/teams.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import LinkOff from '@material-ui/icons/LinkOff';

interface Props extends RouteComponentProps {
  memberList: Array<any>;
  pendingMemberList: Array<any>;
  removePendingMember: Function;
  reloadData: Function;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

interface State {}

class MemberList extends React.PureComponent<Props, State> {
  static contextType = AppContext;

  confirmLinkUnlink = (
    userAccountUuid: string,
    unlink: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const elementButton = e.currentTarget;
    let message = unlink ? 'unlink' : 're-link';
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to request to ' + message + ' your account?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.requestLinkUnlink(elementButton, userAccountUuid, unlink),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  requestLinkUnlink = (
    elementButton: HTMLButtonElement,
    userAccountUuid: string,
    unlink: boolean
  ) => {
    const { activeAccountUUID } = this.context;

    let buttonPreviousText = elementButton.innerText;
    elementButton.classList.toggle('inlineloading');
    elementButton.innerText = '';

    // request to server;
    Account.requestLinkUnlink(activeAccountUUID, userAccountUuid, unlink)
      .then((response) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = buttonPreviousText;

        if (response.data.success === 1) {
          toast.success('Request sent successfully', {
            autoClose: 2000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = buttonPreviousText;
      });
  };

  acceptInviteConfirm = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to accept this invite?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.subcontractorAcceptPendingCorporateInvite(
              elementButton,
              pendingInviteUuid
            );
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  subcontractorAcceptPendingCorporateInvite = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    const prevButtonText = elementButton.innerText;

    elementButton.classList.toggle('inlineloading');
    elementButton.innerText = '';

    // request to server;
    Account.subcontractorAcceptPendingCorporateInvite(
      activeAccountUUID,
      pendingInviteUuid
    )
      .then((response) => {
        if (response.data.success === 1) {
          this.props.reloadData();
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  cancelInviteConfirm = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to cancel this invite?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.cancelPendingInvitation(elementButton, pendingInviteUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  cancelPendingInvitation = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    elementButton.classList.toggle('inlineloading');
    const prevButtonText = elementButton.innerText;
    elementButton.innerText = '';

    // request to server;
    Account.cancelPendingCorporateInvite(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.removePendingMember(pendingInviteUuid);
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  render() {
    return (
      <>
        <div className="myteams--section myteams--section-sm">
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
            <h2 className="u-heading--medium u-heading--medium-sm">Subcontracted List</h2>
          </div>
          <ul className="admin--stats admin--stats-sm">
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">{this.props.activeMemberCount}</div>
              <div className="desc desc-sm">Linked</div>
            </li>
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">
                {this.props.pendingMemberList.length}
              </div>
              <div className="desc desc-sm">Pending</div>
            </li>
          </ul>
          <p>List shows all the companies you are currently subcontracting for.</p>
          <div className="portlet">
            <div className="portlet--body">
              <div className="profile--links">
                {this.props.pendingMemberList.length > 0 && (
                  <div className="profile--links u-mt--35">
                    <h3 className="title">To Confirm</h3>
                    <ListGroup>
                      {this.props.pendingMemberList.map((data: any) => (
                        <ListGroupItem className="head" key={data.uuid}>
                          <div className="NameColumn">
                            <div className="avatar--40">
                              <img
                                src={
                                  data.profileImageUrl
                                    ? data.profileImageUrl
                                    : ProfileAvatar
                                }
                                alt="Profile"
                              />
                            </div>
                            <div className="list-group--details">
                              <p className="user--name">{data.name}</p>
                              <p className="user--email">{data.email}</p>
                            </div>
                          </div>
                          <div className="RoleColumn"></div>
                          <div className="ActivityColumn">
                            <div className="ml-auto">
                              <div className="deny u-flexed u-align-center">
                                {data.status === 0 && (
                                  <>
                                    <button
                                      type="button"
                                      id={`${data.uuid}resend`}
                                      onClick={(e) =>
                                        this.cancelInviteConfirm(e, data.uuid)
                                      }
                                      className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                                    >
                                      Deny
                                    </button>

                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        this.acceptInviteConfirm(e, data.uuid)
                                      }
                                      className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                                    >
                                      Confirm
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                )}
                <ListGroup>
                  <ListGroupItem className="head">
                    <div className="NameColumn">Name</div>
                  </ListGroupItem>
                  {this.props.activeMemberCount > 0 ? (
                    this.props.memberList.map(
                      (data: any, key: number) =>
                        data.status === 1 && (
                          <ListGroupItem key={data.uuid}>
                            <div className="NameColumn">
                              <div className="avatar--40">
                                <img
                                  src={
                                    data.profileImageUrl
                                      ? data.profileImageUrl
                                      : ProfileAvatar
                                  }
                                  alt="Profile"
                                />
                              </div>
                              <div className="list-group--details">
                                <p className="user--name">{data.name}</p>
                                <p className="user--email">{data.email}</p>
                              </div>
                            </div>
                            <div className="RoleColumn"></div>
                            <div className="ActivityColumn">
                              <div className="ml-auto">
                                <div className="deny u-flexed u-align-center">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.confirmLinkUnlink(data.uuid, true, e)
                                    }
                                    className="c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90"
                                  >
                                    <LinkOff fontSize="small" className="icons u-mr--5" />
                                    Unlink
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        )
                    )
                  ) : (
                    <ListGroupItem>
                      <p>Not linked to any company yet</p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </div>
          {this.props.unlinkedMemberCount > 0 && (
            <div className="profile--links u-mt--35">
              <h3 className="title">Link History</h3>
              <ListGroup>
                {this.props.memberList.map(
                  (data: any) =>
                    data.status === 0 && (
                      <ListGroupItem className="head" key={data.uuid}>
                        <div className="NameColumn">
                          <div className="avatar--40">
                            <img
                              src={
                                data.profileImageUrl
                                  ? data.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="list-group--details">
                            <p className="user--name">{data.name}</p>
                            <p className="user--email">{data.email}</p>
                          </div>
                        </div>
                        <div className="RoleColumn"></div>
                        <div className="ActivityColumn">
                          <div className="ml-auto">
                            <div className="deny u-flexed u-align-center">
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.confirmLinkUnlink(data.uuid, false, e)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Re-Link
                              </button>
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    )
                )}
              </ListGroup>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(MemberList);
