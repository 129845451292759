import Api from './Api';

export default class Project extends Api {
  static createProject = async (
    accountUuid: string,
    projectname: string,
    description: string,
    projectManagersUserUuids: Array<any>,
    startDate: any
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_name: projectname,
      project_manager_user_uuids: projectManagersUserUuids,
      start_date: startDate,
      description,
    };

    return await Api.postRequest('/project/create-project', postData);
  };

  static getProject = async (accountUuid: string, projectUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
    };

    return await Api.postRequest('/project/get-project', postData);
  };

  static updateProject = async (
    accountUuid: string,
    projectUuid: string,
    projectname: string,
    description: string,
    projectManagersUserUuids: Array<any>,
    startDate: any
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
      project_name: projectname,
      project_manager_user_uuids: projectManagersUserUuids,
      start_date: startDate,
      description,
    };

    return await Api.postRequest('/project/update-project', postData);
  };

  static addAccontToProject = async (
    accountUuid: string,
    projectUuid: string,
    accountsList: Array<any>
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
      accounts_list: accountsList,
    };

    return await Api.postRequest('/project/add-accounts', postData);
  };

  static removeAccountFromProject = async (
    accountUuid: string,
    projectUuid: string,
    projectAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
      project_account_uuid: projectAccountUuid,
    };

    return await Api.postRequest('/project/remove-account', postData);
  };

  static getProjects = async (
    accountUuid: string,
    mode: string,
    linkedAccountUuid?: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      account_uuid: linkedAccountUuid,
      mode,
    };

    return await Api.postRequest('/project/get-projects', postData);
  };

  static getAccountInvitedProjects = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/account-link/get-invited-projects', postData);
  };

  static deleteProject = async (
    accountUuid: string,
    projectUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
    };

    return await Api.postRequest('/project/delete-project', postData);
  };

  static favouriteProjectToggler = async (
    accountUuid: string,
    projectUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
    };

    return await Api.postRequest('/project/favourite-project-toggler', postData);
  };

  static getProjectSummary = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/project/get-projects-summary', postData);
  };

  static toggleProjectStatus = async (
    accountUuid: string,
    projectUuid: string,
    isCompleted: number,
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_uuid: projectUuid,
      completed: isCompleted,
    };

    return await Api.postRequest('/project/toggle-completion', postData);
  };
}
