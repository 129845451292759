import React from 'react';
import {
  Row,
  Col,
  ListGroup,
  ListGroupItem,
  FormGroup,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';

// Import Icons
import Close from '@material-ui/icons/Close';
import Add from '@material-ui/icons/Add';
import Search from '@material-ui/icons/Search';
import Graphic250 from '../../../assets/images/Graphic--250.jpg';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

//application imports
import { linkedAs } from '../../../helpers/Constant';

interface Props {
  isOpen: boolean;
  toggle: Function;
  memberList: Array<any>;
  addedMemberList: Array<any>;
  addSelectedMemberListHandler: Function;
}

interface State {
  activeFilterItem: string;
  filterWord: string;
  memberList: Array<any>;
  memberListState: Array<any>;
  allCount: number;
  employeeCount: number;
  subcontractorCount: number;
}

class AddMemberModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      activeFilterItem: 'all',
      filterWord: '',
      memberList: this.props.memberList,
      memberListState: [],
      allCount: 0,
      subcontractorCount: 0,
      employeeCount: 0,
    };
  }

  filterHandler = (e: any, buttonClick: boolean = false) => {
    const value = buttonClick ? e : e.target.value;
    this.setState({
      filterWord: value,
    });

    const memberList = this.state.memberList.map((data: any) => {
      data.matchesFilterWord =
        data.name.toLowerCase().indexOf(value.toLowerCase()) !== -1 ? true : false;
      return data;
    });

    this.setState({
      memberList,
    });
  };

  addMemberListHandler = (addUserObj: any) => {
    const memberList = this.state.memberList.map((data: any) => {
      if (data.uuid === addUserObj.uuid) {
        data.memberAddedToSelection = true;
      }
      return data;
    });

    this.setState((prevState) => ({
      memberListState: [...prevState.memberListState, addUserObj],
      memberList,
    }));
  };

  removeMemberListHandler = (removeUserObj: any) => {
    const memberListState = this.state.memberListState.filter((data: any) => {
      return data.uuid !== removeUserObj.uuid;
    });
    const memberList = this.state.memberList.map((data: any) => {
      if (data.uuid === removeUserObj.uuid) {
        data.memberAddedToSelection = false;
      }
      return data;
    });
    this.setState((prevState) => ({
      memberList,
      memberListState,
    }));
  };

  addMembers = (e: any) => {
    e.preventDefault();
    const memberListState = this.state.memberListState;
    const memberList = this.state.memberList.map((data: any) => {
      data.memberAddedToSelection = false;
      return data;
    });
    this.setState({
      memberListState: [],
      memberList,
    });
    this.props.addSelectedMemberListHandler(memberListState);
  };

  closeModal = () => {
    this.props.toggle();
  };

  filterListOfDocument = (e: any) => {
    let activeFilterItem = 'all';
    let linkedAsToShow = 'any';

    if (e === 'employee') {
      activeFilterItem = 'employee';
      linkedAsToShow = linkedAs.employee;
    } else if (e === 'subcontractor') {
      activeFilterItem = 'subcontractor';
      linkedAsToShow = linkedAs.subcontractor;
    }

    const memberList = this.state.memberList.map((data: any) => {
      data.matchesSubFilter =
        linkedAsToShow === 'any' || data.linkedAs === linkedAsToShow ? true : false;
      return data;
    });

    this.setState({
      memberList,
      activeFilterItem,
    });
  };

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevProps.memberList !== this.props.memberList) {
      this.setState({
        memberList: this.props.memberList,
      });
    }

    if (prevState.memberList !== this.state.memberList) {
      const memberList = this.state.memberList;
      const totalNotAddedList = memberList.filter((data: any) => {
        return !data.memberAddedToSelection;
      });
      const employeeList = memberList.filter((data: any) => {
        return !data.memberAddedToSelection && data.linkedAs === linkedAs.employee;
      });

      this.setState({
        allCount: totalNotAddedList.length,
        employeeCount: employeeList.length,
        subcontractorCount: totalNotAddedList.length - employeeList.length,
      });
    }
  }

  checkIfAccountAlreadyAdded = (memberToCheck: any) => {
    return this.props.addedMemberList.some((addedMember) => {
      return addedMember.accountUuid === memberToCheck.accountUuid;
    });
  };

  showMemberRow = (member: any) => {
    if (member.memberAddedToSelection) {
      return false;
    }

    if (member.matchesFilterWord === false) {
      return false;
    }

    if (member.matchesSubFilter === false) {
      return false;
    }

    return true;
  };

  render() {
    return (
      <Modal
        backdrop={true}
        id="AddDocumentModal"
        isOpen={this.props.isOpen}
        toggle={this.closeModal}
        className="modal--fullwidth modal--fullwidth-md gray"
      >
        <ModalHeader
          className="start u-border--btm0 plr--45 plr--45-sm u-pt--25"
          toggle={this.closeModal}
        >
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--0">
            <h2 className="u-heading--medium u-heading--medium-sm">
              Search Members/Subcontractors
            </h2>
          </div>
        </ModalHeader>
        <ModalBody className="plr--45 plr--45-sm" style={{ paddingTop: '0px' }}>
          <div className="u-flexed fullwidth mobile--column">
            <div className="role--select role--select-sm">
              <div className="u-flexed fullwidth mobile--column">
                <div className="role--select--Left role--select--Left-sm">
                  {/* 
                  <p className="fs--15 dark-gray">
                    <strong>Name:</strong> Fletchers Apartment
                  </p>
                  */}
                  <div className="custom--search">
                    <FormGroup>
                      <Input
                        type="text"
                        name="fileSearchWord"
                        className="form-control"
                        value={this.state.filterWord}
                        placeholder="Search members/subcontractors"
                        onChange={this.filterHandler}
                      />
                      <span
                        className="pass--toggler"
                        onClick={() => this.filterHandler(this.state.filterWord, true)}
                      >
                        <Search fontSize="large" className="icons" />
                      </span>
                    </FormGroup>
                  </div>

                  <ul className="taglists">
                    <li
                      className={`taglists--items ${
                        this.state.activeFilterItem === `all` ? `is--selected` : ``
                      }`}
                    >
                      <button
                        type="button"
                        onClick={() => this.filterListOfDocument('All')}
                      >
                        All ({this.state.allCount})
                      </button>
                    </li>
                    <li
                      className={`taglists--items ${
                        this.state.activeFilterItem === `employee` ? `is--selected` : ``
                      }`}
                    >
                      <button
                        type="button"
                        onClick={() => this.filterListOfDocument('employee')}
                      >
                        Employees ({this.state.employeeCount})
                      </button>
                    </li>
                    <li
                      className={`taglists--items ${
                        this.state.activeFilterItem === `subcontractor`
                          ? `is--selected`
                          : ``
                      }`}
                    >
                      <button
                        type="button"
                        onClick={() => this.filterListOfDocument('subcontractor')}
                      >
                        Subcontractors ({this.state.subcontractorCount})
                      </button>
                    </li>
                  </ul>

                  <div className="custom--search__lists">
                    <ListGroup>
                      {this.state.memberList.length > 0 ? (
                        this.state.memberList.map((data: any) => (
                          <>
                            {this.showMemberRow(data) && (
                              <ListGroupItem
                                key={data.uuid}
                                className={
                                  this.checkIfAccountAlreadyAdded(data)
                                    ? 'is--inactive'
                                    : ''
                                }
                              >
                                <FormGroup>
                                  <div className="u-flexed u-align-center">
                                    <div className="avatar--40">
                                      <img
                                        src={
                                          data.profileImageUrl
                                            ? data.profileImageUrl
                                            : ProfileAvatar
                                        }
                                        width="40"
                                        alt="Profile Avatar"
                                      />
                                    </div>
                                    <div className="members--details">
                                      <p className="members--name">{data.name}</p>
                                      <p className="members--position">
                                        {data.roleDisplayAs}
                                      </p>
                                    </div>
                                  </div>
                                  {!this.checkIfAccountAlreadyAdded(data) ? (
                                    <button
                                      className="c-button--absolute role-doc-added"
                                      onClick={() => this.addMemberListHandler(data)}
                                    >
                                      <Add fontSize="small" className="icons" />
                                    </button>
                                  ) : (
                                    <button className="c-button--absolute role-doc-added">
                                      <Close fontSize="small" className="icons" />
                                    </button>
                                  )}
                                </FormGroup>
                              </ListGroupItem>
                            )}
                          </>
                        ))
                      ) : (
                        <ListGroupItem>
                          <p>No member available.</p>
                        </ListGroupItem>
                      )}
                    </ListGroup>
                  </div>
                </div>

                <div className="role--select--Right role--select--Right-sm">
                  {this.state.memberListState.length < 1 && (
                    <div className="No--DocumentsAdded text-center u-mb--30">
                      <figure className="graphic--250 graphic--250-sm">
                        <img src={Graphic250} alt="Slide 01" className="img-fluid" />
                      </figure>
                      <h4 className="">Start adding members!</h4>
                      <p className="fs--13 text--dark-gray">
                        These are the people who will be working together
                      </p>
                    </div>
                  )}

                  {this.state.memberListState.length > 0 && (
                    <>
                      <p className="text--dark-gray u-mb--5">
                        Selected ({this.state.memberListState.length})
                      </p>
                      <div className="custom--search__lists">
                        <ListGroup>
                          {this.state.memberListState.map((data: any) => (
                            <ListGroupItem>
                              <FormGroup>
                                <div className="u-flexed u-align-center">
                                  <div className="avatar--40">
                                    <img
                                      src={
                                        data.profileImageUrl
                                          ? data.profileImageUrl
                                          : ProfileAvatar
                                      }
                                      width="40"
                                      alt="Profile Avatar"
                                    />
                                  </div>
                                  <div className="members--details">
                                    <p className="members--name">{data.name}</p>
                                    <p className="members--position">
                                      {data.roleDisplayAs}
                                    </p>
                                  </div>
                                </div>
                                <button
                                  className="c-button c-button--warning c-button-inline c-button-radius c-button--absolute"
                                  onClick={() => this.removeMemberListHandler(data)}
                                >
                                  REMOVE
                                </button>
                              </FormGroup>
                            </ListGroupItem>
                          ))}
                        </ListGroup>
                      </div>
                    </>
                  )}
                  {this.state.memberListState.length > 0 && (
                    <Row className="u-mt--25 u-mb--30">
                      <Col xs={12} sm={12} md={12} lg={12} className="text-center">
                        <button
                          type="button"
                          onClick={this.addMembers}
                          className="c-button c-button-primary c-button-inlineflex c-button-radius c-button-sm u-upper"
                        >
                          ADD SELECTED MEMBERS
                        </button>
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddMemberModal;
