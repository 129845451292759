import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody, Label } from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

//Application Importsß
import AlertError from '../../../layout/includes/Alert/Error';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './profile-settings.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

// Import Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

interface Props {
  isOpen: boolean;
  toggle: Function;
}

interface State {
  hidePassword: boolean;
  hidePasswordCon: boolean;
  submitFormRequest: boolean;
  alertErrorMessage: Array<string> | string;
}

class EditPasswordModal extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      hidePassword: false,
      hidePasswordCon: false,
      submitFormRequest: false,
      alertErrorMessage: '',
    };
  }
  handlePasswordToggle = () => {
    this.setState({
      hidePassword: !this.state.hidePassword,
    });
  };

  handleConfirmPasswordToggle = () => {
    this.setState({
      hidePasswordCon: !this.state.hidePasswordCon,
    });
  };
  submitHandler = (e: React.FormEvent<HTMLFormElement>, values: any) => {
    this.setState({
      submitFormRequest: true,
      alertErrorMessage: '',
    });

    // Prepare post data to send
    const updatePasswordRequestData = {
      password: values.password,
    };

    //sending a post request to the server
    axiosInstance
      .post('/user/update-password', updatePasswordRequestData)
      .then((response: AxiosResponse) => {
        this.setState({
          submitFormRequest: false,
        });

        if (response.data.success === 1) {
          toast.success('Your password is updated successfully.', {
            autoClose: 4000,
          });
          this.props.toggle();
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitFormRequest: false,
        });
      });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md"
        backdrop={true}
      >
        <ModalHeader className="center u-border--btm0" toggle={() => this.props.toggle()}>
          Change your password
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm">
          <AlertError message={this.state.alertErrorMessage} />
          <AvForm className="form-horizontal u-mt--0" onValidSubmit={this.submitHandler}>
            <AvGroup>
              <Label for="password">Password</Label>
              <AvInput
                type={!this.state.hidePassword ? 'password' : 'text'}
                name="password"
                id="password"
                required
                className="is--pass"
                minLength={8}
                maxLength={20}
                errorMessage="Password is required and must between be between 8 - 20 characters"
                validate={{
                  pattern: {
                    value: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$',
                    errorMessage:
                      'Password should contain atleast one number and one special character (!@#$%^&*)',
                  },
                }}
              />
              <button
                type="button"
                onClick={this.handlePasswordToggle}
                className="pass--toggler"
              >
                {this.state.hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </button>
              <AvFeedback>
                Password should contain atleast one number and one special character
                (!@#$%^&*) and must be between 8 - 20 characters.
              </AvFeedback>
            </AvGroup>
            <AvGroup>
              <Label for="confirm_password">Confirm Password</Label>
              <AvInput
                type={!this.state.hidePasswordCon ? 'password' : 'text'}
                name="confirm_password"
                className="form-control is--pass"
                required
                minLength={8}
                validate={{
                  match: {
                    value: 'password',
                    errorMessage: 'Password and Confirm password must match',
                  },
                }}
              />
              <button
                type="button"
                onClick={this.handleConfirmPasswordToggle}
                className="pass--toggler"
              >
                {this.state.hidePasswordCon ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </button>
              <AvFeedback>Password and Confirm password not matching</AvFeedback>
            </AvGroup>

            <Row className="row u-mt--30">
              <Col
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="u-flexed u-align-center u-justify-center"
              >
                <Button
                  type="submit"
                  className={`c-button c-button--primary c-button-w210 c-button-radius ${
                    this.state.submitFormRequest ? `inlineloading` : ``
                  }`}
                  id="submit"
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    );
  }
}

export default EditPasswordModal;
