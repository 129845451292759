import Api from './Api';

export default class Activity extends Api {
  static getActivityLog = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/account/get-activity-logs', postData);
  };

  static getActivityLogBySearch = async (
    accountUuid: string,
    searchText: any,
    page: number
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      search_value: searchText,
      page,
    };

    return Api.postRequest('/account/get-activity-logs', postData);
  };
}
