import React from 'react';
import Lightbox from 'react-image-lightbox';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// static assets;
import NoImg from '../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  imageArray: Array<any>;
  linkOpenHandler: Function;
}

interface State {
  photoIndex: number;
}

class DocumentPreviewLightbox extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      photoIndex: 0,
    };
  }
  render() {
    return (
      <Lightbox
        discourageDownloads={false}
        imageLoadErrorMessage="Please wait..loading image"
        mainSrc={
          this.props.imageArray.length > 0
            ? this.props.imageArray[this.state.photoIndex].previewUrl
            : NoImg
        }
        nextSrc={
          this.props.imageArray.length > 1
            ? this.props.imageArray[
                (this.state.photoIndex + 1) % this.props.imageArray.length
              ]
            : undefined
        }
        prevSrc={
          this.props.imageArray.length > 1
            ? this.props.imageArray[
                (this.state.photoIndex + this.props.imageArray.length - 1) %
                  this.props.imageArray.length
              ]
            : undefined
        }
        onMovePrevRequest={() =>
          this.setState({
            photoIndex:
              (this.state.photoIndex + this.props.imageArray.length - 1) %
              this.props.imageArray.length,
          })
        }
        onMoveNextRequest={() =>
          this.setState({
            photoIndex: (this.state.photoIndex + 1) % this.props.imageArray.length,
          })
        }
        onCloseRequest={() => this.props.linkOpenHandler()}
      />
    );
  }
}

export default withRouter(DocumentPreviewLightbox);
