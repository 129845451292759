import Api from './Api';

export default class LoginSignup extends Api {
  static validateLogin = async (username: string, password: string): Promise<any> => {
    const postData = {
      username,
      password,
    };

    return Api.postRequest('/auth/authenticate', postData);
  };

  static validateResetToken = async (token: string): Promise<any> => {
    const postData = {
      token,
    };

    return Api.postRequest('/auth/verify-reset-password-token', postData);
  };

  static resetPasswordUpdate = async (token: string, password: string): Promise<any> => {
    const postData = {
      token,
      password,
    };

    return Api.postRequest('/auth/reset-password', postData);
  };

  static verifySignupToken = async (token: string): Promise<any> => {
    const postData = {
      token,
    };

    return Api.postRequest('/auth/verify-account', postData);
  };
}
