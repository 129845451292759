import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ReactWOW from 'react-wow';

// Static Assets
import CTG from '../../../assets/images/Corporate--Top--Graphic.svg';
import CBG from '../../../assets/images/Corporate--Bottom--Graphic.svg';
import AlertsInbox from '../../../assets/images/icons/AlertsInbox.png';
import Visability from '../../../assets/images/icons/Visability.png';
import CreateTeams from '../../../assets/images/icons/CreateTeams.png';
import Share from '../../../assets/images/icons/Share.png';
import UnlimitedStorage from '../../../assets/images/icons/UnlimitedStorage.png';
import DirectoryProfile from '../../../assets/images/icons/DirectoryProfile.png';

import { Container, Row, Col } from 'reactstrap';

interface SectionCorporateProps {}
interface SectionCorporateState {}

class SectionCorporate extends React.Component<
  SectionCorporateProps,
  SectionCorporateState
> {
  constructor(props: SectionCorporateProps) {
    super(props);
    this.state = {};
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <section
          className="section--corporate section--corporate-sm u-pb--0"
          data-bg={'white'}
          id="AboutUs"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} xl={11} className="col-centered text-center">
                <ReactWOW animation="fadeInUp" delay="200ms">
                  <div className="page--head page--head-sm noborder center u-mb--50 u-mb--50-sm">
                    <h3 className="u-heading__small u-heading__small-sm u-mb--10">
                      <strong>Keep your team's compliance up to date with ease</strong>
                    </h3>
                    <h1 className="u-heading__exlarge u-heading__exlarge-sm u-mb--15">
                      <strong>For Corporates</strong>
                    </h1>
                    <p className="fs--18 fs--18-sm">
                      Keeping up to date with compliance can be a tough ask when you run a
                      large team. Now you can take control of your company's compliance
                      with one easy tool, made with corporates in mind. Make expiring
                      compliance documents a thing of the past.
                    </p>

                    <p className="fs--18 fs--18-sm">
                      Keep on top of employee compliance with expiry date alerts and
                      verify documents through approvals. Everything you need to have the
                      peace of mind that your company compliance is up to date.
                    </p>
                  </div>
                </ReactWOW>
                <div className="fullwidth u-mb--40 u-mb--40-sm">
                  <div className="top--graphic top--graphic-sm">
                    <ReactWOW animation="slideInLeft" delay="250ms">
                      <img src={CTG} alt="Sole Traders" className="img-fluid" />
                    </ReactWOW>
                  </div>
                  <div className="bottom--graphic bottom--graphic-sm">
                    <ReactWOW animation="slideInRight" delay="300ms">
                      <img src={CBG} alt="Sole Traders" className="img-fluid" />
                    </ReactWOW>
                  </div>
                </div>
                <div className="equal--width equal--width-sm u-mb--80 u-mb--80-sm">
                  <ReactWOW animation="fadeInLeft" delay="300ms">
                    <Link
                      to="Pricing"
                      spy={true}
                      smooth={true}
                      offset={-30}
                      duration={500}
                      className="button button--primary button--primary-gradient button-inlineflex button-w190"
                    >
                      Try For Free
                    </Link>
                  </ReactWOW>
                  <ReactWOW animation="fadeInRight" delay="400ms">
                    <a
                      href="mailto:hello@owlcds.com.au"
                      className="button button--green button-inlineflex button-w190"
                    >
                      Request A Demo
                    </a>
                  </ReactWOW>
                </div>

                <ul className="corporate--lists">
                  <ReactWOW animation="fadeInLeft" delay="300ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--alert">
                          <img
                            src={AlertsInbox}
                            alt="Alerts to your inbox"
                            className="img-fluid"
                          />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Alerts in your inbox</h3>
                          <p className="fs--18 fs--18-sm">
                            Receive alerts when employees' compliance documents are due
                            for renewal or have expired.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInRight" delay="400ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--blue">
                          <img src={Visability} alt="Visibility" className="img-fluid" />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Visibility</h3>
                          <p className="fs--18 fs--18-sm">
                            Gain full visibility over of all your employees' and
                            subcontractors' compliance status.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInLeft" delay="500ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--teams">
                          <img
                            src={CreateTeams}
                            alt="Create teams"
                            className="img-fluid"
                          />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Create teams</h3>
                          <p className="fs--18 fs--18-sm">
                            Create and manage your employees through teams, giving them
                            access based on their roles and responsibilities.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInRight" delay="600ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--share">
                          <img src={Share} alt="Sharing" className="img-fluid" />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Sharing</h3>
                          <p className="fs--18 fs--18-sm">
                            Share teams and company profile as compliance proof to others.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInLeft" delay="700ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--storage">
                          <img
                            src={UnlimitedStorage}
                            alt="Unlimited Storage"
                            className="img-fluid"
                          />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Unlimited storage</h3>
                          <p className="fs--18 fs--18-sm">
                            Store as many documents as you need with unlimited storage
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInRight" delay="800ms">
                    <li className="corporate--lists__items corporate--lists__items-sm">
                      <div className="corporate--lists__inner corporate--lists__inner-sm">
                        <div className="corporate--lists__Icons corporate--lists__Icons-sm bg--profile">
                          <img
                            src={DirectoryProfile}
                            alt="Directory listing"
                            className="img-fluid"
                          />
                        </div>
                        <div className="corporate--lists__details corporate--lists__details-sm">
                          <h3>Directory listing</h3>
                          <p className="fs--18 fs--18-sm">
                            Get your company profile listed in our Compliance Directory,
                            helping customers to find compliant businesses to work with.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionCorporate;
