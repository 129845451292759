import React from 'react';
import './dropdown.scss';
import { DropdownMenu } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import { UncontrolledTooltip } from 'reactstrap';

// Application imports;
import AppContext from '../../../../components/AppContext';
import ManageUser from '../../../../api/ManageUser';

// Import Icons
import Edit from '@material-ui/icons/Edit';
import Delete from '@material-ui/icons/Delete';

// Imports css;
import 'react-confirm-alert/src/react-confirm-alert.css';

interface Props extends RouteComponentProps {
  userUuid: string;
  editUserHandler: Function;
  userData: any;
  reloadData: Function;
}

interface State {}

class UserDropdown extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  userDeleteConfirmation = (userUuid: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this user?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteUser(userUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  deleteUser = (userUuid: string) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    ManageUser.deleteUser(activeAccountUUID, userUuid).then((response) => {
      //console.log(response.data);
      if (response.data.success) {
        this.props.reloadData();
        toast.success('Your request processed successfully', {
          autoClose: 3000,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  render() {
    return (
      <>
        <DropdownMenu right className="w200">
          <ol className="dropdown-menu__lists profile--menu">
            <li className="dropdown-divider" />
            <li>
              <button
                id="InlineEdit"
                onClick={() => this.props.editUserHandler(this.props.userData)}
              >
                <Edit fontSize="large" className="icons" />
                <UncontrolledTooltip placement="top" target="InlineEdit">
                  Edit
                </UncontrolledTooltip>
              </button>
            </li>
            <li className="dropdown-divider" />
            <li>
              <button
                className="text--error"
                id="InlineDelete"
                onClick={() => this.userDeleteConfirmation(this.props.userUuid)}
              >
                <Delete fontSize="large" className="icons text--error" />
                <UncontrolledTooltip placement="top" target="InlineDelete">
                  Delete
                </UncontrolledTooltip>
              </button>
            </li>
          </ol>
        </DropdownMenu>
      </>
    );
  }
}

export default withRouter(UserDropdown);
