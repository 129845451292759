import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Application imports;
import Helper from '../../../helpers/Helpers';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import DocumentPreviewLightbox from '../../common/documents-upload/DocumentPreviewLightbox';

// Import Icons
import Done from '@material-ui/icons/Done';
import Close from '@material-ui/icons/Close';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import Search from '@material-ui/icons/Search';

// Import CSS
import './team-member-lists.scss';
import './team-member-landing.scss';

interface Props extends RouteComponentProps {
  accountList: Array<any>;
  employeeList: Array<any>;
  subcontractorList: Array<any>;
  userRoledocumentMapping: Array<any>;
  documentHeadingList: Array<any>;
}

interface State {
  imageArray: Array<any>;
  isLinkOpen: boolean;
}

class ListView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLinkOpen: false,
      imageArray: [],
    };
  }

  documentPreviewHandler = (images?: Array<any>) => {
    this.setState({
      isLinkOpen: !this.state.isLinkOpen,
      imageArray: images ? images : [],
    });
  };

  getPreviewImageArray = (
    userUuid: any,
    roleDocumentUuid: string,
    openLightBox: boolean
  ) => {

    const documentObjectByUserUuid = this.props.userRoledocumentMapping[userUuid];
    if (documentObjectByUserUuid && documentObjectByUserUuid[roleDocumentUuid] && documentObjectByUserUuid[roleDocumentUuid].media.length > 0) {
        if (openLightBox) {
          this.documentPreviewHandler(documentObjectByUserUuid[roleDocumentUuid].media);
          return;
        }
        return documentObjectByUserUuid[roleDocumentUuid].media;
    }

    return null;
  };

  getDocumentStatus = (documentStatus: any) => {
    if (documentStatus.hasExpiredDocument) {
      return 'row-fixed--expired';
    }

    if (documentStatus.hasWarningDocument) {
      return 'row-fixed--warning';
    }

    return '';
  };

  getDocumentExpiryWithHtml = (
    userUuid: any,
    roleDocumentUuid: string,
    getTdClassName: boolean,
    columnIndex: number
  ) => {
    const documentObjectByUserUuid = this.props.userRoledocumentMapping[userUuid];
    if (documentObjectByUserUuid && documentObjectByUserUuid[roleDocumentUuid]) {
      const documentObj = documentObjectByUserUuid[roleDocumentUuid];

      let formattedCalculateDate = Helper.getDocumentExpiryDate(documentObj.expiry_date);
      let simpleCalculateDate = documentObj.expiry_date;
      // let className = columnIndex === 0 ? 'second-cell' : '';

      const docExpiryStatus = Helper.getDocumentExpiryStatus(simpleCalculateDate);
      const daysDiff = Helper.getDateDiff(simpleCalculateDate);
      if (simpleCalculateDate === null || simpleCalculateDate === '-') {
        formattedCalculateDate = 'N/A';
      }
      if (docExpiryStatus === 'expired') {
        if (getTdClassName) {
          return 'column--expired';
        } else {
          return (
            <div className="u-flexed u-align-center u-bordered">
              <div className="date text--error">
                {formattedCalculateDate}
                <br></br>
                <span className="u-bold">Expired</span>
              </div>
              <div className="ml-auto">
                <Close fontSize="small" className="icons text--error u-ml--5" />
              </div>
            </div>
          );
        }
      } else if (docExpiryStatus === 'warning') {
        if (getTdClassName) {
          return 'column--warning';
        } else {
          return (
            <div className="u-flexed u-align-center u-bordered">
              <div className="date">
                {formattedCalculateDate}
                <br></br>
                <span className="u-bold text--warning">Due in {daysDiff} days</span>
              </div>
              <div className="ml-auto d-none">
                <Close fontSize="small" className="icons text--warning u-ml--5" />
              </div>
            </div>
          );
        }
      } else {
        if (getTdClassName) {
          return '';
        } else {
          return (
            <div className="u-flexed u-align-center">
              <div className="date">{formattedCalculateDate}</div>
              <div className="ml-auto">
                <Done fontSize="small" className="icons text--green" />
              </div>
            </div>
          );
        }
      }
    }
    return '-';
  };

  render() {
    return (
      <>
        <div className="board--helper"></div>
        <div className="board--wrapper u-mb--50 u-mb--50-sm">
          <div
            className="board--container"
            style={{
              width: 'calc(100vw - 220px)',
              maxWidth: 'calc(100vw - 220px)',
            }}
          >
            <div
              className={`board--container__wrapper ${
                this.props.documentHeadingList.length > 6 ? `` : `column--6`
              }`}
            >
              <table>
                <thead>
                  <tr>
                    <th className="fixed h110">
                      Employees ({this.props.employeeList.length})
                    </th>
                    {this.props.documentHeadingList.map((data: any) => (
                      <th className="w170" key={data.uuid}>
                        {data.title}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {this.props.employeeList.map((data: any) => (
                    <React.Fragment key={data.account_data.uuid}>
                      <tr>
                        <th
                          className={`fixed ${GetAccountDocumentStatusClassName(
                            data.account_data.accountDocumentStatus,
                            false
                          )}`}
                        >
                          <div className="u-flexed u-align-center">
                            <div className="avatar--40">
                              <img
                                src={
                                  data.account_data.profileImageUrl
                                    ? data.account_data.profileImageUrl
                                    : ProfileAvatar
                                }
                                width="40"
                                alt="Profile Avatar"
                              />
                            </div>
                            <div className="members--details">
                              <p className="members--name">{data.account_data.name}</p>
                              <p className="members--position">
                                {data.account_data.roleDisplayAs}
                              </p>
                            </div>
                          </div>
                        </th>
                        {this.props.documentHeadingList.map(
                          (documentHeadingData: any, documentHeadingIndex: number) => (
                            <td
                              className={`${this.getDocumentExpiryWithHtml(
                                data.account_data.uuid,
                                documentHeadingData.uuid,
                                true,
                                documentHeadingIndex
                              )}`}
                              key={documentHeadingData.uuid}
                            >
                              {this.getPreviewImageArray(
                                data.account_data.uuid,
                                documentHeadingData.uuid,
                                false
                              ) && (
                                <div className="absolute--links">
                                  <button
                                    type="button"
                                    className="button white md"
                                    onClick={() =>
                                      this.getPreviewImageArray(
                                        data.account_data.uuid,
                                        documentHeadingData.uuid,
                                        true
                                      )
                                    }
                                  >
                                    <Search
                                      fontSize="large"
                                      className="icons"
                                    />
                                  </button>
                                </div>
                              )}
                              {this.getDocumentExpiryWithHtml(
                                data.account_data.uuid,
                                documentHeadingData.uuid,
                                false,
                                documentHeadingIndex
                              )}
                            </td>
                          )
                        )}
                      </tr>

                      <tr className="noStyle">
                        <td colSpan={this.props.documentHeadingList.length + 1}></td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          {this.props.subcontractorList.length > 0 && (
            <div className="board--container fullwidth">
              <table>
                <thead>
                  <tr>
                    <th className="">Subcontractors</th>
                  </tr>
                </thead>
                <tbody>
                  {this.props.subcontractorList.map((data: any) => (
                    <tr key={data.account_data.accountUuid}>
                      <td>
                        <div className="u-flexed u-align-center">
                          <div className="avatar--40">
                            {data.account_data.profileImageUrl && (
                              <img
                                src={data.account_data.profileImageUrl}
                                width="40"
                                alt="Profile Avatar"
                              />
                            )}
                          </div>
                          <div className="members--details">
                            <p className="members--name">{data.account_data.name}</p>
                            <p className="members--position">
                              {data.account_data.roleDisplayAs}
                            </p>
                          </div>
                          <div className="ml-auto u-flexed u-align-center">
                            <button
                              onClick={() =>
                                this.props.history.push({
                                  pathname: `/account/${data.account_data.accountUuid}`,
                                  state: { prevPath: this.props.location.pathname },
                                })
                              }
                              color="secondary"
                              type="button"
                              className="c-button c-button--primary c-button-xs u-mr--15"
                            >
                              View Profile
                            </button>
                            <Done fontSize="small" className="icons text--green" />
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
        {this.state.isLinkOpen && (
          <DocumentPreviewLightbox
            imageArray={this.state.imageArray}
            linkOpenHandler={this.documentPreviewHandler}
          />
        )}
      </>
    );
  }
}

export default withRouter(ListView);
