import React from 'react';

// Static Includes
import Layout from './Layout';
import appConfig from '../../../config';
import ProjectsList from './ProjectsList';

interface Props {}

interface State {}

class FavouriteProjectLanding extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Favourite Projects ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <>
        <Layout>
          <div className="owl--content">
            <ProjectsList area="favouriteProject" title="Favourite Projects" />
          </div>
        </Layout>
      </>
    );
  }
}

export default FavouriteProjectLanding;
