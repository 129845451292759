import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Card, Row, Col } from 'reactstrap';
import queryString from 'query-string';

//Application Imports
import AuthPageWrapper from '../common/AuthPageWrapper';
import appConfig from '../../config';
import LoginSignup from '../../api/LoginSignup';

// Import Axios instance
import { AxiosResponse } from 'axios';

// Import CSS
import './login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {}

interface State {
  message: string;
  hideLoader: boolean;
  showLoginButton: boolean;
}

class VerifyAccount extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: 'Please Wait',
      hideLoader: true,
      showLoginButton: false,
    };
  }

  componentDidMount() {
    document.title = `Account Verification ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    let stringQuery: any = queryString.parse(this.props.location.search);

    if (!stringQuery.token) {
      this.setState({
        message: 'Token verification failed.',
      });
    } else {
      //sending a post request to the server
      LoginSignup.verifySignupToken(stringQuery.token)
        .then((response: AxiosResponse) => {
          if (response.data.success === 1) {
            this.setState({
              showLoginButton: true,
              message: 'Successfully verified.',
            });
          } else {
            this.setState({
              message: response.data.messages,
            });
          }
        })
        .catch((err) => {
          this.setState({
            message: err.message,
          });
        })
        .then(() => {
          this.setState({
            hideLoader: false,
          });
        });
    }
  }

  render() {
    return (
      <AuthPageWrapper>
        <Card className="card card-sm">
          <div className="card--header card--header-sm u-mb--0">
            <h2 className="card--title card--title-sm">Account Verification</h2>
          </div>
          <div className="card--body card--body-sm">
            {this.state.showLoginButton ? (
              <div>
                <p className="fs--15 dark-gray text-center">{this.state.message}</p>
              </div>
            ) : (
              <p className="fs--15 dark-gray text-center">{this.state.message}</p>
            )}
            <Row className="u-mt--30 u-mb--20">
              <Col
                xs={12}
                md={12}
                lg={12}
                className="u-flexed u-align-center u-justify-center"
              >
                <Link
                  to="/auth/login"
                  className={`c-button c-button--primary c-button-w210 c-button-radius`}
                >
                  Go to Login
                </Link>
              </Col>
            </Row>
          </div>
        </Card>
      </AuthPageWrapper>
    );
  }
}

export default VerifyAccount;
