import Api from './Api';

export default class Team extends Api {
  static getOptions = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/team/data-get-option', postData);
  };

  static createTeam = async (
    accountUuid: string,
    projectname: string,
    description: string,
    teamManager: string,
    startDate: any
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      project_name: projectname,
      team_manager: teamManager,
      start_date: startDate,
      description,
    };

    return await Api.postRequest('/team/create-team', postData);
  };

  static getTeams = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/team/get-teams', postData);
  };
}
