import React from 'react';
import Datetime from 'react-datetime';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
  Alert,
} from 'reactstrap';
import InputMask from 'react-input-mask';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import ReactFilestack from 'filestack-react';
import { toast } from 'react-toastify';
import scrollToComponent from 'react-scroll-to-component';
import Lightbox from 'react-image-lightbox';
import { AxiosResponse } from 'axios';

//Application Imports
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AlertError from '../../../layout/includes/Alert/Error';
import LocalStorage from '../../../helpers/LocalStorage';
import { getDate } from '../../../helpers/Helpers';
import User from '../../../api/User';
import ShareProfileModal from '../../../layout/includes/Header/ShareProfileModal';
import { accountType } from '../../../helpers/Constant';

// Import CSS
import './myprofile.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';
import 'react-image-lightbox/style.css';

// Import Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScreenShare from '@material-ui/icons/ScreenShare';
import FileCopy from '@material-ui/icons/FileCopy';
import Backup from '@material-ui/icons/Backup';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import Lock from '@material-ui/icons/Lock';

interface Props extends RouteComponentProps {}

interface FileStackProps {
  onPick: () => void;
}

interface State {
  expiryDateValue: Date | undefined;
  expiryDateError: boolean;
  dob: Date | undefined;
  dobDisabled: boolean;
  dobError: boolean;
  crnNo: string;
  profileImageUrl: string;
  profileImageArray: any;
  visaImageUrl: string;
  visaImageError: boolean;
  visaImageFullUrl: string;
  visaImageArray: any;
  taxFileError: boolean;
  taxFileValue: string;
  citizenValue: string;
  citizenError: boolean;
  showLoader: boolean;
  formSubmitted: boolean;
  firstName: string;
  lastName: string;
  email: string;
  contactNo: string;
  visaType: string;
  visaNumber: string;
  USINumber: string;
  modalVisaPreview: boolean;
  copied: boolean;
  errorMessages: Array<string> | string;
  shareModalOpen: boolean;
  hideMessageDiv: boolean;
  isTFNRequired: boolean;
  parentConsentError: boolean;
  parentConsent: boolean;
}

class PersonalProfile extends React.Component<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);

    this.state = {
      expiryDateValue: undefined,
      expiryDateError: false,
      dob: undefined,
      dobDisabled: true,
      dobError: false,
      crnNo: '',
      profileImageUrl: '',
      profileImageArray: {},
      visaImageUrl: '',
      visaImageError: false,
      visaImageFullUrl: '',
      visaImageArray: {},
      taxFileError: false,
      taxFileValue: '',
      citizenValue: '',
      citizenError: false,
      showLoader: true,
      formSubmitted: false,
      firstName: '',
      lastName: '',
      email: '',
      contactNo: '',
      visaType: '',
      visaNumber: '',
      USINumber: '',
      modalVisaPreview: false,
      copied: false,
      errorMessages: '',
      shareModalOpen: false,
      hideMessageDiv: true,
      isTFNRequired: false,
      parentConsentError: false,
      parentConsent: false,
    };
  }

  parentConsentHandler = (e: any) => {
    this.setState({
      parentConsent: !this.state.parentConsent,
      parentConsentError: this.state.parentConsent,
    });
  };

  shareModalHandler = () => {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  };

  updateCopiedState = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 4000);
  };

  togglemodalVisaPreview = () => {
    this.setState({
      modalVisaPreview: !this.state.modalVisaPreview,
    });
  };

  getDOBDifference = (date: any): boolean => {
    if (!date) {
      return true;
    }
    let selectedDate: any = new Date(date);
    let currentDate: any = new Date();
    let diffDate: any = new Date(currentDate - selectedDate);
    return diffDate.toISOString().slice(0, 4) - 1970 > 17 ? true : false;
  };

  handleChangeDOB = (date: any) => {
    const isTFNRequired = this.getDOBDifference(date._d);
    this.setState({
      dob: date._d,
      dobError: false,
      isTFNRequired: isTFNRequired,
    });
  };

  handleChangeExpiry = (date: any) => {
    this.setState({
      expiryDateValue: date._d,
      expiryDateError: false,
    });
  };

  getImageURL = (urlArray: any) => {
    if (urlArray.filesUploaded.length > 0) {
      this.setState({
        profileImageUrl: LocalStorage.getFilestackSecureUrl(
          urlArray.filesUploaded[0].url
        ),
        profileImageArray: urlArray.filesUploaded[0],
      });
    }
  };

  getImageURLVisaUpload = (urlArray: any) => {
    if (urlArray.filesUploaded.length > 0) {
      this.setState({
        visaImageUrl: LocalStorage.getFilestackSecureUrl(urlArray.filesUploaded[0].url),
        visaImageError: false,
        visaImageFullUrl: LocalStorage.getFilestackSecureUrl(
          urlArray.filesUploaded[0].url
        ),
        visaImageArray: urlArray.filesUploaded[0],
      });
    }
  };

  citizenHandler = (e: any) => {
    this.setState({
      citizenValue: e.target.value,
      citizenError: false,
      expiryDateError: false,
    });
  };

  taxFileChangeHandler = (e: any) => {
    this.setState({
      taxFileValue: e.target.value,
      taxFileError: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { updateUser } = this.context;
    const { expiryDateValue, dob, taxFileValue, citizenValue } = this.state;

    let isError = false;

    if (dob === undefined) {
      this.setState({
        dobError: true,
      });
      isError = true;
    }

    if (dob === undefined && !this.state.dobDisabled) {
      this.setState({
        parentConsentError: true,
      });
      isError = true;
    }

    if (this.state.citizenValue !== 'australian_citizen') {
      if (expiryDateValue === undefined) {
        this.setState({
          expiryDateError: true,
        });
        isError = true;
      }

      if (!citizenValue || citizenValue.trim() === null || citizenValue.trim() === '') {
        this.setState({
          citizenError: true,
        });
        isError = true;
      }

      if (this.state.visaImageUrl === '') {
        this.setState({
          visaImageError: true,
          errorMessages: 'Please upload Visa image',
        });
        scrollToComponent(this.alertErrorRef, { duration: 500 });
        isError = true;
      }
    }

    // if (!taxFileValue || taxFileValue.trim() === null || taxFileValue.trim() === '') {
    //   this.setState({
    //     taxFileError: true,
    //   });
    //   isError = true;
    // }

    if (errors.length > 0) {
      isError = true;
    }

    if (isError) {
      return;
    }

    // make error state false for re checking and render the error & loader to true;
    this.setState({
      dobError: false,
      expiryDateError: false,
      taxFileError: false,
      citizenError: false,
      formSubmitted: true,
      parentConsentError: false,
      errorMessages: '',
    });

    // prepage JSON data for the request;
    const postData = {
      first_name: values.first_name,
      last_name: values.last_name,
      contact_number: values.contactnumber,
      email: values.email,
      visa_type: values.visatype,
      visa_number: values.visanumber,
      usi_number: values.USINumber,
      citizenship: citizenValue,
      tax_file_number: taxFileValue,
      dob: getDate(dob),
      visa_expiry_date: getDate(expiryDateValue),
      profile_image_array: this.state.profileImageArray,
      visa_image_array: this.state.visaImageArray,
      parental_consent: this.state.dobDisabled ? null : this.state.parentConsent,
    };

    // request to server for data update;
    User.updatePersonalProfile(postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          updateUser({
            firstName: values.first_name,
            lastName: values.last_name,
            email: values.email,
          });
          this.setStateData(response.data.data);
          toast.success('Your profile updated successfully.', {
            autoClose: 2000,
            onClose: () => {
              this.props.history.push('/dashboard');
            },
          });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });

          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .then(() => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  fetchActiveProfile = () => {
    // Set loader to true;
    this.setState({
      showLoader: true,
    });

    // send request to server;
    User.getDetails('my-profile').then((response: AxiosResponse) => {
      if (response.data.success === 1) {
        this.setStateData(response.data.data);
      } else {
        toast.error('Error fetching data. Please try again.', {
          autoClose: 3000,
        });
      }
    });
  };

  setStateData = (data: any) => {
    this.setState({
      showLoader: false,
      crnNo: data.CRN_number,
      firstName: data.first_name,
      lastName: data.last_name,
      email: data.email,
      contactNo: data.contact_number,
      dob: data.dob === null ? undefined : new Date(data.dob),
      dobDisabled: data.dob === null ? false : true,
      profileImageUrl: data.profile_image,
      citizenValue: data.citizenship,
      visaType: data.visa_type,
      visaNumber: data.visa_number,
      expiryDateValue: data.visa_expiry_date
        ? data.visa_expiry_date === null
          ? undefined
          : new Date(data.visa_expiry_date)
        : undefined,
      visaImageUrl: data.visa_image ? data.visa_image : '',
      visaImageFullUrl: data.visa_image_full ? data.visa_image_full : '',
      taxFileValue: data.tax_file_number,
      USINumber: data.usi_number,
      hideMessageDiv: data.dob === null ? false : true,
      isTFNRequired: data.dob === null ? true : this.getDOBDifference(data.dob),
    });
  };

  componentDidMount() {
    document.title = `My Profile ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch active profile data;
    this.fetchActiveProfile();
  }

  render() {
    const filestackLSObject = LocalStorage.getFilestackObject();
    const activeAccount = LocalStorage.getActiveAccount();
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={9} lg={7} className="col-centered">
              <div className="myprofile--section myprofile--section-sm">
                {!this.state.showLoader ? (
                  <>
                    {!this.state.hideMessageDiv ? (
                      <>
                        <div className="row--full">
                          <Alert
                            color="danger"
                            className="u-radius--0 u-border--0 text-center fs--15"
                          >
                            <Lock fontSize="small" className="icon u-mr--5 fs--20" />
                            <span className="message">
                              To unlock the ability to share your profile and
                              documentation with others you need to complete your profile
                            </span>
                          </Alert>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--large u-heading--large-sm">My Profile</h2>
                      <div className="page-head--actions page-head--actions-sm">
                        <button
                          type="button"
                          onClick={() => this.props.history.push('/profile/preview')}
                          className="c-button c-button--primary c-button--primary-bordered c-button-radius c-button-inline size--xs has-icon--before"
                        >
                          <VisibilityIcon
                            fontSize="small"
                            className="icons u-mr--5"
                          ></VisibilityIcon>
                          Preview
                        </button>
                        <button
                          type="button"
                          onClick={this.shareModalHandler}
                          className="c-button c-button--primary c-button--primary-bordered c-button-radius c-button-inline size--xs has-icon--before"
                        >
                          <ScreenShare
                            fontSize="small"
                            className="icons u-mr--5"
                          ></ScreenShare>
                          Share
                        </button>
                        <ShareProfileModal
                          isOpen={this.state.shareModalOpen}
                          toggle={this.shareModalHandler}
                        />
                      </div>
                    </div>
                    <p className="fs--14 dark-gray">
                      The information below will display on your summary profile unless it
                      is stated otherwise.
                    </p>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />
                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <h4 className="section--title u-bold u-mb--15">PERSONAL DETAILS</h4>
                      <FormGroup className="">
                        <Label for="uniquenumber">Customer Reference Number</Label>
                        <FormText color="black">
                          {this.state.crnNo}{' '}
                          {activeAccount.account_type === accountType.corporateView && (
                            <CopyToClipboard
                              text={this.state.crnNo}
                              onCopy={this.updateCopiedState}
                            >
                              <button type="button" className="links--primary">
                                <span className="svg--icon">
                                  <FileCopy fontSize="small" className="icons"></FileCopy>
                                </span>
                              </button>
                            </CopyToClipboard>
                          )}
                          {this.state.copied && (
                            <span className="clipboard">Copied to clipboard.</span>
                          )}
                        </FormText>
                      </FormGroup>

                      <AvField
                        name="first_name"
                        id="first_name"
                        label="First Name"
                        required
                        errorMessage="Please enter First Name"
                        value={this.state.firstName}
                      />

                      <AvField
                        name="last_name"
                        id="last_name"
                        label="Last Name"
                        required
                        errorMessage="Please enter Last Name"
                        value={this.state.lastName}
                      />

                      <FormGroup
                        className={this.state.dobError ? 'form-group--has-error' : ''}
                      >
                        <Label htmlFor="dob">
                          Date of Birth (This is used for Identity verification and is not
                          displayed on your profile)
                        </Label>
                        <Datetime
                          inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            name: 'dob',
                            disabled: this.state.dobDisabled ? true : false,
                          }}
                          value={this.state.dob}
                          onChange={this.handleChangeDOB}
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          closeOnSelect={true}
                        />
                        <span className="help-block">Please enter DOB</span>
                      </FormGroup>

                      {!this.state.dobDisabled && !this.state.isTFNRequired ? (
                        <FormGroup
                          className={
                            this.state.parentConsentError ? 'form-group--has-error' : ''
                          }
                        >
                          <div className="radio-btn--group">
                            <div className="button-set">
                              <Label>
                                <Input
                                  type="checkbox"
                                  name="parental_consent"
                                  id="parental_consent"
                                  defaultChecked={this.state.parentConsent}
                                  onClick={this.parentConsentHandler}
                                />
                                <span /> Tick to confirm Parental/Guardian consent
                              </Label>
                              <span className="help-block">
                                Please check the Parent/Guardian consent
                              </span>
                            </div>
                          </div>
                        </FormGroup>
                      ) : (
                        ''
                      )}
                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <div className="profile--upload profile--upload-md">
                            <div className="profile--thumbnail profile--thumbnail-md">
                              <img
                                src={
                                  this.state.profileImageUrl
                                    ? this.state.profileImageUrl
                                    : ProfileAvatar
                                }
                                width="150"
                                alt="Profile Avatar"
                              />
                            </div>
                            <div className="profile--controls profile--controls-md">
                              <p>Profile photo (optional)</p>
                              <ReactFilestack
                                apikey={appConfig.FILESTACK_API_KEY}
                                actionOptions={{
                                  accept: 'image/*',
                                }}
                                clientOptions={{
                                  security: {
                                    policy: filestackLSObject.policy,
                                    signature: filestackLSObject.signature,
                                  },
                                }}
                                customRender={({ onPick }: FileStackProps) => (
                                  <button
                                    type="button"
                                    className="btn-upload u-flexed u-align-center"
                                    onClick={onPick}
                                  >
                                    <Backup
                                      fontSize="large"
                                      className="icons u-mr--5"
                                    ></Backup>{' '}
                                    Upload file
                                  </button>
                                )}
                                onSuccess={(res: any) => this.getImageURL(res)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <div className="hr">
                        <hr />
                      </div>
                      <h4 className="section--title u-bold u-mb--15">Contact</h4>

                      <AvField
                        name="contactnumber"
                        id="contactnumber"
                        label="Mobile Number"
                        required
                        errorMessage="Please enter Mobile number"
                        placeholder="04xxxxxxxx"
                        minLength={10}
                        maxLength={10}
                        validate={{
                          pattern: {
                            value: '^[0-9]+$',
                            errorMessage: 'You can enter only numbers',
                          },
                        }}
                        value={this.state.contactNo}
                      />

                      <AvField
                        name="email"
                        id="email"
                        label="Email Address"
                        required
                        errorMessage="Please enter email address"
                        validate={{
                          email: {
                            value: true,
                            errorMessage: 'Enter valid Email address',
                          },
                        }}
                        value={this.state.email}
                      />

                      <div className="clearfix" />
                      <div className="hr">
                        <hr />
                      </div>
                      <h4 className="section--title u-bold u-mb--15">Citizenship</h4>
                      <div className="clearfix" />
                      <FormGroup
                        className={this.state.citizenError ? 'form-group--has-error' : ''}
                      >
                        <div className="radio-btn--group">
                          <div className="button-set">
                            <Label>
                              <Input
                                type="radio"
                                checked={
                                  this.state.citizenValue === 'australian_citizen'
                                    ? true
                                    : false
                                }
                                name="citizen"
                                id="citizen"
                                value="australian_citizen"
                                onChange={this.citizenHandler}
                              />
                              <span /> Australian Citizen
                            </Label>
                          </div>
                          <div className="button-set">
                            <Label>
                              <Input
                                type="radio"
                                checked={
                                  this.state.citizenValue === 'overseas_worker'
                                    ? true
                                    : false
                                }
                                name="citizen"
                                id="citizen"
                                value="overseas_worker"
                                onChange={this.citizenHandler}
                              />
                              <span /> Overseas Worker
                            </Label>
                          </div>
                        </div>
                        <span className="help-block">
                          Please select one of these options
                        </span>
                      </FormGroup>

                      {this.state.citizenValue === 'overseas_worker' && (
                        <>
                          <AvField
                            name="visatype"
                            id="visatype"
                            label="Visa Type"
                            required
                            errorMessage="Please enter Visa Type"
                            value={this.state.visaType}
                          />

                          <AvField
                            name="visanumber"
                            id="visanumber"
                            label="Visa Number"
                            required
                            errorMessage="Please enter Visa Number"
                            value={this.state.visaNumber}
                          />

                          <FormGroup
                            className={
                              this.state.expiryDateError ? 'form-group--has-error' : ''
                            }
                          >
                            <Label htmlFor="state">Expiry Date</Label>
                            <Datetime
                              inputProps={{
                                placeholder: 'DD/MM/YYYY',
                                name: 'expirydate',
                              }}
                              value={this.state.expiryDateValue}
                              onChange={this.handleChangeExpiry}
                              dateFormat="DD/MM/YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                            />
                            <span className="help-block">
                              Please enter expiry date of visa
                            </span>
                          </FormGroup>

                          <Row>
                            <Col xs={12} md={12} lg={12}>
                              <div className={`profile--upload profile--upload-md`}>
                                {this.state.visaImageUrl && (
                                  <div className="profile--thumbnail profile--thumbnail-md u-radius--0">
                                    <img
                                      src={
                                        this.state.visaImageUrl
                                          ? this.state.visaImageUrl
                                          : ProfileAvatar
                                      }
                                      width="150"
                                      alt="Profile Avatar"
                                      className=" u-radius--0"
                                      onClick={this.togglemodalVisaPreview}
                                    />
                                  </div>
                                )}
                                <div
                                  className={
                                    'profile--controls profile--controls-md' +
                                    (this.state.visaImageUrl ? '' : ' u-ml--0')
                                  }
                                >
                                  <p>Upload Visa</p>
                                  <ReactFilestack
                                    apikey={appConfig.FILESTACK_API_KEY}
                                    actionOptions={{
                                      accept: 'image/*',
                                    }}
                                    clientOptions={{
                                      security: {
                                        policy: filestackLSObject.policy,
                                        signature: filestackLSObject.signature,
                                      },
                                    }}
                                    customRender={({ onPick }: FileStackProps) => (
                                      <button
                                        type="button"
                                        className="btn-upload u-flexed u-align-center"
                                        onClick={onPick}
                                      >
                                        <Backup
                                          fontSize="large"
                                          className="icons u-mr--5"
                                        ></Backup>{' '}
                                        Upload file
                                      </button>
                                    )}
                                    onSuccess={(res: any) =>
                                      this.getImageURLVisaUpload(res)
                                    }
                                  />
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}

                      <div className="clearfix" />
                      <div className="hr">
                        <hr />
                      </div>
                      <h4 className="section--title u-bold u-mb--15">Tax</h4>
                      <div className="clearfix" />

                      <AvField
                        name="taxfilenumber"
                        id="taxfilenumber"
                        label="Tax File Number (You can find this on your Tax Returns)"
                        value={this.state.taxFileValue}
                        minLength={this.state.isTFNRequired ? 10 : 0}
                        tag={[Input, InputMask]}
                        required={this.state.isTFNRequired ? true : false}
                        mask="999 999 999 99"
                        maskChar=""
                        onChange={this.taxFileChangeHandler}
                        errorMessage="Tax File Number is required"
                      />

                      <AvField
                        name="USINumber"
                        id="USINumber"
                        label="USI Number (optional)"
                        value={this.state.USINumber}
                        maxLength={15}
                        validate={{
                          minLength: {
                            value: 10,
                            errorMessage: 'USI Number must be minimum 10 characters long',
                          },
                          pattern: {
                            value: '^[A-Za-z0-9]+$',
                            errorMessage: 'You can enter letters and numbers only',
                          },
                        }}
                      />

                      <div className="clearfix" />
                      <div className="u-mt--25 u-flexed u-align-center u-justify-center">
                        <Button
                          id="submit"
                          color="primary"
                          disabled={this.state.formSubmitted ? true : false}
                          className={`btn-radius btn-sm btn-w180 ${
                            this.state.formSubmitted ? `inlineloading` : ``
                          }`}
                        >
                          Update
                        </Button>
                      </div>
                    </AvForm>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {this.state.modalVisaPreview && (
          <Lightbox
            mainSrc={this.state.visaImageFullUrl}
            onCloseRequest={() => this.setState({ modalVisaPreview: false })}
          />
        )}
      </>
    );
  }
}

export default withRouter(PersonalProfile);
