import React from 'react';

//Application imports;
import TeamBlankScreen from './TeamBlankScreen';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AppContext from '../../../components/AppContext';
import Team from '../../../api/Team';
import { toast } from 'react-toastify';

interface Props {}

interface State {
  teamList: Array<any>;
  showLoader: boolean;
}

class MemberList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      teamList: [],
      showLoader: true,
    };
  }

  fetchTeamList = () => {
    const { activeAccountUUID } = this.context;

    // Request to server;
    Team.getTeams(activeAccountUUID)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            teamList: response.data.data.team_list,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    //fetch team list;
    this.fetchTeamList();
  }

  render() {
    return (
      <div className="owl--content">
        {this.state.showLoader ? (
          <div className="invite--section invite--section-sm text-center">
            <Loader />
          </div>
        ) : this.state.teamList.length > 0 ? (
          'Test'
        ) : (
          <TeamBlankScreen />
        )}
      </div>
    );
  }
}

export default MemberList;
