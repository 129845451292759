import Api from './Api';

export default class ProfileSearch extends Api {
  static searchAccountByCrn = async (accountUuid: string, crn: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      crn,
    };

    return Api.postRequest('/search/search-by-crn', postData);
  };

  static sendInviteRequest = async (
    accountUuid: string,
    inviteAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      invite_account_uuid: inviteAccountUuid,
    };

    return Api.postRequest('/search/send-account-invite', postData);
  };

  static searchSubcontractors = async (
    accountUuid: string,
    what: string,
    where: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      what,
      where,
    };

    return Api.postRequest('/search/search-subcontractors', postData);
  };

  static updateSavedList = async (accountUuid: string, crn: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      crn,
    };

    return Api.postRequest('/profile/update-saved-status', postData);
  };
}
