import React from 'react';

//Application Imports
import appConfig from '../../config';

export default () => {
  return (
    <p className="fs--11 text-black text-center">
      By signing up, I agree to the OWL{' '}
      <a href={appConfig.PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </a>{' '}
      and{' '}
      <a href={appConfig.TERMS_CONDITIONS_URL} target="_blank" rel="noopener noreferrer">
        Terms of Service
      </a>
      .
    </p>
  );
};
