import React from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import { Container, Row, Col, Label, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import scrollToComponent from 'react-scroll-to-component';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';

// Application Imports
import Breadcrumb from './Breadcrumb';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Project from '../../../api/Project';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AlertError from '../../../layout/includes/Alert/Error';
import ManageUser from '../../../api/ManageUser';
import { getDate } from '../../../helpers/Helpers';

// Import CSS
import './teams.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface RouteState {
  prevPath: string;
}

interface Props extends RouteComponentProps<RouteState> {}

interface State {
  startDate: Date | undefined;
  formSubmitted: boolean;
  showLoader: boolean;
  projectManagerValue: Array<any>;
  projectManagerValueError: boolean;
  managerOptions: Array<any>;
  errorMessages: Array<string> | string;
}

class CreateProject extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.alertErrorRef = React.createRef();
    this.state = {
      startDate: undefined,
      formSubmitted: false,
      showLoader: true,
      projectManagerValue: [],
      projectManagerValueError: false,
      managerOptions: [],
      errorMessages: '',
    };
  }

  handleDateChange = (date: any) => {
    this.setState({
      startDate: date._d,
    });
  };

  managerChangeHandler = (data: any) => {
    this.setState({
      projectManagerValue: data,
      projectManagerValueError: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { projectManagerValue } = this.state;
    const { activeAccountUUID } = this.context;
    let isError = false;

    if (projectManagerValue === null || projectManagerValue.length === 0) {
      this.setState({
        projectManagerValueError: true,
      });
    }

    if (errors.length > 0) {
      isError = true;
    }

    if (isError) {
      return;
    }

    const projectManagersUserUuid = projectManagerValue.map((data: any) => {
      return data.value;
    });

    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    // request to server;
    Project.createProject(
      activeAccountUUID,
      values.projectname,
      values.description,
      projectManagersUserUuid,
      getDate(this.state.startDate)
    )
      .then((response: any) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          toast.success('Project created successfully.', {
            autoClose: 3000,
          });

          // redirect to edit page;
          this.props.history.push(`/corporate/project/edit/${response.data.data.uuid}`, {
            area: 'allProject',
          });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err: any) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  convertLinkedMembersToArray = (data: any) => {
    let dataArray: any = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let objData: any = {
          label: data[i].first_name + ' ' + data[i].last_name + ' (' + data[i].role + ')',
          value: data[i].uuid,
        };
        dataArray.push(objData);
      }
    }
    return dataArray;
  };

  fetchMemberLists = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    ManageUser.getUserList(activeAccountUUID).then((response: any) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          managerOptions: this.convertLinkedMembersToArray(response.data.data.userList),
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Create Project ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch team manager/ vehicle options;
    this.fetchMemberLists();
  }

  render() {
    return (
      <>
        <Breadcrumb prevPath={this.props.location.state} />
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              <div className="myteams--section myteams--section-sm">
                {this.state.showLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--0">
                      <h2 className="u-heading--medium u-heading--medium-sm">
                        Create Project
                      </h2>
                    </div>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />

                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <AvField
                        required
                        name="projectname"
                        label="Project name"
                        placeholder="Enter project name"
                        autoComplete="off"
                        errorMessage="Project Name is required"
                      />
                      <AvField
                        type="textarea"
                        name="description"
                        label="Description (optional)"
                        placeholder="Description"
                      />

                      <FormGroup
                        className={
                          this.state.projectManagerValueError
                            ? 'form-group--has-error'
                            : ''
                        }
                      >
                        <Label htmlFor="teamManager">Select Project Manager(s)</Label>
                        <div className="Team--Manager">
                          <Select
                            isMulti
                            name="teamManager"
                            options={this.state.managerOptions}
                            className="basic-multi-select"
                            onChange={this.managerChangeHandler}
                            classNamePrefix="select"
                            placeholder="Select Project Manager(s)"
                          />
                        </div>
                        <span className="help-block">Please select Project Manager</span>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="startdate">Estimated start date (optional)</Label>
                        <Datetime
                          inputProps={{
                            placeholder: 'DD/MM/YYYY',
                            name: 'startdate',
                          }}
                          value={this.state.startDate}
                          onChange={this.handleDateChange}
                          className="size--md"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          closeOnSelect={true}
                        />
                      </FormGroup>

                      <div className="clearfix"></div>

                      <Row className="u-mt--30 u-mt--30-sm">
                        <Col className="text-center">
                          <button
                            type="submit"
                            disabled={this.state.formSubmitted}
                            className={`c-button c-button--green c-button-inlineflex c-button-w210 c-button-radius c-button-sm u-upper ${
                              this.state.formSubmitted ? `inlineloading` : ``
                            }`}
                          >
                            Save & Continue
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CreateProject;
