import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Application Imports
import LocalStorage from '../../../../helpers/LocalStorage';

// Import CSS
import './sidebar.scss';

interface Props extends RouteComponentProps {}

interface State {}

class ProjectSidebar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <aside className="owl--sidebar d-none d-md-flex d-lg-flex d-xl-flex">
          <header className="owl--sidebar__heading d-flex align-items-center">
            {/* PROJECTS{' '}
            {!LocalStorage.hasManagerRole() && (
              <button
                onClick={() =>
                  this.props.history.push({
                    pathname: '/corporate/project/create',
                    state: { prevPath: this.props.location.pathname },
                  })
                }
                className="c-button c-button--primary c-button--primary-bordered c-button-inline c-button-xs"
              >
                + Project
              </button>
            )} */}
          </header>

          <ul className="team--lists">
            <li
              className={`team--lists__items ${
                this.props.location.pathname === '/corporate/project'
                  ? `is--selected`
                  : ``
              }`}
            >
              <Link to="/corporate/project" color="primary">
                Internal Projects
              </Link>
            </li>
            {!LocalStorage.hasManagerRole() && (
              <li
                className={`team--lists__items ${
                  this.props.location.pathname === '/corporate/invite/project'
                    ? `is--selected`
                    : ``
                }`}
              >
                <Link to="/corporate/invite/project" color="primary">
                  External Projects
                </Link>
              </li>
            )}
            {/* <li
              className={`team--lists__items ${
                this.props.location.pathname === '/corporate/favourite/project'
                  ? `is--selected`
                  : ``
              }`}
            >
              <Link to="/corporate/favourite/project" color="primary">
                Favourite Projects
              </Link>
            </li> */}
          </ul>
        </aside>
      </>
    );
  }
}

export default withRouter(ProjectSidebar);
