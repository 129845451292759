import React from 'react';
import { Container } from 'reactstrap';

// Static Includes
import appConfig from '../../../config';
import ProjectsList from '../../corporate/project/ProjectsList';
import Breadcrumb from '../account-links/Breadcrumb';

interface Props {}

interface State {}

class PersonalProjectLanding extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Projects ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <>
        <Breadcrumb selectedArea="myProject" />
        <div className="myteams--section myteams--section-sm sideBarEnabled">
          <Container fluid={true}>
            <div className="row flex-xl-nowrap">
              <div className="owl--content fullwidth">
                <div className="row">
                  <div className="col-sm-12 col-md-11 col-lg-10 col-centered">
                    <ProjectsList area="personalProjectView" title="Projects" />
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default PersonalProjectLanding;
