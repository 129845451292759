import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

interface Props extends RouteComponentProps {
  selectedArea: string;
}

interface State {}

class Breadcrumb extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="c-breadcrumb--section c-breadcrumb--section-sm">
          <Container fluid className="container-fluid-sm">
            <Row className="u-flexed u-align-center u-justify-btw">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="u-flexed u-align-center u-justify-start">
                  <ul className="c-breadcrumb--lists c-breadcrumb--lists-sm">
                    <li>
                      <Link
                        to="/company/profile"
                        className={
                          this.props.selectedArea === 'profile' ? 'is--selected' : ''
                        }
                      >
                        Company Profile
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/documentation"
                        className={
                          this.props.selectedArea === 'document' ? 'is--selected' : ''
                        }
                      >
                        Documentation
                      </Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Breadcrumb);
