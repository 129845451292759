import React from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Container, Row, Col, Table } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';

//Application Imports
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import EditCreditCardModal from './EditCreditCardModal';
import EditMailAddressModal from './EditMailAddressModal';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import { CapitalizeWords } from '../../../helpers/Helpers';
import { accountStatus } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './settings.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

interface Props extends RouteComponentProps {}

interface State {
  modalEditCreditCard: boolean;
  modalEditMail: boolean;
  showLoader: boolean;
  accountCreateAt: string;
  accountStatus: string;
  receiptMail: string;
  receipts: any;
  creditCardDetail: any;
  subscriptionDetail: any;
  closeAccountPermission: boolean;
}

class AccountSettings extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalEditCreditCard: false,
      modalEditMail: false,
      showLoader: false,
      accountStatus: '',
      accountCreateAt: '',
      receiptMail: '',
      receipts: {},
      creditCardDetail: {},
      subscriptionDetail: {},
      closeAccountPermission: true,
    };
  }

  toggleCreditCardModal = () => {
    this.setState({
      modalEditCreditCard: !this.state.modalEditCreditCard,
    });
  };

  toggleMailModal = () => {
    this.setState({
      modalEditMail: !this.state.modalEditMail,
    });
  };

  updateEmail = (e: string) => {
    this.setState({
      receiptMail: e,
    });
  };

  closeAccount = (data: boolean) => {
    const { activeAccountUUID, updateActiveAccountDetails } = this.context;
    const postData = {
      close_account: 1,
      uuid: activeAccountUUID,
    };

    let buttonElement: any = document.getElementById('closeAccount');
    buttonElement.classList.toggle('inlineloading');
    buttonElement.setAttribute('disabled', 'true');

    axiosInstance
      .post('/account/close-account', postData)
      .then((response: AxiosResponse) => {
        buttonElement.classList.toggle('inlineloading');
        buttonElement.removeAttribute('disabled');
        if (response.data.success === 1) {
          this.getAccountDetails();

          const activeAccount = LocalStorage.getActiveAccount();
          activeAccount.account_status = accountStatus.closed;
          updateActiveAccountDetails(activeAccount);

          toast.success('Account is successfully closed.', {
            autoClose: 3000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      });
  };

  closeAccountConfirm = () => {
    confirmAlert({
      title: 'Close Account',
      message:
        "Do you really to proceed with this step. Please do read T&C's before going ahead.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.closeAccount(true),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  reactivateAccountConfirm = () => {
    confirmAlert({
      title: 'Reactivate Account',
      message:
        "Do you really to proceed with this step. Please do read T&C's before going ahead.",
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.reactivateClosedAccount(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  reactivateClosedAccount = () => {
    const { activeAccountUUID, updateActiveAccountDetails } = this.context;
    const postData = {
      uuid: activeAccountUUID,
    };

    let buttonElement: any = document.getElementById('renewAccount');
    buttonElement.classList.toggle('inlineloading');
    buttonElement.setAttribute('disabled', 'true');

    axiosInstance
      .post('/account/reactivate-closed-account', postData)
      .then((response: AxiosResponse) => {
        buttonElement.classList.toggle('inlineloading');
        buttonElement.removeAttribute('disabled', 'true');
        if (response.data.success === 1) {
          this.getAccountDetails();

          const activeAccount = LocalStorage.getActiveAccount();
          activeAccount.account_status = accountStatus.active;
          updateActiveAccountDetails(activeAccount);

          toast.success('Account is successfully reactivated.', {
            autoClose: 3000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      });
  };

  getAccountDetails = () => {
    const { activeAccountUUID } = this.context;
    // set loader to loading;
    this.setState({
      showLoader: true,
    });

    const postData = {
      uuid: activeAccountUUID,
    };

    axiosInstance.post('/account/get-account-detail', postData).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          closeAccountPermission: response.data.data.allow_close,
          showLoader: false,
          accountStatus: response.data.data.account_status,
          accountCreateAt: response.data.data.account_created_at,
          receiptMail: response.data.data.receipt_mail,
          receipts: response.data.data.receipts,
          creditCardDetail: response.data.data.credit_card_detail,
          subscriptionDetail: response.data.data.subscription_detail,
        });
      } else {
        toast.error('Error Loading Details. Please try again.', {
          autoClose: 4000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Account Settings ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch user detail;
    this.getAccountDetails();
  }

  render() {
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              <div className="Accounts__section Accounts__section-sm">
                {!this.state.showLoader ? (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--15">
                      <h2 className="u-heading--medium u-heading--medium-sm">
                        Account Settings
                      </h2>
                      {this.state.closeAccountPermission &&
                        this.state.accountStatus === accountStatus.active && (
                          <div className="page-head--actions page-head--actions-sm">
                            <button
                              id="closeAccount"
                              onClick={this.closeAccountConfirm}
                              className="c-button c-button-primary c-button-primary-bordered c-button-block c-button-radius c-button-xs"
                            >
                              Close account
                            </button>
                          </div>
                        )}
                      {this.state.accountStatus === accountStatus.closed && (
                        <div className="page-head--actions page-head--actions-sm">
                          <button
                            id="renewAccount"
                            onClick={this.reactivateAccountConfirm}
                            className="c-button c-button-primary c-button-primary-bordered c-button-block c-button-radius c-button-xs"
                          >
                            Reactivate Subscription
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                      <h3 className="u-heading--xsmall u-heading--xsmall-sm">
                        Billing Information
                      </h3>
                    </div>
                    <div className="table-responsive u-mb--30">
                      <Table>
                        <tbody>
                          <tr>
                            <td>Member Since</td>
                            <td>
                              <strong>{this.state.accountCreateAt}</strong>
                            </td>
                            <td></td>
                          </tr>
                          <tr>
                            <td>Subscription Plan</td>
                            <td>
                              <strong>
                                {this.state.subscriptionDetail?.plan_name} (
                                {this.state.accountStatus &&
                                  CapitalizeWords(this.state.accountStatus)}
                                )
                              </strong>
                              {this.state.subscriptionDetail.plan_price && (
                                <>${this.state.subscriptionDetail.plan_price} annually</>
                              )}
                              {this.state.subscriptionDetail
                                .total_chargeable_accounts && (
                                <>
                                  Charged Seats:{' '}
                                  {
                                    this.state.subscriptionDetail
                                      .total_chargeable_accounts
                                  }
                                </>
                              )}
                            </td>
                            <td></td>
                          </tr>
                          {this.state.accountStatus !== accountStatus.closed && (
                            <>
                              <tr>
                                <td>Next Billing Date</td>
                                <td>{this.state.subscriptionDetail.next_billing_at}</td>
                                <td></td>
                              </tr>

                              <tr>
                                <td>Credit Card</td>
                                <td>
                                  <strong>
                                    {this.state.creditCardDetail?.masked_number}
                                  </strong>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={this.toggleCreditCardModal}
                                    className="c-button c-button--primary c-button-radius c-button-inline c-button-sm c-button-w110 u-upper"
                                  >
                                    Edit Details
                                  </button>
                                </td>
                              </tr>
                              <tr>
                                <td>Receipt Recipient</td>
                                <td>
                                  <strong>{this.state.receiptMail}</strong>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    onClick={this.toggleMailModal}
                                    className="c-button c-button--primary c-button-radius c-button-inline c-button-sm c-button-w110 u-upper"
                                  >
                                    Edit Email
                                  </button>
                                </td>
                              </tr>
                            </>
                          )}
                          <tr>
                            <td>Recent Receipts</td>
                            <td>
                              <ul className="receipt--lists">
                                {this.state.receipts.length > 0 ? (
                                  this.state.receipts.map((receipt: any, id: any) => (
                                    <li key={id}>
                                      <a href={receipt.downloadUrl}>{receipt.date}</a>
                                    </li>
                                  ))
                                ) : (
                                  <li>No receipt(s) found</li>
                                )}
                              </ul>
                            </td>
                            <td></td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <EditCreditCardModal
          area="editAccountDetail"
          isOpen={this.state.modalEditCreditCard}
          toggle={this.toggleCreditCardModal}
          getAccountDetail={this.getAccountDetails}
        />
        <EditMailAddressModal
          isOpen={this.state.modalEditMail}
          toggle={this.toggleMailModal}
          mailAddress={this.state.receiptMail}
          updateMailState={this.updateEmail}
        />
      </>
    );
  }
}

export default AccountSettings;
