import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  area: string;
}

interface State {
  prevPath: any;
  isListView: boolean;
}

class Breadcrumb extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isListView: false,
      prevPath: '',
    };
  }

  componentDidMount() {
    // check the router state for prev path;
    const prevPath: any = this.props.location.state;
    if (prevPath) {
      if (prevPath.prevPath === '/corporate/team/member') {
        this.setState({
          prevPath: prevPath.prevPath,
          isListView: true,
        });
      }
      this.setState({
        prevPath: prevPath.prevPath,
      });
    }
  }

  render() {
    const { prevPath, isListView } = this.state;
    return (
      <div>
        <div className="c-breadcrumb--section c-breadcrumb--section-sm">
          <Container fluid className="container-fluid-sm">
            <Row className="u-flexed u-align-center u-justify-btw">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="u-flexed u-align-center u-justify-start">
                  {this.props.area === 'accountPreview' &&
                    (isListView ? (
                      <Button
                        className="c-button--Back fs--16 u-mr--20"
                        onClick={() =>
                          this.props.history.push({
                            pathname: prevPath,
                            state: { isListView },
                          })
                        }
                      >
                        <i className="arrow left" /> Back
                      </Button>
                    ) : (
                      <Button
                        className="c-button--Back fs--16 u-mr--20"
                        onClick={() => this.props.history.goBack()}
                      >
                        <i className="arrow left" /> Back
                      </Button>
                    ))}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Breadcrumb);
