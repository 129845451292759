import React from 'react';
import { Container } from 'reactstrap';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';

// Static Includes
import ProjectSidebar from './Sidebar/ProjectSidebar';

interface Props {}

interface State {}

class Layout extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="myteams--section myteams--section-sm sideBarEnabled">
          <Container fluid={true}>
            <div className="row flex-xl-nowrap">
              <ProjectSidebar {...this.props} />
              {this.props.children}
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Layout;
