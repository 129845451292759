import React from 'react';
import { Link } from 'react-router-dom';

// Application Imports
import LocalStorage from '../../../helpers/LocalStorage';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';

// Static Includes
import TeamEmptyGraphic from '../../../assets/images/Team-Empty.jpg';

interface Props {
  area: string;
}

interface State {}

class ProjectBlankScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    let titleMsg = '';
    if (this.props.area === 'allProject') {
      titleMsg = 'Create your first project!';
    } else if (this.props.area === 'inviteProject') {
      titleMsg = 'No invited projects!';
    } else if (this.props.area === 'favouriteProject') {
      titleMsg = 'No favourite projects!';
    } else {
      titleMsg = 'No project(s) found!';
    }
    return (
      <>
        <div className="invite--section invite--section-sm text-center">
          <div className="invite--graphic invite--graphic-sm u-mb--25 u-mb--25-sm">
            <img src={TeamEmptyGraphic} alt="Team Empty" className="img-fluid" />
          </div>
          <div className="page-head page-head-sm center column noborder">
            <h2 className="u-heading--xsmall u-heading--xsmall-sm">
              <span style={{ color: '#4f4f4f' }}>{titleMsg}</span>
            </h2>
            {this.props.area === 'projectView' ? (
              <p className="text-center fs--13 dark-gray"> You don't have any project</p>
            ) : (
              <p className="text-center fs--13 dark-gray">
                Easily share your project and their compliance requirements with third
                parties
              </p>
            )}
          </div>
          {this.props.area === 'allProject' && !LocalStorage.hasManagerRole() && (
            <Link
              to="/corporate/project/create"
              className="c-button c-button--primary c-button-radius c-button-w210 c-button-inline size--xs has-icon--before"
            >
              Create Project
            </Link>
          )}
        </div>
      </>
    );
  }
}

export default ProjectBlankScreen;
