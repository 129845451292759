import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as serviceWorker from './serviceWorker';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import '../node_modules/animate.css/animate.min.css';
import './assets/scss/owl-cds.scss?v1.2.0';
import 'react-datetime/css/react-datetime.css';

// Application Imports
import App from './App';
import * as Sentry from '@sentry/react';
import config from './config';

// Breadcrumb css;
import './breadcrumb.scss';

// Sentry Tracking
if (config.ENABLE_SENTRY) {
  Sentry.init({
    dsn: config.SENTRY_DSN,
    environment: process.env.REACT_APP_STAGE,
    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        Sentry.showReportDialog({ eventId: event.event_id });
      }
      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
