import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

//Application Imports
import LocalStorage from '../../../helpers/LocalStorage';
import LinkMember from '../../../api/LinkMember';
import Roles from '../../../api/Roles';
import AppContext from '../../../components/AppContext';
import InviteSelectRoleModal from './InviteSelectRoleModal';
import { accountType } from '../../../helpers/Constant';

// Import Icons
import Language from '@material-ui/icons/Language';

// css
import './search-profile.scss';

interface Props extends RouteComponentProps {
  accountData: any;
  crn?: string;
}

interface State {
  loading: boolean;
  selectRoleModal: boolean;
  rolesOption: any;
  rolesLoaded: boolean;
}

class AccountDetail extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      selectRoleModal: false,
      rolesOption: {},
      rolesLoaded: false,
    };
  }

  formatNumber = (phoneNumberString: any, type: string) => {
    var cleaned: any = ('' + phoneNumberString).replace(/\D/g, '');
    var match;
    if (type === 'abn_acn') {
      match = cleaned.match(/.{1,3}/g).join(' ');
      return match;
    } else {
      match = cleaned.match(/^(1|)?(\d{4})(\d{3})(\d{3})$/);
    }

    if (match) {
      var intlCode = match[1] ? '+1 ' : '';
      return [intlCode, '', match[2], ' ', match[3], ' ', match[4]].join('');
    }
    return null;
  };

  selectRoleModalToggler = () => {
    this.setState({
      selectRoleModal: !this.state.selectRoleModal,
    });
  };

  inviteAccount = () => {
    const { activeAccountUUID } = this.context;

    this.setState({
      loading: true,
    });

    if (this.props.accountData.account_type === accountType.personal) {
      if (this.state.rolesLoaded) {
        this.setState({
          selectRoleModal: true,
          loading: false,
        });
      } else {
        // Request to server;
        Roles.getRoles(activeAccountUUID).then((response) => {
          this.setState({
            loading: false,
          });
          if (response.data.success === 1) {
            let roles = [] as any;
            for (let role of response.data.data.roles) {
              roles = [
                ...roles,
                {
                  uuid: role.uuid,
                  value: role.title,
                },
              ];
            }

            this.setState({
              selectRoleModal: true,
              rolesOption: roles,
              rolesLoaded: true,
            });
          } else {
            toast.error('Error fetching roles.', {
              autoClose: 3000,
            });
          }
        });
      }
    } else {
      LinkMember.inviteSubcontractorAccount(
        activeAccountUUID,
        this.props.accountData.uuid
      )
        .then((response) => {
          this.setState({
            loading: false,
          });
          if (response.data.success === 1) {
            toast.success('Invite sent successfully', {
              autoClose: 3000,
            });
          } else {
            toast.error(response.data.messages[0], {
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {
          this.setState({
            loading: false,
          });
        });
    }
  };

  render() {
    const isCorporateAccount = LocalStorage.isCurrentAccountCorporate();
    return (
      <>
        <Row>
          <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
            <div
              className={`myprofile--section myprofile--section-sm ${
                isCorporateAccount ? `u-pt--0` : `u-mt--45`
              }`}
            >
              <div className="myprofile--details myprofile--details-sm u-mb--0">
                <div className="myprofile--details__inner myprofile--details__inner-sm">
                  {this.props.accountData.logoImageUrl && (
                    <div className="myprofile--avatar myprofile--avatar-sm">
                      <img
                        src={this.props.accountData.logoImageUrl}
                        width="150"
                        alt="Avatar"
                      />
                    </div>
                  )}

                  <div className="myprofile--excerpt myprofile--excerpt-sm">
                    <h3 className="user--name user--name-sm">
                      {this.props.accountData.company_trading_name === null
                        ? this.props.accountData.company_name
                        : this.props.accountData.company_trading_name}{' '}
                      {this.props.crn &&
                      isCorporateAccount &&
                      !this.props.accountData.connectionData.linkStatus &&
                      !this.props.accountData.connectionData.inviteStatus ? (
                        <button
                          className={`c-button c-button-sm ${
                            this.state.loading ? `inlineloading` : ``
                          }`}
                          disabled={this.state.loading}
                          onClick={this.inviteAccount}
                        >
                          {this.state.loading ? '' : '+ Invite'}
                        </button>
                      ) : (
                        ''
                      )}
                      {this.props.crn &&
                      isCorporateAccount &&
                      !this.props.accountData.connectionData.linkStatus &&
                      this.props.accountData.connectionData.inviteStatus ? (
                        <button className="c-button c-button-sm c-button--green">
                          Invited
                        </button>
                      ) : (
                        ''
                      )}
                      {this.props.crn &&
                      isCorporateAccount &&
                      this.props.accountData.connectionData.linkStatus ? (
                        <button className="c-button c-button-sm c-button--green">
                          Linked
                        </button>
                      ) : (
                        ''
                      )}
                    </h3>
                    <div className="fs--14 u-mb--0 d-flex align-items-start flex-column">
                      {this.props.accountData.account_type !== 'personal' &&
                        this.props.accountData.website && (
                          <a
                            href={this.props.accountData.website}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="c-button c-button--primary-bordered u-mr--0 c-button-mini d-inline-flex align-items-center"
                          >
                            <Language fontSize="small" className="icons u-mr--5" />
                            View website
                          </a>
                        )}
                      <div className="u-mt--5">
                        <strong>CRN: {this.props.accountData?.CRN_number}</strong>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-auto">
                  <ul className="myprofile--lists u-mb--0 text-right">
                    {this.props.accountData.contact_number && (
                      <li className="tel">
                        <span className="order-1">
                          {this.formatNumber(
                            this.props.accountData.contact_number,
                            'phone_no'
                          )}
                        </span>
                      </li>
                    )}

                    {this.props.accountData?.email && (
                      <li className="email">
                        <span className="order-1">{this.props.accountData?.email}</span>
                      </li>
                    )}

                    {this.props.accountData?.account_type === 'personal' ? (
                      <li className="tfn">TFN: {this.props.accountData?.tax_file_no}</li>
                    ) : (
                      <li className="tfn">
                        ABN:{' '}
                        {this.formatNumber(this.props.accountData.abn_acn, 'abn_acn')}
                      </li>
                    )}

                    {this.props.accountData.address.postcode && (
                      <li className="address">
                        {this.props.accountData?.address.street_address_1}{' '}
                        {this.props.accountData?.address.street_address_1 ? ',' : ''}{' '}
                        {this.props.accountData?.address.street_address_2}{' '}
                        {this.props.accountData?.address.street_address_2 ? ',' : ''}
                        {this.props.accountData?.address.suburb}, <br />
                        {this.props.accountData?.address.state} ,
                        {this.props.accountData?.address.postcode}
                      </li>
                    )}
                  </ul>
                </div>
              </div>

              {this.props.accountData.account_type !== 'personal' && (
                <div className="u-mb--30 subcontractor--profile__details mt-4">
                  {this.props.accountData.company_description && (
                    <div className="profile--coloumn">
                      <div className="profile--box">
                        <h3>About</h3>
                        <p>{this.props.accountData.company_description}</p>
                      </div>
                    </div>
                  )}
                  {this.props.accountData.services.length > 0 &&
                    this.props.accountData.services[0] !== '' && (
                      <div className="profile--coloumn">
                        <div className="profile--box">
                          <h3>Services</h3>
                          <ul className="styled--check">
                            {this.props.accountData.services.map((service: any) => (
                              <li key={service}>{service}</li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                </div>
              )}
            </div>
          </Col>
        </Row>
        <InviteSelectRoleModal
          inviteAccountUuid={this.props.accountData.uuid}
          isOpen={this.state.selectRoleModal}
          toggle={this.selectRoleModalToggler}
          rolesOption={this.state.rolesOption}
        />
      </>
    );
  }
}

export default withRouter(AccountDetail);
