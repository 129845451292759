import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {}

interface State {}

class Loader extends React.Component<Props, State> {
  render() {
    return (
      <>
        <ContentLoader
          speed={3}
          width={600}
          height={250}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
        >
          <rect x="0" y="12" rx="4" ry="4" width="90" height="8" />
          <rect x="0" y="30" rx="4" ry="4" width="50" height="8" />
          <rect x="0" y="60" rx="4" ry="4" width="400" height="8" />
          <rect x="0" y="78" rx="4" ry="4" width="380" height="8" />
          <rect x="0" y="96" rx="4" ry="4" width="170" height="8" />
        </ContentLoader>
      </>
    );
  }
}

export default Loader;
