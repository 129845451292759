import React from 'react';
import { DropdownMenu } from 'reactstrap';
import { Link, RouteComponentProps, withRouter, matchPath } from 'react-router-dom';

// Application Imports;
import AppContext from '../../../../components/AppContext';
import LocalStorage from '../../../../helpers/LocalStorage';
import EditCreditCardModal from '../../../../pages/common/accounts/EditCreditCardModal';

// Import Icons
import Done from '@material-ui/icons/Done';
import ThumbUp from '@material-ui/icons/ThumbUp';

// Import Css
import './dropdown.scss';

interface Props extends RouteComponentProps {
  logout: Function;
  toggle: Function;
}

interface State {
  modalEditCreditCard: boolean;
}

class UserDropdown extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      modalEditCreditCard: false,
    };
  }

  toggleCreditCardModal = () => {
    this.setState({
      modalEditCreditCard: !this.state.modalEditCreditCard,
    });
  };

  switchAccountDetail = (accountData: any) => {
    const { setActiveAccountUUID } = this.context;
    setActiveAccountUUID(accountData.uuid);
    this.props.history.push('/dashboard');
    this.props.toggle();
  };

  render() {
    const accountsList = LocalStorage.getAccountsList();
    let hasCorporateAccount = LocalStorage.hasCorporateAccount();
    let hasCorporateViewAccount = LocalStorage.hasCorporateViewAccount();
    const hasManagerRole = LocalStorage.hasManagerRole();
    const { activeAccountUUID } = this.context;

    return (
      <React.Fragment>
        <DropdownMenu right className="w220">
          <ol className="dropdown-menu__lists">
            <>
              {accountsList.length > 0 &&
                accountsList.map((accountDetail: any) => (
                  <li
                    key={accountDetail?.uuid}
                    className={
                      accountDetail.uuid === activeAccountUUID ? 'is--selected' : ''
                    }
                  >
                    {accountDetail.uuid === activeAccountUUID && (
                      <Done fontSize="small" className="svg--icon icons u-mr--10" />
                    )}
                    <button
                      className="text--ellipsis line1 w150"
                      onClick={() => this.switchAccountDetail(accountDetail)}
                    >
                      {accountDetail?.company_name}
                    </button>
                  </li>
                ))}
              {hasCorporateViewAccount && (
                <li>
                  <button
                    onClick={this.toggleCreditCardModal}
                    className="btn--upgrade btn-radius btn-sm"
                  >
                    <ThumbUp fontSize="small" className="icons u-mr--5" /> Upgrade
                  </button>
                </li>
              )}
              {!hasCorporateAccount && !hasCorporateViewAccount && (
                <li
                  className={
                    this.props.location.pathname === '/company/add' ? 'is--selected' : ''
                  }
                >
                  <Link
                    to="/company/add"
                    className="link--blue"
                    onClick={() => this.props.toggle()}
                  >
                    + new company
                  </Link>
                </li>
              )}
              <li className="dropdown-divider" />
            </>
            {hasCorporateAccount && !hasManagerRole && (
              <>
                <li
                  className={
                    matchPath(this.props.match.path, {
                      path: '/corporate/role',
                    })
                      ? 'is--selected'
                      : ''
                  }
                >
                  <Link to="/corporate/role" onClick={() => this.props.toggle()}>
                    Manage Roles
                  </Link>
                </li>

                <li
                  className={
                    this.props.location.pathname === '/corporate/manage/user'
                      ? 'is--selected'
                      : ''
                  }
                >
                  <Link to="/corporate/manage/user" onClick={() => this.props.toggle()}>
                    Manage Users
                  </Link>
                </li>
              </>
            )}

            <li
              className={
                this.props.location.pathname === '/profile/settings' ? 'is--selected' : ''
              }
            >
              <Link to="/profile/settings" onClick={() => this.props.toggle()}>
                My Profile Settings
              </Link>
            </li>

            {!hasCorporateViewAccount && !hasManagerRole && (
              <>
                <li
                  className={
                    this.props.location.pathname === '/account/settings'
                      ? 'is--selected'
                      : ''
                  }
                >
                  <Link to="/account/settings" onClick={() => this.props.toggle()}>
                    Account Settings
                  </Link>
                </li>

                <li
                  className={
                    this.props.location.pathname === '/activity/log' ? 'is--selected' : ''
                  }
                >
                  <Link to="/activity/log" onClick={() => this.props.toggle()}>
                    Activity Logs
                  </Link>
                </li>
              </>
            )}
            <li>
              <button onClick={() => this.props.logout()}>Logout</button>
            </li>
          </ol>
        </DropdownMenu>
        <EditCreditCardModal
          area="upgradeAccount"
          isOpen={this.state.modalEditCreditCard}
          toggle={this.toggleCreditCardModal}
          getAccountDetail={() => {}}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(UserDropdown);
