import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row, Col, Card, Button } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Application Imports
import appConfig from '../../config';
import AuthPageWrapper from '../common/AuthPageWrapper';
import AlertError from '../../layout/includes/Alert/Error';

// Import Axios instance
import axiosInstance from '../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {}

interface State {
  userInput: string;
  submitFormRequest: boolean;
  showThankDiv: boolean;
  alertErrorMessage: Array<string> | string;
}

class ForgotPassword extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userInput: '',
      submitFormRequest: false,
      showThankDiv: false,
      alertErrorMessage: '',
    };
  }

  submitHandler = (event: React.FormEvent<HTMLFormElement>, values: any) => {
    const data = {
      user_input: values.userInput,
    };

    // loader set to true and error message set to blank;
    this.setState({
      alertErrorMessage: '',
      submitFormRequest: true,
    });
    document.getElementById('submit')?.setAttribute('disabled', 'true');

    //sending a post request to the server
    axiosInstance
      .post('/auth/forgot-password', data)
      .then((response: AxiosResponse) => {
        //disabling spinner
        this.setState({
          submitFormRequest: false,
        });
        document.getElementById('submit')?.removeAttribute('disabled');

        if (response.data.success === 1) {
          this.setState({
            userInput: values.userInput,
            showThankDiv: true,
          });
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitFormRequest: false,
        });
        document.getElementById('submit')?.removeAttribute('disabled');
      });
  };

  componentDidMount() {
    document.title = `Forgot Password ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <AuthPageWrapper>
        {!this.state.showThankDiv ? (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--0">
              <h2 className="card--title card--title-sm">Forgot Password</h2>
            </div>
            <div className="card--body card--body-sm">
              <AlertError message={this.state.alertErrorMessage} />
              <p className="fs--15 dark-gray text-center">
                Enter your Email or CRN below to be sent a reset password
              </p>
              <AvForm
                className="form-horizontal u-mt--30"
                onValidSubmit={this.submitHandler}
                autoComplete="off"
              >
                <AvField
                  type="text"
                  name="userInput"
                  label="Email or CRN"
                  id="email"
                  required
                  errorMessage="Please Enter Email or CRN"
                />

                <Row className="u-mt--30">
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className="u-flexed u-align-center u-justify-center"
                  >
                    <Button
                      color="primary"
                      className={`btn-radius btn-w210 ${
                        this.state.submitFormRequest ? `inlineloading` : ``
                      }`}
                      id="submit"
                    >
                      Submit
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </div>
          </Card>
        ) : (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--10">
              <h2 className="card--title card--title-sm">You have been sent a link</h2>
            </div>
            <div className="card--body card--body-sm">
              <div className="u-flexed u-flex-direction-column u-align-center u-justify-center text-center">
                <p className="fs--15 dark-gray u-mb--20">
                  We have sent a link to{' '}
                  <a href={`mailto:${this.state.userInput}`} className="links--primary">
                    {this.state.userInput}
                  </a>
                  . Click the link in your email to reset your password.
                </p>

                <Link
                  to="/auth/login"
                  className="c-button c-button--primary c-button-w210 c-button-radius"
                >
                  GO TO LOGIN
                </Link>
              </div>
            </div>
          </Card>
        )}
      </AuthPageWrapper>
    );
  }
}

export default ForgotPassword;
