import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

interface Props extends RouteComponentProps {
  selectedArea: string;
}

interface State {}

class Breadcrumb extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="c-breadcrumb--section c-breadcrumb--section-sm u-mb--0">
        <Container fluid className="container-fluid-sm">
          <Row className="u-flexed u-align-center u-justify-btw">
            <Col xs={12} sm={12} md={12} lg={12}>
              <div className="u-flexed u-align-center u-justify-start">
                <ul className="c-breadcrumb--lists c-breadcrumb--lists-sm">
                  <li>
                    <Link
                      to="/links"
                      className={
                        this.props.selectedArea === 'linkedCompanies'
                          ? 'is--selected'
                          : ''
                      }
                    >
                      Linked Companies
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/links/project"
                      className={
                        this.props.selectedArea === 'myProject' ? 'is--selected' : ''
                      }
                    >
                      My Projects
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default withRouter(Breadcrumb);
