import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ReactWOW from 'react-wow';
import { Container, Row, Col } from 'reactstrap';

interface Props {}

interface State {}

class SectionTry4Free extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <section className="section--try4free section--try4free-sm" data-bg={'black'}>
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={9} lg={9} className="col-centered text-center">
                <ReactWOW animation="fadeInUp" delay="200ms">
                  <div className="page-head page-head-sm noborder center u-mb--50 u-mb--50-sm">
                    <h3 className="u-heading--large u-heading--large-sm text-white u-mb--0">
                      <strong>Try for free</strong>
                    </h3>
                  </div>
                </ReactWOW>
                <div className="equal--width equal--width-sm u-mb--0">
                  <ReactWOW animation="fadeInLeft">
                    <Link
                      to="Pricing"
                      spy={true}
                      smooth={true}
                      offset={-30}
                      duration={500}
                      className="button button--primary button--primary-gradient button-inlineflex button-w190"
                    >
                      Try For Free
                    </Link>
                  </ReactWOW>
                  <ReactWOW animation="fadeInRight">
                    <a
                      href="mailto:hello@owlcds.com.au"
                      className="button button--green button-inlineflex button-w190"
                    >
                      Request A Demo
                    </a>
                  </ReactWOW>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionTry4Free;
