import React from 'react';
import Datetime from 'react-datetime';
import Select from 'react-select';
import {
  Container,
  Row,
  Col,
  Label,
  FormGroup,
  ListGroupItem,
  ListGroup,
  Input,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import scrollToComponent from 'react-scroll-to-component';
import { toast } from 'react-toastify';
import { RouteComponentProps } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';

// Application Imports
import Breadcrumb from './Breadcrumb';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Project from '../../../api/Project';
import LinkMember from '../../../api/LinkMember';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AlertError from '../../../layout/includes/Alert/Error';
import AddMemberModal from './AddMemberModal';
import ManageUser from '../../../api/ManageUser';
import { getDate } from '../../../helpers/Helpers';

// Import CSS
import './teams.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-confirm-alert/src/react-confirm-alert.css';

// Static Images
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {}

interface State {
  startDate: Date | undefined;
  formSubmitted: boolean;
  showLoader: boolean;
  projectManagerValue: Array<any>;
  projectManagerValueError: boolean;
  managerOptions: Array<any>;
  addedMemberList: Array<any>;
  memberList: Array<any>;
  projectManagersObj: Array<any>;
  errorMessages: Array<string> | string;
  memberModalOpen: boolean;
  projectDetail: any;
  updateMemberList: boolean;
  isEdit: boolean;
}

class EditProject extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.alertErrorRef = React.createRef();
    this.state = {
      projectDetail: {},
      startDate: undefined,
      formSubmitted: false,
      showLoader: true,
      projectManagerValue: [],
      projectManagerValueError: false,
      managerOptions: [],
      projectManagersObj: [],
      addedMemberList: [],
      memberList: [],
      errorMessages: '',
      memberModalOpen: false,
      updateMemberList: true,
      isEdit: true,
    };
  }

  memberModalHandler = () => {
    this.setState({
      memberModalOpen: !this.state.memberModalOpen,
    });
  };

  handleChange = (date: any) => {
    this.setState({
      startDate: date._d,
    });
  };

  managerChangeHandler = (data: any) => {
    this.setState({
      projectManagersObj: data,
      projectManagerValue: data,
      projectManagerValueError: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { projectManagerValue } = this.state;
    const { activeAccountUUID } = this.context;
    let isError = false;

    if (projectManagerValue === null || projectManagerValue.length === 0) {
      this.setState({
        projectManagerValueError: true,
      });
      isError = true;
    }

    if (errors.length > 0) {
      isError = true;
    }

    if (isError) {
      return;
    }

    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    const projectManagersUserUuid = projectManagerValue.map((data: any) => {
      return data.value;
    });

    // request to server;
    Project.updateProject(
      activeAccountUUID,
      this.state.projectDetail.uuid,
      values.projectname,
      values.description,
      projectManagersUserUuid,
      getDate(this.state.startDate)
    )
      .then((response: any) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          toast.success('Project updated successfully.', {
            autoClose: 3000,
          });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err: any) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  convertLinkedMembersToArray = (data: any) => {
    let dataArray: any = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        let objData: any = {
          label: data[i].first_name + ' ' + data[i].last_name + ' (' + data[i].role + ')',
          value: data[i].uuid,
        };
        dataArray.push(objData);
      }
    }
    return dataArray;
  };

  removeMemberListHandlerConfimation = (projectAccountUuid: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to remove this member?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeMemberHandler(projectAccountUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  removeMemberHandler = (projectAccountUuid: string) => {
    const { activeAccountUUID } = this.context;

    let buttonElement: any = document.getElementById(projectAccountUuid);
    buttonElement.classList.toggle('inlineloading');
    buttonElement.setAttribute('disabled', 'true');
    buttonElement.innerText = '';

    // request to server;
    Project.removeAccountFromProject(
      activeAccountUUID,
      this.state.projectDetail.uuid,
      projectAccountUuid
    )
      .then((response: any) => {
        if (response.data.success === 1) {
          const addedMemberList = this.state.addedMemberList.filter((data: any) => {
            return data.uuid !== projectAccountUuid;
          });
          this.setState((prevState) => ({
            addedMemberList,
          }));
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .then(() => {
        buttonElement.classList.toggle('inlineloading');
        buttonElement.removeAttribute('disabled');
        buttonElement.innerText = 'Remove';
      });
  };

  //Adding members to the project
  addSelectedMemberListHandler = (memberList: any) => {
    const { activeAccountUUID } = this.context;
    this.memberModalHandler();

    // request to server;
    Project.addAccontToProject(
      activeAccountUUID,
      this.state.projectDetail.uuid,
      memberList
    ).then((response: any) => {
      if (response.data.success === 1) {
        this.setState((prevState) => ({
          addedMemberList: response.data.data.assigned_list,
        }));
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  fetchMemberLists = () => {
    const { activeAccountUUID } = this.context;

    LinkMember.getAccountsList(activeAccountUUID, true, true).then((response: any) => {
      if (response.data.success === 1) {
        this.setState({
          memberList: response.data.data.linked,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  fetchUserLists = () => {
    const { activeAccountUUID } = this.context;

    ManageUser.getUserList(activeAccountUUID).then((response: any) => {
      if (response.data.success === 1) {
        this.setState({
          managerOptions: this.convertLinkedMembersToArray(response.data.data.userList),
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  fetchProjectData = () => {
    let projectUuid: any = this.props.match.params.uuid;
    const { activeAccountUUID } = this.context;

    if (projectUuid.includes('#')) {
      projectUuid = projectUuid.split('#').shift();
    }

    // request to server;
    Project.getProject(activeAccountUUID, projectUuid).then((response: any) => {
      if (response.data.success === 1) {
        let responseDate = response.data.data.project_detail.start_date;
        this.setState({
          showLoader: false,
          addedMemberList: response.data.data.assigned_list,
          projectManagerValue: response.data.data.project_detail.managers,
          projectManagersObj: response.data.data.project_detail.managers,
          projectDetail: response.data.data.project_detail,
          startDate: responseDate === null ? undefined : new Date(responseDate),
          isEdit: response.data.data.project_detail.invited ? false : true,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  toggleProjectStatus = () => {
    let projectUuid: any = this.props.match.params.uuid;
    const { activeAccountUUID } = this.context;
    this.setState({
      showLoader: true,
    });

    // request to server;
    Project.toggleProjectStatus(
      activeAccountUUID,
      projectUuid,
      this.state.projectDetail.is_completed ? 0 : 1
    ).then((response: any) => {
      if (response.data.success === 1) {
        const projectDetail = { ...this.state.projectDetail };

        projectDetail.is_completed = !projectDetail.is_completed;

        this.setState({
          showLoader: false,
          projectDetail: projectDetail,
        });
      } else {
        this.setState({
          showLoader: false,
        });
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Edit Project ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch team manager options;
    this.fetchMemberLists();
    this.fetchUserLists();
    this.fetchProjectData();

    // scroll to target id
    let currentLocation = window.location.href;
    const hasCommentAnchor = currentLocation.includes('#');
    if (hasCommentAnchor) {
      const anchorCommentId = `${currentLocation.substring(
        currentLocation.indexOf('#') + 1
      )}`;
      setTimeout(() => {
        const anchorComment = document.getElementById(anchorCommentId);
        if (anchorComment) {
          anchorComment.scrollIntoView({ behavior: 'smooth' });
        }
      }, 1000);
    }
  }

  render() {
    return (
      <>
        <Breadcrumb prevPath={this.props.location.state} />
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              <div className="myteams--section myteams--section-sm">
                {this.state.showLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="page-head page-head-sm start u-justify-btw noborder align-items-center sm:flex-row mb-2">
                      <h2 className="u-heading--medium u-heading--medium-sm sm:mb--0">
                        {this.state.isEdit ? 'Edit Project' : 'Manage Team'}
                      </h2>
                      {this.state.isEdit && (
                        <div className="ml-auto w-auto">
                          <Label check className="custom-toggle--label">
                            <span className="mr-2 pointer">Completed?</span>
                            <div className="custom-toggle">
                              <Input
                                type="checkbox"
                                defaultChecked={
                                  this.state.projectDetail.is_completed ? true : false
                                }
                                onChange={() => this.toggleProjectStatus()}
                              />
                              <span className="custom-toggle-slider"></span>
                            </div>
                          </Label>
                        </div>
                      )}
                    </div>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />

                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      {this.state.isEdit ? (
                        <>
                          <AvField
                            required
                            name="projectname"
                            label="Project name"
                            placeholder="Enter project name"
                            value={this.state.projectDetail.project_name}
                            errorMessage="Project Name is required"
                            autoComplete="off"
                          />
                          <AvField
                            type="textarea"
                            name="description"
                            label="Description (optional)"
                            placeholder="Description"
                            value={this.state.projectDetail.description}
                          />

                          <FormGroup
                            className={
                              this.state.projectManagerValueError
                                ? 'form-group--has-error'
                                : ''
                            }
                          >
                            <Label htmlFor="teamManager">Select Project Manager(s)</Label>
                            <div className="Team--Manager">
                              <Select
                                isMulti
                                name="teamManager"
                                options={this.state.managerOptions}
                                className="basic-multi-select"
                                onChange={this.managerChangeHandler}
                                classNamePrefix="select"
                                value={this.state.projectManagersObj}
                                placeholder="Select Project Manager(s)"
                              />
                            </div>
                            <span className="help-block">
                              Please select Project Manager
                            </span>
                          </FormGroup>

                          <FormGroup>
                            <Label htmlFor="startdate">
                              Estimated start date (optional)
                            </Label>
                            <Datetime
                              inputProps={{
                                placeholder: 'DD/MM/YYYY',
                                name: 'startdate',
                              }}
                              value={this.state.startDate}
                              onChange={this.handleChange}
                              dateFormat="DD/MM/YYYY"
                              timeFormat={false}
                              closeOnSelect={true}
                              className="size--md"
                            />
                          </FormGroup>

                          <Row className="u-mt--30 u-mt--30-sm">
                            <Col className="text-center">
                              <button
                                type="submit"
                                disabled={this.state.formSubmitted}
                                className={`c-button c-button--green c-button-inlineflex c-button-w210 c-button-radius c-button-sm u-upper ${
                                  this.state.formSubmitted ? `inlineloading` : ``
                                }`}
                              >
                                Save Project
                              </button>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <Label>
                          <strong>Project:</strong>{' '}
                          {this.state.projectDetail.project_name}
                        </Label>
                      )}
                      <div className="clearfix"></div>
                      <div className="hr">
                        <hr></hr>
                      </div>

                      <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--0">
                        <h2 className="u-heading--medium u-heading--medium-sm">
                          Project Members/ Subcontractors (
                          {this.state.addedMemberList.length})
                        </h2>
                      </div>

                      {this.state.addedMemberList.length > 0 && (
                        <>
                          <div className="custom--search__lists">
                            <ListGroup>
                              {this.state.addedMemberList.map((data: any) => (
                                <ListGroupItem key={data.uuid}>
                                  <FormGroup>
                                    <div className="u-flexed u-align-center">
                                      <div className="avatar--40">
                                        <img
                                          src={
                                            data.profileImageUrl
                                              ? data.profileImageUrl
                                              : ProfileAvatar
                                          }
                                          width="40"
                                          alt="Profile Avatar"
                                        />
                                      </div>
                                      <div className="members--details">
                                        <p className="members--name">{data.name}</p>
                                        <p className="members--position">
                                          {data.roleDisplayAs}
                                        </p>
                                      </div>
                                    </div>
                                    <button
                                      type="button"
                                      id={data.uuid}
                                      className="c-button c-button--warning c-button-inline c-button-radius c-button--absolute"
                                      onClick={() =>
                                        this.removeMemberListHandlerConfimation(data.uuid)
                                      }
                                    >
                                      REMOVE
                                    </button>
                                  </FormGroup>
                                </ListGroupItem>
                              ))}
                            </ListGroup>
                          </div>
                        </>
                      )}
                      <div className="clearfix"></div>
                      <Row className="u-mt--25">
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <button
                            id="addMember"
                            type="button"
                            onClick={this.memberModalHandler}
                            className="c-button c-button-primary c-button-inlineflex c-button-radius c-button-sm u-upper"
                          >
                            + Add
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
        <AddMemberModal
          isOpen={this.state.memberModalOpen}
          toggle={this.memberModalHandler}
          memberList={this.state.memberList}
          addedMemberList={this.state.addedMemberList}
          addSelectedMemberListHandler={this.addSelectedMemberListHandler}
        />
      </>
    );
  }
}

export default EditProject;
