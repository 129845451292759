import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Import Icons
import ManageUser from '../../../assets/images/Invite-Team-Members.jpg';

interface Props {
  toggle: Function;
}

interface State {}

class BlankScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={6} lg={5} className="col-centered">
              <div className="invite--section invite--section-sm text-center">
                <div className="invite--graphic invite--graphic-sm u-mb--60 u-mb--60-sm">
                  <img src={ManageUser} alt="Manage Users" className="img-fluid" />
                </div>
                <div className="page-head page-head-sm center column noborder">
                  <h2 className="u-heading--xsmall u-heading--xsmall-sm">
                    <span style={{ color: '#4f4f4f' }}>No Users Found</span>
                  </h2>
                  <p className="text-center fs--13 dark-gray">
                    Add user to link to your company. This will allow them to have access
                    to their projects.
                  </p>
                </div>
                <button
                  type="button"
                  onClick={() => this.props.toggle()}
                  className="c-button c-button--primary c-button-radius c-button-w210 c-button-inline size--xs has-icon--before"
                >
                  Add User
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BlankScreen;
