import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  data: any;
}

interface State {}

class ProfileUpdate extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <>
        <img
          className="avatar size--18"
          src={
            data.userProfilePic
              ? data.userProfilePic
              : data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : Avatar
          }
          alt={data.userFirstName + ' ' + data.userLastName}
        />
        <p className="u-mr--5">
          {data.userFirstName} {data.userLastName}
        </p>
        updated profile details
      </>
    );
  }
}

export default withRouter(ProfileUpdate);
