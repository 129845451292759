import Api from './Api';

export default class ManageUser extends Api {
  static getUserList = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/corporate-user/get-users', postData);
  };

  static addEditUser = async (
    accountUuid: string,
    firstName: string,
    lastName: string,
    mobileNumber: string,
    email: string,
    password: string,
    role: string,
    url: string,
    userUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_uuid: userUuid,
      first_name: firstName,
      last_name: lastName,
      mobile_number: mobileNumber,
      email: email,
      password: password,
      role: role,
    };

    return Api.postRequest(url, postData);
  };

  static deleteUser = async (accountUuid: string, userUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_uuid: userUuid,
    };

    return Api.postRequest('/corporate-user/mark-delete', postData);
  };
}
