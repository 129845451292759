import React from 'react';
import { DropdownMenu } from 'reactstrap';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

//Application Imports
import AppContext from '../../../../components/AppContext';

//Import CSS
import './dropdown.scss';

interface Props extends RouteComponentProps {
  projectUuid: string;
  deleteProjectConfirmation: Function;
  area: string;
}

interface State {}

class ProfileDropdown extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <DropdownMenu right className="w200">
          <ol className="dropdown-menu__lists profile--menu">
            <li>
              <Link
                to={{
                  pathname: `/corporate/project/edit/${this.props.projectUuid}`,
                  state: {
                    prevPath: this.props.location.pathname,
                  },
                }}
                className="u-ml--5"
              >
                <i className="svg--icon">
                  <svg
                    className="owl__icon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z" />
                  </svg>
                </i>
                {this.props.area !== 'inviteProject' ? 'Edit Project' : 'Manage Team'}
              </Link>
            </li>
            {this.props.area !== 'inviteProject' && (
              <>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={() =>
                      this.props.deleteProjectConfirmation(this.props.projectUuid)
                    }
                    className="text--error"
                  >
                    Delete Project
                  </button>
                </li>
              </>
            )}
          </ol>
        </DropdownMenu>
      </>
    );
  }
}

export default withRouter(ProfileDropdown);
