import React from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import { AvForm, AvGroup, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports
import Roles from '../../../api/Roles';
import Document from '../../../api/Document';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import EditRoleBreadcrumb from './EditRoleBreadcrumb';
import ViewLinkedDocumentModal from '../../common/account-links/ViewLinkedDocumentModal';
import LinkMember from '../../../api/LinkMember';

//Application UI Imports
import SelectOptions from '../../../components/UI/SelectOptions';
import AlertError from '../../../layout/includes/Alert/Error';
import DocumentModal from './DocumentModal';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import {
  CapitalizeWords,
  GetAccountDocumentStatusClassName,
} from '../../../helpers/Helpers';

// Import CSS
import './role.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import Visibility from '@material-ui/icons/Visibility';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {}

interface State {
  isEditMode: boolean;
  allDataTypes: any;
  industryOptions: any;
  tradeOptions: any;
  showLoader: boolean;
  errorMessages: Array<string> | string;
  formSubmitted: boolean;
  redirectEditPage: boolean;
  editRoleDetail: any;
  documentTypeOptions: any;
  typeOptions: any;
  documentModal: boolean;
  documentList: Array<any>;
  appliedList: Array<any>;
  viewDocumentModalOpen: boolean;
  previewDocumentList: Array<any>;
}

class AddEditRole extends React.Component<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      isEditMode: matchPath(this.props.match.path, {
        path: '/corporate/role/edit/:uuid',
        exact: true,
      })
        ? true
        : false,
      showLoader: true,
      allDataTypes: [],
      industryOptions: null,
      tradeOptions: null,
      errorMessages: '',
      formSubmitted: false,
      redirectEditPage: false,
      editRoleDetail: {},
      documentTypeOptions: {},
      typeOptions: [],
      documentModal: false,
      documentList: [],
      appliedList: [],
      viewDocumentModalOpen: false,
      previewDocumentList: [],
    };
  }

  previewDocumentModal = (userAccountUuid: string, fetchData?: boolean) => {
    const { activeAccountUUID } = this.context;
    if (!this.state.viewDocumentModalOpen) {
      // button loading state;
      let buttonElement: any = document.getElementById(`preview_${userAccountUuid}`);
      if (buttonElement) {
        buttonElement.classList.toggle('inlineloading');
      }

      if (fetchData) {
        // request to server;
        LinkMember.getLinkedMemberSubmittedDocuments(activeAccountUUID, userAccountUuid)
          .then((response) => {
            if (response.data.success === 1) {
              this.setState({
                previewDocumentList: response.data.data.documents,
                viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
              });
            } else {
              toast.error(response.data.messages[0], {
                autoClose: 3000,
              });
            }
          })
          .then(() => {
            if (buttonElement) {
              buttonElement.classList.toggle('inlineloading');
            }
          });
      } else {
        this.fetchRole();
      }
    } else {
      this.setState({
        viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
      });
    }
  };

  documentModalToggler = () => {
    this.setState({
      documentModal: !this.state.documentModal,
    });
  };

  industryChangeHandler = (data: any) => {
    this.fetchTradeTypes(data.target.value);
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;

    let roleUuid = this.props.match.params.uuid;
    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    //Add Role
    if (!this.state.isEditMode) {
      // request to server;
      Roles.insertRoles(activeAccountUUID, values.rolename, values.industry, values.trade)
        .then((response) => {
          this.setState({
            formSubmitted: false,
          });
          if (response.data.success === 1) {
            toast.success('Role created successfully.', {
              autoClose: 3000,
            });
            this.setState({
              redirectEditPage: true,
              isEditMode: true,
              editRoleDetail: response.data.data.role,
            });
            this.props.history.push(
              `/corporate/role/edit/${response.data.data.role.uuid}`
            );
            this.fetchDocumentTypes();
          } else {
            this.setState({
              errorMessages: response.data.messages,
            });
            scrollToComponent(this.alertErrorRef, { duration: 500 });
          }
        })
        .catch((err) => {
          this.setState({
            formSubmitted: false,
          });
        });
    }

    //Edit Role
    if (this.state.isEditMode) {
      // request to server;
      Roles.updateRole(
        activeAccountUUID,
        roleUuid,
        values.rolename,
        values.industry,
        values.trade
      )
        .then((response) => {
          this.setState({
            formSubmitted: false,
          });
          if (response.data.success === 1) {
            toast.success('Role updated successfully.', {
              autoClose: 2000,
            });
          } else {
            this.setState({
              errorMessages: response.data.messages,
            });
            scrollToComponent(this.alertErrorRef, { duration: 500 });
          }
        })
        .catch((err) => {
          this.setState({
            formSubmitted: false,
          });
        });
    }
  };

  convertArrayDataObj = (data: any) => {
    let dataArray: any = [];
    if (data.length > 0 && data[0] !== '') {
      if (typeof data[0] === 'string') {
        for (let i = 0; i < data.length; i++) {
          let objData: any = { value: data[i], label: data[i] };
          dataArray.push(objData);
        }
      }
    }
    return dataArray;
  };

  getTypeToRender = (value: string) => {
    let typesLicense: any;
    const { documentTypeOptions } = this.state;
    for (let i = 0; i < documentTypeOptions.length; i++) {
      if (value === documentTypeOptions[i].value) {
        if (documentTypeOptions[i].optionSubTypes) {
          let optionData: any = [];
          for (
            let index = 0;
            index < documentTypeOptions[i].optionSubTypes.length;
            index++
          ) {
            let dataOptionGroup: any = {
              label: documentTypeOptions[i].optionSubTypes[index].label,
              options: this.convertArrayDataObj(
                documentTypeOptions[i].optionSubTypes[index].options
              ),
            };
            optionData.push(dataOptionGroup);
          }
          typesLicense = optionData;
        }

        if (documentTypeOptions[i].subTypes) {
          typesLicense = this.convertArrayDataObj(documentTypeOptions[i].subTypes);
        }
      }
    }
    return typesLicense;
  };

  fetchDocumentTypes = () => {
    const { activeAccountUUID } = this.context;

    Document.getTypes(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          documentTypeOptions: response.data.data.document_type,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  fetchIndustryData = () => {
    // Get data from server
    Roles.getIndustryTypes().then((response) => {
      this.setState({
        showLoader: false,
      });
      if (response.data.success === 1) {
        let industries = Object.keys(response.data.data.industries);
        this.setState({
          industryOptions: this.convertArrayDataObj(industries),
          allDataTypes: response.data.data.industries,
        });
      }
    });
  };

  fetchTradeTypes = (id: string) => {
    const { allDataTypes } = this.state;
    if (allDataTypes.hasOwnProperty(id)) {
      let tradeArray = allDataTypes[id];
      this.setState({
        tradeOptions: this.convertArrayDataObj(tradeArray),
      });
    } else {
      this.setState({
        tradeOptions: null,
      });
    }
  };

  fetchRole = () => {
    const { activeAccountUUID } = this.context;
    let roleUuid = this.props.match.params.uuid;
    if (roleUuid === '' && this.state.editRoleDetail.uuid !== '') {
      roleUuid = this.state.editRoleDetail.uuid;
    }

    this.setState({
      showLoader: true,
    });

    // request to server for get roles data;
    Roles.getRole(activeAccountUUID, roleUuid, true).then((response) => {
      this.setState({
        showLoader: false,
      });
      if (response.data.success === 1) {
        let industries = Object.keys(response.data.data.industries);
        this.setState({
          showLoader: false,
          editRoleDetail: response.data.data.role,
          documentList: response.data.data.documents,
          appliedList: response.data.data.applied_list,
          industryOptions: this.convertArrayDataObj(industries),
          allDataTypes: response.data.data.industries,
        });
        this.fetchTradeTypes(response.data.data.role.industry);
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
        this.props.history.push('/corporate/role');
      }
    });
  };

  addDocTolist = (data: any) => {
    this.setState((prevState) => ({
      documentList: [...prevState.documentList, data],
    }));
  };

  removeRoleDocumentConfirm = (id: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this requirement?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.removeDocument(id),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  removeDocument = (id: string) => {
    let buttonElement: any = document.getElementById(id);
    buttonElement.classList.toggle('inlineloading');
    buttonElement.setAttribute('disabled', 'true');

    const { activeAccountUUID } = this.context;
    const roleUuid = this.props.match.params.uuid;

    Roles.deleteDcoument(activeAccountUUID, roleUuid, id)
      .then((response) => {
        buttonElement.classList.toggle('inlineloading');
        buttonElement.removeAttribute('disabled');

        if (response.data.success === 1) {
          const documentListArray = this.state.documentList.filter((data) => {
            return data.uuid !== id;
          });

          this.setState({ documentList: documentListArray });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });

          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {});
  };

  documentOptionToggler = (id: string) => {
    this.setState({
      errorMessages: '',
    });

    const { activeAccountUUID } = this.context;
    const roleUuid = this.props.match.params.uuid;

    Roles.updateDocumentOption(activeAccountUUID, roleUuid, id).then((response) => {
      if (response.data.success === 1) {
        const documentListArray = this.state.documentList.filter((data) => {
          if (data.uuid === id) {
            data.optional = data.optional === 1 ? 0 : 1;
          }
          return data;
        });
        this.setState({
          documentList: documentListArray,
        });
      } else {
        this.setState({
          errorMessages: response.data.messages,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `${this.state.isEditMode ? `Edit` : `Add`} Role ${
      appConfig.DOCUMENT_TITLE_POSTFIX
    }`;

    // fetch existing role data;
    if (this.state.isEditMode) {
      this._loadRole();
    } else {
      // fetch Industry type;
      this.fetchIndustryData();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.redirectEditPage === false &&
      this.state.redirectEditPage &&
      this.state.editRoleDetail.uuid !== ''
    ) {
      document.title = `Edit Role ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
    }
  }

  _loadRole() {
    this.fetchRole();
    this.fetchDocumentTypes();
  }

  render() {
    return (
      <>
        <EditRoleBreadcrumb
          showDeleteButton={
            this.state.isEditMode &&
            this.state.editRoleDetail.uuid &&
            this.state.appliedList.length === 0
              ? true
              : false
          }
        />
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              {this.state.showLoader ? (
                <Loader />
              ) : (
                <div className="myteams--section myteams--section-sm">
                  <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                    <h2 className="u-heading--medium u-heading--medium-sm">
                      {this.state.isEditMode ? 'Edit' : 'Create'} role
                    </h2>
                  </div>
                  <p className="fs--18 dark-gray">
                    Roles will be applied to employees when they are added
                  </p>
                  <AlertError
                    ref={(div: any) => {
                      this.alertErrorRef = div;
                    }}
                    message={this.state.errorMessages}
                  />
                  <AvForm
                    className="form-horizontal"
                    autoComplete="off"
                    onValidSubmit={this.submitHandler}
                  >
                    <AvField
                      autoFocus
                      name="rolename"
                      className="form-control"
                      placeholder="Name of role"
                      label="Name of role"
                      required
                      errorMessage="Please enter Role Name"
                      value={this.state.editRoleDetail.title}
                    />

                    <Row>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <AvGroup>
                          <Label htmlFor="industry">Industry</Label>
                          <div className="select--wrapper">
                            <AvField
                              required
                              id="industry"
                              name="industry"
                              type="select"
                              placeholder="Please select Industry"
                              onChange={this.industryChangeHandler}
                              value={this.state.editRoleDetail.industry}
                            >
                              <SelectOptions
                                data={this.state.industryOptions}
                                placeholder="Please select Industry..."
                              />
                            </AvField>
                          </div>
                        </AvGroup>
                      </Col>
                      <Col xs={12} sm={12} md={6} lg={6}>
                        <AvGroup>
                          <Label htmlFor="trade">Field</Label>
                          <div className="select--wrapper">
                            <AvField
                              required
                              id="trade"
                              name="trade"
                              type="select"
                              errorMessage="Please select Field"
                              placeholder="Please select Field"
                              value={this.state.editRoleDetail.position}
                            >
                              <SelectOptions
                                data={this.state.tradeOptions}
                                placeholder="Please select Field..."
                                defaultMsg="Please select Industry first"
                              />
                            </AvField>
                          </div>
                        </AvGroup>
                      </Col>
                    </Row>
                    <div className="clearfix"></div>
                    <Row>
                      <Col className="text-center">
                        <button
                          disabled={this.state.formSubmitted ? true : false}
                          className={`c-button c-button--green c-button-inlineflex c-button-w140 c-button-radius c-button-sm u-upper ${
                            this.state.formSubmitted ? `inlineloading` : ``
                          }`}
                        >
                          {this.state.isEditMode ? 'Update Role' : 'Submit & Continue'}
                        </button>
                      </Col>
                    </Row>
                  </AvForm>
                  {this.state.isEditMode && (
                    <>
                      <div className="hr">
                        <hr></hr>
                      </div>

                      <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                        <h2 className="u-heading--medium u-heading--medium-sm">
                          Documentation Required ({this.state.documentList.length})
                        </h2>
                      </div>

                      {this.state.documentList.length > 0 &&
                        this.state.documentList.map((data: any) => (
                          <div className="inline--form u-mb--30" key={data.uuid}>
                            <FormGroup>
                              <div className="inline--form__option">
                                <div className="button-set">
                                  <label htmlFor="optional" className="start">
                                    <Input
                                      type="checkbox"
                                      name="optional"
                                      onChange={() => {}}
                                      checked={data.optional === 1 ? true : false}
                                    />
                                    <span className="eds-checked double grey">
                                      <span
                                        className="check"
                                        onClick={() =>
                                          this.documentOptionToggler(data.uuid)
                                        }
                                      ></span>
                                    </span>{' '}
                                    Optional
                                  </label>
                                </div>
                              </div>
                              {!data.hasDocumentsAttached && (
                                <button
                                  id={data.uuid}
                                  type="button"
                                  onClick={() =>
                                    this.removeRoleDocumentConfirm(data.uuid)
                                  }
                                  className={`c-button c-button--warning c-button-inline c-button-radius c-button--absolute`}
                                >
                                  REMOVE
                                </button>
                              )}
                              <div className="u-flexed u-flex-direction-column wneg220">
                                <Label
                                  className="text--ellipsis line1"
                                  for="certificatename"
                                >
                                  {data.title}
                                </Label>
                                {data.type && (
                                  <p className="u-mt--5 dark-grey">({data.type})</p>
                                )}
                                {!data.type && (
                                  <p className="u-mt--5 dark-grey">
                                    {CapitalizeWords(data.document_type)}
                                  </p>
                                )}
                              </div>
                            </FormGroup>
                          </div>
                        ))}
                      <Row className="u-mb--30">
                        <Col xs={12} sm={12} md={12} lg={12}>
                          <button
                            type="button"
                            onClick={this.documentModalToggler}
                            className="c-button c-button-primary c-button-inlineflex c-button-radius c-button-sm u-upper"
                          >
                            Add Requirements
                          </button>
                        </Col>
                      </Row>

                      <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--10">
                        <h2 className="u-heading--medium u-heading--medium-sm">
                          Applied ({this.state.appliedList.length})
                        </h2>
                      </div>

                      <div className="custom--search u-mb--25">
                        <div className="custom--search__lists">
                          <ListGroup>
                            {this.state.appliedList.length > 0 &&
                              this.state.appliedList.map((data: any) => (
                                <ListGroupItem
                                  key={data.uuid}
                                  className={GetAccountDocumentStatusClassName(
                                    data.accountDocumentStatus
                                  )}
                                >
                                  <div className="avatar--40">
                                    <img
                                      src={
                                        data.profileImageUrl
                                          ? data.profileImageUrl
                                          : ProfileAvatar
                                      }
                                      alt="Profile"
                                    />
                                  </div>
                                  <div className="list-group--details">
                                    <div className="u-flexed u-align-center">
                                      <div>
                                        <p className="user--name">
                                          {data.name}
                                          {data.status === 0 ? ' (Unlinked)' : ''}
                                        </p>
                                        <p className="user--email">{data.email}</p>
                                      </div>
                                      <div className="ml-auto">
                                        <button
                                          type="button"
                                          color="link"
                                          id={`preview_${data.uuid}`}
                                          className={`link--absolute c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90`}
                                          onClick={() =>
                                            this.previewDocumentModal(data.uuid, true)
                                          }
                                        >
                                          <Visibility
                                            fontSize="small"
                                            className="icons u-mr--5"
                                          />
                                          View
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </ListGroupItem>
                              ))}
                          </ListGroup>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Container>
        <DocumentModal
          isOpen={this.state.documentModal}
          toggleFun={this.documentModalToggler}
          documentTypeOptions={this.state.documentTypeOptions}
          addDocTolist={this.addDocTolist}
          getTypeToRender={this.getTypeToRender}
          convertArrayDataObj={this.convertArrayDataObj}
          roleDetails={this.state.editRoleDetail}
        />

        <ViewLinkedDocumentModal
          area="corporateLinkedMemberView"
          getAccountDocumentTypeOptions={() => {}}
          isOpen={this.state.viewDocumentModalOpen}
          toggle={this.previewDocumentModal}
          documentList={this.state.previewDocumentList}
          userAccountUuid=""
        />
      </>
    );
  }
}

export default withRouter(AddEditRole);
