import React from 'react';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';
import { Container, Row, Col } from 'reactstrap';

// Menu
import Menu from '../Menu/Menu';
import AppContext from '../../../../../components/AppContext';

//static assets
import logo from '../../../../../assets/images/owl-logo-large.svg';

interface HeaderProps extends RouteComponentProps {}

interface HeaderState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

class Header extends React.Component<HeaderProps, HeaderState> {
  static contextType = AppContext;
  constructor(props: HeaderProps) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
    };
  }

  private navsidebarToggle = React.createRef<HTMLButtonElement>();

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  openNavSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };
  closeNavSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    const { isLoggedIn } = this.context;
    return (
      <div>
        <div className="menu--overlay" onClick={() => this.closeNavSidebar()} />
        <div className="header header-sm">
          <Container fluid className="container-fluid-sm">
            <div className="navbar-toggle d-block d-lg-none d-xl-none">
              <button
                type="button"
                id="jsCloseSidebar"
                ref={this.navsidebarToggle}
                className="navbar-toggler"
                onClick={() => this.openNavSidebar()}
              >
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>

            <Row>
              <Col md="12" className="">
                <div className="header__main-info header__main-info-sm">
                  <div className="header__main-info--logo header__main-info--logo-md">
                    <a href="/">
                      <img src={logo} className="image-fluid app--logo" alt="OWL" />
                    </a>
                  </div>
                  <Menu {...this.props} />
                  <div className="header__main-info--right header__main-info--right-md ml-auto d-none d-sm-flex d-xl-flex">
                    <div className="header__additional--info header__additional--info-sm">
                      <ul className="topbar-menu">
                        {isLoggedIn ? (
                          <li>
                            <RouterLink to="/dashboard">Dashboard</RouterLink>
                          </li>
                        ) : (
                          <>
                            <li>
                              <RouterLink to="/auth/login">Login</RouterLink>
                            </li>
                            <li>
                              <Link
                                to="Pricing"
                                spy={true}
                                smooth={true}
                                offset={-30}
                                duration={500}
                                className="button button-xs"
                              >
                                Try For Free
                              </Link>
                            </li>
                          </>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
