import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, Button, DropdownToggle, Dropdown } from 'reactstrap';

//Application Imports
import AppContext from '../../../components/AppContext';
import UserDropdown from './UserDropdown/UserDropdown';

// Import CSS
import '../members/member-list.scss';
import '../members/teams.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets;
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import MoreHoriz from '@material-ui/icons/MoreHoriz';

interface Props extends RouteComponentProps {
  userList: Array<any>;
  toggle: Function;
  editUserHandler: Function;
  reloadData: Function;
}

interface State {
  dropdownOpen: Array<boolean>;
}

class UserList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  typingTimeout: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.typingTimeout = setTimeout(() => '', 1000);
    this.state = {
      dropdownOpen: [],
    };
  }

  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  render() {
    return (
      <>
        <div className="myteams--section myteams--section-sm">
          {/*
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
            <h2 className="u-heading--medium u-heading--medium-sm">Manage Users</h2>
          </div>
          */}
          <div className="portlet">
            <div className="portlet--body">
              <div className="portlet--body__wrapper">
                {/* <div className="portlet--search size--300">
                  <Input
                    name="filterValue"
                    id="filterValue"
                    className="form--fields"
                    placeholder="Search"
                    onChange={this.onChangeFilterValue}
                  />
                  <button className="portlet--search__btn" type="button">
                    <Search
                      fontSize="large"
                      className="icons"
                      onClick={this.filterMemberListFunc}
                    />
                  </button>
                </div> */}
                <div className="ml-auto">
                  <Button
                    type="button"
                    onClick={() => this.props.toggle()}
                    className="btn-radius btn-w160 btn btn-primary"
                  >
                    + Add User
                  </Button>
                </div>
              </div>

              <div className="profile--links">
                <ListGroup>
                  <ListGroupItem className="head">
                    <div className="NameColumn">Name</div>
                    <div className="RoleColumn">Role</div>
                  </ListGroupItem>
                  {this.props.userList.length > 0 ? (
                    this.props.userList.map((data: any, key: number) => (
                      <ListGroupItem key={data.uuid}>
                        <div className="NameColumn">
                          <div className="avatar--40">
                            <img
                              src={
                                data.profileImageUrl
                                  ? data.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="list-group--details">
                            <p className="user--name">
                              {data.first_name} {data.last_name}
                            </p>
                            <p className="user--email">{data.email}</p>
                          </div>
                        </div>
                        <div className="RoleColumn">
                          <>{data.role}</>
                        </div>
                        <div className="ActivityColumn">
                          <Link
                            to={{
                              pathname: `/corporate/member-projects/${data.uuid}`,
                              state: { userName: data.name },
                            }}
                          >
                            <span className="badge badge-primary text-dark u-ml--5">
                              {data.totalAssignedProjects
                                ? data.totalAssignedProjects
                                : 0}{' '}
                              Project
                            </span>
                          </Link>
                          <div className="ml-auto">
                            <Dropdown
                              className="members--dropdown inline--dropdown"
                              isOpen={this.state.dropdownOpen[key]}
                              toggle={() => this.toggleDropdown(key)}
                            >
                              <DropdownToggle color="primary">
                                <MoreHoriz
                                  fontSize="large"
                                  className="icons"
                                  style={{ margin: '0' }}
                                />
                              </DropdownToggle>
                              <UserDropdown
                                userUuid={data.uuid}
                                editUserHandler={this.props.editUserHandler}
                                userData={data}
                                reloadData={this.props.reloadData}
                              />
                            </Dropdown>
                          </div>
                        </div>
                      </ListGroupItem>
                    ))
                  ) : (
                    <ListGroupItem>
                      <p>No user(s) found</p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(UserList);
