import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, Dropdown, DropdownToggle } from 'reactstrap';

//Application Imports;
import LocalStorage from '../../../helpers/LocalStorage';
import ProfileDropdown from './ProfileDropDownMenu/ProfileDropdownmenu';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import AppContext from '../../../components/AppContext';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';
import '../members/member-list.scss';

// Import Icons
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  projectList: Array<any>;
  area: string;
  favouriteClickHandler: Function;
  deleteProjectConfirmation: Function;
  title: string;
}

interface State {
  dropdownOpen: Array<boolean>;
}

class ProjectTableHtml extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: [],
    };
  }

  // Function for toggle dropdown option for edit and delete button;
  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  render() {
    const projectPreviewUrlPrefix =
      this.props.area === 'personalProjectView' ? '/project' : '/corporate/project-view';
    const { activeAccountUUID } = this.context;
    return (
      <div className="profile--links mt-0">
        <div className="page-head page-head-sm start noborder m-0 p-0 mb-2">
          <h3 className="u-heading--small u-heading--small-sm">
            <strong>{this.props.title}</strong>
          </h3>
        </div>
        <ListGroup>
          <ListGroupItem className="head">
            <div className="NameColumn">Project Name</div>
            <div className="RoleColumn">Start Date</div>
          </ListGroupItem>
          {this.props.projectList.map((data: any, key: number) => (
            <React.Fragment key={data.uuid}>
              {data.matchesFilterWord !== false && (
                <ListGroupItem
                  className={GetAccountDocumentStatusClassName(
                    data.accountDocumentStatus,
                    false
                  )}
                >
                  <div className="NameColumn">
                    {this.props.area !== 'personalProjectView' && (
                      <button
                        type="button"
                        onClick={() => this.props.favouriteClickHandler(data.uuid)}
                      >
                        {data.favourite ? <StarIcon /> : <StarBorderIcon />}
                      </button>
                    )}
                    <div className="list-group--details">
                      {activeAccountUUID === data.account_uuid ? (
                        <Link
                          to={{
                            pathname: `${projectPreviewUrlPrefix}/${data.uuid}${
                              projectPreviewUrlPrefix === '/project'
                                ? ''
                                : '/' + activeAccountUUID
                            }`,
                            state: { prevPath: this.props.location.pathname },
                          }}
                        >
                          <p className="user--name">{data.project_name}</p>
                        </Link>
                      ) : (
                        <p className="user--name">{data.project_name}</p>
                      )}

                      {this.props.area === 'personalProjectView' && (
                        <p className="user--name">({data.company_name})</p>
                      )}
                    </div>
                  </div>
                  <div className="RoleColumn">
                    <>{data.start_date ? data.start_date : '-'}</>
                  </div>
                  <div className="ActivityColumn">
                    {activeAccountUUID === data.account_uuid ? (
                      <Link
                        to={{
                          pathname: `${projectPreviewUrlPrefix}/${data.uuid}${
                            projectPreviewUrlPrefix === '/project'
                              ? ''
                              : '/' + activeAccountUUID
                          }`,
                          state: { prevPath: this.props.location.pathname },
                        }}
                      >
                        <span className="badge badge-primary text-dark u-ml--5">
                          {data.total_linked_accounts ? data.total_linked_accounts : 0}{' '}
                          Members
                        </span>
                      </Link>
                    ) : (
                      <span className="badge badge-primary text-dark u-ml--5">
                        {data.total_linked_accounts ? data.total_linked_accounts : 0}{' '}
                        Members
                      </span>
                    )}
                    {!LocalStorage.hasManagerRole() && (
                      <div className="ml-auto">
                        <div className="deny u-flexed u-align-center">
                          {this.props.area !== 'personalProjectView' && (
                            <Dropdown
                              className="members--dropdown"
                              isOpen={this.state.dropdownOpen[key]}
                              toggle={() => this.toggleDropdown(key)}
                            >
                              <DropdownToggle color="primary">
                                <MoreHoriz
                                  fontSize="large"
                                  className="icons"
                                  style={{ margin: '0' }}
                                />
                              </DropdownToggle>
                              <ProfileDropdown
                                area={data.invited ? 'inviteProject' : this.props.area}
                                projectUuid={data.uuid}
                                deleteProjectConfirmation={
                                  this.props.deleteProjectConfirmation
                                }
                              />
                            </Dropdown>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </ListGroupItem>
              )}
            </React.Fragment>
          ))}
        </ListGroup>
      </div>
    );
  }
}

export default withRouter(ProjectTableHtml);
