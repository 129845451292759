import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ReactWOW from 'react-wow';

import ReactPlayer from 'react-player';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

// Static Assets
import ChoosePlan from '../../../assets/images/icons/choose--plan.svg';
import Secure from '../../../assets/images/icons/secure.svg';
import SafeSharing from '../../../assets/images/icons/safe--sharing.svg';
import StayUpToDate from '../../../assets/images/icons/stay--up-to-date.svg';

import MP4 from '../../../assets/videos/video.mp4';
import WEBM from '../../../assets/videos/video.webm';
import OGG from '../../../assets/videos/video.ogg';

interface SectionHomeProps {}
interface SectionHomeState {}

class SectionHome extends React.Component<SectionHomeProps, SectionHomeState> {
  constructor(props: SectionHomeProps) {
    super(props);
    this.state = {};
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <section
          className="section--main section--main-sm text-center"
          data-bg={'purple'}
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} xl={10} className="col-centered">
                <ReactWOW animation="fadeInUp">
                  <div className="page--head page--head-sm noborder center">
                    <p className="text-white u-mb--15">
                      <strong>Compliance Data Solution</strong>
                    </p>
                    <h1 className="u-heading__large u-heading__large-sm u-mb--15">
                      <strong>Never get caught out with compliance again!</strong>
                    </h1>
                    <p className="fs--20 fs--20-sm text-white">
                      Now you can safely store and share your important documents,
                      certificates, licences and compliance documents with anyone,
                      anywhere, anytime, with ease.
                    </p>
                  </div>
                </ReactWOW>
                <div className="equal--width equal--width-sm">
                  <ReactWOW animation="fadeInLeft">
                    <Link
                      to="Pricing"
                      spy={true}
                      smooth={true}
                      offset={-30}
                      duration={500}
                      className="button button--white button-inlineflex button-w190"
                    >
                      Try For Free
                    </Link>
                  </ReactWOW>
                  <ReactWOW animation="fadeInRight">
                    <a
                      href="mailto:hello@owlcds.com.au"
                      className="button button--green button-inlineflex button-w190"
                    >
                      Request A Demo
                    </a>
                  </ReactWOW>
                </div>
                <ReactWOW animation="zoomIn">
                  <div className="embed-responsive embed-responsive-sm embed-responsive-16by9">
                    <ReactPlayer
                      loop
                      playing={true}
                      volume={0}
                      muted={true}
                      className="embed-responsive-item"
                      config={{
                        file: {
                          attributes: {
                            autoPlay: true,
                            muted: true,
                          },
                        },
                      }}
                      url={[
                        {
                          src: MP4,
                          type: 'video/mp4',
                        },
                        {
                          src: WEBM,
                          type: 'video/webm',
                        },
                        {
                          src: OGG,
                          type: 'video/ogg',
                        },
                      ]}
                      width="100%"
                      height="100%"
                    />
                  </div>
                </ReactWOW>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="section--gray section--gray-sm" data-bg={'gray'}>
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <Row className="u-flexed u-align-center u-mb--40">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col-centered">
                    <ReactWOW animation="fadeInDown">
                      <div className="page--head page--head-sm noborder column center align-items-center text-center u-mb--15">
                        <h3 className="u-heading__small u-heading__small-sm u-mb--10">
                          <strong>Cloud-based compliance data platform</strong>
                        </h3>
                        <h2 className="u-heading__large u-heading__large-sm text-black u-mb--20">
                          <strong>
                            Safely store, share and manage your important documents.
                          </strong>
                        </h2>
                        <p className="fs--18 fs--18-sm">
                          Have you ever lost a certificate just before an interview, lost
                          a building site license just before you're arriving on-site, or
                          had to manage the first aid and other certifications of your
                          team? If so, we're here to make your life soo much simpler! How?
                          The Organised Work Life is a purpose-built, premium cloud-based
                          platform which helps people store, manage, keep up to date, and
                          share their important documents and compliance data.
                        </p>
                      </div>
                    </ReactWOW>
                  </Col>
                </Row>

                <Row>
                  <ReactWOW animation="fadeInLeft">
                    <Col xs={12} md={3} lg={3} className="mobile--mb--35">
                      <picture className="icon--100">
                        <img
                          src={ChoosePlan}
                          alt="Choose your plan"
                          className="img-fluid"
                        />
                      </picture>
                      <h3 className="title">Choose your plan</h3>
                      <p className="fs--18">
                        Suited to individuals, sole traders and companies. Simply choose
                        the plan that works best for you.
                      </p>
                    </Col>
                  </ReactWOW>
                  <ReactWOW animation="fadeInUp">
                    <Col xs={12} md={3} lg={3} className="mobile--mb--35">
                      <picture className="icon--100">
                        <img src={Secure} alt="Secure" className="img-fluid" />
                      </picture>
                      <h3 className="title">Secure</h3>
                      <p className="fs--18">
                        The OWLCDS platform tracks and records every action on the
                        account, helping you keep on top of document security and
                        governance.
                      </p>
                    </Col>
                  </ReactWOW>
                  <ReactWOW animation="fadeInDown">
                    <Col xs={12} md={3} lg={3} className="mobile--mb--35">
                      <picture className="icon--100">
                        <img src={SafeSharing} alt="Safe sharing" className="img-fluid" />
                      </picture>
                      <h3 className="title">Safe sharing</h3>
                      <p className="fs--18">
                        Safely share your relevant certificates, licences and documents
                        anywhere, anytime (and know who has seen them).
                      </p>
                    </Col>
                  </ReactWOW>
                  <ReactWOW animation="fadeInRight">
                    <Col xs={12} md={3} lg={3} className="mobile--mb--35">
                      <picture className="icon--100">
                        <img
                          src={StayUpToDate}
                          alt="Stay up to date"
                          className="img-fluid"
                        />
                      </picture>
                      <h3 className="title">Stay up to date</h3>
                      <p className="fs--18">
                        Receive reminders before documents are due to expire so you can
                        get them updated on-time, every time.
                      </p>
                    </Col>
                  </ReactWOW>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionHome;
