import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import BlankScreen from './BlankScreen';
import Account from '../../../api/Account';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import MemberList from './MemberList';
import Breadcrumb from './Breadcrumb';

// Import CSS
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';
import './admin.scss';

interface Props {}

interface State {
  activeMemberList: Array<any>;
  activeMemberListCopy: Array<any>;
  pendingMemberList: Array<any>;
  showLoader: boolean;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

class MemberLanding extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      activeMemberList: [],
      activeMemberListCopy: [],
      pendingMemberList: [],
      activeMemberCount: 0,
      unlinkedMemberCount: 0,
    };
  }

  fetchAccountsList = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Account.getLinkedAccounts(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        const activeMemberList = response.data.data.linked;
        const activeMemberCount = activeMemberList.filter(
          (data: any) => data.status !== 0
        ).length;
        const unlinkedMemberCount = activeMemberList.length - activeMemberCount;

        this.setState({
          showLoader: false,
          activeMemberList: activeMemberList,
          activeMemberListCopy: activeMemberList,
          pendingMemberList: response.data.data.pending,
          activeMemberCount,
          unlinkedMemberCount,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  removePendingInvitationMember = (uuid: string) => {
    const data = this.state.pendingMemberList.filter((data: any) => {
      if (data.uuid === uuid) {
        return false;
      }
      return data;
    });
    this.setState({
      pendingMemberList: data,
    });
  };

  postAcceptPendingInvitation = (uuid: string) => {
    const data = this.state.pendingMemberList.filter((data: any) => {
      if (data.uuid === uuid) {
        data.status = 1;
      }
      return data;
    });
    this.setState({
      pendingMemberList: data,
    });
  };

  filterMemberList = (filterWord: string) => {
    const filter = filterWord.toUpperCase();

    let filteredActiveMemberList = this.state.activeMemberListCopy.filter((data: any) => {
      let txtValue = data.name;
      let txtValueEmail = data.email || '';
      if (
        txtValue.toUpperCase().indexOf(filter) > -1 ||
        txtValueEmail.toUpperCase().indexOf(filter) > -1
      ) {
        return data;
      }
      return false;
    });

    this.setState({
      activeMemberList: filteredActiveMemberList,
    });
  };

  resetMemberFilter = () => {
    this.setState({
      activeMemberList: this.state.activeMemberListCopy,
    });
  };

  componentDidMount() {
    document.title = `Linked Companies ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch members list;
    this.fetchAccountsList();
  }

  render() {
    return (
      <>
        <Breadcrumb selectedArea="linkedCompanies" />
        <Container fluid={true} className="u-pt--50 u-pt--50-sm u-pb--50 u-pb--50-sm">
          <Row>
            <Col sm={12} md={11} lg={10} className="col-centered">
              {this.state.showLoader ? (
                <Loader />
              ) : this.state.activeMemberListCopy.length > 0 ||
                this.state.pendingMemberList.length > 0 ? (
                <MemberList
                  activeMemberCount={this.state.activeMemberCount}
                  unlinkedMemberCount={this.state.unlinkedMemberCount}
                  memberList={this.state.activeMemberList}
                  pendingMemberList={this.state.pendingMemberList}
                  reloadData={this.fetchAccountsList}
                  filterMemberList={this.filterMemberList}
                  resetMemberFilter={this.resetMemberFilter}
                  removePendingInvitationMember={this.removePendingInvitationMember}
                  postAcceptPendingInvitation={this.postAcceptPendingInvitation}
                />
              ) : (
                <BlankScreen />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default MemberLanding;
