import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Import CSS
import './maintenance.scss';
import LogoBlack from '../../assets/images/owl-logo-large.svg';

interface Props extends RouteComponentProps<any> {}

class Maintenance extends React.Component<Props, {}> {
  render() {
    return (
      <>
        <section className="Owl__Maintenance Owl__Maintenance-sm">
          <div className="logo logo-md">
            <a href="/">
              <img src={LogoBlack} className="image-fluid app--logo" alt="OWLCDS" />
            </a>
          </div>
          <Container fluid={false} className="">
            <Row>
              <Col xs={12} md={12} lg={12} className="col-centered">
                <div className="info info-sm text-center">
                  <h1 className="title title-sm">System Maintenance</h1>
                  <p className="fs20 fs20-sm">
                    We are currently doing some scheduled maintenece on our platform, we
                    will be back online soon.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default Maintenance;
