import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  ButtonGroup,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  Form,
  FormGroup,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

// Application Imports;
import appConfig from '../../../config';
import ProfileSearch from '../../../api/ProfileSearch';
import LinkMember from '../../../api/LinkMember';
import AppContext from '../../../components/AppContext';
import DocumentList from '../../common/documents-upload/DocumentList';
import AccountDetail from '../../common/profile-preview/AccountDetail';
import NoDataMsg from '../../common/profile-preview/NoDataMsg';
import AlertError from '../../../layout/includes/Alert/Error';
import SavedList from './SavedList';
import BlankScreen from './BlankScreen';
import Breadcrumb from './Breadcrumb';

// Static Imports
import Close from '@material-ui/icons/Close';

// Import CSS
import './server-profile.scss';
import './server-subcontractor.scss';

interface Props extends RouteComponentProps {}

interface State {
  activeItem: string;
  showProfileArea: boolean;
  showSubContractorArea: boolean;
  showSavedArea: boolean;
  hideResetButton: boolean;
  profileValue: string;
  profileValueError: boolean;
  formSubmitted: boolean;
  errorMessages: string;
  accountData: any;
  documentList: any;
  savedList: Array<any>;
  classificationValueError: boolean;
  classificationValue: Array<string>;
  classificationValueObj: Array<any>;
  errorMessagesContractor: string;
  showNoDataMsg: boolean;
  subContractorProfileList: any;
  showBlankScreen: boolean;
  profilePreviewData: any;
  previewAccountData: any;
  showProfilePreviewDiv: boolean;
  message: string;
  searchWhat: string;
  searchWhere: string;
}

class SearchProfileLanding extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      activeItem: 'profile',
      showProfileArea: true,
      showSubContractorArea: false,
      showSavedArea: false,
      hideResetButton: true,
      profileValue: '',
      profileValueError: false,
      formSubmitted: false,
      errorMessages: '',
      documentList: [],
      accountData: null,
      savedList: [],
      classificationValueError: false,
      classificationValue: [],
      classificationValueObj: [],
      errorMessagesContractor: '',
      showNoDataMsg: false,
      subContractorProfileList: [],
      showBlankScreen: true,
      profilePreviewData: null,
      previewAccountData: null,
      showProfilePreviewDiv: false,
      message: '',
      searchWhat: '',
      searchWhere: '',
    };
  }

  TagsOptions = [
    { value: 'Automotive', label: 'Automotive' },
    { value: 'Builder', label: 'Builder' },
    { value: 'Engineering', label: 'Engineering' },
    { value: 'Environmental Management', label: 'Environmental Management' },
    { value: 'General', label: 'General' },
    { value: 'Irrigation', label: 'Irrigation' },
    { value: 'Manufacturing Technology', label: 'Manufacturing Technology' },
    { value: 'Operator', label: 'Operator' },
    { value: 'Plasterer', label: 'Plasterer' },
    { value: 'Plumber', label: 'Plumber' },
    { value: 'Pool & Spa', label: 'Pool & Spa' },
    { value: 'Printing', label: 'Printing' },
    { value: 'Roofing', label: 'Roofing' },
    { value: 'Scaffolding', label: 'Scaffolding' },
    { value: 'Sheetmetal', label: 'Sheetmetal' },
    { value: 'Signwriter', label: 'Signwriter' },
    { value: 'Slaughterer', label: 'Slaughterer' },
    { value: 'Stonemason', label: 'Stonemason' },
    { value: 'Telecommunications', label: 'Telecommunications' },
    { value: 'Tiler (wall & floor)', label: 'Tiler (wall & floor)' },
    { value: 'Upholsterer', label: 'Upholsterer' },
    {
      value: 'Watch and Clock Maker and Repairere',
      label: 'Watch and Clock Maker and Repairere',
    },
    { value: 'Welding', label: 'Welding' },
  ];

  subContractorAreaHandler = () => {
    this.setState({
      activeItem: 'subcontractor',
      showSubContractorArea: true,
      showProfileArea: false,
      showSavedArea: false,
      message: this.state.errorMessagesContractor,
    });
  };

  savedProfileAreaHandler = () => {
    this.setState({
      activeItem: 'savedProfile',
      showSavedArea: true,
      showProfileArea: false,
      showSubContractorArea: false,
      message: '',
    });
  };

  profileAreaHandler = () => {
    this.setState({
      activeItem: 'profile',
      showProfileArea: true,
      showSubContractorArea: false,
      showSavedArea: false,
      message: this.state.errorMessages,
    });
  };

  resetProfileValue = () => {
    this.setState({
      hideResetButton: true,
      profileValue: '',
      errorMessages: '',
      documentList: [],
      accountData: null,
    });
  };

  resetSubContractorSearch = () => {
    this.setState({
      subContractorProfileList: [],
      classificationValueObj: [],
      classificationValue: [],
      errorMessagesContractor: '',
    });
  };

  profileChangeHandler = (e: any) => {
    this.setState({
      profileValue: e.target.value,
      hideResetButton: false,
      profileValueError: false,
    });
  };

  //Search for Profile using CRN
  submitProfileFormHandle = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { activeAccountUUID } = this.context;

    if (this.state.profileValue.trim() === '') {
      this.setState({
        profileValueError: true,
      });
      return;
    }

    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    ProfileSearch.searchAccountByCrn(activeAccountUUID, this.state.profileValue)
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.setState({
            documentList: response.data.data.document_list,
            accountData: response.data.data.account,
          });
        } else {
          this.setState({
            errorMessages: response.data.messages[0],
            message: response.data.messages[0],
            documentList: [],
            accountData: null,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  convertServiceArrayData = (data: any) => {
    let dataArray: any = [];
    if (data) {
      if (data.length > 0 && data[0] !== '') {
        for (let i = 0; i < data.length; i++) {
          dataArray.push(data[i].value);
        }
      }
    }
    return dataArray;
  };

  resetErrorState = () => {
    this.setState({
      errorMessagesContractor: '',
      showNoDataMsg: false,
    });
  };

  onChangeClassification = (data: any) => {
    this.setState({
      classificationValue: this.convertServiceArrayData(data),
      classificationValueObj: data,
    });
    this.resetErrorState();
  };

  //Search for subcontractor
  subcontractorFormHandler = (
    e: React.FormEvent<HTMLInputElement>,
    errors: any,
    values: any
  ) => {
    const { activeAccountUUID } = this.context;

    // validation of empty search request.
    if (values.what === '' && values.where === '') {
      this.setState({
        errorMessagesContractor: 'Please provide search criteria.',
      });
      return;
    }

    this.setState({
      formSubmitted: true,
      errorMessagesContractor: '',
      showBlankScreen: false,
      searchWhat: values.what,
      searchWhere: values.where,
    });

    ProfileSearch.searchSubcontractors(activeAccountUUID, values.what, values.where)
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.setState({
            subContractorProfileList: response.data.data.list,
            message: response.data.data.list.length <= 0 ? 'No records found' : '',
            showBlankScreen: true,
          });
        } else {
          this.setState({
            message: response.data.messages[0],
            subContractorProfileList: [],
            showBlankScreen: true,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  updateInviteListState = (uuid: string) => {
    const subContractorProfileList = this.state.subContractorProfileList.map(
      (data: any) => {
        if (data.uuid === uuid) {
          data.connectionData.inviteStatus = 1;
        }
        return data;
      }
    );

    const savedList = this.state.savedList.map((data: any) => {
      if (data.uuid === uuid) {
        data.connectionData.inviteStatus = 1;
      }
      return data;
    });

    this.setState({
      subContractorProfileList,
      savedList,
    });
  };

  updateSavedListState = (uuid: string, newSavedStatus?: number) => {
    if (this.state.showSubContractorArea) {
      //adding or removing the entry to savedList
      let addRow = {} as any;
      const subContractorProfileList = this.state.subContractorProfileList.map(
        (data: any) => {
          if (data.uuid === uuid) {
            data.connectionData.savedStatus = newSavedStatus;
            addRow = data;
          }
          return data;
        }
      );

      const savedList = this.state.savedList.filter((data: any) => {
        if (data.uuid === uuid) {
          return false;
        }
        return true;
      });

      const newSavedList = newSavedStatus === 0 ? [...savedList] : [...savedList, addRow];

      this.setState((prevState) => ({
        subContractorProfileList,
        savedList: newSavedList,
      }));
    } else {
      const subContractorProfileList = this.state.subContractorProfileList.map(
        (data: any) => {
          if (data.uuid === uuid) {
            data.connectionData.savedStatus = newSavedStatus;
          }
          return data;
        }
      );

      const oldSavedList = this.state.savedList;
      const newSavedList = oldSavedList.map((data: any) => {
        if (data.uuid === uuid) {
          data.connectionData.savedStatus = newSavedStatus;
        }
        return data;
      });

      this.setState((prevState) => ({
        subContractorProfileList,
        savedList: newSavedList,
      }));
    }
  };

  showProfilePreviewDivHandler = (crn: string) => {
    const { activeAccountUUID } = this.context;

    ProfileSearch.searchAccountByCrn(activeAccountUUID, crn)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            profilePreviewData: response.data.data.document_list,
            previewAccountData: response.data.data.account,
            showProfilePreviewDiv: true,
          });
        } else {
          this.setState({
            profilePreviewData: null,
            previewAccountData: null,
            showProfilePreviewDiv: false,
          });
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        this.setState({
          showProfilePreviewDiv: false,
        });
      });
  };

  hideProfileDivHandler = () => {
    this.setState({
      profilePreviewData: null,
      previewAccountData: null,
      showProfilePreviewDiv: false,
    });
  };

  fetchSavedList = () => {
    const { activeAccountUUID } = this.context;

    LinkMember.getSavedList(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          savedList: response.data.data.list,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Search ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch the saved list;
    this.fetchSavedList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (prevState !== this.state) {
      if (this.state.showProfileArea) {
        this.setState({
          showBlankScreen: this.state.accountData === null,
        });
      }

      if (this.state.showSubContractorArea) {
        this.setState({
          showBlankScreen: this.state.subContractorProfileList.length > 0 ? false : true,
        });
      }

      if (this.state.showSavedArea) {
        this.setState({
          showBlankScreen: this.state.savedList.length > 0 ? false : true,
        });
      }
    }
  }

  render() {
    return (
      <>
        {!this.state.showProfilePreviewDiv ? (
          <>
            <div className="c-breadcrumb--section c-breadcrumb--section-sm u-mb--0">
              <Container fluid={true} className="container-fluid-sm">
                <Row className="u-flexed u-align-center u-justify-btw">
                  <Col xs={12} sm={12} md={12} lg={12}>
                    <div className="u-flexed u-align-center u-justify-center">
                      <ButtonGroup size="sm" className="bordered">
                        <Button
                          color="primary"
                          className={this.state.showProfileArea ? 'is--active' : ''}
                          onClick={this.profileAreaHandler}
                        >
                          Profiles
                        </Button>
                        <Button
                          color="primary"
                          className={this.state.showSubContractorArea ? 'is--active' : ''}
                          onClick={this.subContractorAreaHandler}
                        >
                          Subcontractors
                        </Button>
                        <Button
                          color="primary"
                          className={this.state.showSavedArea ? 'is--active' : ''}
                          onClick={this.savedProfileAreaHandler}
                        >
                          Saved ({this.state.savedList.length})
                        </Button>
                      </ButtonGroup>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>

            {this.state.showProfileArea && (
              <div className="profile--search u-mb--25">
                <Container fluid={true} className="container-fluid-sm">
                  <Row className="u-flexed u-align-center u-justify-center">
                    <Col xs={12} sm={12} md={6} lg={6} className="col-centered">
                      <Form
                        className="form-horizontal"
                        onSubmit={this.submitProfileFormHandle}
                      >
                        <FormGroup
                          className={`form-group u-mb--0 ${
                            this.state.profileValueError ? `form-group--has-error` : ``
                          }`}
                        >
                          <label htmlFor="CRM">Customer Reference Number (CRN)</label>
                          <InputGroup>
                            <Input
                              name="CRM"
                              id="CRM"
                              placeholder="Customer Reference Number"
                              className="form-control"
                              value={this.state.profileValue}
                              onChange={this.profileChangeHandler}
                            />
                            {!this.state.hideResetButton && (
                              <button
                                type="reset"
                                className="btn-clear--results"
                                onClick={this.resetProfileValue}
                              >
                                <Close
                                  fontSize="large"
                                  className="icons u-ml--0 u-mr--0"
                                />
                              </button>
                            )}
                            <InputGroupAddon addonType="append">
                              <Button
                                color="primary"
                                disabled={this.state.formSubmitted}
                                className={
                                  this.state.formSubmitted ? 'inlineloading' : ''
                                }
                              >
                                Search
                              </Button>
                            </InputGroupAddon>
                          </InputGroup>
                          <span className="help-block">Please enter CRN</span>
                        </FormGroup>
                      </Form>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}

            {this.state.showSubContractorArea && (
              <div className="profile--search u-no--shadow u-mb--25">
                <Container fluid={true} className="container-fluid-sm">
                  <Row className="u-flexed u-align-center u-justify-center">
                    <Col xs={12} sm={12} md={11} lg={10} className="col-centered">
                      <AlertError
                        ref={(div: any) => {
                          this.alertErrorRef = div;
                        }}
                        message={this.state.errorMessagesContractor}
                      />
                      <div className="custom--searcharea">
                        <AvForm
                          className="form-horizontal"
                          onSubmit={this.subcontractorFormHandler}
                        >
                          <div className="row custom--row">
                            <div className="col-12 custom--col w-45">
                              <AvField
                                label="What"
                                name="what"
                                id="what"
                                value={this.state.searchWhat}
                                placeholder="Enter keywords"
                                onChange={this.resetErrorState}
                              />
                            </div>
                            <div className="col-12 custom--col w-45">
                              <AvField
                                label="Where"
                                name="where"
                                id="where"
                                value={this.state.searchWhere}
                                placeholder="Enter suburb, city, state or postcode"
                                onChange={this.resetErrorState}
                              />
                            </div>
                            <div className="col-12 custom--col w-10">
                              <Button
                                color="primary"
                                disabled={this.state.formSubmitted}
                                className={`btn-radius btn-md ${
                                  this.state.formSubmitted ? `inlineloading` : ``
                                }`}
                              >
                                Search
                              </Button>
                              <div className="clear--search">
                                <button
                                  type="reset"
                                  className=""
                                  onClick={this.resetSubContractorSearch}
                                >
                                  <Close fontSize="large" className="icons" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </AvForm>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            )}

            {this.state.showBlankScreen && (
              <BlankScreen
                activeItem={this.state.activeItem}
                message={this.state.message}
              />
            )}

            {this.state.showProfileArea && this.state.accountData !== null && (
              <>
                <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
                  <AccountDetail
                    accountData={this.state.accountData}
                    crn={this.state.accountData.CRN_number}
                  />
                </Container>
                {this.state.documentList !== null ? (
                  <DocumentList
                    showEditElements={false}
                    documentList={this.state.documentList}
                    updateListData={() => {}}
                  />
                ) : (
                  <NoDataMsg />
                )}
              </>
            )}

            {this.state.showSavedArea && (
              <SavedList
                activeArea="savedArea"
                savedList={this.state.savedList}
                updateInviteListState={this.updateInviteListState}
                hideNoDataMsg={this.state.showNoDataMsg}
                updateSavedListState={this.updateSavedListState}
                profileView={true}
                showProfilePreviewDivHandler={this.showProfilePreviewDivHandler}
              />
            )}

            {this.state.showSubContractorArea && (
              <SavedList
                activeArea="subcontractor"
                savedList={this.state.subContractorProfileList}
                updateInviteListState={this.updateInviteListState}
                hideNoDataMsg={false}
                updateSavedListState={this.updateSavedListState}
                profileView={true}
                showProfilePreviewDivHandler={this.showProfilePreviewDivHandler}
              />
            )}
          </>
        ) : (
          <>
            <Breadcrumb profilePreviewHandler={this.hideProfileDivHandler} />
            <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm">
              <AccountDetail accountData={this.state.previewAccountData} />
            </Container>
            {this.state.profilePreviewData !== null ? (
              <DocumentList
                showEditElements={false}
                documentList={this.state.profilePreviewData}
                updateListData={() => {}}
              />
            ) : (
              <NoDataMsg />
            )}
          </>
        )}
      </>
    );
  }
}

export default SearchProfileLanding;
