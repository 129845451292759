import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

// Application Imports;
import LocalStorage from '../../../../helpers/LocalStorage';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  data: any;
}

interface State {}

class DocumentApprove extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const hasCorporateAccount = LocalStorage.hasCorporateAccount();
    const { data } = this.props;
    return (
      <>
        <img
          className="avatar size--18"
          src={
            data.userProfilePic
              ? data.userProfilePic
              : data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : Avatar
          }
          alt={data.userFirstName + ' ' + data.userLastName}
        />
        {hasCorporateAccount ? (
          <p>
            {data.userFirstName} {data.userLastName}
          </p>
        ) : (
          <Link to={`/account/${data.activityByUuid}`}>{data.activityByName}</Link>
        )}
        &nbsp;approved&nbsp;
        {hasCorporateAccount && (
          <>
            <Link to={`/corporate/team/member-documents/${data.activityForUuid}`}>
              {data.activityForName}
            </Link>
            &nbsp;
          </>
        )}
        document&nbsp;
        {data.documentUuid && <p>({data.documentName})</p>}
      </>
    );
  }
}

export default withRouter(DocumentApprove);
