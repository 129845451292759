import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, Row, Col } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

//Application Imports
import AlertError from '../../../layout/includes/Alert/Error';
import AppContext from '../../../components/AppContext';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

interface Props {
  isOpen: boolean;
  toggle: Function;
  mailAddress: string;
  updateMailState: Function;
}

interface State {
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
}

class EditCreditCardModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      formSubmitted: false,
      alertErrorMessage: '',
    };
  }

  updateMailAddressHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;
    // Set loader to true;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    const postData = {
      uuid: activeAccountUUID,
      receipt_email: values.email,
    };

    // request data to server;
    axiosInstance
      .post('/account/update-receipt-email', postData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          toast.success('Your receipt email is updated successfully.', {
            autoClose: 3000,
          });
          this.props.toggle();
          this.props.updateMailState(values.email);
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md"
        backdrop={true}
      >
        <ModalHeader className="center u-border--btm0" toggle={() => this.props.toggle()}>
          Edit Receipt Email Address
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm">
          <h3 className="modal-section--title">Enter Email address</h3>
          <AlertError message={this.state.alertErrorMessage} />
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.updateMailAddressHandler}
          >
            <AvField
              type="email"
              name="email"
              required
              value={this.props.mailAddress}
              errorMessage="Please enter a valid Email address"
              validate={{
                email: {
                  value: true,
                  errorMessage: 'Please enter a valid Email address',
                },
              }}
            />
            <Row className="u-flexed u-align-center u-justify-center u-mt--30">
              <Col xs={12} md={12} lg={12}>
                <Button
                  color="primary"
                  id="submit"
                  disabled={this.state.formSubmitted ? true : false}
                  className={`${
                    this.state.formSubmitted ? `inlineloading` : ``
                  } has--centered`}
                >
                  UPDATE MAIL
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>
    );
  }
}

export default EditCreditCardModal;
