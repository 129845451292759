import { corporateRoleType } from './Constant';
import moment from 'moment';

//converts the react-date moment date to local date
export const getDate = (dateTime: any) => {
  if (!dateTime) {
    return undefined;
  }

  return moment(dateTime).local().format('YYYY-MM-DD');
};

export const getAccountDefaultRoute = (
  accountType: string,
  selectAccount?: boolean,
  userRole?: string
): string => {
  if (selectAccount) {
    return '/select-account';
  }
  if (userRole === corporateRoleType.manager) {
    return '/corporate/project';
  }
  return '/dashboard';
};

export const GetAccountDocumentStatusClassName = (
  status: number,
  horizontal: boolean = false
) => {
  switch (status) {
    case 2:
      if (horizontal) {
        return 'hrInfo';
      } else {
        return 'vrInfo';
      }
    case 3:
      if (horizontal) {
        return 'hrExpiry';
      } else {
        return 'vrExpiry';
      }
    case 4:
      if (horizontal) {
        return 'hrWarning';
      } else {
        return 'vrWarning';
      }
    default:
      return '';
  }
};

export const getCircleColor = (status: number) => {
  switch (status) {
    case 2:
      return 'info--circle';
    case 3:
      return 'expiry--circle';
    case 4:
      return 'warning--circle';
    default:
      return 'grey--circle';
  }
};

export const GetDocumentStatusClassName = (status: number) => {
  switch (status) {
    case 2:
      return 'vrInfo';
    case 3:
      return 'vrExpiry';
    default:
      return '';
  }
};

export const Capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const CapitalizeWords = (str: string): string => {
  let strWithoutUnderscore = str.replace('_', ' ');

  return strWithoutUnderscore
    .toLowerCase()
    .split(' ')
    .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
    .join(' ');
};

export default class Helper {
  static getDocumentExpiryDate = (date: string) => {
    if (date === null) {
      return '-';
    }
    return date.toString().split('-').reverse().join('/');
  };

  static getDateDiff = (date: string) => {
    if (date === null) {
      return '';
    }
    const currentDate: any = new Date(new Date().toISOString().split('T')[0]);
    const dbDate: any = new Date(date);
    let timeDifference: any;
    if (dbDate > currentDate) {
      timeDifference = Math.abs(dbDate - currentDate);
    } else {
      timeDifference = dbDate - currentDate;
    }
    const daysDiff = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return daysDiff;
  };

  static getDocumentExpiryStatus = (date: string) => {
    if (date === null) {
      return 'ok';
    }

    const daysDiff = Helper.getDateDiff(date);

    if (daysDiff < 0) {
      return 'expired';
    } else if (daysDiff >= 0 && daysDiff < 31) {
      return 'warning';
    }

    return 'ok';
  };
}
