import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';

// Application Imports;
import appConfig from '../../../config';
import Breadcrumb from '../roles/Breadcrumb';
import BlankScreen from './BlankScreen';
import AddEditUserModal from './AddEditUserModal';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import ManageUser from '../../../api/ManageUser';
import AlertError from '../../../layout/includes/Alert/Error';
import UserList from './UserList';

interface Props {}

interface State {
  addEditModalOpen: boolean;
  showLoader: boolean;
  errorMessages: Array<string> | string;
  userList: Array<any>;
  modalArea: string;
  userData: any;
}

class UserLandingView extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      addEditModalOpen: false,
      showLoader: true,
      errorMessages: [],
      userList: [],
      modalArea: 'add',
      userData: {},
    };
  }

  addEditModalHandler = (edit?: boolean) => {
    this.setState({
      addEditModalOpen: !this.state.addEditModalOpen,
      modalArea: edit ? 'edit' : 'add',
    });
    if (!edit) {
      this.setState({
        userData: {},
      });
    }
  };

  fetchUserList = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    ManageUser.getUserList(activeAccountUUID)
      .then((response) => {
        this.setState({
          showLoader: false,
        });
        if (response.data.success === 1) {
          this.setState({
            userList: response.data.data.userList,
          });
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          showLoader: false,
          errorMessages: err.message,
        });
        scrollToComponent(this.alertErrorRef, { duration: 500 });
      });
  };

  editUserHandler = (userData: any) => {
    this.setState({
      userData,
    });
    this.addEditModalHandler(true);
  };

  componentDidMount() {
    document.title = `Manage Users ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    this.fetchUserList();
  }

  render() {
    return (
      <>
        <Breadcrumb selectedArea="manageUser" />
        <Container fluid={true} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col sm={12} md={11} lg={10} className="col-centered">
              <AlertError
                ref={(div: any) => {
                  this.alertErrorRef = div;
                }}
                message={this.state.errorMessages}
              />
              {this.state.showLoader ? (
                <Loader />
              ) : this.state.userList.length > 0 ? (
                <UserList
                  userList={this.state.userList}
                  toggle={this.addEditModalHandler}
                  editUserHandler={this.editUserHandler}
                  reloadData={this.fetchUserList}
                />
              ) : (
                <BlankScreen toggle={this.addEditModalHandler} />
              )}
            </Col>
          </Row>
        </Container>
        <AddEditUserModal
          area={this.state.modalArea}
          isOpen={this.state.addEditModalOpen}
          toggle={this.addEditModalHandler}
          reloadData={this.fetchUserList}
          userData={this.state.userData}
        />
      </>
    );
  }
}

export default UserLandingView;
