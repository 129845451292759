import React from 'react';
import { Row, Col, Modal, ModalBody, Button, FormGroup, Label, Input } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import scrollToComponent from 'react-scroll-to-component';

// Application imports
import Loader from '../../../layout/includes/ContentLoader/Loader';
import LinkMember from '../../../api/LinkMember';
import Roles from '../../../api/Roles';
import AppContext from '../../../components/AppContext';
import SelectOptions from '../../../components/UI/SelectOptions';
import AlertError from '../../../layout/includes/Alert/Error';

// Import Icons
import Delete from '@material-ui/icons/Delete';

// Import CSS
import './invite.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

interface Props {
  isOpen: boolean;
  toggle: Function;
  reloadData: Function;
}

interface InviteMember {
  name: string;
  email: string;
  role: string;
}

interface State {
  showLoader: boolean;
  rolesOption: any;
  sendDataArray: Array<InviteMember>;
  errorMessages: Array<string> | string;
  formSubmitted: boolean;
  noticeText: string;
  removeLoader: boolean;
}

class InviteModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      sendDataArray: [{ name: '', email: '', role: '' }],
      rolesOption: [],
      showLoader: true,
      errorMessages: '',
      formSubmitted: false,
      noticeText: '',
      removeLoader: false,
    };
  }

  fetchRole = () => {
    const { activeAccountUUID } = this.context;

    // Request to server;
    Roles.getRoles(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        let roles = [] as any;
        for (let role of response.data.data.roles) {
          roles = [
            ...roles,
            {
              uuid: role.uuid,
              value: role.title,
            },
          ];
        }

        this.setState({
          showLoader: false,
          rolesOption: roles,
        });
      } else {
        toast.error('Error fetching roles.', {
          autoClose: 3000,
        });
        this.props.toggle();
      }
    });
  };

  addNewMember = () => {
    this.setState((prevState) => ({
      sendDataArray: [...prevState.sendDataArray, { name: '', email: '', role: '' }],
    }));
  };

  removeMember = (id: any) => {
    const sendDataArray = this.state.sendDataArray.filter((data, key) => {
      return key !== id;
    });
    this.setState({ sendDataArray });
  };

  onChangeName = (e: any, index: number) => {
    let prevState = [...this.state.sendDataArray];
    prevState[index].name = e.target.value;
    this.setState({
      sendDataArray: prevState,
    });
  };

  onChangeEmail = (e: any, index: number) => {
    let prevState = [...this.state.sendDataArray];
    prevState[index].email = e.target.value;
    this.setState({
      sendDataArray: prevState,
    });
  };

  onChangeRole = (e: any, index: number) => {
    let prevState = [...this.state.sendDataArray];
    prevState[index].role = e.target.value;
    this.setState({
      sendDataArray: prevState,
    });
  };

  onChangeNotice = (e: any) => {
    this.setState({
      noticeText: e.target.value,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { activeAccountUUID } = this.context;
    let isError = false;
    let emailError = false;
    let errorMessages = [];

    this.setState({
      errorMessages: '',
    });

    for (let i = 0; i < this.state.sendDataArray.length; i++) {
      if (
        this.state.sendDataArray[i].name.trim() === '' ||
        this.state.sendDataArray[i].name.trim() === null
      ) {
        isError = true;
        break;
      }
      if (
        this.state.sendDataArray[i].email.trim() === '' ||
        this.state.sendDataArray[i].email.trim() === null
      ) {
        isError = true;
        break;
      }
      if (
        this.state.sendDataArray[i].role.trim() === '' ||
        this.state.sendDataArray[i].role.trim() === null
      ) {
        isError = true;
        break;
      }
    }

    if (isError) {
      errorMessages.push('Please fill all the fields for each member.');
      this.setState({
        errorMessages: errorMessages,
      });
      scrollToComponent(this.alertErrorRef, { duration: 500 });
      return;
    }

    if (emailError) {
      errorMessages.push('Please fill all email fields with correct value.');
      this.setState({
        errorMessages: errorMessages,
      });
      scrollToComponent(this.alertErrorRef, { duration: 500 });
      return;
    }

    this.setState({
      formSubmitted: true,
    });

    // request to server;
    LinkMember.sendInviteRequest(
      activeAccountUUID,
      this.state.sendDataArray,
      this.state.noticeText
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.setState({
            sendDataArray: [{ name: '', email: '', role: '' }],
          });
          this.props.reloadData();
          this.props.toggle();
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  componentDidMount() {
    this.fetchRole();
  }

  render() {
    const sendDataArray = this.state.sendDataArray;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--large modal--large-md"
        backdrop={true}
      >
        <ModalBody className="plr--60 plr--60-sm" style={{ maxHeight: 'inherit' }}>
          <button
            className="close"
            onClick={() => this.props.toggle()}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          {this.state.showLoader ? (
            <Loader />
          ) : (
            <div className="modal-body--inner pt--30">
              <header className="modal-body--header modal-body--header-sm center">
                <h3 className="title">Invite employees to connect</h3>
                <p className="fs--15">
                  Your employee will get an email that gives them the option to
                  <br></br> connect their account with your company
                </p>
              </header>
              <AvForm className="form-horizontal" onSubmit={this.submitHandler}>
                <AlertError
                  ref={(div: any) => {
                    this.alertErrorRef = div;
                  }}
                  message={this.state.errorMessages}
                />
                {sendDataArray.map((data: any, key: number) => (
                  <Row className="u-flexed u-align-start remove-row" key={key}>
                    <Col xs={12} md={4} lg={4}>
                      <FormGroup className="">
                        <Label htmlFor="name">Name</Label>
                        <Input
                          type="text"
                          name="name"
                          placeholder="Enter Name"
                          onChange={(e) => this.onChangeName(e, key)}
                          value={data.name}
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <FormGroup className="">
                        <Label htmlFor="email">Email</Label>
                        <Input
                          name="email"
                          placeholder="Enter Email"
                          type="email"
                          onChange={(e) => this.onChangeEmail(e, key)}
                          value={data.email}
                          // required
                        />
                      </FormGroup>
                    </Col>
                    <Col xs={12} md={4} lg={4}>
                      <div className="select__wrapper">
                        <AvField
                          type="select"
                          name="role"
                          label={'Assign Role'}
                          placeholder="Please select Role"
                          onChange={(e: any) => this.onChangeRole(e, key)}
                          value={data.role}
                        >
                          <SelectOptions
                            data={this.state.rolesOption}
                            placeholder="Please select Role..."
                          />
                        </AvField>
                      </div>
                      {key > 0 && (
                        <button
                          type="button"
                          className="absolute--delete u-mt--0 u-pt--0"
                          onClick={() => this.removeMember(key)}
                        >
                          <Delete fontSize="small" className="icons u-mt--0 u-pt--0" />
                        </button>
                      )}
                    </Col>
                  </Row>
                ))}
                <Row className="u-flexed u-align-center u-mb--15">
                  <Col xs={12} md={12} lg={12}>
                    <button
                      type="button"
                      className="links--primary"
                      onClick={this.addNewMember}
                    >
                      + Add another member
                    </button>
                  </Col>
                </Row>
                <Row className="u-flexed u-align-center u-mb--20">
                  <Col xs={12} md={12} lg={12}>
                    <FormGroup>
                      <Label htmlFor="Notes">Notes</Label>
                      <textarea
                        name="Notes"
                        id="Notes"
                        className="form-control"
                        rows={5}
                        cols={5}
                        onChange={this.onChangeNotice}
                      ></textarea>
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="u-flexed u-align-center u-justify-center text-center">
                  <Col xs={12} md={12} lg={12}>
                    <Button
                      type="submit"
                      disabled={this.state.formSubmitted}
                      className={`btn-radius btn btn-primary u-upper ${
                        this.state.formSubmitted ? `inlineloading` : ``
                      }`}
                    >
                      SEND connection INVITE
                    </Button>
                  </Col>
                </Row>
              </AvForm>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default InviteModal;
