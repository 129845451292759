import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import AppContext from '../../../components/AppContext';
import appConfig from '../../../config';
import BlankScreen from './BlankScreen';
import LinkMember from '../../../api/LinkMember';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import MemberList from './MemberList';
import InviteModal from './InviteModal';
import Breadcrumb from '../roles/Breadcrumb';

// Import CSS
import './invite.scss';
import './admin.scss';

interface Props {}

interface State {
  activeMemberList: Array<any>;
  activeMemberListCopy: Array<any>;
  pendingMemberList: Array<any>;
  roles: Array<any>;
  showLoader: boolean;
  inviteModalOpen: boolean;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

class MemberLanding extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      activeMemberList: [],
      activeMemberListCopy: [],
      pendingMemberList: [],
      roles: [],
      inviteModalOpen: false,
      activeMemberCount: 0,
      unlinkedMemberCount: 0,
    };
  }

  inviteModalHandler = () => {
    this.setState({
      inviteModalOpen: !this.state.inviteModalOpen,
    });
  };

  fetchMemberList = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    LinkMember.getAccountsList(activeAccountUUID, false, false, true).then((response) => {
      if (response.data.success === 1) {
        const activeMemberList = response.data.data.linked;
        const activeMemberCount = activeMemberList.filter(
          (data: any) => data.status !== 0
        ).length;
        const unlinkedMemberCount = activeMemberList.length - activeMemberCount;

        this.setState({
          showLoader: false,
          activeMemberList: response.data.data.linked,
          activeMemberListCopy: response.data.data.linked,
          pendingMemberList: response.data.data.pending,
          roles: response.data.data.roles,
          activeMemberCount,
          unlinkedMemberCount,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  getRoleName = (roleUuid: string) => {
    for (let roleIndex in this.state.roles) {
      let role = this.state.roles[roleIndex];
      if (role.uuid === roleUuid) {
        return role.title;
      }
    }
    return '';
  };

  updateMemberListStatus = (uuid: string, roleUuid?: string) => {
    const data = this.state.activeMemberList.map((data: any) => {
      if (data.uuid === uuid) {
        if (roleUuid) {
          data.role = this.getRoleName(roleUuid);
          data.roleUuid = roleUuid;
        } else {
          data.status = data.status === 0 ? 1 : 0;
        }
      }
      return data;
    });
    this.setState({
      activeMemberList: data,
    });
  };

  updatePendingMemberList = (pendingInviteUuid: string) => {
    const pendingMemberList = this.state.pendingMemberList.filter((data: any) => {
      return data.uuid !== pendingInviteUuid;
    });

    this.setState({
      pendingMemberList,
    });
  };

  deleteFromMemberList = (uuid: string) => {
    const activeMemberList = this.state.activeMemberList.filter((data: any) => {
      return data.uuid !== uuid;
    });

    this.setState({
      activeMemberList,
    });
  };

  removePendingInvitationMemberListStatus = (uuid: string, roleUuid?: string) => {
    const data = this.state.activeMemberList.map((data: any) => {
      if (data.uuid === uuid) {
        if (roleUuid) {
          data.role = this.getRoleName(roleUuid);
          data.roleUuid = roleUuid;
        } else {
          data.status = data.status === 0 ? 1 : 0;
        }
      }
      return data;
    });
    this.setState({
      activeMemberList: data,
    });
  };

  filterMemberList = (filterWord: string) => {
    const filter = filterWord.toUpperCase();

    let filteredActiveMemberList = this.state.activeMemberListCopy.filter((data: any) => {
      let txtValue = data.name;
      let txtValueEmail = data.email || '';

      if (
        txtValue.toUpperCase().indexOf(filter) > -1 ||
        txtValueEmail.toUpperCase().indexOf(filter) > -1
      ) {
        return data;
      }
      return false;
    });

    this.setState({
      activeMemberList: filteredActiveMemberList,
    });
  };

  resetMemberFilter = () => {
    this.setState({
      activeMemberList: this.state.activeMemberListCopy,
    });
  };

  componentDidMount() {
    document.title = `Linked Personnel ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch members list;
    this.fetchMemberList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.activeMemberList !== this.state.activeMemberList &&
      this.state.activeMemberList.length > 0
    ) {
      const activeMemberList = this.state.activeMemberList.filter((data: any) => {
        return data.status !== 0;
      });

      const unlinkedMemberList = this.state.activeMemberList.filter((data: any) => {
        return data.status !== 1;
      });

      this.setState({
        activeMemberCount: activeMemberList.length,
        unlinkedMemberCount: unlinkedMemberList.length,
      });
    }
  }

  render() {
    return (
      <>
        <Breadcrumb selectedArea="linkedMember" />
        <Container fluid={true} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col sm={12} md={11} lg={10} className="col-centered">
              {this.state.showLoader ? (
                <Loader />
              ) : this.state.activeMemberListCopy.length > 0 ||
                this.state.pendingMemberList.length > 0 ? (
                <MemberList
                  activeMemberCount={this.state.activeMemberCount}
                  unlinkedMemberCount={this.state.unlinkedMemberCount}
                  memberList={this.state.activeMemberList}
                  pendingMemberList={this.state.pendingMemberList}
                  roles={this.state.roles}
                  filterMemberList={this.filterMemberList}
                  resetMemberFilter={this.resetMemberFilter}
                  toggle={this.inviteModalHandler}
                  updateMemberListStatus={this.updateMemberListStatus}
                  updatePendingMemberList={this.updatePendingMemberList}
                  reloadData={this.fetchMemberList}
                  deleteFromMemberList={this.deleteFromMemberList}
                />
              ) : (
                <BlankScreen toggle={this.inviteModalHandler} />
              )}
            </Col>
          </Row>
        </Container>
        <InviteModal
          isOpen={this.state.inviteModalOpen}
          toggle={this.inviteModalHandler}
          reloadData={this.fetchMemberList}
        />
      </>
    );
  }
}

export default MemberLanding;
