import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Import Icons
import InviteGraphic from '../../../assets/images/No-Team-Members.svg';

interface Props {}

interface State {}

class BlankScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={6} lg={5} className="col-centered">
              <div className="invite--section invite--section-sm text-center">
                <div className="invite--graphic invite--graphic-sm u-mb--60 u-mb--60-sm">
                  <img
                    src={InviteGraphic}
                    alt="Linked Accounts"
                    className="img-fluid"
                  />
                </div>
                <div className="page-head page-head-sm center column noborder">
                  <h2 className="u-heading--xsmall u-heading--xsmall-sm">
                    <span style={{ color: '#4f4f4f' }}>No Linked Accounts</span>
                  </h2>
                  <p className="text-center fs--13 dark-gray">
                    Sorry, there are no linked corporate accounts to your profile yet.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BlankScreen;
