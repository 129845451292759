import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Application imports;
import BlankRoleScreen from './BlankRoleScreen';
import RoleList from './RoleList';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import Breadcrumb from './Breadcrumb';

// Import Api's functions;
import Roles from '../../../api/Roles';

// Import CSS;
import './role.scss';

interface Props extends RouteComponentProps {}

interface State {
  roleList: any;
  showLoader: boolean;
}

class RolesLanding extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      roleList: [],
      showLoader: true,
    };
  }

  fetchRoles = () => {
    const { activeAccountUUID } = this.context;

    // Get data from server
    Roles.getRoles(activeAccountUUID).then((response) => {
      this.setState({
        showLoader: false,
      });
      if (response.data.success === 1) {
        this.setState({
          roleList: response.data.data.roles,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Roles ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // Fetch Role List;
    this.fetchRoles();
  }

  render() {
    return (
      <React.Fragment>
        <Breadcrumb selectedArea="role" />
        <Container fluid={true} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={12} lg={12} className="col-centered">
              {this.state.showLoader ? (
                <Loader />
              ) : this.state.roleList.length > 0 ? (
                <RoleList roleList={this.state.roleList} />
              ) : (
                <BlankRoleScreen />
              )}
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(RolesLanding);
