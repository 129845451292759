import appConfig from '../config';
import { getAccountDefaultRoute } from './Helpers';
import { accountType, corporateRoleType } from './Constant';
import { encrypt, decrypt } from '../utils/EncryptDecrypt';

export type Account = {
  uuid: string;
  account_type: string;
  company_name: string;
  company_email: string;
  user_role: string;
  account_status: string;
  account_subscription_paid: number;
};

type User = {
  first_name?: string;
  last_name?: string;
  email?: string;
};

export default class LocalStorage {
  static clearData = () => {
    localStorage.removeItem(appConfig.LOCAL_STORAGE_NAME);
  };

  static getInitialState = () => {
    return {
      user: {
        firstName: '',
        lastName: '',
        email: '',
        totalAccounts: 0,
      },
      isLoggedIn: false,
      jwt: '',
      activeAccountUUID: '',
      accountsList: [],
      filestack: {
        policy: '',
        signature: '',
      },
      intercom: {
        user_id: '',
        hash: '',
      },
    };
  };

  static init = () => {
    let localData = localStorage.getItem(appConfig.LOCAL_STORAGE_NAME);
    if (localData === null) {
      localStorage.setItem(
        appConfig.LOCAL_STORAGE_NAME,
        JSON.stringify(LocalStorage.getInitialState())
      );
    }
  };

  static getData = () => {
    return localStorage.getItem(appConfig.LOCAL_STORAGE_NAME);
  };

  static getJsonData = () => {
    const localData = LocalStorage.getData();
    const localJSONData = JSON.parse(localData || '') || {};

    return localJSONData;
  };

  static getByKey = (key: string) => {
    const localJSONData = LocalStorage.getJsonData();
    return localJSONData[key];
  };

  static setJsonData = (data: object) => {
    localStorage.setItem(appConfig.LOCAL_STORAGE_NAME, JSON.stringify(data));
  };

  static changeLocalStorageVal = (key: string, val: any) => {
    const localJSONData = LocalStorage.getJsonData();
    localJSONData[key] = val;
    LocalStorage.setJsonData(localJSONData);
  };

  ////////////////////////////////////
  //filestack Object Related Functions
  ////////////////////////////////////
  static getFilestackObject = () => {
    const localJSONData = LocalStorage.getJsonData();
    return localJSONData['filestack'];
  };

  static getFilestackSecureUrl = (url: string) => {
    const filestackLSObject = LocalStorage.getFilestackObject();
    return (
      url +
      '?policy=' +
      filestackLSObject.policy +
      '&signature=' +
      filestackLSObject.signature
    );
  };

  static getFilestackTransformedUrl = (
    handleId: string,
    transformations: string = '',
    mediaDetails: any = {}
  ) => {
    const filestackLSObject = LocalStorage.getFilestackObject();
    let url = 'https://cdn.filestackcontent.com';
    if (mediaDetails.mimetype.includes('image')) {
      if (transformations !== '') {
        url += '/' + transformations;
      }
    } else {
      url += '/output=format:png';
    }

    url +=
      '/security=policy:' +
      filestackLSObject.policy +
      ',signature:' +
      filestackLSObject.signature;
    url += '/' + handleId;

    return url;
  };

  ////////////////////////////////////
  //JWT Object Related Functions
  ////////////////////////////////////
  static getJwtSecretKey = (): string => {
    return LocalStorage.getUser('uuid') + '-' + appConfig.CRYPTR_SECRET_KEY;
  };

  static getJwt = () => {
    const localJSONData = LocalStorage.getJsonData();
    return decrypt(localJSONData['jwt'], LocalStorage.getJwtSecretKey());
  };

  static setJwt = (jwt: string) => {
    const localJSONData = LocalStorage.getJsonData();
    localJSONData['jwt'] = encrypt(jwt, LocalStorage.getJwtSecretKey());

    LocalStorage.setJsonData(localJSONData);
  };

  ////////////////////////////////////
  //User Object Related Functions
  ////////////////////////////////////
  static getUser = (field: string = '') => {
    const localJSONData = LocalStorage.getJsonData();
    return field !== '' ? localJSONData['user'][field] : localJSONData['user'];
  };

  static setUser = (data: User) => {
    const localJSONData = LocalStorage.getJsonData();
    localJSONData.user = { ...data };

    //lets change the personal account details
    let accountsList = localJSONData.accountsList;
    for (let i = 0; i < accountsList.length; i++) {
      if (accountsList[i].account_type === 'personal') {
        accountsList[i].company_name =
          localJSONData.user.first_name + ' ' + localJSONData.user.last_name;
      }
    }

    LocalStorage.changeLocalStorageVal('accountsList', accountsList);
    LocalStorage.changeLocalStorageVal('user', localJSONData.user);
  };

  static setUserField = (field: string, value: any) => {
    const localJSONData = LocalStorage.getJsonData();
    localJSONData['user'][field] = value;

    LocalStorage.setJsonData(localJSONData);
  };

  ////////////////////////////////
  //Account Related Functions
  ////////////////////////////////
  static setActiveAccountUUID = (value: string): void => {
    LocalStorage.changeLocalStorageVal('activeAccountUUID', value);
  };

  static getAccountsList = (): Array<Account> => {
    const localJSONData = LocalStorage.getJsonData();
    return localJSONData.accountsList;
  };

  static getActiveAccount = (): Account => {
    const localJSONData = LocalStorage.getJsonData();
    let currentAccount = {} as Account;
    for (let i = 0; i < localJSONData.accountsList.length; i++) {
      currentAccount = localJSONData.accountsList[i];
      if (currentAccount.uuid === localJSONData.activeAccountUUID) {
        return currentAccount;
      }
    }
    return currentAccount;
  };

  static updateActiveAccountDetails = (updatedAccountDetails: Account): void => {
    const localJSONData = LocalStorage.getJsonData();

    //lets change the active account details
    let accountsList = localJSONData.accountsList;
    accountsList = accountsList.map((data: any) => {
      if (data.uuid === updatedAccountDetails.uuid) {
        return updatedAccountDetails;
      }
      return data;
    });
    LocalStorage.changeLocalStorageVal('accountsList', accountsList);
  };

  static getCurrentAccountType = () => {
    const activeAccount = LocalStorage.getActiveAccount();
    return activeAccount.account_type;
  };

  static getCurrentAccountRole = () => {
    const activeAccount = LocalStorage.getActiveAccount();
    return activeAccount.user_role;
  };

  static isCurrentAccountCorporate = () => {
    const activeAccountType = LocalStorage.getCurrentAccountType();
    return accountType.corporate === activeAccountType;
  };

  static hasManagerRole = () => {
    const activeAccountRole = LocalStorage.getCurrentAccountRole();
    return corporateRoleType.manager === activeAccountRole;
  };

  static isCurrentAccountCorporateViewOnly = () => {
    const activeAccountType = LocalStorage.getCurrentAccountType();
    return accountType.corporateView === activeAccountType;
  };

  static isCurrentAccountPersonal = () => {
    const activeAccountType = LocalStorage.getCurrentAccountType();
    return accountType.personal === activeAccountType;
  };

  static getDefaultRoute = () => {
    const currentAccountType = LocalStorage.getCurrentAccountType();
    return getAccountDefaultRoute(
      currentAccountType,
      false,
      LocalStorage.getCurrentAccountRole()
    );
  };

  static hasCorporateAccount = (): boolean => {
    const accountList = LocalStorage.getAccountsList();
    let account: Account;

    for (account of accountList) {
      if (account.account_type === accountType.corporate) {
        return true;
      }
    }

    return false;
  };

  static hasCorporateViewAccount = (): boolean => {
    const accountList = LocalStorage.getAccountsList();
    let account: Account;

    for (account of accountList) {
      if (account.account_type === accountType.corporateView) {
        return true;
      }
    }

    return false;
  };

  //redirect after login
  static setLoginRedirectRoute = (redirectRoute: string) => {
    localStorage.setItem('redirectRoute', redirectRoute);
  };

  static getLoginRedirectRoute = (clearAfterGet: boolean = true) => {
    const redirectRoute = localStorage.getItem('redirectRoute');
    if (clearAfterGet) {
      localStorage.removeItem('redirectRoute');
    }
    return redirectRoute;
  };
}
