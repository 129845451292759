import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Link, animateScroll as scroll } from 'react-scroll';

import { Nav, NavItem } from 'reactstrap';

interface MenuProps extends RouteComponentProps {}

interface MenuState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

class Menu extends React.Component<MenuProps, MenuState> {
  constructor(props: MenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
    };
  }

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
    this.setState({
      isOpen: !this.state.isOpen,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <nav className="navigation d-none d-lg-flex d-xl-flex">
          <Nav className="navigation--list">
            <NavItem>
              <Link
                to="Features"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                activeClass="is--active"
              >
                Features
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="AboutUs"
                spy={true}
                smooth={true}
                offset={-40}
                duration={500}
                activeClass="is--active"
              >
                About us
              </Link>
            </NavItem>
            <NavItem>
              <Link
                to="Pricing"
                spy={true}
                smooth={true}
                offset={-30}
                duration={500}
                activeClass="is--active"
              >
                Pricing
              </Link>
            </NavItem>
          </Nav>
        </nav>
      </>
    );
  }
}

export default withRouter(Menu);
