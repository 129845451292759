import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Static Assets
import ProfileSearch from '../../assets/images/search.svg';

interface Props {}

class BlankScreen extends React.PureComponent<Props, {}> {
  render() {
    return (
      <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
        <Row>
          <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
            <div className="myprofile--section myprofile--section-sm u-pt--0">
              <div className="search--profile">
                <div className="search--profile__graphic search--profile__graphic-sm">
                  <img src={ProfileSearch} width="255" alt="Profile Search" />
                </div>
                <p className="fs--15">
                  <strong>Enter the Customer Reference Number to start seaching!</strong>
                </p>
                <p className="fs--13 dark-grey">
                  This CRN would have been given to you by the person <br></br>
                  or company it belongs to
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default BlankScreen;
