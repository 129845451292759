import React from 'react';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';
import { ListGroup, ListGroupItem, Dropdown, DropdownToggle, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports;
import LocalStorage from '../../../helpers/LocalStorage';
import ProfileDropdown from './ProfileDropDownMenu/ProfileDropdownmenu';
import Project from '../../../api/Project';
import AppContext from '../../../components/AppContext';
import ProjectBlankScreen from './ProjectBlankScreen';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';
import '../members/member-list.scss';

// Import Icons
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Search from '@material-ui/icons/Search';
import StarIcon from '@material-ui/icons/Star';
import StarBorderIcon from '@material-ui/icons/StarBorder';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  userName?: string;
  title: string;
  area: string;
}

interface State {
  dropdownOpen: Array<boolean>;
  filterWord: string;
  projectList: Array<any>;
  showLoader: boolean;
}

class ProjectsList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: [],
      filterWord: '',
      projectList: [],
      showLoader: true,
    };
  }

  // Function for toggle dropdown option for edit and delete button;
  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  // Function for filter the data;
  filterHandler = (e: any, buttonClick?: boolean) => {
    const value = buttonClick ? e : e.target.value;
    this.setState({
      filterWord: value,
    });

    const projectList = this.state.projectList.map((project: any) => {
      project.matchesFilterWord =
        project.project_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          ? true
          : false;
      return project;
    });

    this.setState({
      projectList,
    });
  };

  // Function for delete the project confirmation;
  deleteProjectConfirmation = (projectUuid: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteProject(projectUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  // Function for delete the project;
  deleteProject = (projectUuid: string) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Project.deleteProject(activeAccountUUID, projectUuid).then((response) => {
      if (response.data.success === 1) {
        this.updateProjectListState(projectUuid);
        toast.success('Project deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  favouriteClickHandler = (projectUuid: string) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Project.favouriteProjectToggler(activeAccountUUID, projectUuid).then((response) => {
      if (response.data.success === 1) {
        this.updateProjectListState(projectUuid, true);
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  updateProjectListState = (projectUuid: string, favouriteClicked: boolean = false) => {
    let projectList = [];
    if (!favouriteClicked) {
      projectList = this.state.projectList.filter((data: any) => {
        return data.uuid !== projectUuid;
      });
    } else {
      projectList = this.state.projectList.map((data: any) => {
        if (data.uuid === projectUuid) {
          data.favourite = data.favourite === 1 ? 0 : 1;
        }
        return data;
      });
    }

    this.setState({
      projectList,
    });
  };

  fetchProjects = () => {
    const { activeAccountUUID } = this.context;

    if (this.props.area === 'personalProjectView') {
      Project.getAccountInvitedProjects(activeAccountUUID).then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            projectList: response.data.data.projects,
          });
        }
      });
    } else if (this.props.area === 'linkedAccountProjectView') {
      Project.getProjects(
        activeAccountUUID,
        'linkedAccountProjects',
        this.props.match.params.uuid
      ).then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            projectList: response.data.data.projects,
          });
        }
      });
    } else {
      Project.getProjects(activeAccountUUID, this.props.area).then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            projectList: response.data.data.projects,
          });
        }
      });
    }
  };

  componentDidMount() {
    // fetch project list;
    this.fetchProjects();
  }

  render() {
    const projectPreviewUrlPrefix =
      this.props.area === 'personalProjectView' ? '/project' : '/corporate/project-view';
    const { activeAccountUUID } = this.context;
    return (
      <>
        {this.state.showLoader ? (
          <div className="u-ml--20 loader-left">
            <Loader />
          </div>
        ) : this.state.projectList.length > 0 ? (
          <div className="myteams--section myteams--section-sm owl--content__heading">
            <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
              <h2 className="u-heading--medium u-heading--medium-sm">
                {this.props.title} {this.props.userName && `(${this.props.userName})`}
              </h2>
            </div>
            <div className="portlet">
              <div className="portlet--body">
                <div className="portlet--body__wrapper">
                  <div className="portlet--search size--300">
                    <Input
                      autoComplete="off"
                      name="filterValue"
                      id="filterValue"
                      className="form--fields"
                      placeholder="Search"
                      value={this.state.filterWord}
                      onChange={this.filterHandler}
                    />
                    <button className="portlet--search__btn" type="button">
                      <Search
                        fontSize="large"
                        className="icons"
                        onClick={() => this.filterHandler(this.state.filterWord, true)}
                      />
                    </button>
                  </div>
                  {this.props.area === 'allProject' && (
                    <div className="ml-auto">
                      {/* <Link
                        to={{
                          pathname: '/corporate/project/create',
                          state: { prevPath: this.props.location.pathname },
                        }}
                        className="btn-radius btn-w160 btn btn-primary"
                      >
                        + Add Project
                      </Link> */}
                    </div>
                  )}
                </div>

                <div className="profile--links">
                  <ListGroup>
                    <ListGroupItem className="head">
                      <div className="NameColumn">Project Name</div>
                      <div className="RoleColumn">Start Date</div>
                    </ListGroupItem>
                    {this.state.projectList.map((data: any, key: number) => (
                      <React.Fragment key={data.uuid}>
                        {data.matchesFilterWord !== false && (
                          <ListGroupItem
                            className={GetAccountDocumentStatusClassName(
                              data.accountDocumentStatus,
                              false
                            )}
                          >
                            <div className="NameColumn">
                              {this.props.area !== 'personalProjectView' && (
                                <button
                                  type="button"
                                  onClick={() => this.favouriteClickHandler(data.uuid)}
                                >
                                  {data.favourite ? <StarIcon /> : <StarBorderIcon />}
                                </button>
                              )}
                              <div className="list-group--details">
                                {activeAccountUUID === data.account_uuid ? (
                                  <Link
                                    to={{
                                      pathname: `${projectPreviewUrlPrefix}/${data.uuid}${
                                        projectPreviewUrlPrefix === '/project'
                                          ? ''
                                          : '/' + activeAccountUUID
                                      }`,
                                      state: { prevPath: this.props.location.pathname },
                                    }}
                                  >
                                    <p className="user--name">{data.project_name}</p>
                                  </Link>
                                ) : (
                                  <p className="user--name">{data.project_name}</p>
                                )}

                                {this.props.area === 'personalProjectView' && (
                                  <p className="user--name">({data.company_name})</p>
                                )}
                              </div>
                            </div>
                            <div className="RoleColumn">
                              <>{data.start_date ? data.start_date : '-'}</>
                            </div>
                            <div className="ActivityColumn">
                              {activeAccountUUID === data.account_uuid ? (
                                <Link
                                  to={{
                                    pathname: `${projectPreviewUrlPrefix}/${data.uuid}${
                                      projectPreviewUrlPrefix === '/project'
                                        ? ''
                                        : '/' + activeAccountUUID
                                    }`,
                                    state: { prevPath: this.props.location.pathname },
                                  }}
                                >
                                  <span className="badge badge-primary text-dark u-ml--5">
                                    {data.total_linked_accounts
                                      ? data.total_linked_accounts
                                      : 0}{' '}
                                    Members
                                  </span>
                                </Link>
                              ) : (
                                <span className="badge badge-primary text-dark u-ml--5">
                                  {data.total_linked_accounts
                                    ? data.total_linked_accounts
                                    : 0}{' '}
                                  Members
                                </span>
                              )}
                              {!LocalStorage.hasManagerRole() && (
                                <div className="ml-auto">
                                  <div className="deny u-flexed u-align-center">
                                    {this.props.area !== 'personalProjectView' && (
                                      <Dropdown
                                        className="members--dropdown"
                                        isOpen={this.state.dropdownOpen[key]}
                                        toggle={() => this.toggleDropdown(key)}
                                      >
                                        <DropdownToggle color="primary">
                                          <MoreHoriz
                                            fontSize="large"
                                            className="icons"
                                            style={{ margin: '0' }}
                                          />
                                        </DropdownToggle>
                                        <ProfileDropdown
                                          area={
                                            data.invited
                                              ? 'inviteProject'
                                              : this.props.area
                                          }
                                          projectUuid={data.uuid}
                                          deleteProjectConfirmation={
                                            this.deleteProjectConfirmation
                                          }
                                        />
                                      </Dropdown>
                                    )}
                                  </div>
                                </div>
                              )}
                            </div>
                          </ListGroupItem>
                        )}
                      </React.Fragment>
                    ))}
                  </ListGroup>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <ProjectBlankScreen area={this.props.area} />
        )}
      </>
    );
  }
}

export default withRouter(ProjectsList);
