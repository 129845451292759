import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter, matchPath } from 'react-router-dom';
import { toast } from 'react-toastify';

//Axios Instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

//Application Imports
import AppContext from '../../../components/AppContext';
import LocalStorage from '../../../helpers/LocalStorage';
import { accountType } from '../../../helpers/Constant';
import TableList from './TableList';

// Import Icons
import Backup from '@material-ui/icons/Backup';

// Import CSS
import './document-list.scss';
import './upload.scss';

interface Props extends RouteComponentProps {
  area?: string;
  documentList: any;
  updateListData: Function;
  showEditElements?: boolean;
}

interface State {}

class DocumentList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  visibleChangeHandler = (documentUUID: string) => {
    const { activeAccountUUID } = this.context;

    // prepare JSON data for request;
    const postData = {
      uuid: activeAccountUUID,
      document_uuid: documentUUID,
    };

    // server request through axios;
    axiosInstance
      .post('/document/update-public-status', postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          this.props.updateListData();
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      });
  };

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    return (
      <>
        <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
          <Row>
            <Col
              xs={12}
              sm={12}
              md={
                matchPath(this.props.match.path, {
                  path: '/corporate/team/member-documents/:uuid',
                })
                  ? 12
                  : 11
              }
              lg={
                matchPath(this.props.match.path, {
                  path: '/corporate/team/member-documents/:uuid',
                })
                  ? 12
                  : 11
              }
              className={`col-centered ${
                activeAccount.account_type === accountType.corporate
                  ? `corporate-documentation`
                  : ``
              }`}
            >
              <div
                className={`myprofile--section myprofile--section-sm ${
                  !this.props.showEditElements ||
                  activeAccount.account_type === accountType.corporate
                    ? `u-pt--0`
                    : `u-pt--25`
                }`}
              >
                {this.props.showEditElements && (
                  <div className="myprofile--docUpload myprofile--docUpload-sm">
                    <button
                      onClick={() => this.props.history.push('/document/add')}
                      type="button"
                      style={{ overflow: 'hidden' }}
                      className="c-button c-button--primary c-button--primary-bordered c-button-dashed c-button-block c-button-radius c-button-lg"
                    >
                      <Backup fontSize="small" className="icons u-mr--5" />
                      Upload Documentation
                    </button>
                  </div>
                )}

                {this.props.documentList.license && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">License </h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="license"
                      data={this.props.documentList.license}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.registration && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">
                        Registration
                      </h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="registration"
                      data={this.props.documentList.registration}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.insurance && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">Insurance</h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="insurance"
                      data={this.props.documentList.insurance}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.certificate && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">
                        Certificate
                      </h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="certificate"
                      data={this.props.documentList.certificate}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.security_check && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">
                        Security Check
                      </h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="security_check"
                      data={this.props.documentList.security_check}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.passport && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">Passport</h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="passport"
                      data={this.props.documentList.passport}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.visa && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">Visa</h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="visa"
                      data={this.props.documentList.visa}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.resume && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">Resume</h2>
                    </div>
                    <TableList
                      showEditElements={this.props.showEditElements}
                      type="resume"
                      data={this.props.documentList.resume}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.cover_letter && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">
                        Cover Letter
                      </h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="cover_letter"
                      data={this.props.documentList.cover_letter}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}

                {this.props.documentList.others && (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--small u-heading--small-sm">Others</h2>
                    </div>
                    <TableList
                      area={this.props.area}
                      showEditElements={this.props.showEditElements}
                      type="others"
                      data={this.props.documentList.others}
                      visibleChangeHandler={this.visibleChangeHandler}
                      updateListData={this.props.updateListData}
                    />
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(DocumentList);
