import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

// Static Includes
import Layout from './Layout';
import appConfig from '../../../config';
import ProjectsList from './ProjectsList';

interface Props extends RouteComponentProps {}

interface State {
  userName: string;
}

class LinkedProjectListLanding extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      userName: '',
    };
  }

  componentDidMount() {
    document.title = `Projects ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    const prevState: any = this.props.location.state;
    if (prevState) {
      this.setState({
        userName: prevState.userName,
      });
    }
  }

  render() {
    return (
      <>
        <Layout>
          <div className="owl--content">
            <ProjectsList
              area="linkedAccountProjectView"
              title="Projects"
              userName={this.state.userName}
            />
          </div>
        </Layout>
      </>
    );
  }
}

export default LinkedProjectListLanding;
