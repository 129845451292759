import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

interface AlertSuccessProps {
  message: string;
}

interface AlertSuccessState {}

class AlertSuccess extends React.PureComponent<AlertSuccessProps, AlertSuccessState> {
  render() {
    return this.props.message !== '' ? (
      <UncontrolledAlert color="success">{this.props.message}</UncontrolledAlert>
    ) : (
      ''
    );
  }
}

export default AlertSuccess;
