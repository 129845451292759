import React from 'react';

// Static Includes
import Layout from './Layout';
import ProjectPreview from '../../common/project-preview/ProjectPreview';

class CorporateProjectPreview extends React.PureComponent {
  render() {
    return (
      <Layout>
        <div className="owl--content">
          <div className="corporate-project-view">
            <ProjectPreview area="corporateSide" />
          </div>
        </div>
      </Layout>
    );
  }
}

export default CorporateProjectPreview;
