import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Static Imports
import ProfileSearch from '../../../assets/images/search.jpg';

interface Props extends RouteComponentProps {
  message?: string;
  activeItem: string;
}

interface State {}

class BlankScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    let heading = 'Enter the Customer Reference Number to start searching!';
    let message =
      'This CRN would have been given to you by the person or company it belongs to';
    if (this.props.activeItem === 'subcontractor') {
      heading = 'Use our database to find a subcontractor';
      message = 'Always know the subcontractor you want to hire is compliant';
    }
    return (
      <>
        <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
          <Row>
            <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
              <div className="myprofile--section myprofile--section-sm u-pt--0">
                <div className="search--profile">
                  <div className="search--profile__graphic search--profile__graphic-sm">
                    <img src={ProfileSearch} width="255" alt="Profile Search" />
                  </div>
                  <p className="fs--15">
                    <strong>{this.props.message ? this.props.message : heading}</strong>
                  </p>
                  {!this.props.message && <p className="fs--13 dark-grey">{message}</p>}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(BlankScreen);
