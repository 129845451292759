import axios from 'axios';
import { toast } from 'react-toastify';
import appConfig from '../config';
import LocalStorage from '../helpers/LocalStorage';

const axiosInstance = axios.create({
  baseURL: appConfig.apiGateway.BASE_URL,
});

if (appConfig.REACT_APP_STAGE !== 'test') {
  // Also add/ configure interceptors && all the other cool stuff
  axiosInstance.interceptors.request.use(
    (config) => {
      if (!config.headers.Authorization) {
        const jwt = LocalStorage.getJwt();
        if (jwt !== '') {
          config.headers.Authorization = `Bearer ${jwt}`;
        }
      }

      return config;
    },
    (error) => Promise.reject(error)
  );

  // Add a 401 response interceptor
  axiosInstance.interceptors.response.use(
    function (response) {
      //in case JWT is modified (regenerated due to expiry)
      if (response.data.data && response.data.data.jwt) {
        const jwt = LocalStorage.getJwt();

        if (jwt !== response.data.data.jwt) {
          LocalStorage.setJwt(response.data.data.jwt);
        }
      }

      //in case accountList Status is modified;
      if (response.data.data && response.data.data.accountsList) {
        const accountList = LocalStorage.getAccountsList();
        if (accountList !== response.data.data.accountsList) {
          LocalStorage.changeLocalStorageVal(
            'accountsList',
            response.data.data.accountsList
          );
        }
      }
      return response;
    },
    function (error) {
      if (!error.response) {
        toast.error('Please check your internet connection.');
      } else {
        //unauthorized access. token verification failed
        if (401 === error.response.status) {
          LocalStorage.clearData();
          LocalStorage.setLoginRedirectRoute(window.location.pathname);
          toast.info(
            'You have been logged out due to token invalidation. Please login again.',
            {
              autoClose: 4000,
              onClose: function () {
                window.location.href = '/auth/login';
              },
            }
          );
        } else {
          let message = error.response.statusText;
          let code = 0;
          //exception
          if (error.response.data && error.response.data.message) {
            message = error.response.data.message;
            code = error.response.data.code;
          }

          if (code === 900) {
            toast.error(message, {
              autoClose: 4000,
              onClose: function () {
                window.location.href = '/';
              },
            });
          } else {
            toast.error(message);
          }
        }
      }

      return Promise.reject(error);
    }
  );
}

export default axiosInstance;
