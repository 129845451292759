import React from 'react';

// Application Imports;
import ColumnListingMarkup from './ColumnListingMarkup';
import ApprovalColumnListMarkup from './ApprovalColumnListMarkup';

// Import Icons
import NoComplianceAlertGraphic from '../../../assets/images/No-Compliance-Alerts.jpg';
import NoApprovalsGraphic from '../../../assets/images/No-Approvals.jpg';

// Import CSS
import './team-member-landing.scss';

interface Props {
  approvedList: Array<any>;
  needApprovalList: Array<any>;
  pendingList: Array<any>;
  expiringList: Array<any>;
}

interface State {}

class ColumnView extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const totalNeedApproval =
      this.props.pendingList.length + this.props.needApprovalList.length;

    return (
      <div className="board--wrapper">
        <div className="board--header columnCount--03">
          <div className="board--header__coloumn">
            <div className="coloumn--title">
              Alerts <span className="count">({this.props.expiringList.length})</span>
            </div>
          </div>
          <div className="board--header__coloumn">
            <div className="coloumn--title">
              Needs Approval <span className="count">({totalNeedApproval})</span>
            </div>
          </div>
          <div className="board--header__coloumn">
            <div className="coloumn--title">
              Compliant <span className="count">({this.props.approvedList.length})</span>
            </div>
          </div>
        </div>

        <div className="board--canvas columnCount--03">
          <div className="mobile--board--header__coloumn">
            <div className="coloumn--title">
              Alerts <span className="count">({this.props.expiringList.length})</span>
            </div>
          </div>
          {this.props.expiringList.length > 0 ? (
            <ColumnListingMarkup
              dataList={this.props.expiringList}
              columnListingType="alert"
            />
          ) : (
            <div className="board--canvas__coloumn">
              <div className="board--canvas__widget">
                <div className="board--canvas__widget__content">
                  <div className="board--canvas__widget__content--body">
                    <div className="board--canvas__widget__content--body--scroll">
                      <div className="text-center w250">
                        <div className="Graphic235">
                          <img src={NoComplianceAlertGraphic} alt="No Compliance Alert" />
                        </div>
                        <h4 className="fs--15 u-bold">No Compliance Alerts</h4>
                        <p className="fs--13 dark-gray">
                          All Team Mambers are all up to date with their compliance
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mobile--board--header__coloumn">
            <div className="coloumn--title">
              Needs Approval <span className="count">({totalNeedApproval})</span>
            </div>
          </div>
          {this.props.pendingList.length > 0 || this.props.needApprovalList.length > 0 ? (
            <ApprovalColumnListMarkup
              dataList={this.props.pendingList}
              needApprovalList={this.props.needApprovalList}
              columnListingType="pending"
            />
          ) : (
            <div className="board--canvas__coloumn">
              <div className="board--canvas__widget">
                <div className="board--canvas__widget__content">
                  <div className="board--canvas__widget__content--body">
                    <div className="board--canvas__widget__content--body--scroll">
                      <div className="text-center w250">
                        <div className="Graphic235">
                          <img src={NoApprovalsGraphic} alt="No Approvals" />
                        </div>
                        <h4 className="fs--15 u-bold">No Approvals</h4>
                        <p className="fs--13 dark-gray">
                          Pour youself a coffee, you have no approvals to review
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="mobile--board--header__coloumn">
            <div className="coloumn--title">
              Compliant <span className="count">({this.props.approvedList.length})</span>
            </div>
          </div>
          {this.props.approvedList.length > 0 ? (
            <ColumnListingMarkup
              dataList={this.props.approvedList}
              columnListingType="compliant"
            />
          ) : (
            <div className="board--canvas__coloumn">
              <div className="board--canvas__widget">
                <div className="board--canvas__widget__content">
                  <div className="board--canvas__widget__content--body">
                    <div className="board--canvas__widget__content--body--scroll">
                      <div className="text-center w250">
                        <div className="Graphic235">
                          <img src={NoApprovalsGraphic} alt="No Approvals" />
                        </div>
                        <h4 className="fs--15 u-bold">No Compliants</h4>
                        <p className="fs--13 dark-gray">
                          Pour youself a coffee, you have no approved to review
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default ColumnView;
