import React from 'react';
import { BrowserRouter, Switch, RouteProps } from 'react-router-dom';

//Application Imports
import appConfig from './config';
import { AppContextProvider } from './components/AppContext';
import AppRoute, {
  PersonalAppRoute,
  NonLoggedInAppRoute,
  PrivateAppRoute,
  CorporateViewAppRoute,
  CompanyAppRoute,
} from './components/AppRoute';

// HomePage & Home Layout imports;
import Home from './pages/website/views/Home';
import HomeLayout from './pages/website/layout/Layout';

// 404 page
import NotFound from './pages/404/NotFound';
import Layout404 from './layout/404Layout';

//Auth
import Login from './pages/auth/Login';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import VerifyAccount from './pages/auth/VerifyAccount';

//Signup
import Signup from './pages/signup/Signup';

//sole trader & personal pages
import AddCompany from './pages/soletrader/company-add/AddCompany';
import CompanyProfile from './pages/soletrader/profile/CompanyProfile';

// corporate view only pages
import SearchProfile from './pages/corporate_viewonly/SearchProfile';
import ProfileUpdate from './pages/corporate_viewonly/ProfileUpdate';
import RolesLanding from './pages/corporate/roles/RolesLanding';
import AddEditRole from './pages/corporate/roles/AddEditRole';
import MemberLanding from './pages/corporate/members/MemberLanding';
import CorporateSubcontractors from './pages/corporate/subcontractors/CorporateSubcontractors';
import SubcontractedList from './pages/corporate/subcontracted-list/SubcontractedList';
import SearchProfileLanding from './pages/corporate/search-profile/SearchProfileLanding';
import TeamLanding from './pages/corporate/team/TeamLanding';
import CreateTeam from './pages/corporate/team/CreateTeam';
import TeamMemberLanding from './pages/corporate/team/TeamMemberLanding';
import ProjectLanding from './pages/corporate/project/ProjectLanding';
import CreateProject from './pages/corporate/project/CreateProject';
import EditProject from './pages/corporate/project/EditProject';
import FavoriteProjectLanding from './pages/corporate/project/FavoriteProjectLanding';
import InvitedProjectLanding from './pages/corporate/project/InvitedProjectLanding';
import CorporateProjectPreview from './pages/corporate/project/CorporateProjectPreview';
import LinkedProjectListLanding from './pages/corporate/project/LinkedProjectListLanding';
import TeamMemberDocumentView from './pages/corporate/team/TeamMemberDocumentView';
import AccountPreview from './pages/common/project-preview/AccountPreview';
import UserLandingView from './pages/corporate/users/UserLandingView';

// common includes private pages
import AccountSettings from './pages/common/accounts/AccountSettings';
import ProfileSettings from './pages/common/profile-settings/ProfileSettings';
import ProfileFirstTime from './pages/common/profile-first-time/ProfileFirstTime';
import DocumentView from './pages/common/documents-upload/DocumentView';
import DocumentAdd from './pages/common/documents-upload/DocumentAdd';
import EditDocument from './pages/common/documents-upload/EditDocument';
import DocumentRevision from './pages/common/documents-upload/DocumentRevision';
import PersonalProfile from './pages/personal/profile/PersonalProfile';
import { default as AccountLinks } from './pages/common/account-links/MemberLanding';
import ProfilePreview from './pages/common/profile-preview/ProfilePreview';
import ProjectPreview from './pages/common/project-preview/ProjectPreview';
import PersonalProjectLanding from './pages/common/project/PersonalProjectLanding';
import ActivityLog from './pages/common/activity-log/ActivityLog';
import SelectAccountView from './pages/common/select-account/SelectAccountView';

interface Props extends RouteProps {}

interface State {}

class App extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};

    // @ts-ignore
    window.Intercom('boot', {
      app_id: appConfig.INTERCOM_APP_ID,
    });
  }
  //if user is logged in then validate JWT on each page refresh
  componentDidMount() {
    /*const jwt = LocalStorage.getJwt();
    if (jwt !== '') {
      axiosInstance
        .post('/user/validate-jwt')
        .then((response: any) => {})
        .catch((err) => {});
    }*/
  }

  render() {
    return (
      <AppContextProvider>
        <BrowserRouter>
          <Switch>
            {/* ANY ONE CAN ACCESS THESE ROUTES */}
            <AppRoute path="/" component={Home} layout={HomeLayout} exact />
            <AppRoute path="/auth/verify-account" component={VerifyAccount} />
            {/* NON LOGGED IN ROUTES */}
            <NonLoggedInAppRoute path="/auth/login" component={Login} />
            <NonLoggedInAppRoute path="/signup/personal" component={Signup} exact />
            <NonLoggedInAppRoute path="/signup/soletrader" component={Signup} exact />
            <NonLoggedInAppRoute path="/signup/corporate" component={Signup} exact />
            <NonLoggedInAppRoute
              path="/auth/forgot-password"
              component={ForgotPassword}
            />
            <NonLoggedInAppRoute path="/auth/reset-password" component={ResetPassword} />
            <NonLoggedInAppRoute
              path="/signup/corporate_viewonly"
              component={Signup}
              exact
            />
            {/* ANY ONE LOGGED IN CAN ACCESS THESE ROUTES EXCEPT corporate_viewonly */}
            <PrivateAppRoute
              excludeManagerControl
              path="/account/settings"
              component={AccountSettings}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            {/* WILDCARD routes to be added to the end with common prefix */}
            <PrivateAppRoute
              excludeManagerControl
              path="/account/:accountUuid"
              component={AccountPreview}
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/project/:uuid"
              component={ProjectPreview}
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/project/:uuid/:accountUuid"
              component={ProjectPreview}
              exact
            />
            <PrivateAppRoute path="/profile/settings" component={ProfileSettings} exact />
            <PrivateAppRoute
              excludeManagerControl
              path="/select-account"
              component={SelectAccountView}
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/dashboard"
              component={ProfileFirstTime}
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/documentation"
              component={DocumentView}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/profile/preview"
              component={ProfilePreview}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/company/profile"
              component={CompanyProfile}
              userRoleControl="corporate,soletrader"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/activity/log"
              component={ActivityLog}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/document/add"
              component={DocumentAdd}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/document/edit/:documentUuid"
              component={EditDocument}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <PrivateAppRoute
              excludeManagerControl
              path="/document/revision/:documentUuid"
              component={DocumentRevision}
              excludeUserRoleControl="corporate_viewonly"
              exact
            />
            <CorporateViewAppRoute
              excludeManagerControl
              path="/corporate/search"
              component={SearchProfile}
              exact
            />
            <CorporateViewAppRoute
              excludeManagerControl
              path="/corporate_viewonly/profile"
              component={ProfileUpdate}
              exact
            />
            <PersonalAppRoute
              excludeManagerControl
              path="/personal/profile"
              component={PersonalProfile}
              userRoleControl="personal"
              exact
            />
            <PersonalAppRoute
              excludeManagerControl
              path="/company/add"
              component={AddCompany}
            />
            <PersonalAppRoute
              excludeManagerControl
              path="/links"
              component={AccountLinks}
              exact
            />
            <PersonalAppRoute
              path="/links/project"
              component={PersonalProjectLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/role"
              component={RolesLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/role/add"
              component={AddEditRole}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/role/edit/:uuid"
              component={AddEditRole}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/member"
              component={MemberLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/subcontractors"
              component={CorporateSubcontractors}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/subcontracted-list"
              component={SubcontractedList}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/search/profile"
              component={SearchProfileLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/team"
              component={TeamLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/team/member"
              component={TeamMemberLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/team/create"
              component={CreateTeam}
              exact
            />
            <CompanyAppRoute path="/corporate/project" component={ProjectLanding} exact />
            <CompanyAppRoute
              path="/corporate/favourite/project"
              component={FavoriteProjectLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/invite/project"
              component={InvitedProjectLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/project/create"
              component={CreateProject}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/project/edit/:uuid"
              component={EditProject}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/team/member-documents/:uuid"
              component={TeamMemberDocumentView}
              exact
            />
            <CompanyAppRoute
              path="/corporate/project-view/:uuid"
              component={CorporateProjectPreview}
              exact
            />
            <CompanyAppRoute
              path="/corporate/project-view/:uuid/:accountUuid"
              component={CorporateProjectPreview}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/member-projects/:uuid"
              component={LinkedProjectListLanding}
              exact
            />
            <CompanyAppRoute
              excludeManagerControl
              path="/corporate/manage/user"
              component={UserLandingView}
              exact
            />
            <AppRoute component={NotFound} layout={Layout404} />
          </Switch>
        </BrowserRouter>
      </AppContextProvider>
    );
  }
}

export default App;
