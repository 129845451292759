import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Static Assets
import ProfileSearch from '../../../assets/images/search.svg';

interface Props extends RouteComponentProps {}

interface State {}

class BlankRoleScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
        <Row>
          <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
            <div className="myprofile--section myprofile--section-sm u-pt--0">
              <div className="search--profile">
                <div className="search--profile__graphic search--profile__graphic-sm">
                  <img src={ProfileSearch} width="255" alt="Profile Search" />
                </div>
                <p className="fs--15">
                  <strong>No Roles</strong>
                </p>
                <p className="fs--13 dark-grey">
                  You currently have no roles, click the <br></br>
                  button below to create your first role!
                </p>
                <button
                  onClick={() => this.props.history.push('/corporate/role/add')}
                  className="c-button c-button-primary c-button-w140 c-button-radius c-button-sm u-upper u-mt--20"
                >
                  Create A ROLE
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withRouter(BlankRoleScreen);
