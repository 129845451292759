import React, { MouseEvent } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { ListGroup, ListGroupItem, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports
import AppContext from '../../../components/AppContext';
import LinkedDocumentModal from './LinkedDocumentModal';
import ViewLinkedDocumentModal from './ViewLinkedDocumentModal';
import LocalStorage from '../../../helpers/LocalStorage';
import { documentStatusCode } from '../../../helpers/Constant';

// Import Api
import LinkMember from '../../../api/LinkMember';
import Account from '../../../api/Account';

// Import CSS
import './member-list.scss';
import './teams.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets
import ProfileAvatar from '../../../assets/images/No-Image.jpg';
import LinkOff from '@material-ui/icons/LinkOff';
import Check from '@material-ui/icons/Check';
import Search from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';

interface Props extends RouteComponentProps {
  memberList: Array<any>;
  filterMemberList: Function;
  resetMemberFilter: Function;
  pendingMemberList: Array<any>;
  reloadData: Function;
  activeMemberCount: number;
  unlinkedMemberCount: number;
  removePendingInvitationMember: Function;
  postAcceptPendingInvitation: Function;
}

interface State {
  filterValue: string;
  linkedDocumentModalOpen: boolean;
  pendingInviteUuid: string;
  showLoader: boolean;
  accountDocumentList: Array<any>;
  requiredDocumentList: Array<any>;
  viewDocumentModalOpen: boolean;
  awatingViewDocumentModalOpen: boolean;
  previewDocumentList: Array<any>;
  awatingPreviewDocumentList: Array<any>;
  showVisibleIcon: boolean;
  isShowButton: boolean;
  userAccountUuid: string;
}

class MemberList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  typingTimeout: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.typingTimeout = setTimeout(() => '', 1000);
    this.state = {
      filterValue: '',
      linkedDocumentModalOpen: false,
      pendingInviteUuid: '',
      showLoader: false,
      accountDocumentList: [],
      requiredDocumentList: [],
      viewDocumentModalOpen: false,
      awatingViewDocumentModalOpen: false,
      previewDocumentList: [],
      awatingPreviewDocumentList: [],
      showVisibleIcon: false,
      isShowButton: false,
      userAccountUuid: '',
    };
  }

  confirmLinkUnlink = (
    userAccountUuid: string,
    unlink: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const elementButton = e.currentTarget;
    let message = unlink ? 'unlink' : 're-link';
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to request to ' + message + ' your account?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.requestLinkUnlink(elementButton, userAccountUuid, unlink),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  requestLinkUnlink = (
    elementButton: HTMLButtonElement,
    userAccountUuid: string,
    unlink: boolean
  ) => {
    const { activeAccountUUID } = this.context;

    //let buttonPreviousText = elementButton.innerText;
    elementButton.classList.toggle('inlineloading');
    //elementButton.innerText = '';

    // request to server;
    Account.requestLinkUnlink(activeAccountUUID, userAccountUuid, unlink)
      .then((response) => {
        if (response.data.success === 1) {
          toast.success('Request sent successfully', {
            autoClose: 2000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .then(() => {
        elementButton.classList.toggle('inlineloading');
        //elementButton.innerText = buttonPreviousText;
      });
  };

  subcontractorConfirmCorporateInvite = (
    e: MouseEvent<HTMLButtonElement>,
    pendingInviteUuid: string
  ) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to accept this company invitation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.subcontractorAcceptPendingCorporateInvite(
              elementButton,
              pendingInviteUuid
            );
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  subcontractorAcceptPendingCorporateInvite = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    //const prevButtonText = elementButton.innerText;
    elementButton.classList.toggle('inlineloading');

    // request to server;
    Account.subcontractorAcceptPendingCorporateInvite(
      activeAccountUUID,
      pendingInviteUuid
    )
      .then((response) => {
        if (response.data.success === 1) {
          this.props.removePendingInvitationMember(pendingInviteUuid);
        } else {
          toast.error(response.data.messages[0][0], {
            autoClose: 3000,
          });
        }
      })
      .then(() => {
        elementButton.classList.toggle('inlineloading');
        //elementButton.innerText = prevButtonText;
      })
      .catch((err) => {
        toast.error(err.message, {
          autoClose: 3000,
        });
      });
  };

  cancelInviteConfirm = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to cancel this invitation?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.cancelPendingInvitation(elementButton, pendingInviteUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  cancelPendingInvitation = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    const prevButtonText = elementButton.innerText;

    elementButton.classList.toggle('inlineloading');
    elementButton.innerText = '';

    // request to server;
    Account.cancelPendingCorporateInvite(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.removePendingInvitationMember(pendingInviteUuid);
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  resendInvitation = (
    e: React.MouseEvent<HTMLButtonElement>,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;

    let elementButton = e.currentTarget;
    elementButton.innerText = 'Processing..';

    // request to server;
    LinkMember.resendInvitation(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        elementButton.innerText = 'Resend';
        if (response.data.success === 1) {
          toast.success('Invitation sent successfully.', {
            autoClose: 2000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        elementButton.innerText = 'Resend';
      });
  };

  onChangeFilterValue = (e: any) => {
    this.typingTimeout = setTimeout(this.updateSearchFilter, 500);

    this.setState({
      filterValue: e.target.value,
    });
  };

  updateSearchFilter = () => {
    this.props.filterMemberList(this.state.filterValue);
  };

  linkedModalHandler = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    this.setState({
      showLoader: true,
    });
    if (!this.state.linkedDocumentModalOpen) {
      const { activeAccountUUID } = this.context;
      // request to server;
      LinkMember.getPendingInviteRoleDocuments(activeAccountUUID, pendingInviteUuid)
        .then((response) => {
          if (response.data.success === 1) {
            this.setState({
              showLoader: false,
              pendingInviteUuid: pendingInviteUuid,
              linkedDocumentModalOpen: !this.state.linkedDocumentModalOpen,
              accountDocumentList: response.data.data.account_documents_list,
              requiredDocumentList: response.data.data.required_documents,
            });
          } else {
            toast.error(response.data.messages[0], {
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {});
    } else {
      this.setState({
        showLoader: false,
        linkedDocumentModalOpen: !this.state.linkedDocumentModalOpen,
      });
    }
  };

  awatingViewDocumentModalHandler = (pendingInviteUuid: string) => {
    const { activeAccountUUID } = this.context;
    if (!this.state.awatingViewDocumentModalOpen) {
      this.setState({
        showLoader: true,
      });
      // request to server;
      LinkMember.getAwaitingPendingInviteSubmittedDocuments(
        activeAccountUUID,
        pendingInviteUuid
      )
        .then((response) => {
          if (response.data.success === 1) {
            this.setState({
              showLoader: false,
              isShowButton: false,
              awatingPreviewDocumentList: response.data.data.documents,
              awatingViewDocumentModalOpen: !this.state.awatingViewDocumentModalOpen,
              pendingInviteUuid,
            });
          } else {
            toast.error(response.data.messages[0], {
              autoClose: 3000,
            });
          }
        })
        .catch((err) => {});
    } else {
      this.setState({
        awatingViewDocumentModalOpen: !this.state.awatingViewDocumentModalOpen,
        pendingInviteUuid: '',
        showLoader: false,
      });
    }
  };

  viewDocumentModalHandler = (userAccountUuid: string, showButton: boolean) => {
    const { activeAccountUUID } = this.context;
    if (!this.state.viewDocumentModalOpen) {
      // button loading state;
      let buttonElement: any = document.getElementById(`preview_${userAccountUuid}`);
      buttonElement.classList.toggle('inlineloading');

      // request to server;
      LinkMember.getLinkedMemberSubmittedDocuments(activeAccountUUID, userAccountUuid)
        .then((response) => {
          if (response.data.success === 1) {
            let checkShowButton = showButton;
            if (checkShowButton) {
              let foundNotLinkedDocument = false;
              let foundRejectedLinkedDocument = false;
              const list: any = response.data.data.documents;
              for (let i = 0; i < list.length; i++) {
                if (list[i].linked_document_title === null) {
                  foundNotLinkedDocument = true;
                }
                if (
                  list[i].user_account_document_status === documentStatusCode.rejected
                ) {
                  foundRejectedLinkedDocument = true;
                }
              }
              if (!foundNotLinkedDocument) {
                checkShowButton = false;
              }
              if (foundRejectedLinkedDocument) {
                checkShowButton = true;
              }
            }
            this.setState({
              userAccountUuid: userAccountUuid,
              showVisibleIcon: false,
              isShowButton: checkShowButton,
              accountDocumentList: response.data.data.account_documents_list,
              previewDocumentList: response.data.data.documents,
              viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
            });
          } else {
            toast.error(response.data.messages[0], {
              autoClose: 3000,
            });
          }
        })
        .then(() => {
          buttonElement.classList.toggle('inlineloading');
        });
    } else {
      this.setState({
        viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
        showVisibleIcon: false,
      });
    }
  };

  getAccountDocumentTypeOptions = (type: string) => {
    const documents = this.state.accountDocumentList
      .map((data: any) => {
        if (data.type === type) {
          let optionObject = { value: data.uuid, label: data.title, type: data.type };
          return optionObject;
        }
        return null;
      })
      .filter((data) => data !== null);
    return documents;
  };

  render() {
    const isPersonalAccount = LocalStorage.isCurrentAccountPersonal();
    return (
      <>
        <div className="myteams--section myteams--section-sm">
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
            <h2 className="u-heading--medium u-heading--medium-sm">Links</h2>
          </div>
          <p className="fs--14 dark-gray">
            Below are the companies you are linked with, these companies will
            <br />
            have access to your linked documents.
          </p>
          <div className="portlet">
            <div className="portlet--body">
              <div className="profile--links">
                {this.props.pendingMemberList.length > 0 && (
                  <div className="profile--links u-mt--35">
                    <h3 className="title">To Confirm</h3>
                    <ListGroup>
                      {this.props.pendingMemberList.map((data: any) => (
                        <ListGroupItem className="head" key={data.uuid}>
                          <div className="NameColumn">
                            <div className="avatar--40">
                              <img
                                src={
                                  data.profileImageUrl
                                    ? data.profileImageUrl
                                    : ProfileAvatar
                                }
                                alt="Profile"
                              />
                            </div>
                            <div className="list-group--details">
                              <p className="user--name">{data.name}</p>
                              <p className="user--email">{data.email}</p>
                            </div>
                          </div>
                          <div className="RoleColumn">{data.role}</div>
                          <div className="ActivityColumn">
                            <div className="ml-auto">
                              <div className="deny u-flexed u-align-center">
                                {data.status === 0 && (
                                  <>
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        this.cancelInviteConfirm(e, data.uuid)
                                      }
                                      className="links--primary fs--15 u-mr--10"
                                    >
                                      Deny
                                    </button>

                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        isPersonalAccount
                                          ? this.linkedModalHandler(e, data.uuid)
                                          : this.subcontractorConfirmCorporateInvite(
                                              e,
                                              data.uuid
                                            )
                                      }
                                      className={`c-button c-button--green c-button-inlineflex c-button-radius c-button-xs has-icon--before c-button-w110 u-ml--10 ${
                                        this.state.showLoader ? `inlineloading` : ``
                                      }`}
                                    >
                                      <Check fontSize="small" className="icons u-mr--5" />
                                      Confirm
                                    </button>
                                  </>
                                )}
                                {data.status === 1 && (
                                  <>
                                    <button
                                      type="button"
                                      className={`c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90 ${
                                        this.state.showLoader ? `inlineloading` : ``
                                      }`}
                                      onClick={() =>
                                        this.awatingViewDocumentModalHandler(data.uuid)
                                      }
                                    >
                                      Awaiting Review
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  </div>
                )}
                <div className="portlet--body__wrapper">
                  <div className="portlet--search size--300">
                    <Input
                      name="filterValue"
                      id="filterValue"
                      className="form--fields"
                      placeholder="Search"
                      onChange={this.onChangeFilterValue}
                    />
                    <button className="portlet--search__btn" type="button">
                      <Search fontSize="large" className="icons" />
                    </button>
                  </div>
                </div>
                <ListGroup>
                  <ListGroupItem className="head">
                    <div className="NameColumn">Name</div>
                    <div className="RoleColumn">Role</div>
                  </ListGroupItem>
                  {this.props.activeMemberCount > 0 ? (
                    this.props.memberList.map(
                      (data: any) =>
                        data.status === 1 && (
                          <ListGroupItem key={data.uuid}>
                            <div className="NameColumn">
                              <div className="avatar--40">
                                <img
                                  src={
                                    data.profileImageUrl
                                      ? data.profileImageUrl
                                      : ProfileAvatar
                                  }
                                  alt="Profile"
                                />
                              </div>
                              <div className="list-group--details">
                                <p className="user--name">{data.name}</p>
                                <p className="user--email">{data.email}</p>
                              </div>
                            </div>
                            <div className="RoleColumn">
                              <>{data.role}</>
                            </div>
                            <div className="ActivityColumn">
                              <div className="ml-auto">
                                <div className="deny u-flexed u-align-center">
                                  {data.role !== null && (
                                    <button
                                      type="button"
                                      color="link"
                                      id={`preview_${data.uuid}`}
                                      onClick={() =>
                                        this.viewDocumentModalHandler(data.uuid, true)
                                      }
                                      className={`c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90`}
                                    >
                                      <Visibility
                                        fontSize="small"
                                        className="icons u-mr--5"
                                      />
                                      Manage
                                    </button>
                                  )}
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.confirmLinkUnlink(data.uuid, true, e)
                                    }
                                    className="c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90"
                                  >
                                    <LinkOff fontSize="small" className="icons u-mr--5" />
                                    Unlink
                                  </button>
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        )
                    )
                  ) : (
                    <ListGroupItem>
                      <p>No active linked accounts found</p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </div>

          {this.props.unlinkedMemberCount > 0 && (
            <div className="profile--links u-mt--35">
              <h3 className="title">Link History</h3>
              <ListGroup>
                {this.props.memberList.map(
                  (data: any) =>
                    data.status === 0 && (
                      <ListGroupItem className="head" key={data.uuid}>
                        <div className="NameColumn">
                          <div className="avatar--40">
                            <img
                              src={
                                data.profileImageUrl
                                  ? data.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="list-group--details">
                            <p className="user--name">{data.name}</p>
                            <p className="user--email">{data.email}</p>
                          </div>
                        </div>
                        <div className="RoleColumn">
                          <>{data.role}</>
                        </div>
                        <div className="ActivityColumn">
                          <div className="ml-auto">
                            <div className="deny u-flexed u-align-center">
                              {data.role !== null && (
                                <button
                                  type="button"
                                  color="link"
                                  id={`preview_${data.uuid}`}
                                  onClick={() =>
                                    this.viewDocumentModalHandler(data.uuid, false)
                                  }
                                  className={`c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs c-button-w90`}
                                >
                                  <Visibility
                                    fontSize="small"
                                    className="icons u-mr--5"
                                  />
                                  View
                                </button>
                              )}
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.confirmLinkUnlink(data.uuid, false, e)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Request Re-Link
                              </button>
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    )
                )}
              </ListGroup>
            </div>
          )}
        </div>
        <LinkedDocumentModal
          isOpen={this.state.linkedDocumentModalOpen}
          toggle={this.linkedModalHandler}
          pendingInviteUuid={this.state.pendingInviteUuid}
          accountDocumentList={this.state.accountDocumentList}
          requiredDocumentList={this.state.requiredDocumentList}
          getAccountDocumentTypeOptions={this.getAccountDocumentTypeOptions}
          postAcceptPendingInvitation={this.props.postAcceptPendingInvitation}
        />

        <ViewLinkedDocumentModal
          area="personalUser"
          isOpen={this.state.awatingViewDocumentModalOpen}
          toggle={this.awatingViewDocumentModalHandler}
          documentList={this.state.awatingPreviewDocumentList}
          getAccountDocumentTypeOptions={this.getAccountDocumentTypeOptions}
          isShowButton={this.state.isShowButton}
          userAccountUuid={this.state.userAccountUuid}
        />

        <ViewLinkedDocumentModal
          area="personalUser"
          isOpen={this.state.viewDocumentModalOpen}
          toggle={this.viewDocumentModalHandler}
          documentList={this.state.previewDocumentList}
          getAccountDocumentTypeOptions={this.getAccountDocumentTypeOptions}
          isShowButton={this.state.isShowButton}
          userAccountUuid={this.state.userAccountUuid}
        />
      </>
    );
  }
}

export default withRouter(MemberList);
