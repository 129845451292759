import React from 'react';
import Select from 'react-select';
import Datetime from 'react-datetime';
import { Container, Row, Col, FormGroup, Label } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';
import scrollToComponent from 'react-scroll-to-component';
import { RouteComponentProps, withRouter, Link } from 'react-router-dom';

//Application Imports
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import axiosInstance from '../../../utils/AppAxios';
import AlertError from '../../../layout/includes/Alert/Error';
import Document from '../../../api/Document';
import UploadImages from './UploadImages';
import { CapitalizeWords, getDate } from '../../../helpers/Helpers';

// Import CSS
import './upload.scss';
import Loader from '../../../layout/includes/ContentLoader/Loader';

interface RouteInfo {
  documentUuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  area: string;
}

interface State {
  showLoader: boolean;
  documentData: any;
  documentDataTitle: string;
  documentTypeOptions: any;
  docTypeSelectedValueCmp: any | React.Component;
  docTypeSelectedValue: string;
  documentTypeObj: any;
  documentSubTypeObj: any;
  docTypeSelectedValueError: boolean;
  showLicenceElement: boolean;
  showInsuranceElement: boolean;
  showCertificateElement: boolean;
  expiryDate: Date | undefined;
  expiryDateError: boolean;
  issueDateError: boolean;
  issueDate: Date | undefined;
  typeObjValue: any;
  typeObjError: boolean;
  mediaDetailsArray: any;
  mediaArray: Array<any>;
  typeOptions: any;
  formSubmitted: boolean;
  errorMessages: Array<string> | string;
}

class AddEditDocument extends React.Component<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: this.props.area === 'add' ? false : true,
      documentData: null,
      documentDataTitle: '',
      documentTypeOptions: {},
      docTypeSelectedValueCmp: '',
      docTypeSelectedValue: '',
      documentTypeObj: null,
      documentSubTypeObj: null,
      docTypeSelectedValueError: false,
      showLicenceElement: false,
      showInsuranceElement: false,
      showCertificateElement: false,
      expiryDateError: false,
      expiryDate: undefined,
      issueDate: undefined,
      issueDateError: false,
      typeObjValue: null,
      typeObjError: false,
      mediaDetailsArray: [],
      mediaArray: [],
      typeOptions: [],
      formSubmitted: false,
      errorMessages: '',
    };
  }

  accessMediaArray = (data?: any) => {
    this.setState({
      mediaDetailsArray: data,
    });
  };

  docTypeHandler = (dataObj: any) => {
    this.getTypeToRender(dataObj.value);
    this.setState({
      docTypeSelectedValueError: false,
      docTypeSelectedValue: dataObj.value,
      expiryDateError: false,
      issueDateError: false,
      typeObjError: false,
      expiryDate: undefined,
      issueDate: undefined,
      typeObjValue: null,
      documentTypeObj: dataObj,
    });
  };

  handleChangeExpiryDate = (date: any) => {
    this.setState({
      expiryDate: date._d,
      expiryDateError: false,
    });
  };

  handleChangeIssueDate = (date: any) => {
    this.setState({
      issueDate: date._d,
      issueDateError: false,
    });
  };

  typeChangeHandler = (dataObj: any) => {
    this.setState({
      documentSubTypeObj: dataObj,
      typeObjValue: dataObj,
      typeObjError: false,
    });
  };

  convertArrayDataObj = (data: any) => {
    let dataArray: any = [];
    if (data.length > 0 && data[0] !== '') {
      if (typeof data[0] === 'string') {
        for (let i = 0; i < data.length; i++) {
          let objData: any = { value: data[i], label: data[i] };
          dataArray.push(objData);
        }
      }
    }
    return dataArray;
  };

  getTypeToRender = (value: string) => {
    let typesLicense: any;
    const { documentTypeOptions } = this.state;
    for (let i = 0; i < documentTypeOptions.length; i++) {
      if (value === documentTypeOptions[i].value) {
        if (documentTypeOptions[i].optionSubTypes) {
          let optionData: any = [];
          for (
            let index = 0;
            index < documentTypeOptions[i].optionSubTypes.length;
            index++
          ) {
            let dataOptionGroup: any = {
              label: documentTypeOptions[i].optionSubTypes[index].label,
              options: this.convertArrayDataObj(
                documentTypeOptions[i].optionSubTypes[index].options
              ),
            };
            optionData.push(dataOptionGroup);
          }
          typesLicense = optionData;
        }

        if (documentTypeOptions[i].subTypes) {
          typesLicense = this.convertArrayDataObj(documentTypeOptions[i].subTypes);
        }
      }
    }
    this.setState({
      typeOptions: typesLicense,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { activeAccountUUID } = this.context;

    // set Error To false;
    this.setState({
      expiryDateError: false,
      issueDateError: false,
      typeObjError: false,
      errorMessages: '',
    });
    const {
      docTypeSelectedValue,
      issueDate,
      expiryDate,
      typeObjValue,
      mediaDetailsArray,
    } = this.state;
    let isError = false;
    if (docTypeSelectedValue === null || docTypeSelectedValue === '') {
      this.setState({
        docTypeSelectedValueError: true,
      });
      return;
    }

    // Image validation;
    if (mediaDetailsArray.length < 1) {
      this.setState({
        errorMessages: 'Please upload at least one image.',
      });
      scrollToComponent(this.alertErrorRef, { duration: 500 });
      isError = true;
    }

    // Validation for issue date for all form element;
    if (
      docTypeSelectedValue === 'license' ||
      docTypeSelectedValue === 'certificate' ||
      docTypeSelectedValue === 'registration'
    ) {
      if (issueDate === undefined) {
        this.setState({
          issueDateError: true,
        });
        isError = true;
      }
    }

    if (
      docTypeSelectedValue === 'license' ||
      docTypeSelectedValue === 'insurance' ||
      docTypeSelectedValue === 'registration' ||
      docTypeSelectedValue === 'security_check'
    ) {
      if (typeObjValue === null) {
        this.setState({
          typeObjError: true,
        });
        isError = true;
      }
    }

    // Validation for expiry date for all form element;
    if (docTypeSelectedValue !== 'certificate') {
      if (docTypeSelectedValue !== 'others') {
        if (docTypeSelectedValue !== 'cover_letter') {
          if (docTypeSelectedValue !== 'resume') {
            if (expiryDate === undefined) {
              this.setState({
                expiryDateError: true,
              });
              isError = true;
            }
          }
        }
      }
    }
    if (isError) {
      return;
    }

    const postData = {
      uuid: activeAccountUUID,
      document_uuid: this.props.match.params.documentUuid,
      document_type: this.state.docTypeSelectedValue,
      title: values.title,
      number: values.number,
      issue_date: getDate(this.state.issueDate),
      expiry_date: getDate(this.state.expiryDate),
      type: this.state.typeObjValue ? this.state.typeObjValue.label : '',
      media_array: this.state.mediaDetailsArray,
      passport_security_insurance: values.passportSecurityInsurance
        ? values.passportSecurityInsurance
        : null,
    };
    let url: string = '';
    let successMessage: string = '';

    if (this.props.area === 'edit') {
      url = '/document/edit-document';
      successMessage = 'Document updated successfully.';
    }
    if (this.props.area === 'add') {
      url = '/document/add-document';
      successMessage = 'Document added successfully.';
    }
    if (this.props.area === 'revision') {
      url = '/document/add-revision';
      successMessage = 'Document updated successfully.';
    }

    // Make request to server for add document;
    axiosInstance
      .post(url, postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          toast.success(successMessage, {
            autoClose: 3000,
          });
          this.props.history.push('/documentation');
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  getDocumentTypes = () => {
    const { activeAccountUUID } = this.context;

    Document.getTypes(activeAccountUUID)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            documentTypeOptions: response.data.data.document_type,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  fetchDocumentDetail = () => {
    const { activeAccountUUID } = this.context;
    const documentUuid = this.props.match.params.documentUuid;

    Document.getDocumentData(activeAccountUUID, documentUuid)
      .then((response) => {
        if (response.data.success === 1) {
          const isAttached = response.data.data.data.is_attached_role_requirement;
          if (isAttached === 1 && this.props.area !== 'revision') {
            toast.error('Document is attached with the role.', {
              autoClose: 2000,
              onClose: function () {
                window.location.href = '/documentation';
              },
            });
          } else {
            this.setState({
              showLoader: false,
              documentTypeObj: response.data.data.data.document_type
                ? {
                    value: response.data.data.data.document_type,
                    label: CapitalizeWords(response.data.data.data.document_type),
                  }
                : null,
              documentSubTypeObj: response.data.data.data.doc_type
                ? {
                    value: response.data.data.data.doc_type,
                    label: response.data.data.data.doc_type,
                  }
                : null,
              docTypeSelectedValue: response.data.data.data.document_type,
              typeObjValue: response.data.data.data.doc_type,
              expiryDate:
                this.props.area !== 'revision'
                  ? response.data.data.data.expiry_date === null
                    ? undefined
                    : new Date(response.data.data.data.expiry_date)
                  : undefined,
              issueDate:
                this.props.area !== 'revision'
                  ? response.data.data.data.issue_date === null
                    ? undefined
                    : new Date(response.data.data.data.issue_date)
                  : undefined,
              mediaArray:
                this.props.area !== 'revision' ? response.data.data.data.media : null,
              documentData:
                this.props.area !== 'revision' ? response.data.data.data : null,
              documentDataTitle: response.data.data.data.title,
            });
            this.getTypeToRender(response.data.data.data.document_type);
          }
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  componentDidMount() {
    let title: string = '';
    if (this.props.area === 'edit') {
      title = `Edit Document ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
    }
    if (this.props.area === 'add') {
      title = `Add Document ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
    }
    if (this.props.area === 'revision') {
      title = `Revision Document ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
    }
    document.title = title;

    this.getDocumentTypes();
    if (this.props.area !== 'add') {
      this.fetchDocumentDetail();
    }
  }

  render() {
    let controlLabel = '';
    if (this.state.docTypeSelectedValue === 'insurance') {
      controlLabel = 'Insurer Name';
    } else if (this.state.docTypeSelectedValue === 'security_check') {
      controlLabel = 'Issuing Company';
    } else if (this.state.docTypeSelectedValue === 'passport') {
      controlLabel = 'Issuing Country';
    } else if (this.state.docTypeSelectedValue === 'visa') {
      controlLabel = 'Visa Type';
    } else {
      controlLabel = '';
    }
    return (
      <>
        <Container className="container--fullwidth">
          <Row>
            {this.state.showLoader ? (
              <Col xs={12} md={12} lg={12}>
                <Loader />
              </Col>
            ) : (
              <>
                <Col xs={12} md={6} lg={6} className="column--left">
                  <div className="column--left__inner column--left__inner-sm">
                    <div className="u-mb--25">
                      <Link
                        to="/documentation"
                        className="c-button--Back fs--16 u-mr--20"
                      >
                        <i className="arrow left" /> Back
                      </Link>
                    </div>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--25">
                      <h2 className="u-heading--small u-heading--small-sm">
                        {this.props.area === 'add' && 'Add Document'}
                        {this.props.area === 'edit' && 'Edit Document'}
                        {this.props.area === 'revision' && 'Revision Document'}
                      </h2>
                    </div>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />
                    <AvForm
                      id="docForm"
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <FormGroup
                        className={`${
                          this.state.docTypeSelectedValueError
                            ? `form-group--has-error`
                            : ``
                        }`}
                      >
                        <Label htmlFor="DocumentType">Select document type</Label>
                        <Select
                          isDisabled={this.props.area === 'add' ? false : true}
                          autoFocus
                          name="DocumentType"
                          options={this.state.documentTypeOptions}
                          value={this.state.documentTypeObj}
                          placeholder="Select document type"
                          tabSelectsValue={false}
                          onChange={this.docTypeHandler}
                        />
                        <span className="help-block">Please select document type</span>
                      </FormGroup>
                      {this.state.docTypeSelectedValue !== '' && (
                        <>
                          <AvField
                            name="title"
                            label="Title"
                            required
                            errorMessage="Please enter Title"
                            value={this.state.documentDataTitle}
                          />
                          {this.state.docTypeSelectedValue !== 'resume' &&
                            this.state.docTypeSelectedValue !== 'cover_letter' && (
                              <AvField
                                name="number"
                                value={this.state.documentData?.number}
                                label={`${
                                  this.state.docTypeSelectedValue === 'passport'
                                    ? `Passport`
                                    : this.state.docTypeSelectedValue === 'insurance'
                                    ? `Policy`
                                    : ``
                                } Number`}
                                required={
                                  this.state.docTypeSelectedValue === 'others'
                                    ? false
                                    : true
                                }
                                errorMessage="Please enter Number"
                              />
                            )}

                          {controlLabel && (
                            <AvField
                              name="passportSecurityInsurance"
                              value={this.state.documentData?.issuer_name}
                              label={controlLabel}
                              required
                              errorMessage={`Please enter ${controlLabel}`}
                            />
                          )}

                          <Row>
                            {(this.state.docTypeSelectedValue === 'license' ||
                              this.state.docTypeSelectedValue === 'certificate' ||
                              this.state.docTypeSelectedValue === 'registration' ||
                              this.state.docTypeSelectedValue === 'others') && (
                              <Col
                                xs={12}
                                sm={12}
                                md={6}
                                lg={6}
                                className="mobile--50 mobile-mb--0"
                              >
                                <FormGroup
                                  className={
                                    this.state.issueDateError
                                      ? 'form-group--has-error'
                                      : ''
                                  }
                                >
                                  <Label htmlFor="issueDate">Issue Date</Label>
                                  <Datetime
                                    inputProps={{
                                      placeholder: 'DD/MM/YYYY',
                                      name: 'issueDate',
                                    }}
                                    value={this.state.issueDate}
                                    onChange={this.handleChangeIssueDate}
                                    dateFormat="DD/MM/YYYY"
                                    timeFormat={false}
                                    closeOnSelect={true}
                                  />
                                  <span className="help-block">
                                    Please enter Issue Date
                                  </span>
                                </FormGroup>
                              </Col>
                            )}
                            {this.state.docTypeSelectedValue !== 'resume' &&
                              this.state.docTypeSelectedValue !== 'cover_letter' && (
                                <Col
                                  xs={12}
                                  sm={12}
                                  md={6}
                                  lg={6}
                                  className="mobile--50 mobile-mb--0"
                                >
                                  <FormGroup
                                    className={`ExpiryDate ${
                                      this.state.expiryDateError
                                        ? `form-group--has-error`
                                        : ``
                                    }`}
                                  >
                                    <Label htmlFor="ExpiryDate">Expiry date</Label>
                                    <Datetime
                                      inputProps={{
                                        placeholder: 'DD/MM/YYYY',
                                        name: 'ExpiryDate',
                                      }}
                                      value={this.state.expiryDate}
                                      onChange={this.handleChangeExpiryDate}
                                      dateFormat="DD/MM/YYYY"
                                      timeFormat={false}
                                      closeOnSelect={true}
                                    />
                                    <span className="help-block">
                                      Please enter Expiry Date
                                    </span>
                                  </FormGroup>
                                </Col>
                              )}
                          </Row>
                          {(this.state.docTypeSelectedValue === 'license' ||
                            this.state.docTypeSelectedValue === 'insurance' ||
                            this.state.docTypeSelectedValue === 'registration' ||
                            this.state.docTypeSelectedValue === 'security_check') && (
                            <FormGroup
                              className={`${
                                this.state.typeObjError ? `form-group--has-error` : ``
                              }`}
                            >
                              <Label htmlFor="type">Type</Label>
                              <Select
                                isDisabled={this.props.area === 'edit' ? true : false}
                                name="type"
                                id="type"
                                options={this.state.typeOptions}
                                placeholder="Select Type"
                                tabSelectsValue={false}
                                value={this.state.documentSubTypeObj}
                                onChange={this.typeChangeHandler}
                              />
                              <span className="help-block">Please select type</span>
                            </FormGroup>
                          )}
                        </>
                      )}
                      <button
                        type="submit"
                        className="c-button c-button--primary c-button-inlineflex c-button-w180 c-button-radius c-button-sm u-upper"
                      >
                        {this.props.area === 'edit' ? 'Update' : 'Add'}
                      </button>
                    </AvForm>
                  </div>
                </Col>
              </>
            )}

            <UploadImages
              getMediaArrayFun={this.accessMediaArray}
              mediaArray={this.state.mediaArray}
            />
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(AddEditDocument);
