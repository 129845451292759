export const accountType = {
  corporate: 'corporate',
  soletrader: 'soletrader',
  personal: 'personal',
  corporateView: 'corporate_viewonly',
};

export const corporateRoleType = {
  admin: 'Admin',
  manager: 'Manager',
};

export const linkedAs = {
  employee: 'Employee',
  subcontractor: 'Subcontractor',
};

export const documentStatusCode = {
  approved: 1,
  awaitingApproval: 2,
  rejected: 3,
};

export const groupDocumentStatus = {
  warning: 1,
  awaitingApproval: 2,
  rejected: 3,
};

export const accountStatus = {
  closed: 'Closed',
  active: 'Active',
};
