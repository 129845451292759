import Api from './Api';

export default class Preview extends Api {
  static getProject = async (
    projectUuid: string,
    accountUuid: string,
    activeAccountUuid: string
  ): Promise<any> => {
    const postData = {
      project_uuid: projectUuid,
      account_uuid: accountUuid,
      uuid: activeAccountUuid,
    };

    return await Api.postRequest('/preview/get-project', postData);
  };

  static getAccountDetail = async (
    accountUuid: string,
    searchAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      search_account_uuid: searchAccountUuid,
    };

    return await Api.postRequest('/account/preview', postData);
  };
}
