import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Container, Row, Col, Button, FormGroup, Label, FormText } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import scrollToComponent from 'react-scroll-to-component';
import CopyToClipboard from 'react-copy-to-clipboard';

//Application Imports
import appConfig from '../../config';
import AppContext from '../../components/AppContext';
import Loader from '../../layout/includes/ContentLoader/Loader';
import AlertError from '../../layout/includes/Alert/Error';

// Import Axios instance
import axiosInstance from '../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Imports Icons;
import FileCopy from '@material-ui/icons/FileCopy';

// Import CSS
import './myprofile.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-image-lightbox/style.css';

interface Props extends RouteComponentProps {}

interface State {
  showLoader: boolean;
  formSubmitted: boolean;
  errorMessages: Array<string> | string;
  businessName: string;
  tradingName: string;
  acn: string;
  copied: boolean;
  crnNo: string;
}

class ProfileUpdate extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();

  constructor(props: Props) {
    super(props);

    this.state = {
      showLoader: true,
      formSubmitted: false,
      errorMessages: '',
      businessName: '',
      tradingName: '',
      acn: '',
      copied: false,
      crnNo: '',
    };
  }

  updateCopiedState = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 4000);
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;
    // make loader to true;
    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    // prepage JSON data for the request;
    const postData = {
      uuid: activeAccountUUID,
      company_name: values.businessName,
      trading_name: values.tradingName,
      acn: values.acn,
    };

    // request to server for data update;
    axiosInstance
      .post('/account/update-company-view-only-profile', postData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          toast.success('Company profile is updated successfully.', {
            autoClose: 3000,
          });

          this.setStateData(response.data.data);
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });

          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  fetchProfileDetail = () => {
    const { activeAccountUUID } = this.context;

    // Set loader to true;
    this.setState({
      showLoader: true,
    });

    // prepare JSON data for the request;
    const postData = {
      uuid: activeAccountUUID,
    };

    // send request to server;
    axiosInstance
      .post('/account/get-company-profile-data', postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          this.setStateData(response.data.data);
        } else {
          toast.error('Error fetching data. Please try again.', {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  setStateData = (data: any) => {
    this.setState({
      showLoader: false,
      businessName: data.company_name,
      tradingName: data.trading_name,
      acn: data.aus_business_no,
      crnNo: data.CRN_number,
    });
  };

  componentDidMount() {
    document.title = `Company Profile ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch profile data;
    this.fetchProfileDetail();
  }

  render() {
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              <div className="myprofile--section myprofile--section-sm">
                {!this.state.showLoader ? (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--large u-heading--large-sm">
                        Company Profile
                      </h2>
                    </div>
                    <p className="fs--14 dark-gray">
                      The information below will display on your summary profile unless it
                      is stated otherwise.
                    </p>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />
                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onValidSubmit={this.submitHandler}
                    >
                      <h4 className="section--title u-bold u-mb--15">DETAILS</h4>
                      <FormGroup className="">
                        <Label for="uniquenumber">Unique Number</Label>
                        <FormText color="black">
                          {this.state.crnNo}{' '}
                          <CopyToClipboard
                            text={this.state.crnNo}
                            onCopy={this.updateCopiedState}
                          >
                            <button type="button" className="links--primary">
                              <span className="svg--icon">
                                <FileCopy fontSize="small" className="icons"></FileCopy>
                              </span>
                            </button>
                          </CopyToClipboard>
                          {this.state.copied && (
                            <span className="clipboard">Copied to clipboard.</span>
                          )}
                        </FormText>
                      </FormGroup>

                      <AvField
                        name="businessName"
                        id="businessName"
                        label="Business Name"
                        required
                        errorMessage="Please enter Business Name"
                        value={this.state.businessName}
                      />

                      <AvField
                        name="tradingName"
                        id="tradingName"
                        label="Trading Name"
                        errorMessage="Please enter Trading Name"
                        value={this.state.tradingName}
                      />

                      <AvField
                        name="acn"
                        label="Australian Business Number (ABN)"
                        className="form-control size--lg"
                        required
                        minLength={9}
                        maxLength={15}
                        errorMessage="Please enter Australian Business Number"
                        validate={{
                          pattern: {
                            value: '^[0-9s]+$',
                            errorMessage: 'You can enter only numbers',
                          },
                        }}
                        value={this.state.acn}
                      />

                      <div className="clearfix" />
                      <div className="u-mt--15 u-flexed u-align-center u-justify-center">
                        <Button
                          id="submit"
                          color="primary"
                          disabled={this.state.formSubmitted ? true : false}
                          className={`btn-radius btn-sm btn-w180 ${
                            this.state.formSubmitted ? `inlineloading` : ``
                          }`}
                        >
                          Update Profile
                        </Button>
                      </div>
                    </AvForm>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(ProfileUpdate);
