import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Application imports;
import AppContext from '../../../../components/AppContext';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

// Import CSS
import '../activity.scss';

interface Props extends RouteComponentProps {
  data: any;
}

interface State {}

class ProfileView extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      data.type === 'reset_password' && (
        <>
          <img
            className="avatar size--18"
            src={
              data.userProfilePic
                ? data.userProfilePic
                : data.activityByProfileImageUrl
                ? data.activityByProfileImageUrl
                : data.activityForProfileImageUrl
                ? data.activityForProfileImageUrl
                : Avatar
            }
            alt={data.userFirstName + ' ' + data.userLastName}
          />
          <p>
            {data.userFirstName} {data.userLastName}
          </p>
          &nbsp;reset password
        </>
      )
    );
  }
}

export default withRouter(ProfileView);
