import React from 'react';
import { Link, animateScroll as scroll } from 'react-scroll';
import ReactWOW from 'react-wow';

// Import CSS

// Static Assets
import Personal from '../../../assets/images/Personal.svg';
import SoleTraders from '../../../assets/images/SoleTraders.svg';
import ExpiryAlerts from '../../../assets/images/icons/ExpiryAlerts.svg';
import SharePersonalProfile from '../../../assets/images/icons/SharePersonalProfile.svg';
import UnlimitedStorageColoured from '../../../assets/images/icons/UnlimitedStorageColoured.svg';
import DirectoryProfileColoured from '../../../assets/images/icons/DirectoryProfileColoured.svg';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

interface SectionFeaturesProps {}
interface SectionFeaturesState {}

class SectionFeatures extends React.Component<
  SectionFeaturesProps,
  SectionFeaturesState
> {
  constructor(props: SectionFeaturesProps) {
    super(props);
    this.state = {};
  }
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    return (
      <>
        <section
          className="section--features section--features-sm"
          data-bg={'white'}
          id="Features"
        >
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} className="col-centered">
                <Row className="u-flexed u-align-center u-mb--40">
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} className="col-centered">
                    <ReactWOW animation="fadeInDown">
                      <div className="page--head page--head-sm noborder column center align-items-center text-center u-mb--15">
                        <h3 className="u-heading__small u-heading__small-sm u-mb--10">
                          <strong>Build for professionals</strong>
                        </h3>
                        <h2 className="u-heading__large u-heading__large-sm text-black u-mb--20">
                          <strong>
                            Suitable for anyone, from individuals to corporates
                          </strong>
                        </h2>
                      </div>
                    </ReactWOW>
                  </Col>
                </Row>
                <Row className="u-flexed u-align-center odd">
                  <Col xs={12} sm={12} md={12} lg={12} xl={5} className="order1">
                    <ReactWOW animation="fadeInLeft">
                      <div>
                        <div className="page--head page--head-sm noborder column start align-items-start u-mb--15">
                          <h4 className="u-heading__xsmall u-heading__xsmall-sm u-mb--10">
                            Be ahead of the game
                          </h4>
                          <h1 className="u-heading__exlarge u-heading__exlarge-sm u-mb--0">
                            <strong>For Individuals</strong>
                          </h1>
                        </div>
                        <p>
                          Never lose a document again.{' '}
                          <strong>Organised Work Life's</strong> cloud-based workspace
                          makes it easy to always know where your important documentation
                          is. Even better, the expiry date alerts will let you know what
                          needs updating and when.
                        </p>
                        <ul className="features--tags features--tags-sm">
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={ExpiryAlerts}
                                  alt="Expiry alerts"
                                  className="img-fluid"
                                />
                              </picture>
                              Expiry alerts
                            </div>
                          </li>
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={SharePersonalProfile}
                                  alt="Share personal profile"
                                  className="img-fluid"
                                />
                              </picture>
                              Share personal profile
                            </div>
                          </li>
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={UnlimitedStorageColoured}
                                  alt="Unlimited storage"
                                  className="img-fluid"
                                />
                              </picture>
                              Unlimited storage
                            </div>
                          </li>
                        </ul>
                        <Link
                          to="Pricing"
                          spy={true}
                          smooth={true}
                          offset={-30}
                          duration={500}
                          className="button button--primary button--primary-gradient button-inlineflex button-w190"
                        >
                          Try For Free
                        </Link>
                      </div>
                    </ReactWOW>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={7} className="order2">
                    <ReactWOW animation="fadeInRight">
                      <div className="update--graphic update--graphic-sm">
                        <img src={Personal} alt="Personal" className="img-fluid" />
                      </div>
                    </ReactWOW>
                  </Col>
                </Row>

                <Row className="u-flexed u-align-center even">
                  <Col xs={12} sm={12} md={12} lg={12} xl={7} className="order1">
                    <ReactWOW animation="fadeInLeft">
                      <div className="update--graphic update--graphic-sm">
                        <img src={SoleTraders} alt="Sole Traders" className="img-fluid" />
                      </div>
                    </ReactWOW>
                  </Col>
                  <Col xs={12} sm={12} md={12} lg={12} xl={5} className="order2">
                    <ReactWOW animation="fadeInRight">
                      <div>
                        <div className="page--head page--head-sm noborder column start align-items-start u-mb--15">
                          <h4 className="u-heading__xsmall u-heading__xsmall-sm u-mb--10">
                            Be compliant and job-ready 24/7
                          </h4>
                          <h1 className="u-heading__exlarge u-heading__exlarge-sm u-mb--0">
                            <strong>For Sole Traders</strong>
                          </h1>
                        </div>
                        <p>
                          Running a business is stressful enough without having to
                          remember where all your documents, certificates and insurances
                          are at. Organised Work Life helps you remove the risk of losing
                          documents or forgetting to renew them. With unlimited storage,
                          you can store as many documents as you need, share them with
                          customers or other businesses, and keep them up to date with
                          expiry date alerts. To top it all off, you'll get a listing in
                          the Organised Work Life Directory, helping customers to find
                          compliant businesses.
                        </p>
                        <ul className="features--tags features--tags-sm">
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={ExpiryAlerts}
                                  alt="Expiry alerts"
                                  className="img-fluid"
                                />
                              </picture>
                              Expiry alerts
                            </div>
                          </li>
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={SharePersonalProfile}
                                  alt="Share personal profile"
                                  className="img-fluid"
                                />
                              </picture>
                              Share personal profile
                            </div>
                          </li>
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={DirectoryProfileColoured}
                                  alt="Directory profile"
                                  className="img-fluid"
                                />
                              </picture>
                              Directory profile
                            </div>
                          </li>
                          <li className="features--tags__items">
                            <div className="features--tags__link">
                              <picture className="icon--30">
                                <img
                                  src={UnlimitedStorageColoured}
                                  alt="Unlimited storage"
                                  className="img-fluid"
                                />
                              </picture>
                              Unlimited storage
                            </div>
                          </li>
                        </ul>
                        <Link
                          to="Pricing"
                          spy={true}
                          smooth={true}
                          offset={-30}
                          duration={500}
                          className="button button--primary button--primary-gradient button-inlineflex button-w190"
                        >
                          Try For Free
                        </Link>
                      </div>
                    </ReactWOW>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionFeatures;
