import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';
import { toast } from 'react-toastify';

// Application Imports
import AppContext from '../../../components/AppContext';
import AlertError from '../../../layout/includes/Alert/Error';
import LinkMember from '../../../api/LinkMember';

//import icons
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

// Import Css
import './server-subcontractor.scss';

interface Props extends RouteComponentProps {
  savedList: Array<any>;
  updateInviteListState: Function;
  hideNoDataMsg: boolean;
  updateSavedListState: Function;
  profileView?: boolean;
  showProfilePreviewDivHandler: Function;
  activeArea: string;
}

interface State {
  errorMessages: Array<string> | string;
  loading: boolean;
}

class SavedList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      errorMessages: '',
      loading: false,
    };
  }

  inviteSend = (inviteAccountUuid: string) => {
    const { activeAccountUUID } = this.context;

    this.setState({
      loading: true,
    });

    // request to server;
    LinkMember.inviteSubcontractorAccount(activeAccountUUID, inviteAccountUuid)
      .then((response) => {
        this.setState({
          loading: false,
        });
        if (response.data.success === 1) {
          this.props.updateInviteListState(inviteAccountUuid);
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
        });
      });
  };

  saveListHandler = (saveAccountUuid: string, currentSaveStatus: number) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    LinkMember.toggleSavedAccount(
      activeAccountUUID,
      saveAccountUuid,
      currentSaveStatus === 0 ? 1 : 0
    ).then((response) => {
      if (response.data.success === 1) {
        this.props.updateSavedListState(saveAccountUuid, currentSaveStatus === 0 ? 1 : 0);
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  render() {
    return (
      <>
        {this.props.savedList.length > 0 ? (
          <>
            <AlertError
              ref={(div: any) => {
                this.alertErrorRef = div;
              }}
              message={this.state.errorMessages}
            />

            <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
              <div className="search--results">
                <Row>
                  <Col xs={12} sm={12} md={11} lg={10} className="col-centered">
                    <p className="fs--15 results--found u-mt--30">
                      <strong>{this.props.savedList.length}</strong> subcontractors found
                    </p>
                    {this.props.savedList.map((data: any) => (
                      <div
                        className="card--block card--block-sm"
                        key={this.props.activeArea + '-' + data.uuid}
                      >
                        <header className="card--block__header card--block__header-sm">
                          <div className="card--block__header__profile">
                            <picture className="avatar size--57">
                              <img
                                src={
                                  data.logoImageUrl ? data.logoImageUrl : ProfileAvatar
                                }
                                width="57"
                                alt="Profile Avatar"
                              />
                            </picture>
                            <div className="card--block__header__content">
                              <h3 className="u-mb--10">{data.company_name}</h3>
                              {this.props.profileView && (
                                <button
                                  // href={`/corporate/search/profile/preview/${data.crn}`}
                                  type="button"
                                  className={`c-button-transparent `}
                                  onClick={() =>
                                    this.props.showProfilePreviewDivHandler(
                                      data.CRN_number
                                    )
                                  }
                                >
                                  View Profile
                                </button>
                              )}
                            </div>
                          </div>
                          <div className="ml-auto d-flex">
                            {!data.connectionData.linkStatus &&
                            !data.connectionData.inviteStatus ? (
                              <button
                                className={`c-button c-button-primary c-button-xs ${
                                  this.state.loading ? `inlineloading` : ``
                                }`}
                                disabled={this.state.loading}
                                onClick={() => this.inviteSend(data.uuid)}
                              >
                                {this.state.loading ? '' : 'Invite'}
                              </button>
                            ) : (
                              ''
                            )}
                            {!data.connectionData.linkStatus &&
                            data.connectionData.inviteStatus ? (
                              <button className="c-button c-button--green c-button-xs">
                                Invited
                              </button>
                            ) : (
                              ''
                            )}
                            {data.connectionData.linkStatus ? (
                              <button className="c-button c-button--green c-button-xs">
                                Linked
                              </button>
                            ) : (
                              ''
                            )}

                            <button
                              type="button"
                              onClick={() =>
                                this.saveListHandler(
                                  data.uuid,
                                  data.connectionData.savedStatus
                                )
                              }
                              className={`c-button c-button-xs ${
                                data.connectionData.savedStatus === 0
                                  ? `c-button--primary c-button--primary-bordered c-button-xs`
                                  : `c-button--green c-button--green-bordered`
                              }`}
                            >
                              {data.connectionData.savedStatus === 0 ? 'Save' : 'Saved'}
                            </button>
                          </div>
                        </header>
                        <div className="card--block__body card--block__body-sm">
                          <p className="fs--14">{data.description}</p>

                          {data.services.length > 0 && data.services[0] !== '' && (
                            <ul className="styled--check">
                              {data.services.map((service: any) => (
                                <li key={service}>{service}</li>
                              ))}
                            </ul>
                          )}
                        </div>
                        <footer className="card--block__footer card--block__footer-sm">
                          <div className="card--block__footer__content">
                            <p className="fs--14">
                              <strong>CRN: {data.CRN_number}</strong>{' '}
                              {data.email && '| ' + data.email}{' '}
                              {data.contact_number && '| ' + data.contact_number}
                              {data.website && (
                                <a
                                  href={data.website}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className="links--primary u-ml--5"
                                >
                                  View website
                                </a>
                              )}
                            </p>
                          </div>
                          {data.address.state && (
                            <div className="ml-auto d-flex">
                              <span className="tags">{data.address.state}</span>
                            </div>
                          )}
                        </footer>
                      </div>
                    ))}
                  </Col>
                </Row>
              </div>
            </Container>
          </>
        ) : (
          this.props.hideNoDataMsg && <p className="text-center">No record found</p>
        )}
      </>
    );
  }
}

export default withRouter(SavedList);
