import React from 'react';
import { Container } from 'reactstrap';
import { Link as RouteLink, RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

//Application Imports;
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import TeamMember from '../../../api/TeamMember';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import DocumentList from '../../common/documents-upload/DocumentList';
import NoDataMsg from '../../common/profile-preview/NoDataMsg';
import ViewLinkedDocumentModal from '../../common/account-links/ViewLinkedDocumentModal';
import LinkMember from '../../../api/LinkMember';
import { accountType } from '../../../helpers/Constant';

// Import Icons
import Link from '@material-ui/icons/Link';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

// Import CSS
import './team-members.scss';
import './team-member-landing.scss';

interface RouteInfo {
  uuid: string;
  prevPath: string;
}

interface Props extends RouteComponentProps<RouteInfo> {}

interface State {
  showLoader: boolean;
  accountDetail: any;
  documentList: Array<any>;
  previewDocumentList: Array<any>;
  documentListShow: boolean;
  viewDocumentModalOpen: boolean;
  viewDocumentModalArea: string;
}

class TeamMemberDocumentView extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      accountDetail: null,
      documentList: [],
      previewDocumentList: [],
      documentListShow: false,
      viewDocumentModalOpen: false,
      viewDocumentModalArea: 'corporatePendingMemberView',
    };
  }

  fetchDoucmentDetail = () => {
    const { activeAccountUUID } = this.context;
    let memberUuid = this.props.match.params.uuid;

    // request to server;
    TeamMember.getMemberDocuments(activeAccountUUID, memberUuid).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          documentList: response.data.data.document_list,
          documentListShow:
            response.data.data.document_list === null ||
            response.data.data.document_list.length < 1
              ? false
              : true,
          accountDetail: response.data.data.account_detail,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  previewDocumentModal = (
    userAccountUuid: string,
    getSubmittedData?: boolean,
    fetchData?: boolean
  ) => {
    const { activeAccountUUID } = this.context;
    if (!this.state.viewDocumentModalOpen) {
      // button loading state;
      let buttonElement: any = document.getElementById(`preview_${userAccountUuid}`);
      if (buttonElement) {
        buttonElement.classList.toggle('inlineloading');
      }
      if (fetchData) {
        if (getSubmittedData) {
          // request to server;
          LinkMember.getLinkedMemberSubmittedDocuments(activeAccountUUID, userAccountUuid)
            .then((response) => {
              if (response.data.success === 1) {
                this.setState({
                  previewDocumentList: response.data.data.documents,
                  viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
                  viewDocumentModalArea: 'corporateLinkedMemberView',
                });
              } else {
                toast.error(response.data.messages[0], {
                  autoClose: 3000,
                });
              }
            })
            .then(() => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            });
        } else {
          // request to server;
          LinkMember.getAwaitingPendingInviteSubmittedDocuments(
            activeAccountUUID,
            userAccountUuid
          )
            .then((response) => {
              if (response.data.success === 1) {
                this.setState({
                  previewDocumentList: response.data.data.documents,
                  viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
                  viewDocumentModalArea: 'corporatePendingMemberView',
                });
              } else {
                toast.error(response.data.messages[0], {
                  autoClose: 3000,
                });
              }
            })
            .catch((err) => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            })
            .then(() => {
              if (buttonElement) {
                buttonElement.classList.toggle('inlineloading');
              }
            });
        }
      }
    } else {
      this.setState({
        viewDocumentModalOpen: !this.state.viewDocumentModalOpen,
      });
    }
  };

  componentDidMount() {
    document.title = `Member Documents ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch the user document detail;
    this.fetchDoucmentDetail();
  }

  render() {
    //@ts-ignore
    const prevLocation: any = this.props.location.state;
    return (
      <>
        <div className="myteams--section myteams--section-sm sideBarEnabled">
          <Container fluid={true}>
            <div className="row flex-xl-nowrap">
              <div className="owl--content fullwidth">
                {this.state.showLoader ? (
                  <div className="u-ml--20">
                    <Loader />
                  </div>
                ) : (
                  <>
                    <header className="owl--content__heading p35 d-flex align-items-center">
                      <div className="d-flex align-items-center">
                        <RouteLink
                          to={
                            prevLocation
                              ? prevLocation.prevPath
                              : '/corporate/team/member'
                          }
                          className="c-button--Back w26 fs--16"
                        >
                          <ArrowBackIosIcon fontSize="large" className="icons u-mr--5" />
                        </RouteLink>
                        <div className="team--members__profile">
                          <div className="avatar--40">
                            {this.state.accountDetail.profileImageUrl && (
                              <img
                                src={this.state.accountDetail.profileImageUrl}
                                width="40"
                                alt="Profile Avatar"
                              />
                            )}
                          </div>
                          <div className="team--members__details">
                            <p className="team--members__name">
                              {this.state.accountDetail.name}
                            </p>
                            <p className="team--members__position">
                              {this.state.accountDetail.roleDisplayAs}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="ml-auto u-flexed u-align-center mobile-nowrap d-none d-sm-flex">
                        {this.state.accountDetail.accountType ===
                          accountType.personal && (
                          <button
                            onClick={() =>
                              this.previewDocumentModal(
                                this.state.accountDetail.uuid,
                                true,
                                true
                              )
                            }
                            color="secondary"
                            type="button"
                            className="c-button c-button--primary c-button-xs"
                            id="manage-document-handler"
                          >
                            Manage Document
                          </button>
                        )}

                        <button
                          onClick={() =>
                            this.props.history.push({
                              pathname: `/account/${this.state.accountDetail.accountUuid}`,
                            })
                          }
                          color="secondary"
                          type="button"
                          className="c-button c-button--primary c-button-xs"
                        >
                          View Profile
                        </button>
                        <button
                          onClick={() =>
                            this.props.history.push({
                              pathname: `/corporate/member-projects/${this.state.accountDetail.accountUuid}`,
                              state: {
                                userName: this.state.accountDetail.name,
                              },
                            })
                          }
                          color="secondary"
                          type="button"
                          className="c-button c-button--primary c-button-xs"
                        >
                          Projects ({this.state.accountDetail.totalAssignedProjects})
                        </button>
                        {this.state.accountDetail.status === 1 ? (
                          <button
                            color="secondary"
                            type="button"
                            className="c-button c-button--primary c-button-xs"
                          >
                            <Link fontSize="small" className="icons u-mr--5" />
                            Linked
                          </button>
                        ) : (
                          <button
                            color="danger"
                            type="button"
                            className="c-button c-button--danger c-button-xs"
                          >
                            <Link fontSize="small" className="icons u-mr--5" />
                            Unlinked
                          </button>
                        )}
                      </div>
                    </header>

                    <div className="u-mt--15">
                      {this.state.documentListShow ? (
                        <DocumentList
                          area="corporateMemberProfileView"
                          showEditElements={false}
                          documentList={this.state.documentList}
                          updateListData={() => {}}
                        />
                      ) : (
                        <NoDataMsg />
                      )}
                    </div>
                    <ViewLinkedDocumentModal
                      area={this.state.viewDocumentModalArea}
                      isOpen={this.state.viewDocumentModalOpen}
                      toggle={this.previewDocumentModal}
                      documentList={this.state.previewDocumentList}
                      getAccountDocumentTypeOptions={() => {}}
                      userAccountUuid=""
                    />
                  </>
                )}
              </div>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(TeamMemberDocumentView);
