import Api from './Api';

interface InviteMember {
  name: string;
  email: string;
  role: string;
}

export default class LinkMember extends Api {
  static getAccountsList = async (
    accountUuid: string,
    exlcudePending: boolean = false,
    excludeRoles: boolean = false,
    excludeSubcontractors: boolean = false
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      exclude_pending: exlcudePending,
      exclude_roles: excludeRoles,
      exclude_subcontractors: excludeSubcontractors,
    };

    return Api.postRequest('/corporate/linked-accounts', postData);
  };

  //Subcontractors linked to a corporate account
  static getSubcontractorsAccountsList = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/corporate/linked-subcontractor-accounts', postData);
  };

  //List of all the companies an account is subcontracting for (company working for company)
  static getSubcontractedForAccountsList = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/corporate/subcontracting-for-accounts', postData);
  };

  //Coporate Links and Unlinks a linked account
  static updateAccountStatus = async (
    accountUuid: string,
    userAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_uuid: userAccountUuid,
    };

    return Api.postRequest('/corporate/update-account-status', postData);
  };

  //When a corporate delete a un-linked account
  static deleteUnlinkedAccount = async (
    accountUuid: string,
    userAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_uuid: userAccountUuid,
    };

    return Api.postRequest('/corporate/delete-unlinked-account', postData);
  };

  static resendInvitation = async (
    accountUuid: string,
    pendingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pendingInviteUuid,
    };

    return Api.postRequest('/corporate/resend-invitation', postData);
  };

  static cancelPendingInvite = async (
    accountUuid: string,
    pendingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pendingInviteUuid,
    };

    return Api.postRequest('/corporate/cancel-invitation', postData);
  };

  static sendInviteRequest = async (
    accountUuid: string,
    members: Array<InviteMember>,
    text: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      members,
      invite_text: text,
    };

    return Api.postRequest('/corporate/invite-member', postData);
  };

  static invitePersonalAccount = async (
    accountUuid: string,
    roleUuid: string,
    inviteAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid: roleUuid,
      invite_account_uuid: inviteAccountUuid,
    };

    return Api.postRequest('/corporate/invite-personal-account', postData);
  };

  static inviteSubcontractorAccount = async (
    accountUuid: string,
    inviteAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      invite_account_uuid: inviteAccountUuid,
    };

    return Api.postRequest('/corporate/invite-subcontractor-account', postData);
  };

  static changeAccountRole = async (
    accountUuid: string,
    roleUuid: string,
    userAccountUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid: roleUuid,
      user_account_uuid: userAccountUuid,
    };

    return Api.postRequest('/corporate/update-linked-account-role', postData);
  };

  //get all the saved accounts list
  static getSavedList = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/corporate/get-saved-list', postData);
  };

  //Toggle account in saved_accounts
  static toggleSavedAccount = async (
    accountUuid: string,
    userAccountUuid: string,
    addAccount: number
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      save_account_uuid: userAccountUuid,
      add_account: addAccount,
    };

    return Api.postRequest('/corporate/add-remove-saved-account', postData);
  };

  static approveCorporatePendingInvite = async (
    accountUuid: string,
    pedingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pedingInviteUuid,
    };

    return Api.postRequest('/account-link/approve-corporate-pending-invite', postData);
  };

  static rejectCorporatePendingInvite = async (
    accountUuid: string,
    pedingInviteUuid: string,
    message: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pedingInviteUuid,
      message,
    };

    return Api.postRequest('/account-link/reject-corporate-pending-invite', postData);
  };

  static getPendingInviteRoleDocuments = async (
    accountUuid: string,
    pedingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pedingInviteUuid,
    };

    return Api.postRequest('/account-link/get-pending-invite-role-documents', postData);
  };

  static getAwaitingPendingInviteSubmittedDocuments = async (
    accountUuid: string,
    pedingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pedingInviteUuid,
    };

    return Api.postRequest(
      '/account-link/get-pending-invite-attached-documents',
      postData
    );
  };

  static getLinkedMemberSubmittedDocuments = async (
    accountUuid: string,
    userUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_uuid: userUuid,
    };

    return Api.postRequest(
      '/account-link/get-user-account-attached-role-documents',
      postData
    );
  };

  static approveCorporateMemberRoleDocument = async (
    accountUuid: string,
    userAccountDocumentUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_document_uuid: userAccountDocumentUuid,
    };

    return Api.postRequest(
      '/corporate/approve-corporate-member-revision-document',
      postData
    );
  };

  static rejectCorporateAttachedDocuments = async (
    accountUuid: string,
    userAccountDocumentUuid: string,
    message: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_document_uuid: userAccountDocumentUuid,
      message,
    };

    return Api.postRequest(
      '/corporate/reject-corporate-member-revision-document',
      postData
    );
  };
}
