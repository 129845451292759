import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

// Application Imports;
import AppContext from '../../../components/AppContext';
import Roles from '../../../api/Roles';

// Css Imports;
import 'react-confirm-alert/src/react-confirm-alert.css';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  showDeleteButton: boolean;
}

interface State {
  formSubmitted: boolean;
  breadcrumbHtml: Array<JSX.Element>;
  deleteRoleSubmitted: boolean;
}

class EditRoleBreadcrumb extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      formSubmitted: false,
      breadcrumbHtml: [],
      deleteRoleSubmitted: false,
    };
  }

  deleteRoleConfirmation = () => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this role?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteRole(),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  deleteRole = () => {
    const { activeAccountUUID } = this.context;
    let roleUuid = this.props.match.params.uuid;
    this.setState({
      deleteRoleSubmitted: true,
    });
    Roles.deleteRole(activeAccountUUID, roleUuid)
      .then((response) => {
        this.setState({
          deleteRoleSubmitted: false,
        });
        if (response.data.success === 1) {
          toast.success('Role deleted successfully.', {
            autoClose: 2000,
          });
          this.props.history.push('/corporate/role');
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        this.setState({
          deleteRoleSubmitted: false,
        });
      });
  };

  render() {
    return (
      <div>
        <div className="c-breadcrumb--section c-breadcrumb--section-sm">
          <Container fluid className="container-fluid-sm">
            <Row className="u-flexed u-align-center u-justify-btw">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="u-flexed u-align-center u-justify-start">
                  <Button
                    className="c-button--Back fs--16 u-mr--20"
                    onClick={() => this.props.history.push('/corporate/role')}
                  >
                    <i className="arrow left" /> Back to roles
                  </Button>
                  {this.props.showDeleteButton && (
                    <div className="ml-auto d-flex">
                      <button
                        type="button"
                        onClick={this.deleteRoleConfirmation}
                        disabled={this.state.formSubmitted}
                        className={`c-button c-button-primary c-button-inlineflex c-button-radius c-button-xs ${
                          this.state.formSubmitted ? `inlineloading` : ``
                        }`}
                      >
                        Delete
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(EditRoleBreadcrumb);
