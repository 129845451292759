import React from 'react';
import Select from 'react-select';
import { Row, Col, Modal, ModalHeader, ModalBody, Label, FormGroup } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';

//Application Imports;
import AppContext from '../../../components/AppContext';
import Account from '../../../api/Account';
import AlertError from '../../../layout/includes/Alert/Error';

interface Props {
  isOpen: boolean;
  toggle: Function;
  pendingInviteUuid: string;
  accountDocumentList: Array<any>;
  requiredDocumentList: Array<any>;
  getAccountDocumentTypeOptions: Function;
  postAcceptPendingInvitation: Function;
}

interface State {
  submitDocumentList: Array<any>;
  formSubmitted: boolean;
  errorMessages: Array<string> | string;
}

class LinkedDocumentModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      submitDocumentList: [],
      formSubmitted: false,
      errorMessages: '',
    };
  }

  updateRequiredDocumentList = (data: any, roleDocumentUuid: string) => {
    let submitDocumentList = this.state.submitDocumentList;
    let entryExists = false;

    for (let i = 0; i < submitDocumentList.length; i++) {
      if (submitDocumentList[i].roleDocumentUuid === roleDocumentUuid) {
        submitDocumentList[i].accountDocumentUuid = data.value;
        entryExists = true;
        break;
      }
    }

    if (!entryExists) {
      submitDocumentList.push({ accountDocumentUuid: data.value, roleDocumentUuid });
    }

    this.setState({
      submitDocumentList,
    });
  };

  submitHandler = (e: any) => {
    e.preventDefault();

    const { activeAccountUUID } = this.context;

    // request to server;
    Account.acceptPendingCorporateInvite(
      activeAccountUUID,
      this.props.pendingInviteUuid,
      this.state.submitDocumentList
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.props.postAcceptPendingInvitation(this.props.pendingInviteUuid);
          this.props.toggle();
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  render() {
    return (
      <Modal
        backdrop={true}
        id="LinkedDocumentModal"
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--large modal--large-md"
      >
        <ModalHeader
          className="start plr--60 plr--60-sm u-border--btm0 u-pt--25"
          toggle={() => this.props.toggle()}
        >
          Attach Documents
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm u-pb--40 u-pb--40-sm">
          <p>
            Please select a document from the dropdown for each of the role requirements.
          </p>
          <form onSubmit={this.submitHandler}>
            <AlertError
              ref={(div: any) => {
                this.alertErrorRef = div;
              }}
              message={this.state.errorMessages}
            />
            {this.props.requiredDocumentList.length > 0
              ? this.props.requiredDocumentList.map((data: any) => (
                  <div className="inline--form u-mb--15" key={data.uuid}>
                    <FormGroup className="u-pr--0">
                      <div className="fullwidth">
                        <Row className="u-flexed u-align-center">
                          <Col sm={12} md={6} lg={6} className="">
                            <Label for="certificatename">{data.title}</Label>
                          </Col>
                          <Col sm={12} md={6} lg={6} className="ml-auto">
                            <div className="noStyle">
                              <Select
                                autoFocus
                                id="DocumentType"
                                name="DocumentType"
                                options={this.props.getAccountDocumentTypeOptions(
                                  data.type
                                )}
                                placeholder="Select one"
                                tabSelectsValue={false}
                                onChange={(a) =>
                                  this.updateRequiredDocumentList(a, data.uuid)
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </FormGroup>
                  </div>
                ))
              : ''}

            <div className="fullwidth u-mt--30">
              <button
                type="submit"
                disabled={this.state.formSubmitted}
                className={`btn-radius btn-w160 btn btn-primary has--centered ${
                  this.state.formSubmitted ? `inlineloading` : ``
                }`}
              >
                Submit
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
    );
  }
}

export default LinkedDocumentModal;
