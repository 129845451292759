import React from 'react';
import { Link } from 'react-router-dom';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';

// Static Includes
import NoTeamMembersGraphic from '../../../assets/images/No-Team-Members.svg';

interface Props {}

interface State {}

class MemberBlankScreen extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className="invite--section invite--section-sm text-center">
          <div className="invite--graphic invite--graphic-sm u-mb--25 u-mb--25-sm">
            <img src={NoTeamMembersGraphic} alt="No Team Members" className="img-fluid" />
          </div>
          <div className="page-head page-head-sm center column noborder">
            <h2 className="u-heading--xsmall u-heading--xsmall-sm">
              <span style={{ color: '#4f4f4f' }}>Invite Team Members</span>
            </h2>
            <p className="text-center fs--13 dark-gray">
              Invite team members to link to your company. This will allow you to have
              access to their compliance documentation.
            </p>
          </div>
          <Link
            to="/corporate/member"
            className="c-button c-button--primary c-button-radius c-button-w210 c-button-inline size--xs has-icon--before"
          >
            Invite Team Members
          </Link>
        </div>
      </>
    );
  }
}

export default MemberBlankScreen;
