import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

interface Props extends RouteComponentProps {}

class AuthPageWrapper extends React.PureComponent<Props, {}> {
  render() {
    return (
      <div className="auth--page">
        <Container fluid={true} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col sm={12} md={8} lg={7} xl={6} className="col-centered">
              {this.props.children}
            </Col>
          </Row>
        </Container>
        <div className="clearfix"></div>
      </div>
    );
  }
}

export default withRouter(AuthPageWrapper);
