import React from 'react';
import ContentLoader from 'react-content-loader';

class MyLoader extends React.Component<MyLoaderProps, MyLoaderState> {

  render() {
    return (
      <>
        <ContentLoader
          speed={3}
          width={400}
          height={160}
          viewBox="0 0 400 160"
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          className="ravi"
        >
          <rect x="0" y="12" rx="4" ry="4" width="90" height="8" />
          <rect x="0" y="30" rx="4" ry="4" width="50" height="8" />
          <rect x="0" y="60" rx="4" ry="4" width="400" height="8" />
          <rect x="0" y="78" rx="4" ry="4" width="380" height="8" />
          <rect x="0" y="96" rx="4" ry="4" width="170" height="8" />
        </ContentLoader>
      </>
    );
  }
}

interface MyLoaderProps {}

interface MyLoaderState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

export default MyLoader;
