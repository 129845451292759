import React from 'react';
import { Row, Col, Modal, ModalBody, Button, FormGroup, Label } from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';
import scrollToComponent from 'react-scroll-to-component';
import Select from 'react-select';

// Application imports
import AppContext from '../../../components/AppContext';
import AlertError from '../../../layout/includes/Alert/Error';
import ManageUser from '../../../api/ManageUser';

// Imports icon;
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

// Import CSS
import '../members/invite.scss';

interface Props {
  isOpen: boolean;
  toggle: Function;
  reloadData: Function;
  area: string;
  userData: any;
}

interface State {
  showLoader: boolean;
  errorMessages: Array<string> | string;
  formSubmitted: boolean;
  roleString: string;
  roleObj: any;
  roleError: boolean;
  hidePassword: boolean;
}

class InviteModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      errorMessages: '',
      formSubmitted: false,
      roleString: '',
      roleObj: null,
      roleError: false,
      hidePassword: false,
    };
  }

  roleOptions = [
    { value: 'Admin', label: 'Admin' },
    { value: 'Manager', label: 'Manager' },
  ];

  roleChangeHandler = (role: any) => {
    this.setState({
      roleString: role.value,
      roleObj: role,
      roleError: false,
    });
  };

  handlePasswordToggle = () => {
    this.setState({
      hidePassword: !this.state.hidePassword,
    });
  };

  getRole = (role: string) => {
    return { value: role, label: role };
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { activeAccountUUID } = this.context;
    let isErrors = false;
    this.setState({
      errorMessages: '',
    });

    if (errors.length > 0) {
      isErrors = true;
    }

    if (this.state.roleString.trim() === '' || this.state.roleString.trim() === null) {
      this.setState({
        roleError: true,
      });
      isErrors = true;
    }

    if (isErrors) {
      return false;
    }

    this.setState({
      formSubmitted: true,
    });

    let url = '/corporate-user/add-user';
    if (this.props.area === 'edit') {
      url = '/corporate-user/update-user';
    }
    // request to server;
    ManageUser.addEditUser(
      activeAccountUUID,
      values.first_name,
      values.last_name,
      values.mobile_number,
      values.email,
      values.password,
      this.state.roleString,
      url,
      this.props.userData.uuid
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });

        this.setState({
          roleObj: null,
          roleString: '',
        });
        if (response.data.success === 1) {
          toast.success(
            `User ${this.props.area === 'edit' ? `updated` : `added`} successfully`,
            {
              autoClose: 3000,
            }
          );
          this.props.toggle(this.props.area === 'edit' ? true : false);
          this.props.reloadData();
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
          errorMessages: err.message,
        });
        scrollToComponent(this.alertErrorRef, { duration: 500 });
      });
  };

  componentDidUpdate(prevProps: any) {
    if (
      this.props.userData !== prevProps.userData &&
      this.props.area === 'edit' &&
      this.props.userData !== null
    ) {
      this.setState({
        roleObj: { value: this.props.userData.role, label: this.props.userData.role },
        roleString: this.props.userData.role,
      });
    }
  }

  render() {
    let title = '';
    if (this.props.area === 'add') {
      title = 'Add User';
    } else {
      title = 'Update User';
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--large modal--large-md"
        backdrop={true}
      >
        <ModalBody className="plr--60 plr--60-sm" style={{ maxHeight: 'inherit' }}>
          <button
            className="close"
            onClick={() => this.props.toggle()}
            aria-label="Close"
          >
            <span aria-hidden="true">×</span>
          </button>
          <div className="modal-body--inner pt--30">
            <header className="modal-body--header modal-body--header-sm center">
              <h3 className="title">{title}</h3>
            </header>
            <AvForm
              className="form-horizontal"
              autoComplete="off"
              onSubmit={this.submitHandler}
            >
              <AlertError
                ref={(div: any) => {
                  this.alertErrorRef = div;
                }}
                message={this.state.errorMessages}
              />

              <AvField
                name="first_name"
                className="form-control"
                placeholder="First name"
                label="First name"
                required
                errorMessage="Please enter First name"
                value={this.props.userData.first_name}
              />

              <AvField
                name="last_name"
                className="form-control"
                placeholder="Last name"
                label="Last name"
                required
                errorMessage="Please enter Last name"
                value={this.props.userData.last_name}
              />

              <AvField
                type="text"
                name="mobile_number"
                label="Mobile Number"
                autoComplete="off"
                placeholder="04xxxxxxxx"
                errorMessage="Please enter a valid 10 digit Mobile Number"
                minLength={10}
                maxLength={10}
                validate={{
                  pattern: {
                    value: '^[0-9]+$',
                    errorMessage: 'You can enter only numbers',
                  },
                }}
                value={this.props.userData.mobile_number}
              />

              <AvField
                name="email"
                className="form-control"
                placeholder="Email"
                label="Email"
                required
                errorMessage="Please enter Email"
                validate={{
                  email: {
                    value: true,
                    errorMessage: 'Please enter a valid Email address',
                  },
                }}
                value={this.props.userData.email}
              />

              <AvGroup>
                <Label for="password">Password</Label>
                <AvInput
                  type={!this.state.hidePassword ? 'password' : 'text'}
                  name="password"
                  id="password"
                  placeholder={
                    this.props.area === 'add' ? 'Password' : 'Leave empty to not update'
                  }
                  required={this.props.area === 'add' ? true : false}
                  className="is--pass"
                  minLength={8}
                  maxLength={20}
                  errorMessage="Please enter Password"
                  validate={{
                    pattern: {
                      value: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$',
                      errorMessage:
                        'Password should contain atleast one number and one special character (!@#$%^&*)',
                    },
                  }}
                />
                <button
                  type="button"
                  onClick={this.handlePasswordToggle}
                  className="pass--toggler"
                >
                  {this.state.hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>
                <AvFeedback>
                  Password should contain atleast one number and one special character
                  (!@#$%^&*) and must be between 8 - 20 characters.
                </AvFeedback>
              </AvGroup>

              <FormGroup
                className={`${this.state.roleError ? `form-group--has-error` : ``}`}
              >
                <Label htmlFor="DocumentType">Select role</Label>
                <Select
                  autoFocus
                  name="DocumentType"
                  options={this.roleOptions}
                  value={this.state.roleObj}
                  placeholder="Select role"
                  tabSelectsValue={false}
                  onChange={this.roleChangeHandler}
                />
                <span className="help-block">Please select role</span>
              </FormGroup>

              <Row className="u-flexed u-align-center u-justify-center text-center">
                <Col xs={12} md={12} lg={12}>
                  <Button
                    disabled={this.state.formSubmitted}
                    className={`btn-radius btn btn-primary u-upper has--centered ${
                      this.state.formSubmitted ? `inlineloading` : ``
                    }`}
                  >
                    {this.props.area === 'edit' ? 'Update' : 'Add'} User
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default InviteModal;
