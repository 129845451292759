import React from 'react';
import ReactWOW from 'react-wow';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import appConfig from '../../../../../config';

interface Props {}

interface State {}

class Footer extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <footer className="footer footer-sm">
          <Container fluid={false}>
            <Row>
              <ReactWOW animation="fadeInLeft">
                <Col xs={12} md={8} lg={8} className="first mobile--mb--25">
                  All Rights Reserved &copy; owlcds.com.au
                  <a
                    href={appConfig.TERMS_OF_USE_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Terms of use
                  </a>
                  <a
                    href={appConfig.PRIVACY_POLICY_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Privacy Policy
                  </a>
                  <a
                    href={appConfig.WEBSITE_DISCLAIMER_URL}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Website disclaimer
                  </a>
                </Col>
              </ReactWOW>
              <ReactWOW animation="fadeInRight">
                <Col xs={12} md={4} lg={4} className="text-right last">
                  <a href="mailto:hello@owldcs.com.au">hello@owldcs.com.au</a>{' '}
                </Col>
              </ReactWOW>
            </Row>
          </Container>
        </footer>
      </>
    );
  }
}

export default Footer;
