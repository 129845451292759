import React from 'react';
import {
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  Label,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import Lightbox from 'react-image-lightbox';
import Select from 'react-select';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';

//Application Imports;
import AppContext from '../../../components/AppContext';
import Account from '../../../api/Account';
import AlertError from '../../../layout/includes/Alert/Error';
import LinkMember from '../../../api/LinkMember';
import RejectDocumentModal from './RejectDocumentModal';
import { documentStatusCode } from '../../../helpers/Constant';
import { GetDocumentStatusClassName } from '../../../helpers/Helpers';
import Helper from '../../../helpers/Helpers';

// Import Css;
import 'react-image-lightbox/style.css';

// Import Icons;
import Search from '@material-ui/icons/Search';
import DoneAll from '@material-ui/icons/DoneAll';
import Close from '@material-ui/icons/Close';

interface Props extends RouteComponentProps {
  userAccountUuid: string;
  area: string;
  isOpen: boolean;
  toggle: Function;
  documentList: Array<any>;
  getAccountDocumentTypeOptions: Function;
  isShowButton?: boolean;
}
interface State {
  photoIndex: number;
  isLightBoxOpen: boolean;
  imageArray: any;
  submitDocumentList: Array<any>;
  formSubmitted: boolean;
  errorMessages: Array<string> | string;
  openRejectDocumentModal: boolean;
  rejectedDocumentValueList: Array<any>;
  rejectUserAccountDocumentUuid: string;
}

class ViewLinkedDocumentModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      photoIndex: 0,
      isLightBoxOpen: false,
      imageArray: [],
      submitDocumentList: [],
      formSubmitted: false,
      errorMessages: '',
      openRejectDocumentModal: false,
      rejectedDocumentValueList: [],
      rejectUserAccountDocumentUuid: '',
    };
  }

  rejectMemberSubmittedDocument = (
    closeAllModal: boolean = false,
    userAccountDocumentUuid: string = ''
  ) => {
    this.setState({
      openRejectDocumentModal: !this.state.openRejectDocumentModal,
      rejectUserAccountDocumentUuid: userAccountDocumentUuid,
    });
    if (closeAllModal) {
      this.props.toggle('', false, false);
    }
  };

  lightBoxOpenHandler = (images?: Array<any>) => {
    this.setState({
      isLightBoxOpen: !this.state.isLightBoxOpen,
      imageArray: images ? images : [],
    });
  };

  updateRequiredDocumentList = (data: any, roleDocumentUuid: string, index: number) => {
    let submitDocumentList = this.state.submitDocumentList;
    let entryExists = false;

    for (let i = 0; i < submitDocumentList.length; i++) {
      if (submitDocumentList[i].roleDocumentUuid === roleDocumentUuid) {
        submitDocumentList[i].accountDocumentUuid = data.value;
        entryExists = true;
        break;
      }
    }

    if (!entryExists) {
      submitDocumentList.push({ accountDocumentUuid: data.value, roleDocumentUuid });
    }

    // change selected value from state;
    let rejectedDocumentValueList = [...this.state.rejectedDocumentValueList];
    rejectedDocumentValueList[index] = data;

    this.setState({
      submitDocumentList,
      rejectedDocumentValueList,
    });
  };

  updateAttachedDocuments = (e: any) => {
    e.preventDefault();

    const { activeAccountUUID } = this.context;

    this.setState({
      formSubmitted: true,
    });

    // request to server;
    Account.updateAttachedRoleDocuments(
      activeAccountUUID,
      this.props.userAccountUuid,
      this.state.submitDocumentList
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          this.props.toggle('', false, false);
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  approvalMemberSubmittedDocumentConfirm = (
    e: React.MouseEvent<HTMLButtonElement>,
    userAccountDocumentUuid: string
  ) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to approve the document?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.approvalMemberSubmittedDocument(elementButton, userAccountDocumentUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  approvalMemberSubmittedDocument = (
    elementButton: HTMLButtonElement,
    userAccountDocumentUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    elementButton.classList.toggle('inlineloading');
    const prevButtonText = elementButton.innerText;
    elementButton.innerText = '';

    // request to server;
    LinkMember.approveCorporateMemberRoleDocument(
      activeAccountUUID,
      userAccountDocumentUuid
    )
      .then((response) => {
        if (response.data.success === 1) {
          this.props.toggle(userAccountDocumentUuid, false, false);
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .then(() => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  componentDidUpdate(prevProps: any) {
    if (prevProps.documentList !== this.props.documentList) {
      let selectedDocumentOptions = [];
      for (let i = 0; i < this.props.documentList.length; i++) {
        if (
          this.props.documentList[i].linked_document_data &&
          this.props.documentList[i].linked_document_data !== null
        ) {
          selectedDocumentOptions.push({
            value: this.props.documentList[i].linked_document_data.uuid,
            label: this.props.documentList[i].linked_document_data.title,
          });
        } else {
          selectedDocumentOptions.push(null);
        }
      }
      this.setState({
        rejectedDocumentValueList: selectedDocumentOptions,
      });
    }
  }

  render() {
    const customStyles = {
      overlay: {
        zIndex: 10000,
      },
    };
    return (
      <>
        <Modal
          backdrop={true}
          id="ViewLinkedDocumentModal"
          isOpen={this.props.isOpen}
          toggle={() => this.props.toggle('', false, false)}
          className="modal--large modal--large-md"
        >
          <ModalHeader
            className="start plr--60 plr--60-sm u-border--btm0 u-pt--25"
            toggle={() => this.props.toggle('', false, false)}
          >
            {this.props.isShowButton ? 'Manage Documents' : 'Attached Documents'}
          </ModalHeader>
          <ModalBody className="plr--60 plr--60-sm u-pb--40 u-pb--40-sm">
            <AlertError
              ref={(div: any) => {
                this.alertErrorRef = div;
              }}
              message={this.state.errorMessages}
            />
            {this.props.documentList.length > 0
              ? this.props.documentList.map((data: any, key: number) => (
                  <div
                    className={`inline--form u-mb--15 ${GetDocumentStatusClassName(
                      data.document_status
                    )}`}
                    key={key}
                  >
                    <FormGroup className="u-pr--0">
                      <div className="fullwidth">
                        <Row className="u-flexed u-align-center">
                          <Col sm={12} md={5} lg={5} className="">
                            <Label for="certificatename">
                              {data.role_document_title}
                            </Label>
                          </Col>
                          <Col sm={12} md={2} lg={2} className="">
                            <div>
                              {data.linked_document_data
                                ? Helper.getDocumentExpiryDate(
                                    data.linked_document_data.expiry_date
                                  )
                                : '-'}
                            </div>
                          </Col>
                          <Col sm={12} md={5} lg={5} className="ml-auto text-right">
                            <div className="noStyle fs--15">
                              {data.linked_document_title &&
                              ((this.props.area !== 'personalUser' &&
                                data.document_status === documentStatusCode.approved) ||
                                (this.props.area === 'personalUser' &&
                                  data.document_status !==
                                    documentStatusCode.rejected)) ? (
                                data.linked_document_title
                              ) : this.props.area === 'personalUser' &&
                                this.props.isShowButton &&
                                data.user_account_document_status !== 1 &&
                                data.user_account_document_status !== 2 ? (
                                <Select
                                  autoFocus
                                  id="DocumentType"
                                  name="DocumentType"
                                  options={this.props.getAccountDocumentTypeOptions(
                                    data.role_document_type
                                  )}
                                  placeholder="Select one"
                                  tabSelectsValue={false}
                                  value={this.state.rejectedDocumentValueList[key]}
                                  onChange={(a) =>
                                    this.updateRequiredDocumentList(
                                      a,
                                      data.role_document_uuid,
                                      key
                                    )
                                  }
                                />
                              ) : data.document_status === documentStatusCode.rejected ? (
                                data.document_status_text
                              ) : (
                                ''
                              )}
                              {((this.props.area === 'personalUser' &&
                                data.document_status !== documentStatusCode.rejected) ||
                                this.props.area !== 'personalUser') &&
                                data.media.length > 0 && (
                                  <button
                                    type="button"
                                    onClick={() => this.lightBoxOpenHandler(data.media)}
                                    className="c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs u-ml--10"
                                  >
                                    <Search fontSize="small" className="icons" />
                                  </button>
                                )}

                              {this.props.area === 'corporateLinkedMemberView' &&
                                data.document_status ===
                                  documentStatusCode.awaitingApproval && (
                                  <>
                                    <button
                                      type="button"
                                      id="InlineApproved"
                                      className="c-button c-button--primary c-button-inlineflex c-button-radius c-button-xs u-ml--5"
                                      onClick={(e: any) =>
                                        this.approvalMemberSubmittedDocumentConfirm(
                                          e,
                                          data.user_account_document_uuid
                                        )
                                      }
                                    >
                                      <DoneAll fontSize="small" className="icons" />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="InlineApproved"
                                      >
                                        Approve
                                      </UncontrolledTooltip>
                                    </button>
                                    <button
                                      type="button"
                                      id="InlineDelete"
                                      className="c-button c-button--red c-button-inlineflex c-button-radius c-button-xs u-ml--5"
                                      onClick={() =>
                                        this.rejectMemberSubmittedDocument(
                                          false,
                                          data.user_account_document_uuid
                                        )
                                      }
                                    >
                                      <Close fontSize="small" className="icons" />
                                      <UncontrolledTooltip
                                        placement="top"
                                        target="InlineDelete"
                                      >
                                        Reject
                                      </UncontrolledTooltip>
                                    </button>
                                  </>
                                )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </FormGroup>
                  </div>
                ))
              : ''}
            {this.props.isShowButton && this.props.area === 'personalUser' ? (
              <div className="fullwidth u-mt--30">
                <button
                  onClick={this.updateAttachedDocuments}
                  type="submit"
                  disabled={this.state.formSubmitted}
                  className={`btn-radius btn-w160 btn btn-primary has--centered ${
                    this.state.formSubmitted ? `inlineloading` : ``
                  }`}
                >
                  Submit
                </button>
              </div>
            ) : (
              ''
            )}

            {this.state.isLightBoxOpen && (
              <Lightbox
                reactModalStyle={customStyles}
                discourageDownloads={true}
                imageLoadErrorMessage="Please wait..loading image"
                mainSrc={this.state.imageArray[this.state.photoIndex].previewUrl}
                nextSrc={
                  this.state.imageArray.length > 1
                    ? this.state.imageArray[
                        (this.state.photoIndex + 1) % this.state.imageArray.length
                      ]
                    : undefined
                }
                prevSrc={
                  this.state.imageArray.length > 1
                    ? this.state.imageArray[
                        (this.state.photoIndex + this.state.imageArray.length - 1) %
                          this.state.imageArray.length
                      ]
                    : undefined
                }
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + this.state.imageArray.length - 1) %
                      this.state.imageArray.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex:
                      (this.state.photoIndex + 1) % this.state.imageArray.length,
                  })
                }
                onCloseRequest={this.lightBoxOpenHandler}
              />
            )}
            <RejectDocumentModal
              area="documentReject"
              userAccountDocumentUuid={this.state.rejectUserAccountDocumentUuid}
              isOpen={this.state.openRejectDocumentModal}
              toggle={this.rejectMemberSubmittedDocument}
            />
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(ViewLinkedDocumentModal);
