import React from 'react';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import scrollToComponent from 'react-scroll-to-component';
import Select from 'react-select';

// Application Imports;
import AlertError from '../../../layout/includes/Alert/Error';
import Roles from '../../../api/Roles';
import AppContext from '../../../components/AppContext';

// Import Css;
import './role.scss';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  isOpen?: boolean;
  toggleFun: Function;
  addDocTolist: Function;
  documentTypeOptions: any;
  getTypeToRender: Function;
  convertArrayDataObj: Function;
  roleDetails: any;
}

interface State {
  typeOptions: any;
  errorMessages: Array<string> | string;
  formSubmitted: boolean;
  docTypeSelectedValueError: boolean;
  typeObjError: boolean;
  subTypeValue: string;
  docTypeSelectedValue: string;
  showLoader: boolean;
  certificateType: boolean;
  titleValue: string;
  titleValueError: boolean;
  docTypeObj: any;
  subTypeObj: any;
  certificateValueObj: any;
  certificateOptions: Array<any>;
  certificatesLoaded: boolean;
  inputControl: boolean;
}

class DocumentModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      typeOptions: [],
      errorMessages: '',
      formSubmitted: false,
      docTypeSelectedValueError: false,
      typeObjError: false,
      subTypeValue: '',
      docTypeSelectedValue: '',
      docTypeObj: null,
      subTypeObj: null,
      certificateValueObj: null,
      showLoader: false,
      certificateType: false,
      titleValueError: false,
      titleValue: '',
      certificateOptions: [],
      certificatesLoaded: false,
      inputControl: false,
    };
  }

  docTypeHandler = (data: any) => {
    this.setState({
      docTypeObj: data,
      docTypeSelectedValue: data.value,
      docTypeSelectedValueError: false,
      typeOptions: this.props.getTypeToRender(data.value),
      subTypeObj: null,
      subTypeValue: '',
      titleValue: '',
      certificateValueObj: null,
      inputControl: false,
    });
    if (data.value === 'certificate') {
      this.setState({
        certificateType: true,
      });

      if (!this.state.certificatesLoaded) {
        this.setState({
          showLoader: true,
        });
        this.fetchCertificates();
      }
    } else {
      this.setState({
        certificateType: false,
        showLoader: false,
      });
    }
  };

  certificateChangeHandler = (e: any) => {
    this.setState({
      titleValue: e.value,
      certificateValueObj: e,
      titleValueError: false,
    });
  };

  fetchCertificates = () => {
    const { activeAccountUUID } = this.context;

    // request to server
    Roles.getCertificates(
      activeAccountUUID,
      this.props.roleDetails.industry,
      this.props.roleDetails.position
    ).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          certificatesLoaded: true,
          certificateOptions: this.props.convertArrayDataObj(
            response.data.data.title_option
          ),
        });
      } else {
        this.setState({
          errorMessages: response.data.messages,
        });
        scrollToComponent(this.alertErrorRef, { duration: 500 });
      }
    });
  };

  typeChangeHandler = (data: any) => {
    this.setState({
      subTypeValue: data.value,
      subTypeObj: data,
      typeObjError: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { activeAccountUUID } = this.context;
    const {
      subTypeValue,
      docTypeSelectedValue,
      titleValue,
      certificateType,
    } = this.state;
    let isError = false;

    this.setState({
      typeObjError: false,
      docTypeSelectedValueError: false,
      titleValueError: false,
    });

    if (docTypeSelectedValue.trim() === null || docTypeSelectedValue.trim() === '') {
      this.setState({
        docTypeSelectedValueError: true,
      });
      isError = true;
    }

    if (
      docTypeSelectedValue === 'license' ||
      docTypeSelectedValue === 'insurance' ||
      docTypeSelectedValue === 'registration' ||
      docTypeSelectedValue === 'security_check'
    ) {
      if (!this.state.inputControl) {
        if (subTypeValue.trim() === null || subTypeValue.trim() === '') {
          this.setState({
            typeObjError: true,
          });
          isError = true;
        }
      }
    }

    if (certificateType && !this.state.inputControl) {
      if (titleValue.trim() === null || titleValue.trim() === '') {
        this.setState({
          titleValueError: true,
        });
        isError = true;
      }
    }

    if (errors.length > 0) {
      isError = true;
    }

    if (isError) {
      return;
    }

    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    let roleUuid = this.props.match.params.uuid;

    const title = certificateType
      ? this.state.inputControl
        ? values.certificateTitle
        : this.state.titleValue
      : values.title;

    Roles.insertRoleDocument(
      activeAccountUUID,
      docTypeSelectedValue,
      subTypeValue,
      title,
      roleUuid
    )
      .then((response) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          let JSONdata = {
            document_type: docTypeSelectedValue,
            type: subTypeValue,
            title: title,
            uuid: response.data.data.document.uuid,
            optional: 0,
          };
          this.toggleFunModal();
          this.props.addDocTolist(JSONdata);
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  toggleFunModal = () => {
    this.setState({
      docTypeObj: null,
      inputControl: false,
      docTypeSelectedValue: '',
      certificateType: false,
      subTypeObj: null,
      certificateValueObj: null,
      titleValue: '',
    });
    this.props.toggleFun();
  };

  controlInputHandler = () => {
    this.setState({
      inputControl: !this.state.inputControl,
      certificateValueObj: null,
      titleValue: '',
    });
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.toggleFunModal()}
        className="modal--medium modal--medium-md"
        data-bg="grey"
        backdrop={true}
      >
        <ModalHeader
          className="start u-border--btm0 plr--45 plr--45-sm"
          toggle={() => this.toggleFunModal()}
        >
          <span className="fs--30 fs--30-sm">Add Requirement</span>
        </ModalHeader>
        <ModalBody className="plr--30 plr--30-sm" style={{ paddingTop: '10px' }}>
          <div className="u-flexed fullwidth u-mb--20">
            <Container>
              <Row>
                <Col xs={12} md={12} lg={12}>
                  <div className="column--left__inner column--left__inner-sm">
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5"></div>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />
                    <AvForm
                      id="docForm"
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <FormGroup
                        className={`${
                          this.state.docTypeSelectedValueError
                            ? `form-group--has-error`
                            : ``
                        }`}
                      >
                        <Label htmlFor="DocumentType">Select document type</Label>
                        <Select
                          autoFocus
                          name="DocumentType"
                          id="DocumentType"
                          options={this.props.documentTypeOptions}
                          placeholder="Select Document Type"
                          tabSelectsValue={false}
                          value={this.state.docTypeObj}
                          onChange={this.docTypeHandler}
                        />
                        <span className="help-block">Please select document type</span>
                      </FormGroup>
                      {(this.state.docTypeSelectedValue === 'license' ||
                        this.state.docTypeSelectedValue === 'insurance' ||
                        this.state.docTypeSelectedValue === 'registration' ||
                        this.state.docTypeSelectedValue === 'security_check') && (
                        <FormGroup
                          className={`${
                            this.state.typeObjError ? `form-group--has-error` : ``
                          }`}
                        >
                          <Label htmlFor="type">Type</Label>
                          <Select
                            name="type"
                            id="type"
                            options={this.state.typeOptions}
                            placeholder="Select Type"
                            tabSelectsValue={false}
                            value={this.state.subTypeObj}
                            onChange={this.typeChangeHandler}
                          />
                          <span className="help-block">Please select type</span>
                        </FormGroup>
                      )}
                      {this.state.certificateType ? (
                        this.state.showLoader ? (
                          <p className="inlineloading">
                            <strong>Please wait it's loading</strong>
                          </p>
                        ) : this.state.inputControl ? (
                          <AvField
                            name="certificateTitle"
                            id="certificateTitle"
                            label="Title"
                            required
                            errorMessage="Please enter Title"
                          />
                        ) : (
                          <FormGroup
                            className={`${
                              this.state.titleValueError ? `form-group--has-error` : ``
                            }`}
                          >
                            <Label htmlFor="title">Title</Label>
                            <Select
                              name="title"
                              id="title"
                              options={this.state.certificateOptions}
                              placeholder="Select Certificate"
                              tabSelectsValue={false}
                              value={this.state.certificateValueObj}
                              onChange={this.certificateChangeHandler}
                            />
                            <span className="help-block">Please select certificate</span>
                            <p
                              className="btn-link create-custom-control text-right ml-auto u-mt--5"
                              onClick={this.controlInputHandler}
                            >
                              Cannot Find? Click here to custom enter
                            </p>
                          </FormGroup>
                        )
                      ) : (
                        <AvField
                          name="title"
                          id="title"
                          label="Title"
                          required
                          errorMessage="Please enter Title"
                        />
                      )}

                      <div className="u-mt--30">
                        <button
                          type="submit"
                          disabled={this.state.formSubmitted}
                          className={`c-button c-button--primary c-button-w180 c-button-radius c-button-sm u-upper has--centered ${
                            this.state.formSubmitted ? `inlineloading` : ``
                          }`}
                        >
                          Submit
                        </button>
                      </div>
                    </AvForm>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default withRouter(DocumentModal);
