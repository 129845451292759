import Api from './Api';

export default class Roles extends Api {
  static getRoles = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/role/get-roles', postData);
  };

  static getCertificates = async (
    accountUuid: string,
    industry: string,
    position: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      industry: industry,
      position: position,
    };

    return await Api.postRequest('/general/get-certificates', postData);
  };

  static getIndustryTypes = async (): Promise<any> => {
    return await Api.postRequest('/general/get-industries');
  };

  static insertRoles = async (
    accountUuid: string,
    rolename: string,
    industry: string,
    position: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_name: rolename,
      industry,
      position,
    };

    return await Api.postRequest('/role/create-role', postData);
  };

  static getRole = async (
    accountUuid: string,
    roleUuid: string,
    loadIndusties: boolean = false
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid: roleUuid,
      load_industries: loadIndusties,
    };

    return await Api.postRequest('/role/get-role', postData);
  };

  static updateRole = async (
    accountUuid: string,
    role_uuid: string,
    rolename: string,
    industry: string,
    position: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid,
      role_name: rolename,
      industry,
      position,
    };

    return await Api.postRequest('/role/edit-role', postData);
  };

  static insertRoleDocument = async (
    accountUuid: string,
    documentType: string,
    type: string,
    title: string,
    role_uuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      document_type: documentType,
      type,
      title,
      role_uuid,
    };

    return await Api.postRequest('/role/add-document', postData);
  };

  static updateDocumentOption = async (
    accountUuid: string,
    role_uuid: string,
    document_uuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid,
      document_uuid,
    };

    return await Api.postRequest('/role/toggle-document-optional-status', postData);
  };

  static deleteDcoument = async (
    accountUuid: string,
    role_uuid: string,
    document_uuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid,
      document_uuid,
    };

    return await Api.postRequest('/role/delete-document', postData);
  };

  static updateActiveStatus = async (
    accountUuid: string,
    role_uuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid,
    };

    return await Api.postRequest('/role/role-active-toggle', postData);
  };

  static deleteRole = async (accountUuid: string, role_uuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      role_uuid,
    };

    return await Api.postRequest('/role/delete', postData);
  };
}
