import React from 'react';
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css';

interface Props {}
interface State {
  swiper: any;
  updateSwiper: any;
}

export default class CustomSwiper extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      swiper: null,
      updateSwiper: null,
    };
  }

  params = {
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true,
    },
    direction: 'vertical',
    slidesPerView: 5,
    paginationClickable: true,
    spaceBetween: 15,
    mousewheelControl: true,
    parallax: true,
    speed: 600,
    centeredSlides: true,
  };

  render() {
    return (
      <>
        <Swiper {...this.params} activeSlideKey="Slide3">
          <div key="Slide1">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Building
          </div>
          <div key="Slide2">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Nursing
          </div>
          <div key="Slide3">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Homecare
          </div>
          <div key="Slide4">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Plumbing
          </div>
          <div key="Slide5">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Roofing
          </div>
          <div key="Slide6">
            <span className="size--30">
              <svg
                className="MuiSvgIcon-root jss729"
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
              >
                <path d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"></path>
              </svg>
            </span>
            Doctors
          </div>
        </Swiper>
      </>
    );
  }
}
