import React from 'react';
import ReactWOW from 'react-wow';

// Import CSS

// Static Assets
import Invite2Connect from '../../../assets/images/icons/Invite2Connect.svg';
import AcceptConnection from '../../../assets/images/icons/AcceptConnection.svg';
import GainVisability from '../../../assets/images/icons/GainVisability.svg';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

interface SectionHowItWorksProps {}
interface SectionHowItWorksState {}

class SectionHowItWorks extends React.Component<
  SectionHowItWorksProps,
  SectionHowItWorksState
> {
  constructor(props: SectionHowItWorksProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="section--corporate section--corporate-sm" data-bg={'white'}>
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} xl="11" className="col-centered text-center">
                <ReactWOW animation="fadeInUp" delay="200ms">
                  <div className="page-head page-head-sm noborder center u-mb--50 u-mb--50-sm">
                    <h1 className="u-heading--large u-heading--large-sm text-black u-mb--0">
                      <strong>Gain full visibility of your team</strong>
                    </h1>
                  </div>
                </ReactWOW>

                <ul className="work--lists">
                  <ReactWOW animation="fadeInLeft" delay="300ms">
                    <li className="work--lists__items work--lists__items-sm">
                      <div className="work--lists__inner work--lists__inner-sm">
                        <div className="work--lists__Icons work--lists__Icons-sm">
                          <img
                            src={Invite2Connect}
                            alt="Invite to connect"
                            className="img-fluid"
                          />
                        </div>
                        <div className="work--lists__details work--lists__details-sm">
                          <h4>Invite</h4>
                          <p className="fs--18 fs--18-sm">
                            Send an email invitation to your employees or subcontractors
                            to connect their account to your company account.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInUp" delay="400ms">
                    <li className="work--lists__items work--lists__items-sm spacer">
                      <div className="work--lists__inner work--lists__inner-sm">
                        <div className="work--lists__Icons work--lists__Icons-sm">
                          <img
                            src={AcceptConnection}
                            alt="Accept connection"
                            className="img-fluid"
                          />
                        </div>
                        <div className="work--lists__details work--lists__details-sm">
                          <h4>Accept connection</h4>
                          <p className="fs--18 fs--18-sm">
                            The employee or sub-contractor accepts the connection
                            invitation and joins your team.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>

                  <ReactWOW animation="fadeInRight" delay="500ms">
                    <li className="work--lists__items work--lists__items-sm">
                      <div className="work--lists__inner work--lists__inner-sm">
                        <div className="work--lists__Icons work--lists__Icons-sm">
                          <img
                            src={GainVisability}
                            alt="Gain visability"
                            className="img-fluid"
                          />
                        </div>
                        <div className="work--lists__details work--lists__details-sm">
                          <h4>Gain visibility</h4>
                          <p className="fs--18 fs--18-sm">
                            Once connected, you can view and track their compliance
                            documentation and requirements.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ReactWOW>
                </ul>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionHowItWorks;
