import React from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { Link } from 'react-router-dom';

// Application Imports;
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';

// Import Icons;
import Check from '@material-ui/icons/Check';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface Props {
  dataList: Array<any>;
  columnListingType: string;
}

interface State {}

class ColumnListingMarkup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="board--canvas__coloumn">
        <div className="board--canvas__widget">
          <div className="board--canvas__widget__content">
            <div className="board--canvas__widget__content--body">
              <div className="board--canvas__widget__content--body--scroll">
                <div className="custom--search">
                  <div className="custom--search__lists">
                    <ListGroup>
                      {this.props.dataList.map((data: any) => (
                        <Link
                          to={`/corporate/team/member-documents/${data.account_data.accountUuid}`}
                          key={data.account_data.uuid}
                        >
                          <ListGroupItem
                            className={`u-mb--10 ${GetAccountDocumentStatusClassName(
                              data.account_data.accountDocumentStatus,
                              false
                            )}`}
                          >
                            <>
                              <div className="avatar--40">
                                <img
                                  src={
                                    data.account_data.profileImageUrl
                                      ? data.account_data.profileImageUrl
                                      : ProfileAvatar
                                  }
                                  alt="Profile"
                                />
                              </div>
                              <div className="list-group--details">
                                <div className="u-flexed u-align-center">
                                  <div className="">
                                    <p className="user--name">{data.account_data.name}</p>
                                    <p className="user--email">
                                      {data.account_data.role}
                                      <span className="badge badge-primary u-ml--5">
                                        {data.account_data.totalAssignedProjects} Project
                                      </span>
                                    </p>
                                  </div>
                                  {this.props.columnListingType === 'compliant' && (
                                    <div className="ml-auto">
                                      <div className="checkmark">
                                        <Check
                                          fontSize="small"
                                          className="icons text-white"
                                        />
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          </ListGroupItem>
                        </Link>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ColumnListingMarkup;
