import React from 'react';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row, Col, Card, Button, Label, FormGroup } from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';

//Application Imports
import appConfig from '../../config';
import AppContext from '../../components/AppContext';
import AlertError from '../../layout/includes/Alert/Error';
import AuthPageWrapper from '../common/AuthPageWrapper';
import { getAccountDefaultRoute } from '../../helpers/Helpers';
import LoginSignup from '../../api/LoginSignup';

// Import Axios instance
import { AxiosResponse } from 'axios';

// Import CSS
import './login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';
import LocalStorage from '../../helpers/LocalStorage';

interface Props extends RouteComponentProps {}

interface State {
  username: string;
  password: string;
  hidePassword: boolean;
  submitFormRequest: boolean;
  alertErrorMessage: Array<string> | string;
  validated: boolean;
}

class Login extends React.PureComponent<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      username: localStorage.getItem('emailRemember') || '',
      password: '',
      hidePassword: false,
      submitFormRequest: false,
      alertErrorMessage: '',
      validated: false,
    };
  }

  componentDidMount() {
    document.title = `Login ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  handlePasswordToggle = () => {
    this.setState({
      hidePassword: !this.state.hidePassword,
    });
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>, values: any) => {
    const { login } = this.context;
    // loader set to true and error message set to blank;
    this.setState({
      submitFormRequest: true,
      alertErrorMessage: '',
    });

    //sending a post request to the server
    LoginSignup.validateLogin(values.username, values.password)
      .then((response: AxiosResponse) => {
        //disabling the spinner
        this.setState({
          submitFormRequest: false,
        });

        if (response.data.success === 1) {
          this.setState({
            validated: true,
          });
          // set/update the userInput value to localstorage
          if (values.rememberMe) {
            localStorage.setItem('emailRemember', values.username);
          } else {
            localStorage.removeItem('emailRemember');
          }

          let redirectUrl = LocalStorage.getLoginRedirectRoute(true);

          if (!redirectUrl && response.data.data.accountsList.length > 1) {
            redirectUrl = getAccountDefaultRoute('', true);
          }

          if (!redirectUrl) {
            redirectUrl = getAccountDefaultRoute(
              response.data.data.accountsList[0].account_type,
              false,
              response.data.data.accountsList[0].user_role
            );
          }

          //login the user
          login(response.data.data);

          //redirect to appropriate route
          this.props.history.push(redirectUrl);
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          submitFormRequest: false,
        });
      });
  };

  userNameChangeHandler = (e: any) => {
    this.setState({
      username: e.target.value,
    });
  };

  passwordChangeHandler = (e: any) => {
    this.setState({
      password: e.target.value,
    });
  };

  render() {
    const model = {
      rememberMe: localStorage.getItem('emailRemember') !== null ? true : false,
    };

    return (
      <AuthPageWrapper>
        <Card className="card card-sm">
          <div className="card--header card--header-sm">
            <h2 className="card--title card--title-sm">Sign in</h2>
          </div>
          <div className="card--body card--body-sm">
            <AlertError message={this.state.alertErrorMessage} />
            <AvForm
              className="form-horizontal"
              onValidSubmit={this.handleFormSubmit}
              autoComplete="off"
              model={model}
            >
              <AvField
                type="text"
                name="username"
                id="username"
                value={this.state.username}
                label="Email or CRN"
                required
                errorMessage="Enter Email Address or CRN"
                onChange={this.userNameChangeHandler}
              />

              <AvGroup>
                <Label for="password">Password</Label>
                <AvInput
                  type={!this.state.hidePassword ? 'password' : 'text'}
                  name="password"
                  id="password"
                  required
                  className="form-control is--pass"
                  minLength={4}
                  onChange={this.passwordChangeHandler}
                />
                <button
                  type="button"
                  onClick={this.handlePasswordToggle}
                  className="pass--toggler"
                >
                  {this.state.hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </button>
                <AvFeedback>Enter Password</AvFeedback>
              </AvGroup>

              <FormGroup>
                <Row className="u-mb--30 u-flexed u-align-center u-justify-btw">
                  <Col xs={12} md={6} lg={6} className="mobile--50">
                    <Label check className="custom-toggle--label">
                      <div className="custom-toggle">
                        <AvInput type="checkbox" name="rememberMe" trueValue={true} />
                        <span className="custom-toggle-slider" />{' '}
                        <span className="custom-toggle--text">Remember me</span>
                      </div>
                    </Label>
                  </Col>
                  <Col xs={12} md={6} lg={6} className="mobile--50 text-right">
                    <Link to="/auth/forgot-password" className="links--primary forget">
                      Forgot your password?
                    </Link>
                  </Col>
                </Row>
              </FormGroup>

              <Row className="u-mt--30 u-mb--20">
                <Col
                  xs={12}
                  md={12}
                  lg={12}
                  className="u-flexed u-align-center u-justify-center"
                >
                  <Button
                    color="primary"
                    disabled={this.state.submitFormRequest ? true : false}
                    className={`btn-radius btn-w210 ${
                      this.state.submitFormRequest ? `inlineloading` : ``
                    }`}
                    id="submit"
                  >
                    SiGN IN
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </div>
        </Card>

        <div className="u-flexed u-align-center u-justify-center u-flex-direction-column space--around">
          <Col xs={12} md={12} lg={12} className="text-center">
            <a href="/" className="links--default">
              What is Organised Work Life?
            </a>
            <a
              href={appConfig.PRIVACY_POLICY_URL}
              rel="noopener noreferrer"
              target="_blank"
              className="links--default"
            >
              Privacy Policy
            </a>
          </Col>
        </div>
      </AuthPageWrapper>
    );
  }
}

export default Login;
