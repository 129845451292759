import React from 'react';
import { Row, Col, Card, Button, FormGroup, Label } from 'reactstrap';
import InputMask from 'react-input-mask';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { AxiosResponse } from 'axios';

// Application Import
import axiosInstance from '../../../utils/AppAxios';
import AlertError from '../../../layout/includes/Alert/Error';
import AppContext from '../../../components/AppContext';

// Import CSS & static assets
import '../../auth/login.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

// Imports Images;
import Lock from '@material-ui/icons/Lock';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ChargebeeLogo from '../../../assets/images/chargebee.svg';
import VisaLogo from '../../../assets/images/icons/cards/visa.svg';
import MasterCardLogo from '../../../assets/images/icons/cards/master-card.svg';
import AmexLogo from '../../../assets/images/icons/cards/amex.svg';

interface Props extends RouteComponentProps {
  accountType: string;
  updateMsgDivState: Function;
  prevStep: Function;
}

interface State {
  InputMask: boolean;
  nameOnCard: string;
  nameOnCardError: boolean;
  cardNumber: string;
  cardNumberError: boolean;
  expiry: string;
  expiryError: boolean;
  cvv: string;
  cvvError: boolean;
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
}

class AddCompanyPayment extends React.PureComponent<Props, State> {
  static contextType = AppContext;

  constructor(props: Props) {
    super(props);
    this.state = {
      InputMask: false,
      nameOnCard: '',
      nameOnCardError: false,
      cardNumber: '',
      cardNumberError: false,
      expiry: '',
      expiryError: false,
      cvv: '',
      cvvError: false,
      formSubmitted: false,
      alertErrorMessage: '',
    };
  }

  changeHandlerName = (e: any) => {
    this.setState({
      nameOnCard: e.target.value,
      nameOnCardError: false,
    });
  };

  changeHandlerCardNumber = (e: any) => {
    this.setState({
      cardNumber: e.target.value,
      cardNumberError: false,
    });
  };

  changeHandlerExpiry = (e: any) => {
    this.setState({
      expiry: e.target.value,
      expiryError: false,
    });
  };

  changeHandlerCvv = (e: any) => {
    this.setState({
      cvv: e.target.value,
      cvvError: false,
    });
  };

  handleCreditCardFormSubmit = (e: any, values: any) => {
    const { nameOnCard, cvv, expiry, cardNumber } = this.state;
    let isError = false;

    this.setState({
      nameOnCardError: false,
      cardNumberError: false,
      expiryError: false,
      cvvError: false,
    });

    if (nameOnCard.trim() === null || nameOnCard.trim() === '') {
      this.setState({
        nameOnCardError: true,
      });
      isError = true;
    }

    if (cardNumber.trim() === null || cardNumber.trim() === '') {
      this.setState({
        cardNumberError: true,
      });
      isError = true;
    }

    if (expiry.trim() === null || expiry.trim() === '') {
      this.setState({
        expiryError: true,
      });
      isError = true;
    }

    if (cvv.trim() === null || cvv.trim() === '') {
      this.setState({
        cvvError: true,
      });
      isError = true;
    }

    if (isError) {
      return;
    }

    const creditCardData = {
      name_on_card: nameOnCard,
      cvv: cvv,
      expiry: expiry,
      card_number: cardNumber,
    };

    let localstorageDataKey = 'newCompanyData';

    const storedData = JSON.parse(localStorage.getItem(localstorageDataKey) || '');
    const requestData = { ...creditCardData, ...storedData };

    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    //sending a post request to the server
    axiosInstance
      .post('/user/create-company', requestData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          localStorage.removeItem(localstorageDataKey);

          //updating the accounts list in state and local storage
          const { setAccountsList } = this.context;
          setAccountsList(response.data.data.accountsList);

          //time to show thank you screen
          this.props.updateMsgDivState();
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  render() {
    return (
      <Card className="card card-sm">
        <div className="card--header card--header-sm u-mb--0">
          <h2 className="card--title card--title-sm">Enter Credit Card</h2>
        </div>
        <div className="card--body card--body-sm" style={{ paddingBottom: '20px' }}>
          <AlertError message={this.state.alertErrorMessage} />
          <div className="choose--plan choose--plan-sm text-center">
            <p className="fs--15 dark-gray">
              Add your credit card details below to continue
            </p>
            <div className="choose--plan__inner choose--plan__inner-sm">
              <span className="active--plan">$55 - Annually</span>{' '}
            </div>
          </div>
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleCreditCardFormSubmit}
            autoComplete="off"
          >
            <AvField
              name="cardname"
              label="Name on card"
              id="cardname"
              value={this.state.nameOnCard}
              onChange={this.changeHandlerName}
              required
              errorMessage="Please enter Name on card"
            />

            <FormGroup
              className={this.state.cardNumberError ? 'form-group--has-error' : ''}
            >
              <Label for="cardnumber">Card Number</Label>
              <InputMask
                type="text"
                name="cardnumber"
                id="cardnumber"
                className="form-control"
                mask="9999-9999-9999-9999"
                maskChar=" "
                value={this.state.cardNumber}
                onChange={this.changeHandlerCardNumber}
              />
              <span className="help-block">Card Number is required.</span>
            </FormGroup>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="mobile--50 mobile-mb--20">
                <FormGroup
                  className={this.state.expiryError ? 'form-group--has-error' : ''}
                >
                  <Label for="cardexpiry">Expiry</Label>
                  <InputMask
                    type="text"
                    name="cardexpiry"
                    id="cardexpiry"
                    className="form-control"
                    placeholder="MM/YY"
                    mask="99/99"
                    maskChar=" "
                    value={this.state.expiry}
                    onChange={this.changeHandlerExpiry}
                  />
                  <span className="help-block">Expiry is required.</span>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mobile--50">
                <FormGroup className={this.state.cvvError ? 'form-group--has-error' : ''}>
                  <Label for="cardcvv">CVV</Label>
                  <InputMask
                    type="text"
                    name="cardcvv"
                    id="cardcvv"
                    className="form-control"
                    mask="999"
                    maskChar=" "
                    value={this.state.cvv}
                    onChange={this.changeHandlerCvv}
                  />
                  <span className="help-block">CVV is required.</span>
                </FormGroup>
              </Col>
            </Row>

            <Row className="u-mt--20 u-mb--20">
              <Col
                xs={12}
                md={12}
                lg={12}
                className="u-flexed u-align-center u-justify-btw"
              >
                <Button
                  type="submit"
                  color="primary"
                  className="btn-radius has-icon--before"
                  onClick={() => this.props.prevStep()}
                >
                  <KeyboardBackspaceIcon fontSize="small" className="icons u-mr--5" />
                  Back
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  className={`btn-radius btn-w210 has-icon--before ${
                    this.state.formSubmitted ? `inlineloading` : ``
                  }`}
                  id="addCompany"
                >
                  <Lock fontSize="small" className="icons u-mr--5" />
                  Add New Company
                </Button>
              </Col>
            </Row>
          </AvForm>
        </div>
        <footer className="card--footer card--footer-sm">
          <div className="u-flexed u-align-center u-justify-btw">
            <div className="owl--powered">
              <span className="text">POWERED BY</span>{' '}
              <span className="owl--powered__logo u-ml--10">
                <img src={ChargebeeLogo} className="image-fluid" alt="Chargebee" />
              </span>
            </div>
            <div className="owl--cards">
              <img
                src={MasterCardLogo}
                alt="Master Card"
                className="img-fluid u-ml--10"
              />{' '}
              <img src={VisaLogo} alt="Visa" className="img-fluid u-ml--10" />{' '}
              <img src={AmexLogo} alt="American Express" className="img-fluid u-ml--10" />
            </div>
          </div>
        </footer>
      </Card>
    );
  }
}

export default withRouter(AddCompanyPayment);
