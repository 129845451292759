import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Import CSS
import './404.scss';
import LogoBlack from '../../assets/images/owl-logo-large-black.svg';

interface NotFoundProps extends RouteComponentProps<any> {}

interface NotFoundState {
  pageTitle: string;
}

class NotFound extends React.Component<NotFoundProps, NotFoundState> {
  constructor(props: NotFoundProps) {
    super(props);
    this.state = {
      pageTitle: this.props.match.params.string,
    };
  }

  private myRef = React.createRef<HTMLButtonElement>();

  focus = () => {
    //alert('Click');
    this.myRef.current?.focus();
  };

  render() {
    // console.log(this.props);
    return (
      <>
        <section className="Owl__404 Owl__404-sm">
          <div className="logo logo-md">
            <a href="/">
              <img src={LogoBlack} className="image-fluid app--logo" alt="OWL" />
            </a>
          </div>
          <Container fluid={false} className="">
            <Row>
              <Col xs={12} md={12} lg={11} className="col-centered">
                <div className="info info-sm">
                  <h1 className="title title-sm">
                    We are looking for your page, but we can’t find it.
                  </h1>
                  <p className="fs20 fs20-sm">
                    The page you’re looking is not avaliable or may not exist anymore.
                  </p>
                  <Link
                    className="c-button c-button--primary c-button-inline c-button-sm c-button-radius"
                    to="/"
                  >
                    BACK GO TO HOME
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default NotFound;
