import React from 'react';
import ReactWOW from 'react-wow';

// Static Assets
import CustomSwiper from './CustomSwiper';

import { Container, Row, Col } from 'reactstrap';

interface SectionPerfectFitProps {}
interface SectionPerfectFitState {}

class SectionPerfectFit extends React.Component<
  SectionPerfectFitProps,
  SectionPerfectFitState
> {
  constructor(props: SectionPerfectFitProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="section--industry section--industry-sm" data-bg={'gray'}>
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={9} lg={9} className="col-centered">
                <ReactWOW animation="fadeInUp" delay="200ms">
                  <div className="page-head page-head-sm noborder center u-mb--50 u-mb--50-sm">
                    <h3 className="u-heading--large u-heading--large-sm text-black u-mb--0">
                      <strong>Perfect fit for any industry</strong>
                    </h3>
                  </div>
                </ReactWOW>
                <div className="he400">
                  <CustomSwiper />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionPerfectFit;
