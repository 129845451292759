import React from 'react';
import { UncontrolledAlert } from 'reactstrap';

type Props = {
  message: Array<string> | string;
};

interface State {}

class AlertError extends React.PureComponent<Props, State> {
  render() {
    let messages: Array<string> = [];
    if (!Array.isArray(this.props.message) && this.props.message !== '') {
      messages = [this.props.message];
    }
    if (Array.isArray(this.props.message)) {
      messages = this.props.message;
    }
    return messages.length > 0 ? (
      <UncontrolledAlert color="danger">
        {messages.length > 1
          ? messages.map((err: string, id: any) => <li key={id}>{err}</li>)
          : messages.map((err: string) => err)}
      </UncontrolledAlert>
    ) : (
      ''
    );
  }
}

export default AlertError;
