import Api from './Api';

export default class Document extends Api {
  static getAccountDocuments = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/document/get-account-documents', postData);
  };

  static getTypes = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return await Api.postRequest('/general/get-document-types', postData);
  };

  static deleteDocument = async (
    accountUuid: string,
    documentUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      document_uuid: documentUuid,
    };

    return await Api.postRequest('/document/delete-document', postData);
  };

  static getDocumentData = async (
    accountUuid: string,
    documentUuid: string | undefined
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      document_uuid: documentUuid,
    };

    return await Api.postRequest('/document/get-document', postData);
  };

  static getDocumentRevisions = async (
    accountUuid: string,
    documentUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      document_uuid: documentUuid,
    };

    return await Api.postRequest('/document/get-document-revisions', postData);
  };

  static getRoleAttachedDocumentList = async (
    accountUuid: string,
    documentUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      document_uuid: documentUuid,
    };

    return await Api.postRequest('/document/get-document-attached-roles', postData);
  };
}
