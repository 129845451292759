import React from 'react';
import { Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import ReactFilestack from 'filestack-react';
import Lightbox from 'react-image-lightbox';

//Application Imports
import appConfig from '../../../config';
import LocalStorage from '../../../helpers/LocalStorage';

// Css
import 'react-image-lightbox/style.css';

// Import Icons
import Delete from '@material-ui/icons/Delete';
import Search from '@material-ui/icons/Search';

interface Props extends RouteComponentProps {
  getMediaArrayFun: Function;
  mediaArray?: Array<any>;
}

interface FileStackProps {
  onPick: () => void;
}

interface State {
  modal: boolean;
  imagePreviewUrl: string;
  lightboxImagePreviewUrl: string;
  mediaDetailsArray: Array<any>;
  activeElementHandle: string;
  isPreviewOpen: boolean;
  deleteImageIndex: string;
}

class UploadImages extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      modal: false,
      imagePreviewUrl: '',
      lightboxImagePreviewUrl: '',
      mediaDetailsArray: [],
      activeElementHandle: '',
      isPreviewOpen: false,
      deleteImageIndex: '',
    };
  }

  deleteModalToggle = (index: string) => {
    this.setState({
      modal: !this.state.modal,
      deleteImageIndex: index,
    });
  };

  previewOpenHandler = () => {
    this.setState({
      isPreviewOpen: !this.state.isPreviewOpen,
    });
  };

  imagePreview = (element: any) => {
    this.setState({
      activeElementHandle: element.target.dataset.id,
    });
  };

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      this.state.activeElementHandle !== prevState.activeElementHandle &&
      this.state.activeElementHandle !== ''
    ) {
      const activeElementMediaIndex = this.state.mediaDetailsArray.findIndex(
        (x) => x.handle === this.state.activeElementHandle
      );
      const activeElementMediaDetails = this.state.mediaDetailsArray[
        activeElementMediaIndex
      ];
      //console.log(activeElementMediaDetails);

      this.setState({
        imagePreviewUrl: LocalStorage.getFilestackTransformedUrl(
          this.state.activeElementHandle,
          'resize=width:620,height:350',
          activeElementMediaDetails
        ),
        lightboxImagePreviewUrl: LocalStorage.getFilestackTransformedUrl(
          this.state.activeElementHandle,
          '',
          activeElementMediaDetails
        ),
      });
    }

    if (this.state.mediaDetailsArray.length !== prevState.mediaDetailsArray.length) {
      this.props.getMediaArrayFun(this.state.mediaDetailsArray);
    }

    if (prevProps.mediaArray !== this.props.mediaArray && this.props.mediaArray) {
      let newMediaDetailsArray = this.props.mediaArray.map((media) => {
        return {
          uuid: media.uuid,
          handle: media.handle,
          mimetype: media.mimetype,
          size: media.size,
          filename: media.filename,
        };
      });

      this.setState({
        mediaDetailsArray: newMediaDetailsArray,
        activeElementHandle: newMediaDetailsArray[0].handle,
      });
    }
  }

  addImageUrl = (urlArray: any) => {
    if (urlArray.filesUploaded.length > 0) {
      this.setState((prevState) => ({
        mediaDetailsArray: [...prevState.mediaDetailsArray, urlArray.filesUploaded[0]],
        activeElementHandle:
          prevState.activeElementHandle === ''
            ? urlArray.filesUploaded[0].handle
            : prevState.activeElementHandle,
      }));
    }
  };

  removeImageFromArray = () => {
    const mediaDetailsArray = this.state.mediaDetailsArray.filter((media) => {
      return media.handle !== this.state.deleteImageIndex;
    });

    this.setState({ mediaDetailsArray });

    // Close modal;
    this.deleteModalToggle('');
  };

  openFilestackHandler = () => {
    let refElement: any = document.getElementById('upload-doc');
    refElement.click();
  };

  render() {
    const filestackLSObject = LocalStorage.getFilestackObject();
    const mediaDetailsArray = this.state.mediaDetailsArray;
    const activeElementHandle = this.state.activeElementHandle;

    return (
      <Col xs={12} md={6} lg={6} className="column--right">
        <div className="column--right__inner column--right__inner-sm">
          <figure className="Document__Uploaded--Graphics">
            {this.state.imagePreviewUrl ? (
              <>
                <span className="btn-zoom" onClick={this.previewOpenHandler}>
                  <Search fontSize="large" className="icons" />
                </span>
                <img
                  src={this.state.imagePreviewUrl}
                  alt="Upload"
                  className="img-fluid"
                />
              </>
            ) : (
              <p>
                Please upload image to see preview.
                <strong className="add-cursor" onClick={this.openFilestackHandler}>
                  &nbsp;Click here to upload.
                </strong>
              </p>
            )}
          </figure>
          <ul className="Document--Controls Document--Controls-sm">
            {mediaDetailsArray.map((media: any) => (
              <li
                className={
                  'Document--Controls--items ' +
                  (activeElementHandle === media.handle ? 'active' : '')
                }
                key={media.handle}
              >
                <button
                  type="button"
                  className="btn-delete"
                  onClick={() => this.deleteModalToggle(media.handle)}
                >
                  <Delete fontSize="small" className="icons" />
                </button>
                <img
                  src={LocalStorage.getFilestackTransformedUrl(
                    media.handle,
                    'resize=width:150,height:100',
                    media
                  )}
                  alt="Document"
                  className="img-fluid"
                  onClick={this.imagePreview}
                  data-id={media.handle}
                />
              </li>
            ))}
            <li className="Document--Controls--items handler">
              <ReactFilestack
                apikey={appConfig.FILESTACK_API_KEY}
                actionOptions={{
                  accept: ['image/*', '.pdf', '.doc', '.docx'],
                }}
                clientOptions={{
                  security: {
                    policy: filestackLSObject.policy,
                    signature: filestackLSObject.signature,
                  },
                }}
                customRender={({ onPick }: FileStackProps) => (
                  <button type="button" onClick={onPick} id="upload-doc">
                    + Upload
                  </button>
                )}
                onSuccess={(res: any) => this.addImageUrl(res)}
              />
            </li>
          </ul>
        </div>
        <Modal
          isOpen={this.state.modal}
          toggle={() => this.deleteModalToggle('')}
          className="modal--medium modal--medium-md"
          backdrop={true}
        >
          <ModalHeader
            className="noStyle"
            toggle={() => this.deleteModalToggle('')}
          ></ModalHeader>
          <ModalBody
            className="plr--30 plr--30-sm text-center"
            style={{ maxHeight: 'inherit' }}
          >
            <div className="modal-body--inner modal-body--inner-sm">
              <p>Do you really want to delete this image?</p>
              <button
                onClick={this.removeImageFromArray}
                type="button"
                className="c-button c-button--primary c-button-inlineflex c-button-w180 c-button-radius c-button-sm u-upper"
              >
                Confirm
              </button>
            </div>
          </ModalBody>
        </Modal>
        {this.state.isPreviewOpen && (
          <Lightbox
            mainSrc={this.state.lightboxImagePreviewUrl}
            onCloseRequest={this.previewOpenHandler}
          />
        )}
      </Col>
    );
  }
}

export default withRouter(UploadImages);
