import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  profilePreviewHandler: Function;
}

interface State {}

class Breadcrumb extends React.PureComponent<Props, State> {
  render() {
    return (
      <div>
        <div className="c-breadcrumb--section c-breadcrumb--section-sm">
          <Container fluid className="container-fluid-sm">
            <Row className="u-flexed u-align-center u-justify-btw">
              <Col xs={12} sm={12} md={12} lg={12}>
                <div className="u-flexed u-align-center u-justify-start">
                  <Button
                    className="c-button--Back fs--16 u-mr--20"
                    onClick={() => this.props.profilePreviewHandler()}
                  >
                    <i className="arrow left" /> Back
                  </Button>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

export default withRouter(Breadcrumb);
