import React from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import { toast } from 'react-toastify';
import { AxiosResponse } from 'axios';

//Application Imports;
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import DocumentList from './DocumentList';
import BlankDocumentScreen from './BlankDocumentScreen';
import Document from '../../../api/Document';
import { accountType } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';
import Breadcrumb from '../../soletrader/profile/Breadcrumb';

// Import CSS
import './upload.scss';

// Static Assets
import Lock from '@material-ui/icons/Lock';

interface Props {}

interface State {
  showLoader: boolean;
  documentList: any;
  hideMessageDiv: boolean;
  documentListShow: boolean;
}

class DocumentView extends React.Component<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      documentList: {},
      hideMessageDiv: true,
      documentListShow: false,
    };
  }

  checkAccountUpdateStatus = (accountData: any) => {
    if (accountData.account_type === accountType.personal) {
      return accountData.personal_citizenship === null ? false : true;
    } else {
      return accountData.services.length < 1 ? false : true;
    }
  };

  fetchDocuments = () => {
    const { activeAccountUUID } = this.context;

    Document.getAccountDocuments(activeAccountUUID).then((response: AxiosResponse) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          documentList:
            response.data.data.document_list !== null
              ? response.data.data.document_list
              : {},
          documentListShow: response.data.data.document_list !== null ? true : false,
          hideMessageDiv: this.checkAccountUpdateStatus(response.data.data.account),
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Documentation ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // Fetch the account document;
    this.fetchDocuments();
  }

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    return (
      <>
        {activeAccount.account_type === accountType.corporate && (
          <Breadcrumb selectedArea="document" />
        )}
        <div className="u-pt--0 u-pt--0-sm">
          {!this.state.showLoader ? (
            <>
              {!this.state.hideMessageDiv ? (
                <>
                  <Alert
                    color="danger"
                    className="u-radius--0 u-border--0 text-center fs--15"
                  >
                    <Lock fontSize="small" className="icon u-mr--5 fs--20" />
                    <span className="message">
                      To unlock the ability to share your profile and documentation with
                      others you need to complete your profile
                    </span>
                  </Alert>
                </>
              ) : (
                ''
              )}
              {this.state.documentListShow ? (
                <DocumentList
                  showEditElements
                  documentList={this.state.documentList}
                  updateListData={this.fetchDocuments}
                />
              ) : (
                <BlankDocumentScreen />
              )}
            </>
          ) : (
            <Container fluid={true} className="u-pt--50 u-pt--50-sm">
              <Row>
                <Col xs={12} md={8} lg={7} className="col-centered">
                  <Loader />
                </Col>
              </Row>
            </Container>
          )}
        </div>
      </>
    );
  }
}

export default DocumentView;
