import React from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import { Row, Col, Card, Button, Label } from 'reactstrap';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';

//Application Imports
import appConfig from '../../config';
import AuthPageWrapper from '../common/AuthPageWrapper';
import AlertError from '../../layout/includes/Alert/Error';
import AlertSuccess from '../../layout/includes/Alert/Success';
import LoginSignup from '../../api/LoginSignup';

// Import Axios instance
import { AxiosResponse } from 'axios';

// Import CSS
import './login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {}

interface State {
  message: Array<string> | string;
  passwordVisible: boolean;
  conPasswordVisible: boolean;
  formSubmit: boolean;
  showFormDiv: boolean;
  token: string;
  messageError: Array<string> | string;
  messageSuccess: string;
  showThankDiv: boolean;
}

class ResetPassword extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      message: 'Please wait...',
      passwordVisible: false,
      conPasswordVisible: false,
      formSubmit: false,
      showFormDiv: false,
      token: '',
      messageError: '',
      messageSuccess: '',
      showThankDiv: false,
    };
  }

  passwordToggleHandler = () => {
    this.setState({
      passwordVisible: !this.state.passwordVisible,
    });
  };

  conPasswordToggleHandler = () => {
    this.setState({
      conPasswordVisible: !this.state.conPasswordVisible,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLFormElement>, values: any) => {
    this.setState({
      formSubmit: true,
      messageError: '',
    });

    //sending a post request to the server
    LoginSignup.resetPasswordUpdate(this.state.token, values.password)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmit: false,
        });
        if (response.data.success === 1) {
          this.setState({
            showThankDiv: true,
          });
        } else {
          this.setState({
            messageError: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          messageError: err.message,
          formSubmit: false,
        });
      });
  };

  componentDidMount() {
    let queryString = this.props.location.search;
    let token = queryString.substring(queryString.lastIndexOf('=') + 1);

    this.setState({
      token,
    });

    if (token.trim() === '' || token.trim() === null) {
      this.setState({
        message: 'Token verification failed.',
      });
    } else {
      //sending a post request to the server
      LoginSignup.validateResetToken(token)
        .then((response: AxiosResponse) => {
          if (response.data.success === 1) {
            this.setState({
              showFormDiv: true,
              message: 'Successfully verified.',
            });
          } else {
            this.setState({
              message: response.data.messages,
            });
          }
        })
        .catch((err) => {
          this.setState({
            message: err.message,
          });
        })
        .then(() => {
          this.setState({
            formSubmit: false,
          });
        });
    }

    document.title = `Reset Password ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <AuthPageWrapper>
        {!this.state.showThankDiv ? (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--0">
              <h2 className="card--title card--title-sm">Reset Password</h2>
            </div>
            {this.state.showFormDiv ? (
              <div className="card--body card--body-sm">
                <AlertError message={this.state.messageError} />
                <AlertSuccess message={this.state.messageSuccess} />
                <p className="fs--15 dark-gray text-center">
                  Please enter and confirm your password below to access your account.
                </p>

                <AvForm
                  className="form-horizontal u-mt--30"
                  onValidSubmit={this.submitHandler}
                  autoComplete="off"
                >
                  <AvGroup>
                    <Label for="password">Password</Label>
                    <AvInput
                      type={!this.state.passwordVisible ? 'password' : 'text'}
                      name="password"
                      id="password"
                      required
                      className="is--pass"
                      minLength={8}
                      maxLength={20}
                      errorMessage="Password is required and must between be between 8 - 20 characters"
                      validate={{
                        pattern: {
                          value: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$',
                          errorMessage:
                            'Password should contain atleast one number and one special character (!@#$%^&*)',
                        },
                      }}
                    />
                    <button
                      type="button"
                      onClick={this.passwordToggleHandler}
                      className="pass--toggler"
                    >
                      {this.state.passwordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </button>
                    <AvFeedback>
                      Password should contain atleast one number and one special character
                      (!@#$%^&*) and must be between 8 - 20 characters.
                    </AvFeedback>
                  </AvGroup>
                  <AvGroup>
                    <Label for="confirm_password">Confirm Password</Label>
                    <AvInput
                      type={!this.state.conPasswordVisible ? 'password' : 'text'}
                      name="confirm_password"
                      className="form-control is--pass"
                      required
                      minLength={8}
                      validate={{
                        match: {
                          value: 'password',
                          errorMessage: 'Password and Confirm password must match',
                        },
                      }}
                    />
                    <button
                      type="button"
                      onClick={this.conPasswordToggleHandler}
                      className="pass--toggler"
                    >
                      {this.state.conPasswordVisible ? (
                        <VisibilityOffIcon />
                      ) : (
                        <VisibilityIcon />
                      )}
                    </button>
                    <AvFeedback>Password and Confirm password is not matching</AvFeedback>
                  </AvGroup>

                  <Row className="row u-mt--15">
                    <Col
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      className="u-flexed u-align-center u-justify-center"
                    >
                      <Button
                        type="submit"
                        className={`c-button c-button--primary c-button-w210 c-button-radius ${
                          this.state.formSubmit ? `inlineloading` : ``
                        }`}
                      >
                        Submit
                      </Button>
                    </Col>
                  </Row>
                </AvForm>
              </div>
            ) : (
              <div>
                <p className="fs--15 dark-gray text-center">{this.state.message}</p>
                <Row className="row u-mt--15">
                  <Col
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    className="u-flexed u-align-center u-justify-center"
                  >
                    <Link
                      to="/auth/login"
                      className="c-button c-button--primary c-button-w210 c-button-radius u-mb--30"
                    >
                      GO TO LOGIN
                    </Link>
                  </Col>
                </Row>
              </div>
            )}
          </Card>
        ) : (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--10">
              <h2 className="card--title card--title-sm">Password Updated.</h2>
            </div>
            <div className="card--body card--body-sm">
              <div className="u-flexed u-flex-direction-column u-align-center u-justify-center text-center">
                <p className="fs--15 dark-gray u-mb--20">
                  Password is updated successfully
                </p>

                <Link
                  to="/auth/login"
                  className="c-button c-button--primary c-button-w210 c-button-radius"
                >
                  GO TO LOGIN
                </Link>
              </div>
            </div>
          </Card>
        )}
      </AuthPageWrapper>
    );
  }
}

export default ResetPassword;
