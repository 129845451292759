import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { RouteComponentProps, Link } from 'react-router-dom';

// Application Imports;
import AppContext from '../../../components/AppContext';
import { accountType } from '../../../helpers/Constant';
import appConfig from '../../../config';
import LocalStorage from '../../../helpers/LocalStorage';

// Import CSS
import './profile-first-time.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

// Import Assets
import ProfileSetup from '../../../assets/images/Profile-Setup.svg';
import FinishProfile from '../../../assets/images/Finish-Profile.svg';
import AddDocuments from '../../../assets/images/Add-Documents.jpg';
import SetUpRole from '../../../assets/images/SetUp-Role.svg';

interface Props extends RouteComponentProps {}

interface State {}

class ProfileFirstTime extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Dashboard ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    const activeAccountType = LocalStorage.getCurrentAccountType();
    const welcomeName =
      activeAccount.account_type === accountType.personal
        ? activeAccount.company_name.split(' ', 1)
        : activeAccount.company_name;

    return (
      <>
        <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm u-mt--30">
          <Row>
            <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
              <div className="myprofile--section myprofile--section-sm text-center">
                <div className="page-head page-head-sm center rows noborder u-mb--5">
                  <h2 className="u-heading--large u-heading--large-sm">
                    Hey {welcomeName}, welcome!
                  </h2>
                </div>
                <div className="profile--card__wrapper">
                  <div className="profile--card">
                    <div className="profile--card__body profile--card__body-sm">
                      <figure className="grphic200">
                        <img
                          src={
                            activeAccountType === accountType.personal
                              ? ProfileSetup
                              : FinishProfile
                          }
                          alt="Manage profile"
                          className="img-fluid"
                        />
                      </figure>
                      <header>
                        <h1 className="card--title">
                          {activeAccountType === accountType.personal
                            ? 'Personal'
                            : 'Company'}{' '}
                          profile
                        </h1>
                        <p className="fs--14 text--dark-gray ls-025">
                          {activeAccountType !== accountType.corporate &&
                            'Manage your profile so you can share it with others.'}
                          {activeAccountType === accountType.corporate && (
                            <>
                              Manage your <br />
                              companys profile so you can
                              <br />
                              share it with others
                            </>
                          )}
                        </p>
                      </header>
                      <Link
                        to={
                          activeAccountType === accountType.personal
                            ? '/personal/profile'
                            : activeAccountType === accountType.corporateView
                            ? '/corporate_viewonly/profile'
                            : '/company/profile'
                        }
                        className="c-button c-button--primary c-button-radius u-upper fs--13"
                      >
                        Manage Profile
                      </Link>
                    </div>
                  </div>

                  {activeAccountType === accountType.corporateView && (
                    <div className="profile--card">
                      <div className="profile--card__body profile--card__body-sm">
                        <figure className="grphic200">
                          <img
                            src={AddDocuments}
                            alt="Search Profiles"
                            className="img-fluid"
                          />
                        </figure>
                        <header>
                          <h1 className="card--title">Search Profiles</h1>
                          <p className="fs--14 text--dark-gray ls-025">
                            Search shared profiles
                            <br />
                            using CRN.
                          </p>
                        </header>
                        <Link
                          to="/corporate/search"
                          className="c-button c-button--primary c-button-radius u-upper fs--13"
                        >
                          Search
                        </Link>
                      </div>
                    </div>
                  )}

                  {activeAccountType !== accountType.corporateView && (
                    <div className="profile--card">
                      <div className="profile--card__body profile--card__body-sm">
                        <figure className="grphic200">
                          <img
                            src={AddDocuments}
                            alt="Add Documents"
                            className="img-fluid"
                          />
                        </figure>
                        <header>
                          <h1 className="card--title">Add documentation</h1>
                          <p className="fs--14 text--dark-gray ls-025">
                            {activeAccountType !== accountType.corporate &&
                              'Upload your compliance documents.'}
                            {activeAccountType === accountType.corporate && (
                              <>
                                Upload your companys <br />
                                compliance documents as <br />
                                proof to others
                              </>
                            )}
                          </p>
                        </header>
                        <Link
                          to="/document/add"
                          className="c-button c-button--primary c-button-radius u-upper fs--13"
                        >
                          Upload Documents
                        </Link>
                      </div>
                    </div>
                  )}

                  {activeAccountType === accountType.corporate && (
                    <div className="profile--card">
                      <div className="profile--card__body profile--card__body-sm">
                        <figure className="grphic200">
                          <img src={SetUpRole} alt="Set up roles" className="img-fluid" />
                        </figure>
                        <header>
                          <h1 className="card--title">Set up roles</h1>
                          <p className="fs--14 text--dark-gray ls-025">
                            Set up roles with compliance <br></br>requirements that you
                            assign <br></br>to team members
                          </p>
                        </header>
                        <Link
                          to="/corporate/role"
                          className="c-button c-button--primary c-button-radius u-upper fs--13"
                        >
                          Set Up Roles
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default ProfileFirstTime;
