import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Static Include
import Header from './includes/Header/Header';
import MobileMenu from './includes/Menu/MobileMenu';
import Footer from './includes/Footer/Footer';

interface Props extends RouteComponentProps {
  children?: React.ReactElement;
  exact?: boolean;
  path?: string;
}

interface State {}

class HomeLayout extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loggeIn: true,
      subscribed: true,
    };
  }

  render() {
    return (
      <>
        <div className="wrapper wrapper-full-page">
          <div className="navSidebar">
            <MobileMenu {...this.props} />
          </div>
          <div className="main-panel">
            <Header {...this.props} />
            {this.props.children}

            <Footer {...this.props} />
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(HomeLayout);
