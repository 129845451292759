import React from 'react';

//Application Imports
import AddEditDocument from './AddEditDocument';

interface Props {}

interface State {}

class DocumentAdd extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <AddEditDocument area="add" />
      </>
    );
  }
}

export default DocumentAdd;
