import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import { Container, Row, Col, Label, FormGroup } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import scrollToComponent from 'react-scroll-to-component';

// Application Imports
import Breadcrumb from './Breadcrumb';
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Team from '../../../api/Team';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AlertError from '../../../layout/includes/Alert/Error';

// Import CSS
import './teams.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { toast } from 'react-toastify';

interface Props {}

interface State {
  startDate: Date | null;
  showDescriptionDiv: boolean;
  formSubmitted: boolean;
  showLoader: boolean;
  teamManagerValue: string;
  teamManagerValueError: boolean;
  managerOptions: Array<any>;
  memberListState: Array<any>;
  errorMessages: Array<string> | string;
}

class CreateTeam extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef: React.RefObject<any>;
  constructor(props: Props) {
    super(props);
    this.alertErrorRef = React.createRef();
    this.state = {
      startDate: null,
      showDescriptionDiv: false,
      formSubmitted: false,
      showLoader: true,
      teamManagerValue: '',
      teamManagerValueError: false,
      managerOptions: [],
      memberListState: [],
      errorMessages: '',
    };
  }

  handleChange = (date: any) => {
    this.setState({
      startDate: date,
    });
  };

  descriptionDivHandler = () => {
    this.setState({
      showDescriptionDiv: !this.state.showDescriptionDiv,
    });
  };

  managerChangeHandler = (data: any) => {
    this.setState({
      teamManagerValue: data.value,
      teamManagerValueError: false,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    const { teamManagerValue } = this.state;
    const { activeAccountUUID } = this.context;
    let isError = false;

    if (teamManagerValue.trim() === null || teamManagerValue.trim() === '') {
      this.setState({
        teamManagerValueError: true,
      });
    }

    if (errors.length > 0) {
      isError = true;
    }

    if (isError) {
      return;
    }

    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    // request to server;
    Team.createTeam(
      activeAccountUUID,
      values.projectname,
      values.description,
      this.state.teamManagerValue,
      this.state.startDate
    )
      .then((response: any) => {
        this.setState({
          formSubmitted: false,
        });
        if (response.data.success === 1) {
          // redirect to edit page;
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err: any) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  convertServiceArrayData = (data: any) => {
    let dataArray: any = [];
    if (data) {
      if (data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          let objData: any = {
            label: data[i].name,
            value: data[i].uuid,
            type: data[i].type,
            profile_pic: data[i].profile_pic,
          };
          dataArray.push(objData);
        }
      }
    }
    return dataArray;
  };

  fetchOptions = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Team.getOptions(activeAccountUUID)
      .then((response: any) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            managerOptions: this.convertServiceArrayData(response.data.data.user_list),
          });
        } else {
          toast.error('Please reload page.', {
            autoClose: 3000,
          });
        }
      })
      .catch((err: any) => {});
  };

  componentDidMount() {
    document.title = `Create Team ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch team manager/ vehicle options;
    this.fetchOptions();
  }

  render() {
    return (
      <>
        <Breadcrumb />
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={8} lg={7} className="col-centered">
              <div className="myteams--section myteams--section-sm">
                {this.state.showLoader ? (
                  <Loader />
                ) : (
                  <>
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--0">
                      <h2 className="u-heading--medium u-heading--medium-sm">
                        Create Team
                      </h2>
                    </div>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />

                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <AvField
                        required
                        id="projectname"
                        name="projectname"
                        label="Project name"
                        placeholder="Project name"
                      />
                      <AvField
                        type="textarea"
                        required
                        name="description"
                        label="Description"
                        placeholder="Description"
                        autoComplete="off"
                      />

                      <FormGroup
                        className={
                          this.state.teamManagerValueError ? 'form-group--has-error' : ''
                        }
                      >
                        <Label htmlFor="teamManager">Select a Team Manager</Label>
                        <div className="Team--Manager">
                          <Select
                            isMulti
                            name="teamManager"
                            options={this.state.managerOptions}
                            className="basic-multi-select"
                            onChange={this.managerChangeHandler}
                            classNamePrefix="select"
                            placeholder="Select Team Manager"
                          />
                        </div>
                        <span className="help-block">Please select Team manager</span>
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="startdate">Estimated start date (optional)</Label>
                        <DatePicker
                          required
                          id="startdate"
                          name="startdate"
                          selected={this.state.startDate}
                          onChange={this.handleChange}
                          className="form-control size--md"
                          placeholderText="DD/MM/YYYY"
                          dateFormat="dd/MM/Y"
                          showMonthDropdown
                          showYearDropdown
                        />
                      </FormGroup>
                      <div className="clearfix"></div>

                      <Row className="u-mt--30 u-mt--30-sm">
                        <Col className="text-center">
                          <button
                            type="submit"
                            disabled={this.state.formSubmitted}
                            className={`c-button c-button--green c-button-inlineflex c-button-w210 c-button-radius c-button-sm u-upper ${
                              this.state.formSubmitted ? `inlineloading` : ``
                            }`}
                          >
                            Save Team
                          </button>
                        </Col>
                      </Row>
                    </AvForm>
                  </>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CreateTeam;
