import React, { MouseEvent } from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports
import AppContext from '../../../components/AppContext';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import LinkMember from '../../../api/LinkMember';

// Import CSS
import '../members/member-list.scss';
import '../members/teams.scss';
import 'react-confirm-alert/src/react-confirm-alert.css';

//import static assets
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  memberList: Array<any>;
  pendingMemberList: Array<any>;
  updateMemberListStatus: Function;
  removePendingMember: Function;
  reloadData: Function;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

interface State {
  dropdownOpen: Array<boolean>;
  filterValue: string;
  viewDocumentModalOpen: boolean;
  previewDocumentList: Array<any>;
}

class MemberList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  typingTimeout: ReturnType<typeof setTimeout>;

  constructor(props: Props) {
    super(props);
    this.typingTimeout = setTimeout(() => '', 1000);
    this.state = {
      dropdownOpen: [],
      filterValue: '',
      viewDocumentModalOpen: false,
      previewDocumentList: [],
    };
  }

  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  confirmLinkUnlink = (
    userAccountUuid: string,
    unlink: boolean,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const elementButton = e.currentTarget;
    if (unlink) {
      confirmAlert({
        title: 'Confirm',
        message: 'Do you really want to unlink this subcontractor?',
        buttons: [
          {
            label: 'Yes',
            onClick: () =>
              this.changeAccountStatus(elementButton, userAccountUuid, unlink),
          },
          {
            label: 'No',
            onClick: () => {},
          },
        ],
      });
    } else {
      this.changeAccountStatus(elementButton, userAccountUuid, unlink);
    }
  };

  changeAccountStatus = (
    elementButton: HTMLButtonElement,
    userAccountUuid: string,
    unlink: boolean
  ) => {
    const { activeAccountUUID } = this.context;

    elementButton.classList.add('inlineloading');
    elementButton.innerText = '';

    // request to server;
    LinkMember.updateAccountStatus(activeAccountUUID, userAccountUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.updateMemberListStatus(userAccountUuid);
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
          elementButton.classList.remove('inlineloading');
          elementButton.innerText = unlink ? 'Unlink' : 'Re-Link';
        }
      })
      .catch((err) => {
        elementButton.classList.remove('inlineloading');
        elementButton.innerText = unlink ? 'Unlink' : 'Re-Link';
      });
  };

  cancelInviteConfirm = (e: MouseEvent<HTMLButtonElement>, pendingInviteUuid: string) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to cancel invitation to this subcontractor?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            this.cancelPendingInvitation(elementButton, pendingInviteUuid);
          },
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  cancelPendingInvitation = (
    elementButton: HTMLButtonElement,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;
    elementButton.classList.toggle('inlineloading');
    const prevButtonText = elementButton.innerText;
    elementButton.innerText = '';

    // request to server;
    LinkMember.cancelPendingInvite(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.removePendingMember(pendingInviteUuid);
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
          elementButton.classList.toggle('inlineloading');
          elementButton.innerText = prevButtonText;
        }
      })
      .catch((err) => {
        elementButton.classList.toggle('inlineloading');
        elementButton.innerText = prevButtonText;
      });
  };

  resendInvitation = (
    e: React.MouseEvent<HTMLButtonElement>,
    pendingInviteUuid: string
  ) => {
    const { activeAccountUUID } = this.context;

    let elementButton = e.currentTarget;
    elementButton.innerText = 'Processing..';

    // request to server;
    LinkMember.resendInvitation(activeAccountUUID, pendingInviteUuid)
      .then((response) => {
        elementButton.innerText = 'Resend';
        if (response.data.success === 1) {
          toast.success('Invitation sent successfully.', {
            autoClose: 2000,
          });
        } else {
          toast.error(response.data.messages[0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {
        elementButton.innerText = 'Resend';
      });
  };

  confirmDeleteUnlinkMember = (
    userAccountUuid: string,
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    const elementButton = e.currentTarget;
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this subcontractor?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteSubcontractor(elementButton, userAccountUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  deleteSubcontractor = (elementButton: HTMLButtonElement, userAccountUuid: string) => {
    const { activeAccountUUID } = this.context;

    elementButton.classList.add('inlineloading');
    elementButton.innerText = '';

    // request to server;
    LinkMember.deleteUnlinkedAccount(activeAccountUUID, userAccountUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.props.updateMemberListStatus(userAccountUuid, 'deleted');
        } else {
          toast.error('Please try again.', {
            autoClose: 3000,
          });
          elementButton.classList.remove('inlineloading');
          elementButton.innerText = 'Delete';
        }
      })
      .catch((err) => {
        elementButton.classList.remove('inlineloading');
        elementButton.innerText = 'Delete';
      });
  };

  render() {
    return (
      <>
        <div className="myteams--section myteams--section-sm">
          <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
            <h2 className="u-heading--medium u-heading--medium-sm">
              Linked Subcontractors
            </h2>
          </div>
          <ul className="admin--stats admin--stats-sm">
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">{this.props.activeMemberCount}</div>
              <div className="desc desc-sm">Subcontractors Linked</div>
            </li>
            <li className="admin--stats--items admin--stats--items-sm">
              <div className="number number-sm">
                {this.props.pendingMemberList.length}
              </div>
              <div className="desc desc-sm">Pending Subcontractors</div>
            </li>
          </ul>

          <div className="portlet">
            <div className="portlet--body">
              <div className="profile--links">
                <ListGroup>
                  <ListGroupItem className="head">
                    <div className="NameColumn">Name</div>
                  </ListGroupItem>
                  {this.props.activeMemberCount > 0 ? (
                    this.props.memberList.map(
                      (data: any, key: number) =>
                        data.status === 1 && (
                          <ListGroupItem
                            key={data.uuid}
                            className={`${GetAccountDocumentStatusClassName(
                              data.accountDocumentStatus
                            )}`}
                          >
                            <div className="NameColumn">
                              <div className="avatar--40">
                                <img
                                  src={
                                    data.profileImageUrl
                                      ? data.profileImageUrl
                                      : ProfileAvatar
                                  }
                                  alt="Profile"
                                />
                              </div>
                              <div className="list-group--details">
                                <p className="user--name">{data.name}</p>
                                <p className="user--email">{data.email}</p>
                              </div>
                            </div>
                            <div className="ActivityColumn">
                              <Link
                                to={{
                                  pathname: `/corporate/member-projects/${data.accountUuid}`,
                                  state: { userName: data.name },
                                }}
                              >
                                <span className="badge badge-primary text-dark u-ml--5">
                                  {data.totalAssignedProjects
                                    ? data.totalAssignedProjects
                                    : 0}{' '}
                                  Project
                                </span>
                              </Link>
                              <div className="ml-auto">
                                <div className="deny u-flexed u-align-center">
                                  <button
                                    type="button"
                                    onClick={(e) =>
                                      this.props.history.push(
                                        `/account/${data.accountUuid}`
                                      )
                                    }
                                    className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                                  >
                                    View Profile
                                  </button>
                                  {data.totalAssignedProjects <= 0 && (
                                    <button
                                      type="button"
                                      onClick={(e) =>
                                        this.confirmLinkUnlink(data.uuid, true, e)
                                      }
                                      className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                                    >
                                      Unlink
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </ListGroupItem>
                        )
                    )
                  ) : (
                    <ListGroupItem>
                      <p>No linked subcontractors found</p>
                    </ListGroupItem>
                  )}
                </ListGroup>
              </div>
            </div>
          </div>
          {this.props.unlinkedMemberCount > 0 && (
            <div className="profile--links u-mt--35">
              <h3 className="title">Link History</h3>
              <ListGroup>
                {this.props.memberList.map(
                  (data: any) =>
                    data.status === 0 && (
                      <ListGroupItem className="head" key={data.uuid}>
                        <div className="NameColumn">
                          <div className="avatar--40">
                            <img
                              src={
                                data.profileImageUrl
                                  ? data.profileImageUrl
                                  : ProfileAvatar
                              }
                              alt="Profile"
                            />
                          </div>
                          <div className="list-group--details">
                            <p className="user--name">{data.name}</p>
                            <p className="user--email">{data.email}</p>
                          </div>
                        </div>
                        <div className="RoleColumn"></div>
                        <div className="ActivityColumn">
                          <div className="ml-auto">
                            <div className="deny u-flexed u-align-center">
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.confirmLinkUnlink(data.uuid, false, e)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Re-Link
                              </button>
                              <button
                                type="button"
                                onClick={(e) =>
                                  this.confirmDeleteUnlinkMember(data.uuid, e)
                                }
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Delete
                              </button>
                            </div>
                          </div>
                        </div>
                      </ListGroupItem>
                    )
                )}
              </ListGroup>
            </div>
          )}
          {this.props.pendingMemberList.length > 0 && (
            <div className="profile--links u-mt--35">
              <h3 className="title">Pending Invites</h3>
              <ListGroup>
                {this.props.pendingMemberList.map((data: any) => (
                  <ListGroupItem className="head" key={data.uuid}>
                    <div className="NameColumn">
                      <div className="avatar--40">
                        <img
                          src={
                            data.profileImageUrl ? data.profileImageUrl : ProfileAvatar
                          }
                          alt="Profile"
                        />
                      </div>
                      <div className="list-group--details">
                        <p className="user--name">{data.name}</p>
                        <p className="user--email">{data.email}</p>
                      </div>
                    </div>
                    <div className="RoleColumn"></div>
                    <div className="ActivityColumn">
                      <div className="ml-auto">
                        <div className="deny u-flexed u-align-center">
                          {data.status === 0 && (
                            <>
                              <button
                                type="button"
                                id={`${data.uuid}resend`}
                                onClick={(e) => this.resendInvitation(e, data.uuid)}
                                className="links--primary fs--15 u-mr--10"
                              >
                                Resend
                              </button>

                              <button
                                type="button"
                                onClick={(e) => this.cancelInviteConfirm(e, data.uuid)}
                                className="c-button c-button--primary c-button-inlineflex c-button-w75 c-button-radius c-button-xs"
                              >
                                Cancel Invite
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </div>
          )}
        </div>
      </>
    );
  }
}

export default withRouter(MemberList);
