import React from 'react';
import { Table, Label, Input, Dropdown, DropdownToggle } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

//Application Imports
import Helper from '../../../helpers/Helpers';
import DocumentDropdown from './DocumentDropdown/DocumentDropdown';
import AppContext from '../../../components/AppContext';
import Document from '../../../api/Document';
import RevisionModal from './RevisionModal';
import AttachedRoleModal from './AttachedRoleModal';
import { GetAccountDocumentStatusClassName } from '../../../helpers/Helpers';
import { documentStatusCode } from '../../../helpers/Constant';
import DocumentPreviewLightbox from './DocumentPreviewLightbox';

// Import Icons
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import Search from '@material-ui/icons/Search';

import 'react-image-lightbox/style.css';

interface Props extends RouteComponentProps {
  area?: string;
  showEditElements?: boolean;
  type: string;
  data: any;
  visibleChangeHandler: Function;
  updateListData: Function;
}

interface State {
  showLoader: boolean;
  revisionList: any;
  rolesList: any;
  revisionModalOpen: boolean;
  roleModalOpen: boolean;
  dropdownOpen: Array<boolean>;
  isLinkOpen: boolean;
  photoIndex: number;
  imageArray: Array<any>;
}

class TableList extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: false,
      revisionModalOpen: false,
      roleModalOpen: false,
      rolesList: null,
      revisionList: null,
      isLinkOpen: false,
      imageArray: [],
      dropdownOpen: [],
      photoIndex: 0,
    };
  }

  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  linkOpenHandler = (images?: Array<any>) => {
    this.setState({
      isLinkOpen: !this.state.isLinkOpen,
      imageArray: images ? images : [],
    });
  };

  openManageModalHandler = (e: React.MouseEvent<HTMLButtonElement>) => {
    document.getElementById('manage-document-handler')?.click();
  };

  calculateExpiry = (date: string, docStatus: number, getBoolean: boolean) => {
    if (date === null) {
      return '';
    }

    const docExpiryStatus = Helper.getDocumentExpiryStatus(date);
    const daysDiff = Helper.getDateDiff(date);

    if (docExpiryStatus === 'expired') {
      if (getBoolean) {
        return 'hrExpiry';
      } else {
        return (
          <div className="ml-auto text--error u-flexed u-align-center">
            Expired
            <Close fontSize="small" className="icons text--error u-ml--5" />
          </div>
        );
      }
    } else if (docExpiryStatus === 'warning') {
      if (getBoolean) {
        return 'hrWarning';
      } else {
        return <small className="ml-auto text--warning">Due in {daysDiff} days</small>;
      }
    } else {
      if (getBoolean) {
        return '';
      } else {
        return (
          <>
            {this.props.area === 'corporateMemberProfileView' &&
              docStatus === documentStatusCode.awaitingApproval && (
                <button
                  onClick={(e) => this.openManageModalHandler(e)}
                  color="secondary"
                  type="button"
                  className="c-button c-button--primary c-button-xs u-ml--20"
                >
                  View
                </button>
              )}
            <div className="ml-auto">
              <Done fontSize="small" className="icons text--green" />
            </div>
          </>
        );
      }
    }
  };

  getTimeFormat = (date: string) => {
    return Helper.getDocumentExpiryDate(date);
  };

  revisionModalHandler = () => {
    this.setState({
      revisionModalOpen: !this.state.revisionModalOpen,
    });
  };

  rolesModalHandler = () => {
    this.setState({
      roleModalOpen: !this.state.roleModalOpen,
    });
  };

  viewRevisionHandler = (documentUuid: string) => {
    const { activeAccountUUID } = this.context;

    // set loader to true;
    this.setState({
      showLoader: true,
    });
    this.revisionModalHandler();

    // request to server;
    Document.getDocumentRevisions(activeAccountUUID, documentUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            revisionList: response.data.data.document_list,
          });
        } else {
          this.revisionModalHandler();
          toast.error(response.data.messages[0][0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  viewRolesHandler = (documentUuid: string) => {
    const { activeAccountUUID } = this.context;

    // set loader to true;
    this.setState({
      showLoader: true,
    });
    this.rolesModalHandler();

    // request to server;
    Document.getRoleAttachedDocumentList(activeAccountUUID, documentUuid)
      .then((response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            rolesList: response.data.data.roles_list,
          });
        } else {
          this.rolesModalHandler();
          toast.error(response.data.messages[0][0], {
            autoClose: 3000,
          });
        }
      })
      .catch((err) => {});
  };

  render() {
    return (
      <div className="table-responsive u-mb--30">
        <Table className="table-bordered u-mb--0">
          <thead>
            <tr>
              {this.props.showEditElements && <th className="VisibleColumn">Visible</th>}
              <th className="NameColumn">Name</th>
              {this.props.type !== 'license' &&
                this.props.type !== 'resume' &&
                this.props.type !== 'cover_letter' && (
                  <th className="RegistrationColumn">Number</th>
                )}
              {(this.props.type === 'license' || this.props.type === 'registration') && (
                <th className="IssuedColumn">Issued</th>
              )}
              {this.props.type !== 'resume' && this.props.type !== 'cover_letter' && (
                <th className="ExpiryColumn">Expiry</th>
              )}
            </tr>
          </thead>
          <tbody>
            {this.props.data.map((data: any, key: number) => (
              <React.Fragment key={data.uuid}>
                <tr>
                  {this.props.showEditElements && (
                    <td>
                      <Label check className="custom-toggle--label">
                        <div className="custom-toggle">
                          <Input
                            type="checkbox"
                            defaultChecked={data.is_public ? true : false}
                            onChange={() => this.props.visibleChangeHandler(data.uuid)}
                          />
                          <span className="custom-toggle-slider"></span>
                        </div>
                      </Label>
                    </td>
                  )}
                  <td className="">
                    <div className="u-flexed u-align-center">
                      <div>{data.title}</div>

                      <div className="ml-auto">
                        {this.props.type === 'revision' ? (
                          <button
                            type="button"
                            onClick={() => this.linkOpenHandler(data.media)}
                            className="c-button c-button--primary c-button--primary-bordered c-button-inlineflex c-button-radius c-button-xs u-ml--10"
                          >
                            <Search fontSize="small" className="icons" />
                          </button>
                        ) : (
                          <Dropdown
                            className="members--dropdown inline--dropdown"
                            isOpen={this.state.dropdownOpen[key]}
                            toggle={() => this.toggleDropdown(key)}
                          >
                            <DropdownToggle color="primary">
                              <MoreHoriz
                                fontSize="large"
                                className="icons"
                                style={{ margin: '0' }}
                              />
                            </DropdownToggle>
                            <DocumentDropdown
                              documentUuid={data.uuid}
                              media={data.media}
                              deletePermission={data.delete_permission}
                              linkOpenHandler={this.linkOpenHandler}
                              isAttached={data.is_attached_role_requirement}
                              updateListData={this.props.updateListData}
                              viewRevisionHandler={this.viewRevisionHandler}
                              viewRolesHandler={this.viewRolesHandler}
                            />
                          </Dropdown>
                        )}
                      </div>
                    </div>
                  </td>
                  {this.props.type !== 'license' &&
                    this.props.type !== 'resume' &&
                    this.props.type !== 'cover_letter' && (
                      <td>{data.number ? data.number : '-'}</td>
                    )}
                  {(this.props.type === 'license' ||
                    this.props.type === 'registration') && (
                    <td>{this.getTimeFormat(data.issue_date)}</td>
                  )}
                  {this.props.type !== 'resume' && this.props.type !== 'cover_letter' && (
                    <td
                      className={`${
                        data.document_status !== null && data.document_status > 1
                          ? GetAccountDocumentStatusClassName(data.document_status, true)
                          : this.props.area !== 'revision'
                          ? this.calculateExpiry(
                              data.expiry_date,
                              data.document_status,
                              true
                            )
                          : ``
                      }`}
                    >
                      <div className="u-flexed u-align-center">
                        <div className="date">{this.getTimeFormat(data.expiry_date)}</div>
                        {this.props.area !== 'revision' &&
                          this.calculateExpiry(
                            data.expiry_date,
                            data.document_status,
                            false
                          )}
                      </div>
                    </td>
                  )}
                </tr>
                <tr className="noStyle">
                  <td colSpan={4}></td>
                </tr>
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        {this.state.isLinkOpen && (
          <DocumentPreviewLightbox
            imageArray={this.state.imageArray}
            linkOpenHandler={this.linkOpenHandler}
          />
        )}
        <RevisionModal
          showLoader={this.state.showLoader}
          isOpen={this.state.revisionModalOpen}
          toggle={this.revisionModalHandler}
          revisionList={this.state.revisionList}
        />
        <AttachedRoleModal
          showLoader={this.state.showLoader}
          isOpen={this.state.roleModalOpen}
          toggle={this.rolesModalHandler}
          attachedRoleList={this.state.rolesList}
        />
      </div>
    );
  }
}

export default withRouter(TableList);
