import React from 'react';
import ReactWOW from 'react-wow';

// Import Icons
// import Lock from '@material-ui/icons/Lock';

import { Container, Row, Col } from 'reactstrap';

interface SectionComplianceProps {}
interface SectionComplianceState {}

class SectionCompliance extends React.Component<
  SectionComplianceProps,
  SectionComplianceState
> {
  constructor(props: SectionComplianceProps) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <section className="section--compliance section--compliance-sm" data-bg={'gray'}>
          <Container fluid={false}>
            <Row>
              <Col xs={12} md={11} lg={11} xl={10} className="col-centered">
                <ReactWOW animation="fadeInUp">
                  <div className="page--head page--head-sm noborder center u-mb--50 u-mb--50-sm">
                    <h1 className="u-heading__medium u-heading__medium-sm u-mb--15">
                      Compliance Types
                    </h1>
                    <p className="fs--20 fs--20-sm">
                      Organised Work Life accommodates all types of compliance
                      documentation.
                    </p>
                  </div>
                </ReactWOW>

                <Row>
                  <Col xs={12} md={12} lg={12} className="text-center">
                    <h3 className="title">POPULAR</h3>
                    <ReactWOW animation="slideInRight">
                      <ul className="features--tags features--tags-sm">
                        <li className="features--tags__items">
                          <div className="features--tags__link">Licences</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Machinery</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Tertiary Education</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Police checks</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Insurances</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Registrations</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">First Aid</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Health &amp; Safety</div>
                        </li>
                        <li className="features--tags__items">
                          <div className="features--tags__link">Qualifications</div>
                        </li>
                      </ul>
                    </ReactWOW>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default SectionCompliance;
