import React from 'react';

//Application Imports
import AddEditDocument from './AddEditDocument';

interface Props {}

interface State {}

class EditDocument extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <AddEditDocument area="edit" />
      </>
    );
  }
}

export default EditDocument;
