import React from 'react';
import { Container, Row, Col, Nav, NavItem, Dropdown, DropdownToggle } from 'reactstrap';
import {
  RouteComponentProps,
  matchPath,
  withRouter,
  Link,
  NavLink,
} from 'react-router-dom';

//Application Imports
import AppContext from '../../../components/AppContext';
import UserDropdown from './DropDownMenu/UserDropdown';
import { accountType, accountStatus } from '../../../helpers/Constant';
import LocalStorage from '../../../helpers/LocalStorage';
import ShareProfileModal from './ShareProfileModal';

//import css
import './header.scss';

//import static assets
import logo from '../../../assets/images/owl-logo.svg';
import AuthLogo from '../../../assets/images/owl-logo-large.svg';
import SmallLogo from '../../../assets/images/owl-logo-small.svg';
import Backup from '@material-ui/icons/Backup';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScreenShare from '@material-ui/icons/ScreenShare';
import AccountCircle from '@material-ui/icons/AccountCircle';

interface Props extends RouteComponentProps {}

interface State {
  isOpen: boolean;
  dropdownOpen: boolean;
  shareModalOpen: boolean;
}

class Header extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private navsidebarToggle = React.createRef<HTMLButtonElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      isOpen: false,
      dropdownOpen: false,
      shareModalOpen: false,
    };
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
      dropdownOpen: !this.state.dropdownOpen,
    });
  };

  openNavSidebar = () => {
    document.documentElement.classList.toggle('nav--open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };

  closeNavSidebar = () => {
    document.documentElement.classList.toggle('nav--open');
    this.navsidebarToggle.current?.classList.toggle('toggled');
  };

  logout = () => {
    const { logout } = this.context;
    logout();

    this.props.history.push('/');
  };

  openShareModal = () => {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  };

  render() {
    let showBigLogo = false;
    let showSmallLogo = false;
    if (
      matchPath(this.props.match.path, { path: '/', exact: true }) ||
      matchPath(this.props.match.path, { path: '/signup' }) ||
      matchPath(this.props.match.path, { path: '/auth' })
    ) {
      showBigLogo = true;
    }

    const activeAccount = LocalStorage.getActiveAccount();
    const hasManagerRole = LocalStorage.hasManagerRole();
    const { isLoggedIn } = this.context;
    return (
      <div>
        <div className="menu--overlay" onClick={() => this.closeNavSidebar()} />
        <div
          className={`c-header c-header-sm ${
            matchPath(this.props.match.path, { path: '/select-account' })
              ? `select-account`
              : ``
          }`}
        >
          <Container fluid className="container-fluid-sm">
            {isLoggedIn &&
              !matchPath(this.props.match.path, { path: '/select-account' }) && (
                <div className="navbar-toggle d-block d-md-none d-lg-none d-xl-none">
                  <button
                    type="button"
                    ref={this.navsidebarToggle}
                    className="navbar-toggler"
                    onClick={() => this.openNavSidebar()}
                    id="buttonToggle"
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              )}
            <Row>
              <Col md="12" className="">
                <div className="c-header__main-info c-header__main-info-sm">
                  {!showSmallLogo ? (
                    !showBigLogo ? (
                      <div className="c-header__main-info--logo c-header__main-info--logo-md">
                        <Link
                          to={
                            isLoggedIn
                              ? !hasManagerRole
                                ? '/dashboard'
                                : '/corporate/project'
                              : '/'
                          }
                        >
                          <img
                            src={logo}
                            className="image-fluid app--logo"
                            alt="OWLCDS"
                          />
                        </Link>
                      </div>
                    ) : (
                      <div className="c-header__main-info--logo c-header__main-info--logo-md large">
                        <Link
                          to={
                            isLoggedIn
                              ? !hasManagerRole
                                ? '/dashboard'
                                : '/corporate/project'
                              : '/'
                          }
                        >
                          <img
                            src={AuthLogo}
                            className="image-fluid app--logo"
                            alt="OWLCDS"
                          />
                        </Link>
                      </div>
                    )
                  ) : (
                    <div className="c-header__main-info--logo c-header__main-info--logo-md small">
                      <Link
                        to={
                          isLoggedIn
                            ? !hasManagerRole
                              ? '/dashboard'
                              : '/corporate/project'
                            : '/'
                        }
                      >
                        <img
                          src={SmallLogo}
                          className="image-fluid app--logo"
                          alt="OWLCDS"
                        />
                      </Link>
                    </div>
                  )}
                  <div className="c-header__main-info--right c-header__main-info--right-md">
                    {isLoggedIn &&
                    matchPath(this.props.match.path, { path: '/select-account' }) ? (
                      <div className="c-header__profile--info c-header__profile--info-sm">
                        <div className="topbar-menu">
                          <div className="user--profile">
                            <Dropdown
                              isOpen={this.state.dropdownOpen}
                              toggle={this.toggle}
                            >
                              <DropdownToggle caret color="primary">
                                <span className="username">
                                  {activeAccount.company_name &&
                                    activeAccount.company_name[0]}
                                </span>
                              </DropdownToggle>
                              <UserDropdown logout={this.logout} toggle={this.toggle} />
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <>
                        {isLoggedIn ? (
                          <>
                            {activeAccount.account_type === accountType.corporateView ? (
                              <nav className="c-navigation d-none d-md-flex d-lg-flex d-xl-flex">
                                <Nav className="c-navigation--list">
                                  <NavItem
                                    className={`${
                                      this.props.location.pathname === '/corporate/search'
                                        ? `is--active`
                                        : ``
                                    }`}
                                  >
                                    <NavLink to="/corporate/search">
                                      Search a Profile
                                    </NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={`${
                                      this.props.location.pathname ===
                                      '/corporate_viewonly/profile'
                                        ? `is--active`
                                        : ``
                                    }`}
                                  >
                                    <NavLink to="/corporate_viewonly/profile">
                                      Company Profile
                                    </NavLink>
                                  </NavItem>
                                </Nav>
                              </nav>
                            ) : activeAccount.account_type === accountType.corporate ? (
                              <nav className="c-navigation d-none d-md-flex d-lg-flex d-xl-flex">
                                <Nav className="c-navigation--list">
                                  {!hasManagerRole && (
                                    <NavItem
                                      className={
                                        matchPath(this.props.match.path, {
                                          path: '/corporate/team/member',
                                        })
                                          ? `is--active`
                                          : ``
                                      }
                                    >
                                      <NavLink to="/corporate/team/member">
                                        Personnel
                                      </NavLink>
                                    </NavItem>
                                  )}

                                  <NavItem
                                    className={
                                      this.props.location.pathname ===
                                        '/corporate/invite/project' ||
                                      this.props.location.pathname ===
                                        '/corporate/favourite/project' ||
                                      this.props.location.pathname ===
                                        '/corporate/project' ||
                                      matchPath(this.props.match.path, {
                                        path: '/corporate/project-view/:uuid',
                                      })
                                        ? `is--active`
                                        : ``
                                    }
                                  >
                                    <NavLink to="/corporate/project">Projects</NavLink>
                                  </NavItem>
                                  {!hasManagerRole && (
                                    <>
                                      <NavItem
                                        className={
                                          this.props.location.pathname ===
                                          '/company/profile'
                                            ? 'is--active'
                                            : this.props.location.pathname ===
                                              '/documentation'
                                            ? 'is--active'
                                            : ''
                                        }
                                      >
                                        <NavLink to="/company/profile">
                                          <span className="m768">COMPANY</span> PROFILE &
                                          DOCUMENTS
                                        </NavLink>
                                      </NavItem>
                                      <NavItem
                                        className={
                                          this.props.location.pathname ===
                                          '/corporate/search/profile'
                                            ? 'is--active'
                                            : ''
                                        }
                                      >
                                        <NavLink to="/corporate/search/profile">
                                          Search A profile
                                          <span className="m768"> / subcontractor</span>
                                        </NavLink>
                                      </NavItem>
                                    </>
                                  )}
                                </Nav>
                              </nav>
                            ) : (
                              <nav className="c-navigation d-none d-md-flex d-lg-flex d-xl-flex">
                                <Nav className="c-navigation--list">
                                  <NavItem
                                    className={
                                      this.props.location.pathname ===
                                        '/company/profile' ||
                                      this.props.location.pathname === '/personal/profile'
                                        ? 'is--active'
                                        : ''
                                    }
                                  >
                                    {activeAccount.account_type ===
                                    accountType.soletrader ? (
                                      <NavLink to="/company/profile">
                                        Company Profile
                                      </NavLink>
                                    ) : (
                                      <NavLink to="/personal/profile">My Profile</NavLink>
                                    )}
                                  </NavItem>
                                  <NavItem
                                    className={
                                      this.props.location.pathname === '/documentation' ||
                                      matchPath(this.props.match.path, {
                                        path: '/document',
                                      })
                                        ? 'is--active'
                                        : ''
                                    }
                                  >
                                    <NavLink to="/documentation">Documentation</NavLink>
                                  </NavItem>
                                  <NavItem
                                    className={
                                      matchPath(this.props.match.path, {
                                        path: '/links',
                                      })
                                        ? 'is--active'
                                        : ''
                                    }
                                  >
                                    <NavLink to="/links">Links</NavLink>
                                  </NavItem>
                                </Nav>
                              </nav>
                            )}
                            <div className="c-header__profile--info c-header__profile--info-sm">
                              <div className="topbar-menu">
                                {activeAccount.account_type !== accountType.corporate &&
                                activeAccount.account_type !==
                                  accountType.corporateView ? (
                                  <>
                                    {activeAccount.account_status ===
                                    accountStatus.closed ? (
                                      <div className="topbar-menu--controls u-flexed d-none d-lg-flex d-xl-flex">
                                        <Link
                                          to="/account/settings"
                                          className="c-button c-button--white c-button--white-bordered c-button-radius c-button-inline size--sm has-icon--before"
                                        >
                                          <AccountCircle
                                            fontSize="small"
                                            className="icons u-mr--5"
                                          ></AccountCircle>
                                          Reactivate
                                        </Link>
                                      </div>
                                    ) : (
                                      <div className="topbar-menu--controls u-flexed d-none d-lg-flex d-xl-flex">
                                        <Link
                                          to="/document/add"
                                          className="c-button c-button--white c-button--white-bordered c-button-radius c-button-inline size--sm has-icon--before"
                                        >
                                          <Backup
                                            fontSize="small"
                                            className="icons u-mr--5"
                                          ></Backup>
                                          Upload
                                        </Link>
                                        <Link
                                          to="/profile/preview"
                                          className="c-button c-button--white c-button--white-bordered c-button-radius c-button-inline size--sm has-icon--before"
                                        >
                                          <VisibilityIcon
                                            fontSize="small"
                                            className="icons u-mr--5"
                                          ></VisibilityIcon>
                                          Preview
                                        </Link>
                                        <button
                                          type="button"
                                          className="c-button c-button--white c-button--white-bordered c-button-radius c-button-inline size--sm has-icon--before"
                                          onClick={this.openShareModal}
                                        >
                                          <ScreenShare
                                            fontSize="small"
                                            className="icons u-mr--5"
                                          ></ScreenShare>
                                          Share
                                        </button>
                                        <ShareProfileModal
                                          isOpen={this.state.shareModalOpen}
                                          toggle={this.openShareModal}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  ''
                                )}
                                <div className="user--profile">
                                  <Dropdown
                                    isOpen={this.state.dropdownOpen}
                                    toggle={this.toggle}
                                  >
                                    <DropdownToggle caret color="primary">
                                      <span className="username">
                                        {activeAccount.company_name &&
                                          activeAccount.company_name[0]}
                                      </span>
                                    </DropdownToggle>
                                    <UserDropdown
                                      logout={this.logout}
                                      toggle={this.toggle}
                                    />
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <div className="c-header__additional--info c-header__additional--info-sm">
                            <div>
                              {matchPath(this.props.match.path, { path: '/signup' }) ||
                              matchPath(this.props.match.path, {
                                path: '/auth/forgot-password',
                              }) ? (
                                <>
                                  <span className="d-none d-sm-inline-flex u-mr--5">
                                    Already a customer?
                                  </span>
                                  <Link to="/auth/login">Sign in {'>'}</Link>
                                </>
                              ) : (
                                <>
                                  <span className="d-none d-sm-inline-flex u-mr--5">
                                    Create an account?
                                  </span>
                                  <a href="/#Pricing" rel="noopener noreferrer">
                                    Sign up {'>'}
                                  </a>
                                </>
                              )}
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* {this.props.location.pathname !== '/dashboard' &&
          this.props.location.pathname !== '/document/add' &&
          !matchPath(this.props.match.path, { path: '/corporate/search' }) &&
          !matchPath(this.props.match.path, { path: '/corporate/team' }) &&
          activeAccount.account_type === accountType.corporate && <HeaderBreadcrumb />} */}
      </div>
    );
  }
}

export default withRouter(Header);
