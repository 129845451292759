import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';

export const encrypt = (message, secret) => {
  var ciphertext = CryptoAES.encrypt(message, secret);
  return ciphertext.toString();
};

export const decrypt = (message, secret) => {
  try {
    var decodedtext = CryptoAES.decrypt(message, secret);
    return decodedtext.toString(CryptoENC);
  } catch (ex) {
    return null;
  }
};
