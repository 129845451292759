import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

// Import CSS
import '../activity.scss';

interface Props extends RouteComponentProps {
  data: any;
}

interface State {}

class CreateUser extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <>
        <img
          className="avatar size--18"
          src={
            data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : Avatar
          }
          alt="Avatar"
        />
        <Link to={`/account/${data.activityByUuid}`} className="u-mr--5">
          {data.activityByName
            ? data.activityByName
            : `${data.userFirstName} ${data.userLastName}`}
        </Link>{' '}
        viewed a project&nbsp;
        {data.projectUuid && (
          <Link to={`/project/${data.projectUuid}`}>{data.projectName}</Link>
        )}
      </>
    );
  }
}

export default withRouter(CreateUser);
