import React from 'react';
import { Container, Row, Col, Button } from 'reactstrap';
import { RouteComponentProps } from 'react-router-dom';

// Application Imports;
import AppContext from '../../../components/AppContext';
import { accountType } from '../../../helpers/Constant';
import appConfig from '../../../config';
import LocalStorage from '../../../helpers/LocalStorage';

// Import CSS;
import '../profile-first-time/profile-first-time.scss';

// import static assets;
import ProfileSetup from '../../../assets/images/Profile-Setup.svg';
import FinishProfile from '../../../assets/images/Finish-Profile.svg';

interface Props extends RouteComponentProps {}

interface State {}

class SelectAccountView extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  selectUserHandler = (accountData: any) => {
    const { setActiveAccountUUID } = this.context;
    setActiveAccountUUID(accountData.uuid);
    this.props.history.push('/dashboard');
  };

  componentDidMount() {
    document.title = `Select Account ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    const accountList = LocalStorage.getAccountsList();
    const activeAccount = LocalStorage.getActiveAccount();
    const welcomeName =
      activeAccount.account_type === accountType.personal
        ? activeAccount.company_name.split(' ', 1)
        : activeAccount.company_name;
    return (
      <>
        <Container fluid className="u-pb--50 u-pb--50-sm container-fluid-sm u-mt--30">
          <Row>
            <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
              <div className="myprofile--section myprofile--section-sm text-center">
                <div className="page-head page-head-sm center rows noborder u-mb--5">
                  <h2 className="u-heading--large u-heading--large-sm">
                    Hey {welcomeName}, welcome!
                  </h2>
                </div>
                <div className="profile--card__wrapper">
                  {accountList.length > 0
                    ? accountList.map((accountData: any) => (
                        <div className="profile--card" key={accountData.uuid}>
                          <div className="profile--card__body profile--card__body-sm">
                            <figure className="grphic200">
                              <img
                                src={
                                  accountData.account_type === accountType.personal
                                    ? ProfileSetup
                                    : FinishProfile
                                }
                                alt="Profile"
                                className="img-fluid"
                              />
                            </figure>
                            <header>
                              <h1 className="card--title">{accountData.company_name}</h1>
                              <p className="fs--14 text--dark-gray ls-025">
                                {accountData.company_email}
                              </p>
                            </header>
                            <Button
                              className="c-button c-button--primary c-button-radius u-upper fs--13 has--centered"
                              onClick={() => this.selectUserHandler(accountData)}
                            >
                              Select account
                            </Button>
                          </div>
                        </div>
                      ))
                    : ''}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default SelectAccountView;
