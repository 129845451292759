import React from 'react';
import {
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Label,
} from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

//Application Imports
import AlertError from '../../../layout/includes/Alert/Error';
import AppContext from '../../../components/AppContext';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Static Imports
import ChargebeeLogo from '../../../assets/images/chargebee.svg';
import VisaLogo from '../../../assets/images/icons/cards/visa.svg';
import MasterCardLogo from '../../../assets/images/icons/cards/master-card.svg';
import AmexLogo from '../../../assets/images/icons/cards/amex.svg';

interface Props {
  area: string;
  isOpen: boolean;
  toggle: Function;
  getAccountDetail: Function;
}

interface State {
  nameOnCard: string;
  nameOnCardError: boolean;
  cardNumber: string;
  cardNumberError: boolean;
  expiry: string;
  expiryError: boolean;
  cvv: string;
  cvvError: boolean;
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
}

class EditCreditCardModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      nameOnCard: '',
      nameOnCardError: false,
      cardNumber: '',
      cardNumberError: false,
      expiry: '',
      expiryError: false,
      cvv: '',
      cvvError: false,
      formSubmitted: false,
      alertErrorMessage: '',
    };
  }

  changeHandlerName = (e: any) => {
    this.setState({
      nameOnCard: e.target.value,
      nameOnCardError: false,
    });
  };

  changeHandlerCardNumber = (e: any) => {
    this.setState({
      cardNumber: e.target.value,
      cardNumberError: false,
    });
  };

  changeHandlerExpiry = (e: any) => {
    this.setState({
      expiry: e.target.value,
      expiryError: false,
    });
  };

  changeHandlerCvv = (e: any) => {
    this.setState({
      cvv: e.target.value,
      cvvError: false,
    });
  };

  handleCreditCardFormSubmit = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;
    const { nameOnCard, cvv, expiry, cardNumber } = this.state;
    let isError = false;

    this.setState({
      nameOnCardError: false,
      cardNumberError: false,
      expiryError: false,
      cvvError: false,
    });

    if (nameOnCard.trim() === null || nameOnCard.trim() === '') {
      this.setState({
        nameOnCardError: true,
      });
      isError = true;
    }

    if (cardNumber.trim() === null || cardNumber.trim() === '') {
      this.setState({
        cardNumberError: true,
      });
      isError = true;
    }

    if (expiry.trim() === null || expiry.trim() === '') {
      this.setState({
        expiryError: true,
      });
      isError = true;
    }

    if (cvv.trim() === null || cvv.trim() === '') {
      this.setState({
        cvvError: true,
      });
      isError = true;
    }

    if (isError) {
      return;
    }

    // Set loader to true;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    const postData = {
      name_on_card: nameOnCard,
      cvv: cvv,
      expiry: expiry,
      card_number: cardNumber,
      uuid: activeAccountUUID,
    };

    let url,
      successMessage = '';
    if (this.props.area === 'upgradeAccount') {
      url = '/account/upgrade-corporate-view-only';
      successMessage = 'Your subscription request successfully processed.';
    } else {
      url = '/account/update-credit-card-detail';
      successMessage = 'Your credit card details are updated successfully.';
    }

    axiosInstance
      .post(url, postData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          this.setState({
            nameOnCard: '',
            expiry: '',
            cvv: '',
            cardNumber: '',
          });
          toast.success(successMessage, {
            autoClose: 3000,
          });
          this.props.toggle();
          if (this.props.area !== 'upgradeAccount') {
            this.props.getAccountDetail();
          } else {
            const { setActiveAccountUUID, setAccountsList } = this.context;
            setAccountsList(response.data.data.accountsList);
            setActiveAccountUUID(response.data.data.accountsList[0].uuid);
          }
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };
  render() {
    let title,
      buttonLabel = '';
    if (this.props.area === 'upgradeAccount') {
      title = 'Upgrade account';
      buttonLabel = 'SUBSCRIBE';
    } else {
      title = 'Edit payment details';
      buttonLabel = 'UPDATE INFORMATION';
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md"
        backdrop={true}
      >
        <ModalHeader className="center u-border--btm0" toggle={() => this.props.toggle()}>
          {title}
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm">
          <h3 className="modal-section--title">Credit Card Details</h3>
          <AlertError message={this.state.alertErrorMessage} />
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleCreditCardFormSubmit}
            autoComplete="off"
          >
            <AvField
              name="cardname"
              label="Name on card"
              id="cardname"
              value={this.state.nameOnCard}
              onChange={this.changeHandlerName}
              required
              errorMessage="Please enter Name on card"
            />

            <FormGroup
              className={this.state.cardNumberError ? 'form-group--has-error' : ''}
            >
              <Label for="cardnumber">Card Number</Label>
              <InputMask
                type="text"
                name="cardnumber"
                id="cardnumber"
                className="form-control"
                mask="9999-9999-9999-9999"
                maskChar=" "
                value={this.state.cardNumber}
                onChange={this.changeHandlerCardNumber}
              />
              <span className="help-block">Card Number is required.</span>
            </FormGroup>

            <Row>
              <Col xs={12} sm={12} md={6} lg={6} className="mobile--50 mobile-mb--20">
                <FormGroup
                  className={this.state.expiryError ? 'form-group--has-error' : ''}
                >
                  <Label for="cardexpiry">Expiry</Label>
                  <InputMask
                    type="text"
                    name="cardexpiry"
                    id="cardexpiry"
                    className="form-control"
                    placeholder="MM/YY"
                    mask="99/99"
                    maskChar=" "
                    value={this.state.expiry}
                    onChange={this.changeHandlerExpiry}
                  />
                  <span className="help-block">Expiry is required.</span>
                </FormGroup>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6} className="mobile--50">
                <FormGroup className={this.state.cvvError ? 'form-group--has-error' : ''}>
                  <Label for="cardcvv">CVV</Label>
                  <InputMask
                    type="text"
                    name="cardcvv"
                    id="cardcvv"
                    className="form-control"
                    mask="999"
                    maskChar=" "
                    value={this.state.cvv}
                    onChange={this.changeHandlerCvv}
                  />
                  <span className="help-block">CVV is required.</span>
                </FormGroup>
              </Col>
            </Row>
            <Row className="u-flexed u-align-center u-justify-center u-mt--20">
              <Col xs={12} md={6} lg={6}>
                <Button
                  type="submit"
                  color="primary"
                  id="submit"
                  disabled={this.state.formSubmitted ? true : false}
                  className={`has--centered ${
                    this.state.formSubmitted ? `inlineloading` : ``
                  }`}
                >
                  {buttonLabel}
                </Button>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
        <footer className="card--footer card--footer-sm plr--60 plr--60-sm">
          <div className="u-flexed u-align-center u-justify-btw">
            <div className="owl--powered">
              <span className="text">POWERED BY</span>{' '}
              <span className="owl--powered__logo u-ml--10">
                <img src={ChargebeeLogo} className="image-fluid" alt="Chargebee" />
              </span>
            </div>
            <div className="owl--cards">
              <img
                src={MasterCardLogo}
                alt="Master Card"
                className="img-fluid u-ml--10"
              />{' '}
              <img src={VisaLogo} alt="Visa" className="img-fluid u-ml--10" />{' '}
              <img src={AmexLogo} alt="American Express" className="img-fluid u-ml--10" />
            </div>
          </div>
        </footer>
      </Modal>
    );
  }
}

export default EditCreditCardModal;
