import React from 'react';
import './dropdown.scss';
import { DropdownMenu } from 'reactstrap';
import ChangeRoleModal from '../ChangeRoleModal';
import AppContext from '../../../../components/AppContext';
import { RouteComponentProps, withRouter } from 'react-router-dom';

interface Props extends RouteComponentProps {
  accountData: any;
  accountUuid: string;
  userAccountUuid: string;
  roleUuid: string;
  changeAccountStatus: Function;
  updateMemberListStatus: Function;
  documentModalHandler: Function;
  roles: Array<any>;
}

interface State {
  changeRoleModal: boolean;
  rolesOption: any;
}

class ProfileDropdown extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);

    let option = [] as any;
    for (let roleIndex in this.props.roles) {
      let role = this.props.roles[roleIndex];
      option = [
        ...option,
        {
          value: role.title,
          uuid: role.uuid,
        },
      ];
    }

    this.state = {
      changeRoleModal: false,
      rolesOption: option,
    };
  }

  roleModalToggler = () => {
    this.setState({
      changeRoleModal: !this.state.changeRoleModal,
    });
  };

  render() {
    return (
      <>
        <DropdownMenu right className="w200">
          <ol className="dropdown-menu__lists profile--menu">
            <li>
              <button
                onClick={() =>
                  this.props.documentModalHandler(this.props.userAccountUuid, true, true)
                }
              >
                View Documents
              </button>
            </li>
            {this.props.accountData.totalAssignedProjects <= 0 && (
              <>
                <li className="dropdown-divider" />
                <li>
                  <button onClick={this.roleModalToggler}>
                    <i className="svg--icon">
                      <svg
                        className="owl__icon"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                      >
                        <path d="M504.502 75.496c-9.997-9.998-26.205-9.998-36.204 0L161.594 382.203 43.702 264.311c-9.997-9.998-26.205-9.997-36.204 0-9.998 9.997-9.998 26.205 0 36.203l135.994 135.992c9.994 9.997 26.214 9.99 36.204 0L504.502 111.7c9.998-9.997 9.997-26.206 0-36.204z" />
                      </svg>
                    </i>
                    Change Role
                  </button>
                </li>
                <li className="dropdown-divider" />
                <li>
                  <button
                    onClick={(e) =>
                      this.props.changeAccountStatus(this.props.userAccountUuid, true, e)
                    }
                    className="text--error"
                  >
                    Unlink Member
                  </button>
                </li>
              </>
            )}
          </ol>
        </DropdownMenu>
        <ChangeRoleModal
          roleUuid={this.props.roleUuid}
          userAccountUuid={this.props.userAccountUuid}
          isOpen={this.state.changeRoleModal}
          toggle={this.roleModalToggler}
          updateMemberListStatus={this.props.updateMemberListStatus}
          rolesOption={this.state.rolesOption}
        />
      </>
    );
  }
}

export default withRouter(ProfileDropdown);
