import React from 'react';
import { Row, Col, Card, Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import {
  AvForm,
  AvField,
  AvGroup,
  AvInput,
  AvFeedback,
} from 'availity-reactstrap-validation';
import { Label } from 'reactstrap';
import scrollToComponent from 'react-scroll-to-component';

//Application Imports
import appConfig from '../../config';
import AlertError from '../../layout/includes/Alert/Error';
import SignupCreditCard from './SignupCreditCard';
import SignupThankMsg from './SignupThankMsg';
import AuthPageWrapper from '../common/AuthPageWrapper';
import SignupTerms from './SignupTerms';
import FeeBreakupModal from '../common/fee-breakup/FeeBreakupModal';

// Import Axios instance
import axiosInstance from '../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import Icons;
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { UncontrolledTooltip } from 'reactstrap';

// Import CSS
import '../auth/login.scss';
import '../../scss/partials/forms.scss';
import '../../scss/partials/buttons.scss';

interface Props extends RouteComponentProps {}

interface State {
  hidePassword: boolean;
  hidePasswordCon: boolean;
  accountType: string;
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
  showCreditCard: boolean;
  showThankDiv: boolean;
  email: string;
  isDetailModalOpen: boolean;
}

class Signup extends React.PureComponent<Props, State> {
  private alertErrorRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.alertErrorRef = React.createRef();
    this.state = {
      hidePassword: false,
      hidePasswordCon: false,
      accountType: this.setaccountType(),
      formSubmitted: false,
      alertErrorMessage: '',
      showCreditCard: false,
      showThankDiv: false,
      email: '',
      isDetailModalOpen: false,
    };
  }

  componentDidMount() {
    // Set title of page;
    document.title =
      this.state.accountType === 'personal'
        ? 'Personal Signup' + appConfig.DOCUMENT_TITLE_POSTFIX
        : this.state.accountType === 'soletrader'
        ? 'Sole Trader Signup' + appConfig.DOCUMENT_TITLE_POSTFIX
        : this.state.accountType === 'corporate'
        ? 'Corporate Signup' + appConfig.DOCUMENT_TITLE_POSTFIX
        : this.state.accountType === 'corporate_viewonly'
        ? 'Corporate Viewonly Signup' + appConfig.DOCUMENT_TITLE_POSTFIX
        : '';
  }

  setaccountType = () => {
    // Set type of user;
    if (this.props.location.pathname === '/signup/personal') {
      return 'personal';
    } else if (this.props.location.pathname === '/signup/soletrader') {
      return 'soletrader';
    } else if (this.props.location.pathname === '/signup/corporate') {
      return 'corporate';
    } else if (this.props.location.pathname === '/signup/corporate_viewonly') {
      return 'corporate_viewonly';
    } else {
      return 'personal';
    }
  };

  updateMsgDivState = () => {
    this.setState({
      showThankDiv: true,
      showCreditCard: false,
    });
  };

  prevStep = () => {
    this.setState({
      showThankDiv: false,
      showCreditCard: false,
    });
  };

  handlePasswordToggle = () => {
    this.setState({
      hidePassword: !this.state.hidePassword,
    });
  };

  handleConfirmPasswordToggle = () => {
    this.setState({
      hidePasswordCon: !this.state.hidePasswordCon,
    });
  };

  handleFormSubmit = (event: React.FormEvent<HTMLFormElement>, values: any) => {
    // loader set to true and error message set to blank;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    //preparing post data
    const registerData = {
      first_name: values.first_name,
      last_name: values.last_name,
      email: values.email,
      mobile_no: values.mobile_no,
      password: values.password,
      confirm_password: values.confirm_password,
      account_type: this.state.accountType,
    };

    // Set the final request data;
    let requestData = registerData;

    // Add data to the request data if user type is not personal;
    if (this.state.accountType !== 'personal') {
      const companyData = {
        company_abnacn: values.company_abnacn,
        company_name: values.company_name,
      };

      // Merging the object to create 1 object;
      requestData = { ...registerData, ...companyData };
    }

    // set url for validate data;
    let url = '/signup/validate-signup-data';

    if (this.state.accountType === 'corporate_viewonly') {
      const tradingData = {
        trading_name: values.trading_name,
      };

      // Merging the object to create object;
      requestData = { ...requestData, ...tradingData };
      url = '/signup/create-account';
    }

    //sending a post request to the server
    axiosInstance
      .post(url, requestData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          //setting the email for thank you screen
          this.setState({ email: values.email });

          if (this.state.accountType === 'corporate_viewonly') {
            this.setState({
              showThankDiv: true,
            });
          } else {
            this.setState({
              showCreditCard: true,
            });

            //storing data for step 2
            localStorage.setItem('signupData', JSON.stringify(requestData));
          }
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });

          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  detailPlanModalHandler = () => {
    this.setState({
      isDetailModalOpen: !this.state.isDetailModalOpen,
    });
  };

  render() {
    let localSignupData = localStorage.getItem('signupData');
    let getData;
    if (localSignupData !== null) {
      getData = JSON.parse(localSignupData);
    }
    const model = { ...getData };
    // Set header message of the screen;
    const { accountType } = this.state;
    let freeDays,
      planMessage,
      labelCompany,
      labelABN = 'ABN';
    if (accountType === 'personal') {
      freeDays = '14';
      planMessage = 'Personal - $11 Annually';
    } else if (accountType === 'soletrader') {
      freeDays = '14';
      planMessage = 'Sole Trader - $55 Annually';
      labelCompany = 'Business Name';
    } else if (accountType === 'corporate') {
      freeDays = '30';
      planMessage = '';
      labelCompany = 'Company Name';
    } else if (accountType === 'corporate_viewonly') {
      freeDays = '';
      planMessage = 'Search Only Plan - Free';
      labelCompany = 'Company Name';
    }

    return (
      <AuthPageWrapper>
        {!this.state.showCreditCard && !this.state.showThankDiv ? (
          <Card className="card card-sm">
            <div className="card--header card--header-sm u-mb--0">
              <h2 className="card--title card--title-sm">
                Try Organised Work Life for free
              </h2>
            </div>
            <div className="card--body card--body-sm">
              <AlertError
                ref={(div: any) => {
                  this.alertErrorRef = div;
                }}
                message={this.state.alertErrorMessage}
              />
              <div className="choose--plan choose--plan-sm text-center d-flex align-items-center justify-content-center">
                {this.state.accountType !== 'corporate_viewonly' && (
                  <p className="fs--15 dark-gray">Start your {freeDays} day trial</p>
                )}

                {planMessage ? (
                  <div className="choose--plan__inner choose--plan__inner-sm">
                    <span className="active--plan">{planMessage}</span>
                  </div>
                ) : (
                  ''
                )}

                {this.state.accountType === 'corporate' && (
                  <>
                    <button
                      className="u-ml--10 button--help dark"
                      id="UncontrolledTooltipExample"
                      onClick={this.detailPlanModalHandler}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                        <path d="M8.5 16.7h3.3V20H8.5zM10.1.1c-3.7 0-6.6 3-6.6 6.6h3.3c0-1.8 1.5-3.3 3.3-3.3s3.3 1.5 3.3 3.3c0 3.3-5 2.9-5 8.3h3.3c0-3.7 5-4.2 5-8.3C16.8 3 13.8.1 10.1.1z" />
                      </svg>
                    </button>
                    <UncontrolledTooltip
                      placement="top"
                      target="UncontrolledTooltipExample"
                    >
                      Fee Breakup
                    </UncontrolledTooltip>
                  </>
                )}
              </div>

              <AvForm
                className="form-horizontal"
                onValidSubmit={this.handleFormSubmit}
                autoComplete="off"
                model={model}
              >
                <AvField
                  name="first_name"
                  label="First Name"
                  autoComplete="off"
                  required
                  errorMessage="Enter First name"
                />
                <AvField
                  name="last_name"
                  label="Last Name"
                  autoComplete="off"
                  required
                  errorMessage="Enter Last name"
                />
                <AvField
                  type="email"
                  name="email"
                  label={accountType === 'personal' ? 'Email Address' : 'Work Email'}
                  required
                  errorMessage="Please enter a valid Email Address"
                  validate={{
                    email: {
                      value: true,
                      errorMessage: 'Please enter a valid Email address',
                    },
                  }}
                />
                {accountType !== 'personal' ? (
                  <>
                    <AvField
                      name="company_abnacn"
                      label={labelABN}
                      autoComplete="off"
                      required
                      minLength={9}
                      maxLength={15}
                      validate={{
                        pattern: {
                          value: '^[0-9\\s]+$',
                          errorMessage: 'You can enter only numbers',
                        },
                      }}
                      errorMessage={`Please enter a valid ${labelABN}`}
                    />
                    <AvField
                      name="company_name"
                      label={labelCompany}
                      autoComplete="off"
                      required
                      errorMessage={`Please enter ${labelCompany}`}
                    />
                    {this.state.accountType === 'corporate_viewonly' && (
                      <AvField
                        name="trading_name"
                        label="Trading Name"
                        autoComplete="off"
                        errorMessage={`Please enter Trading Name`}
                      />
                    )}
                  </>
                ) : (
                  ''
                )}
                <AvField
                  type="text"
                  name="mobile_no"
                  label="Mobile Number"
                  autoComplete="off"
                  placeholder="04xxxxxxxx"
                  errorMessage="Please enter a valid 10 digit Mobile Number"
                  minLength={10}
                  maxLength={10}
                  validate={{
                    pattern: {
                      value: '^[0-9]+$',
                      errorMessage: 'You can enter only numbers',
                    },
                  }}
                />
                <AvGroup>
                  <Label for="password">Password</Label>
                  <AvInput
                    type={!this.state.hidePassword ? 'password' : 'text'}
                    name="password"
                    id="password"
                    required
                    className="is--pass"
                    minLength={8}
                    maxLength={20}
                    errorMessage="Password is required and must between be between 8 - 20 characters"
                    validate={{
                      pattern: {
                        value: '^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,20}$',
                        errorMessage:
                          'Password should contain atleast one number and one special character (!@#$%^&*)',
                      },
                    }}
                  />
                  <button
                    type="button"
                    onClick={this.handlePasswordToggle}
                    className="pass--toggler"
                  >
                    {this.state.hidePassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </button>
                  <AvFeedback>
                    Password should contain atleast one number and one special character
                    (!@#$%^&*) and must be between 8 - 20 characters.
                  </AvFeedback>
                </AvGroup>
                <AvGroup>
                  <Label for="confirm_password">Confirm Password</Label>
                  <AvInput
                    type={!this.state.hidePasswordCon ? 'password' : 'text'}
                    name="confirm_password"
                    className="form-control is--pass"
                    required
                    minLength={8}
                    validate={{
                      match: {
                        value: 'password',
                        errorMessage: 'Password and Confirm password must match',
                      },
                    }}
                  />
                  <button
                    type="button"
                    onClick={this.handleConfirmPasswordToggle}
                    className="pass--toggler"
                  >
                    {this.state.hidePasswordCon ? (
                      <VisibilityOffIcon />
                    ) : (
                      <VisibilityIcon />
                    )}
                  </button>
                  <AvFeedback>Password and Confirm password not matching</AvFeedback>
                </AvGroup>
                <Row className="u-mt--30 u-mb--20">
                  <Col
                    xs={12}
                    md={12}
                    lg={12}
                    className="u-flexed u-align-center u-justify-center"
                  >
                    <Button
                      color="primary"
                      disabled={this.state.formSubmitted ? true : false}
                      className={`btn-radius btn-w210 ${
                        this.state.formSubmitted ? `inlineloading` : ``
                      }`}
                      id="submit"
                    >
                      Continue
                    </Button>
                  </Col>
                </Row>
                <SignupTerms />
              </AvForm>
            </div>
          </Card>
        ) : (
          ''
        )}

        {this.state.showCreditCard && !this.state.showThankDiv ? (
          <SignupCreditCard
            accountType={this.state.accountType}
            updateMsgDivState={this.updateMsgDivState}
            prevStep={this.prevStep}
          />
        ) : (
          ''
        )}

        {this.state.showThankDiv && !this.state.showCreditCard ? (
          <SignupThankMsg email={this.state.email} accountType={this.state.accountType} />
        ) : (
          ''
        )}
        <FeeBreakupModal
          isOpen={this.state.isDetailModalOpen}
          toggle={this.detailPlanModalHandler}
        />
      </AuthPageWrapper>
    );
  }
}

export default withRouter(Signup);
