import React from 'react';
import Select from 'react-select';
import { WithContext as ReactTags } from 'react-tag-input';
import CopyToClipboard from 'react-copy-to-clipboard';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  FormText,
  Button,
  Input,
  Alert,
} from 'reactstrap';
import {
  AvForm,
  AvField,
  AvGroup,
  AvFeedback,
  AvInput,
} from 'availity-reactstrap-validation';
import ReactFilestack from 'filestack-react';
import { toast } from 'react-toastify';
import scrollToComponent from 'react-scroll-to-component';
import Lightbox from 'react-image-lightbox';

//Application Imports
import appConfig from '../../../config';
import AppContext from '../../../components/AppContext';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import AlertError from '../../../layout/includes/Alert/Error';
import LocalStorage from '../../../helpers/LocalStorage';
import ShareProfileModal from '../../../layout/includes/Header/ShareProfileModal';
import Breadcrumb from './Breadcrumb';
import { accountType } from '../../../helpers/Constant';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

// Import CSS
import './myprofile.scss';
import '../../../scss/partials/forms.scss';
import '../../../scss/partials/buttons.scss';

// Import Icons
import VisibilityIcon from '@material-ui/icons/Visibility';
import ScreenShare from '@material-ui/icons/ScreenShare';
import FileCopy from '@material-ui/icons/FileCopy';
import Backup from '@material-ui/icons/Backup';
import Lock from '@material-ui/icons/Lock';
import ProfileAvatar from '../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {}

interface FileStackProps {
  onPick: () => void;
}

interface State {
  crnNo: string;
  logoImageUrl: string;
  logoImageArray: any;
  previewModal: boolean;
  stateObject: any | null;
  stateObjectError: boolean;
  formSubmitted: boolean;
  showLoader: boolean;
  companyName: string;
  tradingName: string;
  ABN: string;
  service: string;
  companyDescription: string;
  contactNo: string;
  email: string;
  website: string;
  streetAddOne: string;
  streetAddTwo: string;
  suburb: string;
  postCode: string;
  copied: boolean;
  errorMessages: Array<string> | string;
  tags: any;
  shareModalOpen: boolean;
  hideMessageDiv: boolean;
}

class CompanyProfile extends React.Component<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      crnNo: '',
      logoImageUrl: '',
      logoImageArray: {},
      previewModal: false,
      stateObject: null,
      stateObjectError: false,
      formSubmitted: false,
      showLoader: true,
      companyName: '',
      tradingName: '',
      ABN: '',
      service: '',
      companyDescription: '',
      contactNo: '',
      email: '',
      website: '',
      streetAddOne: '',
      streetAddTwo: '',
      suburb: '',
      postCode: '',
      copied: false,
      errorMessages: '',
      tags: [],
      shareModalOpen: false,
      hideMessageDiv: true,
    };

    this.handleDelete = this.handleDelete.bind(this);
    this.handleAddition = this.handleAddition.bind(this);
    this.handleDrag = this.handleDrag.bind(this);
    this.handleTagClick = this.handleTagClick.bind(this);
  }

  shareModalHandler = () => {
    this.setState({
      shareModalOpen: !this.state.shareModalOpen,
    });
  };

  // Initialize option for the state selection;
  options = [
    { value: 'New South Wales', label: 'New South Wales' },
    { value: 'Queensland', label: 'Queensland' },
    { value: 'South Australia', label: 'South Australia' },
    { value: 'Tasmania', label: 'Tasmania' },
    { value: 'Victoria', label: 'Victoria' },
    { value: 'Western Australia', label: 'Western Australia' },
  ];

  KeyCodes = {
    comma: 188,
    enter: 13,
  };
  delimiters = ['this.KeyCodes.comma', 'this.KeyCodes.enter'];

  handleDelete(i: any) {
    this.setState({
      tags: this.state.tags.filter((tag: any, index: any) => index !== i),
    });
  }

  handleAddition(tag: any) {
    let { tags } = this.state;
    this.setState({ tags: [...tags, { id: tag.id, text: tag.text }] });
  }

  handleDrag(tag: any, currPos: any, newPos: any) {
    const tags = [...this.state.tags];

    // mutate array
    tags.splice(currPos, 1);
    tags.splice(newPos, 0, tag);

    // re-render
    this.setState({ tags });
  }

  handleTagClick(index: any) {
    // console.log('The tag at index ' + index + ' was clicked');
  }

  updateCopiedState = () => {
    this.setState({
      copied: true,
    });

    setTimeout(() => {
      this.setState({
        copied: false,
      });
    }, 4000);
  };

  stateChangeHandler = (stateData: any) => {
    this.setState({
      stateObject: stateData,
      stateObjectError: false,
    });
  };

  getImageURL = (urlArray: any) => {
    if (urlArray.filesUploaded.length > 0) {
      this.setState({
        logoImageUrl: LocalStorage.getFilestackSecureUrl(urlArray.filesUploaded[0].url),
        logoImageArray: urlArray.filesUploaded[0],
      });
    }
  };

  previewModalHandler = () => {
    this.setState({
      previewModal: !this.state.previewModal,
    });
  };

  submitHandler = (e: React.FormEvent<HTMLInputElement>, errors: any, values: any) => {
    let isError = false;
    const { activeAccountUUID, updateActiveAccountDetails } = this.context;
    const { stateObject } = this.state;

    if (errors.length > 0) {
      isError = true;
    }

    if (stateObject === null) {
      this.setState({
        stateObjectError: true,
      });
      isError = true;
    }

    if (isError) {
      return;
    }

    // make error state to default and loader to true;
    this.setState({
      formSubmitted: true,
      stateObjectError: false,
      errorMessages: '',
    });

    // Prepare JSON data for the update request;
    const postData = {
      uuid: activeAccountUUID,
      company_name: values.companyName,
      trading_name: values.tradingName,
      aus_business_no: values.ausBusinessNumber,
      service: this.convertServiceArrayData(this.state.tags),
      company_description: values.companyDescription,
      contact_number: values.contactNumber,
      email: values.email,
      website: values.website,
      street_address_one: values.streetAddressOne,
      street_address_two: values.streetAddressTwo,
      suburb: values.suburb,
      post_code: values.postCode,
      logoImageArray: this.state.logoImageArray,
      state: this.state.stateObject.value,
    };

    // send request to server for data update;
    axiosInstance
      .post('/account/update-company-profile', postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          //changing the company name in state
          const activeAccount = LocalStorage.getActiveAccount();
          activeAccount.company_name = values.companyName;
          updateActiveAccountDetails(activeAccount);

          this.setStateData(response.data.data);
          if (activeAccount.account_type === accountType.soletrader) {
            toast.success('Company profile updated successfully.', {
              autoClose: 2000,
              onClose: () => {
                this.props.history.push('/dashboard');
              },
            });
          } else {
            toast.success('Company profile updated successfully.', {
              autoClose: 1500,
            });
          }
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
          scrollToComponent(this.alertErrorRef, { duration: 500 });
        }
      })
      .then(() => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  fetchActiveCompanyDetail = () => {
    // Set loader to true;
    this.setState({
      showLoader: true,
    });

    const { activeAccountUUID } = this.context;

    // prepare JSON data for the request;
    const postData = {
      uuid: activeAccountUUID,
    };

    // send request to server;
    axiosInstance
      .post('/account/get-company-profile-data', postData)
      .then((response: AxiosResponse) => {
        if (response.data.success === 1) {
          this.setStateData(response.data.data);
        } else {
          this.setState({
            errorMessages: response.data.messages,
          });
        }
      })
      .catch((err) => {});
  };

  setStateData = (data: any) => {
    this.setState({
      showLoader: false,
      crnNo: data.CRN_number,
      companyName: data.company_name,
      tradingName: data.trading_name,
      ABN: data.aus_business_no,
      tags: this.convertServiceArrayData(data.service),
      companyDescription: data.company_description,
      contactNo: data.contact_number,
      email: data.email,
      website: data.website,
      streetAddOne: data.street_address_one,
      streetAddTwo: data.street_address_two,
      suburb: data.suburb,
      postCode: data.post_code,
      logoImageUrl: data.logoImageUrl,
      stateObject: data.state === null ? null : { value: data.state, label: data.state },
      hideMessageDiv:
        this.convertServiceArrayData(data.service).length > 0 ? true : false,
    });
  };

  convertServiceArrayData = (data: any) => {
    let dataArray: any = [];
    if (data.length > 0 && data[0] !== '') {
      if (typeof data[0] === 'string') {
        for (let i = 0; i < data.length; i++) {
          let objData: any = { id: data[i], text: data[i] };
          dataArray.push(objData);
        }
      } else {
        for (let i = 0; i < data.length; i++) {
          dataArray.push(data[i].text);
        }
      }
    }
    return dataArray;
  };

  componentDidMount() {
    document.title = `Company Profile ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch the active company profile detail;
    this.fetchActiveCompanyDetail();
  }

  render() {
    const filestackLSObject = LocalStorage.getFilestackObject();
    const activeAccount = LocalStorage.getActiveAccount();
    const isCorporateAccount = LocalStorage.isCurrentAccountCorporate();
    const abnLabel = 'Australian Business Number (ABN)';
    return (
      <>
        {activeAccount.account_type === accountType.corporate && (
          <Breadcrumb selectedArea="profile" />
        )}
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={9} lg={7} className="col-centered">
              <div
                className={
                  'myprofile--section myprofile--section-sm ' +
                  (isCorporateAccount ? 'u-pt--0' : '')
                }
              >
                {!this.state.showLoader ? (
                  <>
                    {!this.state.hideMessageDiv ? (
                      <>
                        <div className="row--full">
                          <Alert
                            color="danger"
                            className="u-radius--0 u-border--0 text-center fs--15"
                          >
                            <Lock fontSize="small" className="icon u-mr--5 fs--20" />
                            <span className="message">
                              To unlock the ability to share your profile and
                              documentation with others you need to complete your profile
                            </span>
                          </Alert>
                        </div>
                      </>
                    ) : (
                      ''
                    )}
                    <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--5">
                      <h2 className="u-heading--large u-heading--large-sm">
                        Company Profile
                      </h2>
                      <div className="page-head--actions page-head--actions-sm">
                        <button
                          type="button"
                          onClick={() => this.props.history.push('/profile/preview')}
                          className="c-button c-button--primary c-button--primary-bordered c-button-radius c-button-inline size--xs has-icon--before"
                        >
                          <VisibilityIcon
                            fontSize="small"
                            className="icons u-mr--5"
                          ></VisibilityIcon>
                          Preview
                        </button>
                        <button
                          type="button"
                          onClick={this.shareModalHandler}
                          className="c-button c-button--primary c-button--primary-bordered c-button-radius c-button-inline size--xs has-icon--before"
                        >
                          <ScreenShare
                            fontSize="small"
                            className="icons u-mr--5"
                          ></ScreenShare>
                          Share
                        </button>
                        <ShareProfileModal
                          isOpen={this.state.shareModalOpen}
                          toggle={this.shareModalHandler}
                        />
                      </div>
                    </div>
                    <p className="fs--14 dark-gray">
                      The information below will display on your summary profile unless it
                      is stated otherwise.
                    </p>
                    <AlertError
                      ref={(div: any) => {
                        this.alertErrorRef = div;
                      }}
                      message={this.state.errorMessages}
                    />
                    <AvForm
                      className="form-horizontal"
                      autoComplete="off"
                      onSubmit={this.submitHandler}
                    >
                      <h4 className="section--title u-bold u-mb--15">COMPANY DETAILS</h4>
                      <FormGroup className="">
                        <Label for="uniquenumber">Customer Reference Number</Label>
                        <FormText color="black">
                          {this.state.crnNo}{' '}
                          <CopyToClipboard
                            text={this.state.crnNo}
                            onCopy={this.updateCopiedState}
                          >
                            <button type="button" className="links--primary">
                              <span className="svg--icon">
                                <FileCopy fontSize="small" className="icons"></FileCopy>
                              </span>
                            </button>
                          </CopyToClipboard>
                          {this.state.copied && (
                            <span className="clipboard">Copied to clipboard.</span>
                          )}
                        </FormText>
                      </FormGroup>

                      <AvField
                        name="companyName"
                        label="Company Name"
                        required
                        errorMessage="Please enter Company Name"
                        value={this.state.companyName}
                      />

                      <AvField
                        name="tradingName"
                        label="Trading name (If different to company name)"
                        value={this.state.tradingName}
                      />

                      <AvField
                        name="ausBusinessNumber"
                        label={abnLabel}
                        className="form-control size--sm"
                        required
                        minLength={9}
                        maxLength={15}
                        errorMessage={'Please enter ' + abnLabel}
                        validate={{
                          pattern: {
                            value: '^[0-9s]+$',
                            errorMessage: 'You can enter only numbers',
                          },
                        }}
                        value={this.state.ABN}
                      />

                      <AvField
                        name="service"
                        label="Services"
                        required={this.state.tags.length > 0 ? false : true}
                        errorMessage="Please enter Services. Hit enter to add."
                        autofocus={false}
                        tags={this.state.tags}
                        placeholder="Enter Services. Hit enter to add."
                        handleDelete={this.handleDelete}
                        handleAddition={this.handleAddition}
                        handleDrag={this.handleDrag}
                        handleTagClick={this.handleTagClick}
                        tag={[Input, ReactTags]}
                      />

                      <AvGroup>
                        <Label htmlFor="companyDescription">Company Description</Label>
                        <AvInput
                          type="textarea"
                          name="companyDescription"
                          className="form-control textareasize--small"
                          required
                          value={this.state.companyDescription}
                        />
                        <AvFeedback>Please enter Company Description</AvFeedback>
                      </AvGroup>

                      <Row>
                        <Col xs={12} md={12} lg={12}>
                          <div className="profile--upload profile--upload-md">
                            {this.state.logoImageUrl && (
                              <div className="profile--thumbnail profile--thumbnail-md">
                                <img
                                  src={
                                    this.state.logoImageUrl
                                      ? this.state.logoImageUrl
                                      : ProfileAvatar
                                  }
                                  width="150"
                                  alt="Company Logo"
                                  className="u-radius--0"
                                  onClick={this.previewModalHandler}
                                />
                              </div>
                            )}
                            <div
                              className={`profile--controls profile--controls-md ${
                                this.state.logoImageUrl ? `` : ` u-ml--0`
                              }`}
                            >
                              <p>Company Logo (optional)</p>
                              <ReactFilestack
                                apikey={appConfig.FILESTACK_API_KEY}
                                actionOptions={{
                                  accept: 'image/*',
                                }}
                                clientOptions={{
                                  security: {
                                    policy: filestackLSObject.policy,
                                    signature: filestackLSObject.signature,
                                  },
                                }}
                                customRender={({ onPick }: FileStackProps) => (
                                  <button
                                    type="button"
                                    className="btn-upload u-flexed u-align-center"
                                    onClick={onPick}
                                  >
                                    <Backup
                                      fontSize="large"
                                      className="icons u-mr--5"
                                    ></Backup>{' '}
                                    Upload file
                                  </button>
                                )}
                                onSuccess={(res: any) => this.getImageURL(res)}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <div className="hr">
                        <hr />
                      </div>
                      <h4 className="section--title u-bold u-mb--15">Contact</h4>

                      <AvField
                        name="contactNumber"
                        label="Contact Number"
                        required
                        errorMessage="Please enter Contact Number"
                        minLength={10}
                        maxLength={10}
                        validate={{
                          pattern: {
                            value: '^[0-9]+$',
                            errorMessage: 'You can enter only numbers',
                          },
                        }}
                        value={this.state.contactNo}
                      />

                      <AvField
                        name="email"
                        id="email"
                        label="Email Address"
                        required
                        errorMessage="Please enter a valid Email Address"
                        validate={{
                          email: {
                            value: true,
                            errorMessage: 'Please enter a valid Email Address',
                          },
                        }}
                        value={this.state.email}
                      />

                      <AvField
                        name="website"
                        label="Website"
                        /**
                         * @TODO Uncomment when have to apply validation
                         */
                        // type="url"
                        // validate={{
                        //   pattern: {
                        //     value: '^[http://]+[https://]',
                        //     errorMessage: 'Please enter valid URL',
                        //   },
                        // }}
                        value={this.state.website}
                      />

                      <div className="hr">
                        <hr />
                      </div>

                      <h4 className="section--title u-bold u-mb--15">Address</h4>

                      <AvField
                        name="streetAddressOne"
                        label="Street Address 1"
                        errorMessage="Please enter Street Address 1"
                        value={this.state.streetAddOne}
                      />

                      <AvField
                        name="streetAddressTwo"
                        label="Street Address 2"
                        value={this.state.streetAddTwo}
                      />

                      <AvField
                        name="suburb"
                        label="Suburb"
                        required
                        errorMessage="Please enter Suburb"
                        value={this.state.suburb}
                      />

                      <Row>
                        <Col xs={12} md={7} lg={8}>
                          <FormGroup
                            className={`react-select-orientation--top ${
                              this.state.stateObjectError ? `form-group--has-error` : ``
                            }`}
                          >
                            <Label htmlFor="state">State</Label>
                            <Select
                              id="state"
                              name="state"
                              options={this.options}
                              placeholder="Select State"
                              tabSelectsValue={false}
                              value={this.state.stateObject}
                              onChange={this.stateChangeHandler}
                            />
                            <span className="help-block">Please select your state</span>
                          </FormGroup>
                        </Col>
                        <Col xs={12} md={5} lg={4}>
                          <AvField
                            name="postCode"
                            id="postCode"
                            label="Postcode"
                            required
                            errorMessage="Please enter Postcode"
                            value={this.state.postCode}
                            validate={{
                              maxLength: {
                                value: 4,
                                errorMessage: 'Please enter a valid 4 digit Postcode',
                              },
                              pattern: {
                                value: '^[0-9]',
                                errorMessage: 'You can enter only numbers',
                              },
                            }}
                          />
                        </Col>
                      </Row>

                      <div className="clearfix" />
                      <div className="u-mt--25 u-flexed u-align-center u-justify-center">
                        <Button
                          id="submit"
                          color="primary"
                          disabled={this.state.formSubmitted ? true : false}
                          className={`btn-radius btn-sm btn-w180 ${
                            this.state.formSubmitted ? `inlineloading` : ``
                          }`}
                        >
                          Update
                        </Button>
                      </div>
                    </AvForm>
                  </>
                ) : (
                  <Loader />
                )}
              </div>
            </Col>
          </Row>
        </Container>
        {this.state.previewModal && (
          <Lightbox
            mainSrc={this.state.logoImageUrl}
            onCloseRequest={() => this.setState({ previewModal: false })}
          />
        )}
      </>
    );
  }
}

export default withRouter(CompanyProfile);
