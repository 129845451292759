import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Container, Row, Col } from 'reactstrap';

// Static Imports
import TeamEmptyGraphic from '../../../assets/images/Team-Empty.jpg';

interface Props extends RouteComponentProps {
  message: string;
}

interface State {}

class BlankScreen extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
          <Row>
            <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
              <div className="myprofile--section myprofile--section-sm u-pt--0">
                <div className="search--profile">
                  <div className="search--profile__graphic search--profile__graphic-sm">
                    <img src={TeamEmptyGraphic} width="255" alt="Error" />
                  </div>
                  <p className="fs--15">
                    <strong>{this.props.message ? this.props.message : ''}</strong>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(BlankScreen);
