import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

// Import CSS
import '../activity.scss';

interface Props extends RouteComponentProps {
  data: any;
}

interface State {}

class CreateUser extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    return (
      <>
        <img
          className="avatar size--18"
          src={
            data.userProfilePic
              ? data.userProfilePic
              : data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : data.activityForProfileImageUrl
              ? data.activityForProfileImageUrl
              : Avatar
          }
          alt={data.userFirstName + ' ' + data.userLastName}
        />
        <p className="u-mr--5">
          {data.userFirstName} {data.userLastName}
        </p>
        created a new user - {data.message}
      </>
    );
  }
}

export default withRouter(CreateUser);
