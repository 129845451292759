import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

// Application imports;
import TableList from './TableList';
import NoDataMsg from '../../common/profile-preview/NoDataMsg';
import Loader from '../../../layout/includes/ContentLoader/Loader';

interface Props {
  showLoader: boolean;
  isOpen: boolean;
  toggle: Function;
  revisionList: any;
}

interface State {}

class RevisionModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        backdrop={true}
        id="AddDocumentModal"
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--exlarge modal--exlarge-md"
      >
        <ModalHeader
          className="start u-border--btm0 plr--45 plr--45-sm"
          toggle={() => this.props.toggle()}
        >
          <span className="fs--30 fs--30-sm">Revisions</span>
        </ModalHeader>
        <ModalBody className="plr--45 plr--45-sm" style={{ paddingTop: '15px' }}>
          {this.props.showLoader ? (
            <Loader />
          ) : this.props.revisionList === null ? (
            <NoDataMsg area="revision" />
          ) : (
            <TableList
              area="revision"
              showEditElements={false}
              type="revision"
              data={this.props.revisionList}
              visibleChangeHandler={() => {}}
              updateListData={() => {}}
            />
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default RevisionModal;
