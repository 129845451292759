import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';

//Application Imports;
import Project from '../../../api/Project';
import AppContext from '../../../components/AppContext';
import ProjectBlankScreen from './ProjectBlankScreen';
import ProjectTableHtml from './ProjectTableHtml';
import Loader from '../../../layout/includes/ContentLoader/Loader';

// Import CSS
import './team-empty.scss';
import './team-member-landing.scss';
import '../members/member-list.scss';

interface RouteInfo {
  uuid: string;
}

interface Props extends RouteComponentProps<RouteInfo> {
  userName?: string;
  title: string;
  area: string;
}

interface State {
  dropdownOpen: Array<boolean>;
  filterWord: string;
  projectList: Array<any>;
  invitedProjectList: Array<any>;
  currentProjectList: Array<any>;
  archivedProjectList: Array<any>;
  showLoader: boolean;
}

class ProjectListByTitle extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      dropdownOpen: [],
      filterWord: '',
      projectList: [],
      invitedProjectList: [],
      currentProjectList: [],
      archivedProjectList: [],
      showLoader: true,
    };
  }

  // Function for toggle dropdown option for edit and delete button;
  toggleDropdown = (key: number) => {
    let prevState = [...this.state.dropdownOpen];
    prevState[key] = !this.state.dropdownOpen[key];
    this.setState({
      dropdownOpen: prevState,
    });
  };

  // Function for filter the data;
  filterHandler = (e: any, buttonClick?: boolean) => {
    const value = buttonClick ? e : e.target.value;
    this.setState({
      filterWord: value,
    });

    const projectList = this.state.projectList.map((project: any) => {
      project.matchesFilterWord =
        project.project_name.toLowerCase().indexOf(value.toLowerCase()) !== -1
          ? true
          : false;
      return project;
    });

    this.setState({
      projectList,
    });
  };

  // Function for delete the project confirmation;
  deleteProjectConfirmation = (projectUuid: string) => {
    confirmAlert({
      title: 'Confirm',
      message: 'Do you really want to delete this project?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => this.deleteProject(projectUuid),
        },
        {
          label: 'No',
          onClick: () => {},
        },
      ],
    });
  };

  // Function for delete the project;
  deleteProject = (projectUuid: string) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Project.deleteProject(activeAccountUUID, projectUuid).then((response) => {
      if (response.data.success === 1) {
        this.updateProjectListState(projectUuid);
        toast.success('Project deleted successfully.', {
          autoClose: 2000,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  favouriteClickHandler = (projectUuid: string) => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Project.favouriteProjectToggler(activeAccountUUID, projectUuid).then((response) => {
      if (response.data.success === 1) {
        this.updateProjectListState(projectUuid, true);
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  updateProjectListState = (projectUuid: string, favouriteClicked: boolean = false) => {
    let projectList = [];
    if (!favouriteClicked) {
      projectList = this.state.projectList.filter((data: any) => {
        return data.uuid !== projectUuid;
      });
    } else {
      projectList = this.state.projectList.map((data: any) => {
        if (data.uuid === projectUuid) {
          data.favourite = data.favourite === 1 ? 0 : 1;
        }
        return data;
      });
    }

    this.setState({
      projectList,
    });
  };

  sortProjectByType = (projectList: Array<any>) => {
    const invitedProjectList: Array<any> = [], currentProjectList: Array<any> = [], archivedProjectList: Array<any> = [];

    if (projectList && projectList.length > 0) {
      projectList.forEach((data: any) => {
        if (data.total_linked_accounts && parseInt(data.total_linked_accounts) > 0 && !data.is_completed) {
          currentProjectList.push(data);
        }

        if ((!data.total_linked_accounts || data.total_linked_accounts === '0') && !data.is_completed) {
          invitedProjectList.push(data);
        }

        if (data.is_completed) {
          archivedProjectList.push(data);
        }
      });
    }

    return { invitedProjectList, currentProjectList, archivedProjectList };
  };

  fetchProjects = () => {
    const { activeAccountUUID } = this.context;
    Project.getProjects(activeAccountUUID, this.props.area).then((response) => {
      if (response.data.success === 1) {
        const { invitedProjectList, currentProjectList, archivedProjectList } = this.sortProjectByType(response.data.data.projects);

        this.setState({
          showLoader: false,
          projectList: response.data.data.projects,
          invitedProjectList: invitedProjectList,
          currentProjectList: currentProjectList,
          archivedProjectList: archivedProjectList,
        });
      }
    });
  };

  componentDidMount() {
    // fetch project list;
    this.fetchProjects();
  }

  render() {
    return (
      <>
        {this.state.showLoader ? (
          <div className="u-ml--20 loader-left">
            <Loader />
          </div>
        ) : this.state.projectList.length > 0 ? (
          <div className="myteams--section myteams--section-sm owl--content__heading">
            <div className="page-head page-head-sm center rows u-justify-btw noborder u-mb--50 u-mb--50-sm">
              <h2 className="u-heading--medium u-heading--medium-sm">
                {this.props.title} {this.props.userName && `(${this.props.userName})`}
              </h2>
            </div>
            <div className="portlet">
              <div className="portlet--body">
                <div className="portlet--body__wrapper">
                  {/* <div className="portlet--search size--300">
                    <Input
                      autoComplete="off"
                      name="filterValue"
                      id="filterValue"
                      className="form--fields"
                      placeholder="Search"
                      value={this.state.filterWord}
                      onChange={this.filterHandler}
                    />
                    <button className="portlet--search__btn" type="button">
                      <Search
                        fontSize="large"
                        className="icons"
                        onClick={() => this.filterHandler(this.state.filterWord, true)}
                      />
                    </button>
                  </div> */}
                  {this.props.area === 'allProject' && (
                    <div className="ml-auto">
                      {/* <Link
                        to={{
                          pathname: '/corporate/project/create',
                          state: { prevPath: this.props.location.pathname },
                        }}
                        className="btn-radius btn-w160 btn btn-primary"
                      >
                        + Add Project
                      </Link> */}
                    </div>
                  )}
                </div>

                {this.state.invitedProjectList.length > 0 ? 
                  <ProjectTableHtml
                    title="Invited Projects"
                    projectList={this.state.invitedProjectList}
                    area={this.props.area}
                    favouriteClickHandler={this.favouriteClickHandler}
                    deleteProjectConfirmation={this.deleteProjectConfirmation}
                  /> : null
                }

                {this.state.currentProjectList.length > 0 ? 
                  <ProjectTableHtml
                    title="Current Projects"
                    projectList={this.state.currentProjectList}
                    area={this.props.area}
                    favouriteClickHandler={this.favouriteClickHandler}
                    deleteProjectConfirmation={this.deleteProjectConfirmation}
                  /> : null
                }

                {this.state.archivedProjectList.length > 0 ? 
                  <ProjectTableHtml
                    title="Archived Projects"
                    projectList={this.state.archivedProjectList}
                    area={this.props.area}
                    favouriteClickHandler={this.favouriteClickHandler}
                    deleteProjectConfirmation={this.deleteProjectConfirmation}
                  /> : null
                }
              </div>
            </div>
          </div>
        ) : (
          <ProjectBlankScreen area={this.props.area} />
        )}
      </>
    );
  }
}

export default withRouter(ProjectListByTitle);
