import React from 'react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import { ListGroup, ListGroupItem } from 'reactstrap';

// Import CSS
import './team-overview.scss';
import '../team/team-member-landing.scss';

// Import Icons
import Check from '@material-ui/icons/Check';
import Helper, {
  GetAccountDocumentStatusClassName,
  getCircleColor,
} from '../../../helpers/Helpers';

interface Props extends RouteComponentProps {
  projectList: Array<any>;
  area: string;
}

interface State {}

class ProjectListMarkup extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  getDateFormat = (date: string) => {
    const currentDate: any = new Date(date).toISOString().split('T')[0];
    return Helper.getDocumentExpiryDate(currentDate);
  };

  render() {
    return (
      <>
        <div className="board--canvas__coloumn">
          <div className="board--canvas__widget">
            <div className="board--canvas__widget__content">
              <div className="board--canvas__widget__content--body">
                <div className="board--canvas__widget__content--body--scroll">
                  <div className="custom--search">
                    <div className="custom--search__lists">
                      <ListGroup>
                        {this.props.projectList.map((data: any) => (
                          <Link
                            to={`/corporate/project-view/${data.uuid}`}
                            key={data.uuid}
                          >
                            <ListGroupItem
                              className={`u-mb--10 ${GetAccountDocumentStatusClassName(
                                data.accountDocumentStatus,
                                false
                              )}`}
                            >
                              <div className="list-group--details noProfile">
                                <div className="u-flexed u-align-center">
                                  <div className="">
                                    <p className="user--name">{data.project_name}</p>
                                    <p className="user--email">
                                      {data.total_linked_accounts} members |{' '}
                                      {this.getDateFormat(data.start_date)}
                                    </p>
                                  </div>
                                  <div className="ml-auto">
                                    <div
                                      className={`checkmark ${
                                        this.props.area === 'complaint'
                                          ? `success`
                                          : `${getCircleColor(
                                              data.accountDocumentStatus
                                            )}`
                                      }`}
                                    >
                                      {this.props.area === 'complaint' ? (
                                        <Check
                                          fontSize="small"
                                          className="icons text-white"
                                        />
                                      ) : (
                                        data.total_linked_accounts
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </ListGroupItem>
                          </Link>
                        ))}
                      </ListGroup>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ProjectListMarkup);
