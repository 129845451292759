import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AvForm, AvField } from 'availity-reactstrap-validation';

//Application Imports;
import AppContext from '../../../components/AppContext';
import AlertError from '../../../layout/includes/Alert/Error';
import LinkMember from '../../../api/LinkMember';

// Import Css;
import 'react-image-lightbox/style.css';

interface Props extends RouteComponentProps {
  area: string;
  userAccountDocumentUuid: string;
  isOpen: boolean;
  toggle: Function;
}
interface State {
  formSubmitted: boolean;
  errorMessages: Array<string> | string;
}

class RejectDocumentModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  private alertErrorRef = React.createRef<any>();
  constructor(props: Props) {
    super(props);
    this.state = {
      formSubmitted: false,
      errorMessages: '',
    };
  }

  submitHandler = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;
    
    this.setState({
      formSubmitted: true,
      errorMessages: '',
    });

    if (this.props.area === 'pendingReject') {
      // request to server;
      LinkMember.rejectCorporatePendingInvite(
        activeAccountUUID,
        this.props.userAccountDocumentUuid,
        values.rejectMessage
      )
        .then((response) => {
          this.setState({
            formSubmitted: false,
          });
          if (response.data.success === 1) {
            toast.success('Request processed successfully.', {
              autoClose: 3000,
            });
            this.props.toggle(true);
          } else {
            this.setState({
              errorMessages: response.data.messages,
            });
          }
        })
        .catch((err) => {
          this.setState({
            formSubmitted: false,
          });
        });
    } else {
      // request to server;
      LinkMember.rejectCorporateAttachedDocuments(
        activeAccountUUID,
        this.props.userAccountDocumentUuid,
        values.rejectMessage
      )
        .then((response) => {
          this.setState({
            formSubmitted: false,
          });
          if (response.data.success === 1) {
            toast.success('Request processed successfully.', {
              autoClose: 3000,
            });
            this.props.toggle(true);
          } else {
            this.setState({
              errorMessages: response.data.messages,
            });
          }
        })
        .catch((err) => {
          this.setState({
            formSubmitted: false,
          });
        });
    }
  };

  render() {
    return (
      <>
        <Modal
          backdrop={true}
          id="ViewLinkedDocumentModal"
          isOpen={this.props.isOpen}
          toggle={() => this.props.toggle()}
          className="modal--large modal--large-md"
        >
          <ModalHeader
            className="start plr--60 plr--60-sm u-border--btm0 u-pt--25"
            toggle={() => this.props.toggle()}
          >
            Reject Document
          </ModalHeader>
          <ModalBody className="plr--60 plr--60-sm u-pb--40 u-pb--40-sm">
            <AlertError
              ref={(div: any) => {
                this.alertErrorRef = div;
              }}
              message={this.state.errorMessages}
            />
            <AvForm
              className="form-horizontal"
              autoComplete="off"
              onValidSubmit={this.submitHandler}
            >
              <AvField
                type="textarea"
                name="rejectMessage"
                label={'Reason for rejection'}
                placeholder="Enter your message"
                required
                errorMessage="Please enter reason for rejection."
              />
              <Row className="u-flexed u-align-center u-justify-center text-center">
                <Col xs={12} md={12} lg={12}>
                  <Button
                    type="submit"
                    disabled={this.state.formSubmitted}
                    className={`btn-radius btn btn-primary u-upper ${
                      this.state.formSubmitted ? `inlineloading` : ``
                    }`}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </AvForm>
          </ModalBody>
        </Modal>
      </>
    );
  }
}

export default withRouter(RejectDocumentModal);
