import React from 'react';
import { Container, Row, Col, Label } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

// Application imports;
import appConfig from '../../../config';
import MyLoader from '../../../components/UI/ContentLoader/ContentLoader';
import Activity from '../../../api/Activity';
import AppContext from '../../../components/AppContext';

// Partials Imports;
import ResetPassword from './partials/ResetPassword';
import CreateUser from './partials/CreateUser';
import ProfileView from './partials/ProfileView';
import ProjectView from './partials/ProjectView';
import ProfileUpdate from './partials/ProfileUpdate';
import ParentalConsent from './partials/ParentalConsent';
import PaymentDetail from './partials/PaymentDetail';
import ProjectCreate from './partials/ProjectCreate';
import DocumentCreate from './partials/DocumentCreate';
import DocumentApprove from './partials/DocumentApprove';
import LinkUnlinkMember from './partials/LinkUnlinkMember';

// Import Icons
import Search from '@material-ui/icons/Search';

// Import CSS
import './activity.scss';
import 'react-datepicker/dist/react-datepicker.css';

interface Props extends RouteComponentProps {}

interface State {
  searchValue: any;
  startDate: Date;
  showLoader: boolean;
  activityLogList: Array<any>;
  pageCount: number;
  showLoadMoreElement: boolean;
  searchValueRequired: boolean;
}

class ActivityLog extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      searchValue: '',
      startDate: new Date(),
      showLoader: true,
      activityLogList: [],
      pageCount: 1,
      showLoadMoreElement: false,
      searchValueRequired: true,
    };
  }

  loadMoreHandler = () => {
    this.getActivityLogData(this.state.searchValue, this.state.pageCount + 1);
  };

  resetFormHandler = () => {
    this.setState({
      searchValue: '',
      pageCount: 1,
      searchValueRequired: false,
    });
  };

  renderLogMessage = (type: string) => {
    switch (type) {
      case 'view_profile':
        return 'viewed your profile';
      case 'view_project':
        return 'viewed a project ';
      case 'parental_consent':
        return 'updated parental/guardian consent';
      case 'profile_detail':
        return 'updated profile details';
      case 'payment_detail':
        return 'updated payment details';
      case 'project_create':
        return 'created project';
      case 'document_add':
        return 'uploaded';
      case 'approve_document':
        return 'approved document';
      case 'link_member':
        return 'linked you';
      default:
        return '';
    }
  };

  getActivityLogData = (searchValue: any, pageNo: number) => {
    const { activeAccountUUID } = this.context;
    Activity.getActivityLogBySearch(activeAccountUUID, searchValue, pageNo).then(
      (response) => {
        if (response.data.success === 1) {
          this.setState({
            showLoader: false,
            activityLogList: response.data.data.activity_log,
            searchValue: searchValue,
            pageCount: pageNo,
            showLoadMoreElement:
              response.data.data.activity_log.length > 50 ? true : false,
          });
        }
      }
    );
  };

  getActivityLog = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    this.setState((prevState) => ({
      searchValueRequired:
        prevState.searchValue !== values.activitySearchValue ? false : true,
    }));
    if (values.activitySearchValue === '') {
      this.resetFormHandler();
    }
    // request to server;
    this.getActivityLogData(values.activitySearchValue, this.state.pageCount);
  };

  changeActiveClassHandler = (e: any) => {
    const element: any = e.currentTarget;
    let allElements = Array.from(
      document.querySelectorAll('.activity-list.is--selected')
    );
    for (let element of allElements) {
      element.classList.remove('is--selected');
    }
    element.classList.add('is--selected');
  };

  fetchActivityLog = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Activity.getActivityLog(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          activityLogList: response.data.data.activity_log,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  componentDidMount() {
    document.title = `Activity Log ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch the activity logs of profile;
    this.fetchActivityLog();
  }

  render() {
    return (
      <>
        <div className="activity--logs activity--logs-sm">
          <Container fluid={true}>
            <Row className="row">
              <Col xs={12} md={7} lg={6} className="col-centered">
                <div className="page-head page-head-sm start noborder">
                  <h1 className="u-heading--medium u-heading--medium-sm">Activity Log</h1>
                </div>
                <div className="portlet">
                  <div className="portlet--body">
                    <div className="portlet--body__wrapper u-flexed u-justify-start">
                      <div className="portlet--search size--300">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={this.getActivityLog}
                          autoComplete="off"
                        >
                          <AvGroup>
                            <Label for="activitySearchValue">Search keyword</Label>
                            <AvInput
                              type={'text'}
                              name="activitySearchValue"
                              required={this.state.searchValueRequired ? true : false}
                              className="form--fields"
                              placeholder="Search"
                              value={this.state.searchValue}
                            />
                            <button
                              className="portlet--search__btn u-mt--15"
                              type="submit"
                            >
                              <Search fontSize="large" className="icons" />
                            </button>
                            <AvFeedback>Please enter name or email to search</AvFeedback>
                          </AvGroup>
                        </AvForm>
                      </div>
                      <div className="portlet--drop">
                        {/* <button
                          type="button"
                          className="portlet--drop--btn"
                          onClick={this.resetFormHandler}
                        >
                          Reset
                        </button> */}
                        {/* <button type="button" className="portlet--drop--btn">
                          <DateRangeIcon fontSize="small" className="icons" />
                        </button>
                        <DatePicker
                          name="IssueDate"
                          id="IssueDate"
                          selected={this.state.startDate}
                          onChange={this.dateSearchHandler}
                          className="form-control"
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
                {this.state.showLoader ? (
                  <MyLoader />
                ) : (
                  <>
                    <div className="activity--timeline activity--timeline-sm">
                      <ul>
                        {this.state.activityLogList.length > 0 ? (
                          this.state.activityLogList.map((data: any, key: number) => (
                            <li
                              className={`activity-list ${
                                key === 0 ? `is--selected` : ``
                              }`}
                              key={data.activityLogUuid}
                              onClick={this.changeActiveClassHandler}
                            >
                              <div className="activity--timeline__desc">
                                <div className="u-flexed">
                                  {data.type === 'reset_password' && (
                                    <ResetPassword data={data} />
                                  )}

                                  {data.type === 'user_create' && (
                                    <CreateUser data={data} />
                                  )}

                                  {data.type === 'view_profile' && (
                                    <ProfileView data={data} />
                                  )}

                                  {data.type === 'view_project' && (
                                    <ProjectView data={data} />
                                  )}

                                  {data.type === 'parental_consent' && (
                                    <ParentalConsent data={data} />
                                  )}

                                  {data.type === 'profile_detail' && (
                                    <ProfileUpdate data={data} />
                                  )}

                                  {data.type === 'payment_detail' && (
                                    <PaymentDetail data={data} />
                                  )}

                                  {data.type === 'project_create' && (
                                    <ProjectCreate data={data} />
                                  )}

                                  {data.type === 'document_add' && (
                                    <DocumentCreate data={data} />
                                  )}

                                  {data.type === 'approve_document' && (
                                    <DocumentApprove data={data} />
                                  )}

                                  {(data.type === 'link_member' ||
                                    data.type === 'unlink_member') && (
                                    <LinkUnlinkMember
                                      data={data}
                                      isTypeLink={
                                        data.type === 'link_member' ? true : false
                                      }
                                    />
                                  )}

                                  {/**
                                  <>
                                    <img
                                      className="avatar size--18"
                                      src={
                                        data.activityByProfileImageUrl
                                          ? data.activityByProfileImageUrl
                                          : Avatar
                                      }
                                      alt="Avatar"
                                    />
                                    <Link
                                      to={`/account/${data.activityByUuid}`}
                                      className="u-mr--5"
                                    >
                                      {data.activityByName
                                        ? data.activityByName
                                        : `${data.userFirstName} ${data.userLastName}`}
                                    </Link>{' '}
                                    {this.renderLogMessage(data.type)}
                                    &nbsp;
                                    {data.projectUuid && (
                                      <Link to={`/project/${data.projectUuid}`}>
                                        {data.projectName}
                                      </Link>
                                    )}
                                  </>
                                  {data.documentUuid && <p>{data.documentName}</p>}
                                  */}
                                </div>
                                <time>{data.date}</time>
                              </div>
                            </li>
                          ))
                        ) : (
                          <p>No data found</p>
                        )}
                      </ul>
                    </div>
                    {this.state.showLoadMoreElement && (
                      <button onClick={() => this.loadMoreHandler()}>Load more...</button>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(ActivityLog);
