import React from 'react';
import { Row, Col, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { toast } from 'react-toastify';

//Application Imports
import AlertError from '../Alert/Error';
import AppContext from '../../../components/AppContext';
import LocalStorage from '../../../helpers/LocalStorage';
import { accountType } from '../../../helpers/Constant';

// Import Axios instance
import axiosInstance from '../../../utils/AppAxios';
import { AxiosResponse } from 'axios';

interface Props {
  isOpen: boolean;
  toggle: Function;
  isProjectScreen?: boolean;
  projectUrl?: string;
  projectUuid?: string;
}

interface State {
  formSubmitted: boolean;
  alertErrorMessage: Array<string> | string;
}

class ShareProfileModal extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      formSubmitted: false,
      alertErrorMessage: '',
    };
  }

  handleFormSubmit = (e: React.FormEvent<HTMLInputElement>, values: any) => {
    const { activeAccountUUID } = this.context;
    // Set loader to true;
    this.setState({
      formSubmitted: true,
      alertErrorMessage: '',
    });

    let postData = {
      uuid: activeAccountUUID,
      first_name: values.firstName,
      last_name: values.lastName,
      email: values.email,
      subject: values.subject,
    };

    let url = '/account/share-account';
    let message = 'Your profile is successfully shared.';
    if (this.props.isProjectScreen) {
      url = '/project/share-project';
      message = 'Your project is successfully shared.';
      let data = {
        project_url: this.props.projectUrl,
        project_uuid: this.props.projectUuid,
      };
      postData = Object.assign(data, postData);
    }

    axiosInstance
      .post(url, postData)
      .then((response: AxiosResponse) => {
        this.setState({
          formSubmitted: false,
        });

        if (response.data.success === 1) {
          toast.success(message, {
            autoClose: 3000,
          });
          this.props.toggle();
        } else {
          this.setState({
            alertErrorMessage: response.data.messages,
          });
        }
      })
      .catch((err) => {
        this.setState({
          formSubmitted: false,
        });
      });
  };

  render() {
    const activeAccount = LocalStorage.getActiveAccount();
    let subjectPlaceHolder = 'Email Subjectline: enter project or purpose for sharing';
    let secondPoint = 'Enter project reference or purpose of sharing';
    if (activeAccount.account_type === accountType.personal) {
      subjectPlaceHolder = 'Position or potential employment';
      secondPoint = 'Enter position you are applying for';
    }
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--medium modal--medium-md"
        backdrop={true}
      >
        <ModalHeader className="center u-border--btm0" toggle={() => this.props.toggle()}>
          Share {this.props.isProjectScreen ? 'Project' : 'Profile'}
        </ModalHeader>
        <ModalBody className="plr--60 plr--60-sm">
          <ul className="share--lists share--lists-sm">
            <li>Enter details of recipient</li>
            <li>{secondPoint}</li>
          </ul>
          {!this.props.isProjectScreen && (
            <p className="u-mb--25">
              Your CRN will be emailed to the recipient which allows the viewing of your
              summary profile.
            </p>
          )}

          <h3 className="modal-section--title d-none">Enter user details</h3>
          <AlertError message={this.state.alertErrorMessage} />
          <AvForm
            className="form-horizontal"
            onValidSubmit={this.handleFormSubmit}
            autoComplete="off"
          >
            <Row className="two--Column">
              <Col xs={12} md={6} lg={6}>
                <AvField
                  name="firstName"
                  label="First Name"
                  id="firstName"
                  placeholder="First Name"
                  required
                  errorMessage="Please enter First Name"
                />
              </Col>
              <Col xs={12} md={6} lg={6} className="">
                <AvField
                  name="lastName"
                  label="Last Name"
                  id="lastName"
                  placeholder="Last Name"
                  required={
                    activeAccount.account_type === accountType.personal ? false : true
                  }
                  errorMessage="Please enter Last Name"
                />
              </Col>
            </Row>

            <AvField
              type="email"
              name="email"
              id="email"
              label="Email"
              placeholder="Email"
              required
              errorMessage="Please enter Email"
              validate={{
                email: {
                  value: true,
                  errorMessage: 'Please enter a valid Email address',
                },
              }}
            />

            <AvField
              type="text"
              name="subject"
              id="subject"
              label="Email Subject Line"
              placeholder={subjectPlaceHolder}
              required
              errorMessage="Please enter Email Subject"
            />

            <Row className="u-flexed u-align-center u-justify-center u-mb--30 u-mt--30">
              <Col xs={12} md={12} lg={12} className="">
                <Button
                  type="submit"
                  color="primary"
                  id="submit"
                  disabled={this.state.formSubmitted ? true : false}
                  className={`has--centered ${
                    this.state.formSubmitted ? `inlineloading` : ``
                  }`}
                >
                  Submit
                </Button>
              </Col>
            </Row>
          </AvForm>
          {!this.props.isProjectScreen && (
            <div className="modal--tips modal--tips-sm">
              <h4>TIP:</h4>
              <ul className="tips--lists tips--lists-sm">
                {activeAccount.account_type === accountType.personal && (
                  <li>Include your resume</li>
                )}

                <li>
                  Check your profile includes all documents related to the project before
                  sending.
                </li>
                <li>You can view who accesses your profile in Activity Log.</li>
                <li>
                  <strong>Do Not Share Your Password</strong>
                </li>
              </ul>
            </div>
          )}
        </ModalBody>
      </Modal>
    );
  }
}

export default ShareProfileModal;
