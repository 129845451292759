import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Link, RouteComponentProps } from 'react-router-dom';

// Application Imports
import Layout from './Layout';
import appConfig from '../../../config';
import ProjectsList from './ProjectsList';
import ProjectColumnView from './ProjectColumnView';
import LocalStorage from '../../../helpers/LocalStorage';

// Import Icons
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import List from '@material-ui/icons/List';

interface Props extends RouteComponentProps {}

interface State {
  isListView: boolean;
}

class ProjectLanding extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isListView: false,
    };
  }

  layoutTypeHandler = (type: string) => {
    if (type === 'list') {
      this.setState({
        isListView: true,
      });
    } else {
      this.setState({
        isListView: false,
      });
    }
  };

  componentDidMount() {
    document.title = `Internal Projects ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    const prevEditState: any = this.props.location.state;
    if (prevEditState) {
      this.setState({
        isListView: prevEditState.isEditState,
      });
    }
  }

  render() {
    return (
      <>
        <Layout>
          <div className="owl--content">
            <header className="owl--content__heading d-flex align-items-center">
              <ButtonGroup className="u-mr--10">
                <Button
                  color="secondary"
                  size="xs"
                  className={!this.state.isListView ? 'is--selected' : ''}
                  onClick={() => this.layoutTypeHandler('column')}
                >
                  <ViewQuilt fontSize="large" className="icons" />
                </Button>
                <Button
                  color="secondary"
                  size="xs"
                  className={this.state.isListView ? 'is--selected' : ''}
                  onClick={() => this.layoutTypeHandler('list')}
                >
                  <List fontSize="large" className="icons" />
                </Button>
              </ButtonGroup>
              {!LocalStorage.hasManagerRole() && (
                <div className="ml-auto u-flexed u-align-center mobile-nowrap d-none d-sm-flex">
                  <Link
                    color="secondary"
                    to={{
                      pathname: '/corporate/project/create',
                      state: { prevPath: this.props.location.pathname },
                    }}
                    className="c-button c-button--primary c-button-xs"
                  >
                    + Add Project
                  </Link>
                </div>
              )}
            </header>
            {this.state.isListView ? (
              <ProjectsList area="allProject" title="Projects List" />
            ) : (
              <ProjectColumnView />
            )}
          </div>
        </Layout>
      </>
    );
  }
}

export default ProjectLanding;
