import React from 'react';

// Static Includes
import Layout from './Layout';
import appConfig from '../../../config';
import MemberList from './MemberList';

interface Props {}

interface State {}

class TeamLanding extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    document.title = `Teams ${appConfig.DOCUMENT_TITLE_POSTFIX}`;
  }

  render() {
    return (
      <>
        <Layout>
          <MemberList />
        </Layout>
      </>
    );
  }
}

export default TeamLanding;
