import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import './select.scss';

interface Props extends RouteComponentProps {
  data: any;
  placeholder?: string;
  defaultMsg?: string;
}

interface State {}

class SelectOptions extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const data = this.props.data;
    return (
      <>
        {data ? (
          <>
            <option value="">
              {this.props.placeholder ? this.props.placeholder : 'Select option...'}
            </option>
            {data.map((data: any) => (
              <option key={data.value} value={data.uuid}>
                {data.value}
              </option>
            ))}
          </>
        ) : (
          <option value="">
            {this.props.defaultMsg ? this.props.defaultMsg : 'No options available'}
          </option>
        )}
      </>
    );
  }
}

export default withRouter(SelectOptions);
