import React from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';

// Application Imports;
import ColumnView from './ColumnView';
import ListView from './ListView';

// Import Icons
import ViewQuilt from '@material-ui/icons/ViewQuilt';
import List from '@material-ui/icons/List';

// Import CSS
import './team-member-landing.scss';

interface Props extends RouteComponentProps {
  employeeList: Array<any>;
  subcontractorList: Array<any>;
  accountList: Array<any>;
  userRoledocumentMapping: Array<any>;
  documentHeadingList: Array<any>;
  approvedList: Array<any>;
  needApprovalList: Array<any>;
  pendingList: Array<any>;
  expiringList: Array<any>;
}

interface State {
  isListView: boolean;
}

class TeamMemberList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isListView: false,
    };
  }

  layoutTypeHandler = (type: string) => {
    if (type === 'list') {
      this.setState({
        isListView: true,
      });
    } else {
      this.setState({
        isListView: false,
      });
    }
  };

  componentDidMount() {
    const prevEditState: any = this.props.location.state;
    if (prevEditState) {
      this.setState({
        isListView: prevEditState.isListView,
      });
    }
  }

  render() {
    return (
      <>
        <header className="owl--content__heading d-flex align-items-center">
          <ButtonGroup className="u-mr--10">
            <Button
              color="secondary"
              size="xs"
              className={!this.state.isListView ? 'is--selected' : ''}
              onClick={() => this.layoutTypeHandler('column')}
            >
              <ViewQuilt fontSize="large" className="icons" />
            </Button>
            <Button
              color="secondary"
              size="xs"
              className={this.state.isListView ? 'is--selected' : ''}
              onClick={() => this.layoutTypeHandler('list')}
            >
              <List fontSize="large" className="icons" />
            </Button>
          </ButtonGroup>
          {/* <Button color="secondary" type="button" className={`btn btn--search`}>
            <Search fontSize="large" className="icons" />
          </Button> */}

          <div className="ml-auto u-flexed u-align-center mobile-nowrap d-none d-sm-flex">
            <Link
              color="secondary"
              to="/corporate/member"
              className="c-button c-button--primary c-button-xs"
            >
              + Employee
            </Link>
          </div>
        </header>
        {this.state.isListView ? (
          <ListView
            employeeList={this.props.employeeList}
            subcontractorList={this.props.subcontractorList}
            userRoledocumentMapping={this.props.userRoledocumentMapping}
            documentHeadingList={this.props.documentHeadingList}
            accountList={this.props.accountList}
          />
        ) : (
          <ColumnView
            approvedList={this.props.approvedList}
            needApprovalList={this.props.needApprovalList}
            pendingList={this.props.pendingList}
            expiringList={this.props.expiringList}
          />
        )}
      </>
    );
  }
}

export default withRouter(TeamMemberList);
