import React, { Component } from 'react';

import LocalStorage, { Account } from '../helpers/LocalStorage';

interface Props {}
export interface IUser {
  firstName?: string;
  lastName?: string;
  email?: string;
  totalAccounts?: number;
}
export interface IState {
  user: IUser;
  isLoggedIn: boolean;
  activeAccountUUID: string;
  activeAccount: Account;
}

//initalizing Local Storage
LocalStorage.init();

const localStorageJSONValue = LocalStorage.getJsonData();

//creating context
const AppContext = React.createContext({} as IState);

export const AppContextConsumer = AppContext.Consumer;

class AppContextProvider extends Component<{}, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      user: {
        firstName: localStorageJSONValue.user.first_name,
        lastName: localStorageJSONValue.user.last_name,
        email: localStorageJSONValue.user.email,
        totalAccounts: localStorageJSONValue.accountsList.length,
      },
      activeAccountUUID: localStorageJSONValue.activeAccountUUID,
      isLoggedIn: localStorageJSONValue.isLoggedIn,
      activeAccount: LocalStorage.getActiveAccount(),
    };
  }

  login = (data: any) => {
    const localStorageData = {
      user: data.user,
      accountsList: data.accountsList,
      isLoggedIn: true,
      activeAccountUUID: data.accountsList ? data.accountsList[0].uuid : '',
      filestack: data.filestack,
      intercom: data.intercom,
    };
    LocalStorage.setJsonData(localStorageData);
    LocalStorage.setJwt(data.jwt);

    const state = {
      user: {
        firstName: data.user.first_name,
        lastName: data.user.last_name,
        email: data.user.email,
        totalAccounts: data.accountsList.length,
      },
      activeAccountUUID: data.accountsList ? data.accountsList[0].uuid : '',
      isLoggedIn: true,
    };
    this.setState(state);
  };

  logout = () => {
    LocalStorage.clearData();

    // @ts-ignore
    window.Intercom('shutdown');

    window.location.href = '/auth/login';
  };

  setAccountsList = (accountsList: Array<any>) => {
    LocalStorage.changeLocalStorageVal('accountsList', accountsList);

    let user = this.state.user;
    user.totalAccounts = accountsList.length;

    this.setState({
      user: user,
    });
  };

  updateUser = (data: { firstName: string; lastName: string; email: string }) => {
    //update local storage
    let localUser = LocalStorage.getUser();
    localUser.first_name = data.firstName;
    localUser.last_name = data.lastName;
    localUser.email = data.email;

    LocalStorage.setUser(localUser);

    //changing the local state will cause render, so Local Storage needs to be updated first
    let user = this.state.user;
    user.firstName = data.firstName;
    user.lastName = data.lastName;
    user.email = data.email;

    //update state user object
    this.setState({ user: user });
  };

  setActiveAccountUUID = (value: string) => {
    //update local storage
    LocalStorage.setActiveAccountUUID(value);

    //update state activeAccountUUID value
    this.setState({
      activeAccountUUID: value,
      activeAccount: LocalStorage.getActiveAccount(),
    });
  };

  updateActiveAccountDetails = (accountDetails: Account): void => {
    LocalStorage.updateActiveAccountDetails(accountDetails);

    this.setActiveAccountUUID(accountDetails.uuid);
  };

  render() {
    const { children } = this.props;
    const value = {
      ...this.state,
      login: this.login,
      logout: this.logout,
      setActiveAccountUUID: this.setActiveAccountUUID,
      updateUser: this.updateUser,
      setAccountsList: this.setAccountsList,
      updateActiveAccountDetails: this.updateActiveAccountDetails,
    };

    return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
  }
}

export default AppContext;

export { AppContextProvider };
