import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Icon
import Search from '@material-ui/icons/Search';

interface Props {
  area?: string;
}

interface State {}

class NoDataMsg extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Container fluid={true} className="u-pb--50 u-pb--50-sm container-fluid-sm">
        <Row>
          <Col xs={12} sm={12} md={11} lg={11} className="col-centered">
            <div className="myprofile--section myprofile--section-sm u-pt--0 text-center">
              <div className="No--Results">
                <Search fontSize="large" className="icons" />
                <h3 className="fs--20">
                  No {this.props.area === 'revision' ? 'Revision' : 'Document'}(s)
                  attached to this{' '}
                  {this.props.area === 'revision' ? 'Document' : 'account'}{' '}
                </h3>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default NoDataMsg;
