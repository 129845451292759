import React, { FunctionComponent, useEffect } from 'react';
import { Route, RouteProps, Redirect } from 'react-router';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

//Application Imports
import appConfig from '../config';
import LocalStorage from '../helpers/LocalStorage';

//Common Pages
import Layout from '../layout/Layout';
import Layout404 from '../layout/404Layout';

// Maintenance & page
import Maintenance from '../pages/error/Maintenance';

interface AppRouteProps extends RouteProps {
  component: new (props: any) => React.Component;
  layout?: new (props: any) => React.Component;
  accessControl?: string;
  userRoleControl?: string;
  excludeUserRoleControl?: string;
  excludeManagerControl?: boolean;
}

const AppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  layout: ComponentLayout = Layout,
  accessControl = 'any',
  userRoleControl = '',
  excludeUserRoleControl = '',
  excludeManagerControl = false,
  ...rest
}) => {
  const localStorageJsonData = LocalStorage.getJsonData();
  const isLoggedIn = localStorageJsonData.isLoggedIn;
  const defaultRoute = LocalStorage.getDefaultRoute();
  const activeAccount = LocalStorage.getActiveAccount();
  const currentAccountType = LocalStorage.getCurrentAccountType();
  const hasManagerRole = LocalStorage.hasManagerRole();
  const isCorporateViewAccount = LocalStorage.isCurrentAccountCorporateViewOnly();
  const { pathname } = useLocation();

  //react keeps the browser position when clicking on Link, this will make the browser to scroll to the top
  useEffect(() => {
    window.scrollTo(0, 0);

    // Get the URL and add class to body as per URL change;
    const getPathName: string = pathname;
    let convertToClassSuffix: string = getPathName.split('/').join('-');
    convertToClassSuffix = `page-${convertToClassSuffix}`;
    document.body.className = convertToClassSuffix;
  }, [pathname]);

  //some routes can only be accessed if the user IS NOT logged in
  if (accessControl === 'nonloggedin' && isLoggedIn) {
    return <Redirect to={defaultRoute} />;
  }

  //some routes can only be accessed if the user IS logged in
  if (accessControl === 'private') {
    if (!isLoggedIn) {
      // set route in local storage;
      LocalStorage.setLoginRedirectRoute(rest.location?.pathname || '');
      return <Redirect to="/auth/login" />;
    }

    if (userRoleControl !== '' && !userRoleControl.includes(currentAccountType)) {
      return <Redirect to={defaultRoute} />;
    }
    if (excludeUserRoleControl !== '' && excludeUserRoleControl === currentAccountType) {
      return <Redirect to={defaultRoute} />;
    }

    //if the account is current closed and not fully paid (credit card failures etc etc then sending the usert to the account page)

    if (
      activeAccount.account_subscription_paid !== 1 &&
      rest.location?.pathname !== '/account/settings' &&
      !isCorporateViewAccount
    ) {
      return <Redirect to="/account/settings" />;
    }

    if (hasManagerRole && excludeManagerControl) {
      return <Redirect to={defaultRoute} />;
    }
  }

  if (appConfig.MAINTENANCE_MODE === 'true') {
    ComponentLayout = Layout404;
    Component = Maintenance;
  }

  // initialize Google Analytic;
  ReactGA.initialize(appConfig.GA_ID);
  if (isLoggedIn) {
    ReactGA.set({ userId: LocalStorage.getUser('uuid') });
  }
  ReactGA.pageview(pathname);

  // Intercom
  if (isLoggedIn) {
    // @ts-ignore
    window.Intercom('update', {
      user_id: localStorageJsonData.intercom.user_id,
      user_hash: localStorageJsonData.intercom.hash,
      email: localStorageJsonData.user.email,
      name:
        localStorageJsonData.user.first_name + ' ' + localStorageJsonData.user.last_name,
    });
  } else {
    // @ts-ignore
    window.Intercom('update');
  }

  return (
    <Route
      {...rest}
      render={(props) => (
        <ComponentLayout {...props}>
          <Component {...props} />
        </ComponentLayout>
      )}
    />
  );
};

//Wrapper Route for Non Logged In Users
export const NonLoggedInAppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return <AppRoute component={Component} accessControl="nonloggedin" {...rest} />;
};

//Wrapper Route for Logged In Users
export const PrivateAppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return <AppRoute component={Component} accessControl="private" {...rest} />;
};

//Wrapper Route for Personal & Soletrader Account Type
export const PersonalAppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  userRoleControl = 'personal,soletrader',
  ...rest
}) => {
  return (
    <AppRoute
      component={Component}
      layout={Layout}
      accessControl="private"
      userRoleControl={userRoleControl}
      {...rest}
    />
  );
};

//Wrapper Route for Company Account Type
export const CompanyAppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <AppRoute
      component={Component}
      layout={Layout}
      accessControl="private"
      userRoleControl="corporate"
      {...rest}
    />
  );
};

//Wrapper Route for Business Account Type
export const CorporateViewAppRoute: FunctionComponent<AppRouteProps> = ({
  component: Component,
  ...rest
}) => {
  return (
    <AppRoute
      component={Component}
      layout={Layout}
      accessControl="private"
      userRoleControl="corporate_viewonly"
      {...rest}
    />
  );
};

export default AppRoute;
