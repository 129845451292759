import React from 'react';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'reactstrap';

// Application Imports;
import AppContext from '../../../components/AppContext';
import appConfig from '../../../config';
import BlankScreen from './BlankScreen';
import LinkMember from '../../../api/LinkMember';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import MemberList from './MemberList';
import Breadcrumb from '../roles/Breadcrumb';

// Import CSS
import '../members/invite.scss';
import '../members/admin.scss';

interface Props {}

interface State {
  activeMemberList: Array<any>;
  activeMemberListCopy: Array<any>;
  pendingMemberList: Array<any>;
  showLoader: boolean;
  activeMemberCount: number;
  unlinkedMemberCount: number;
}

class CorporateSubcontractors extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      activeMemberList: [],
      activeMemberListCopy: [],
      pendingMemberList: [],
      activeMemberCount: 0,
      unlinkedMemberCount: 0,
    };
  }

  fetchMemberList = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    LinkMember.getSubcontractorsAccountsList(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        const activeMemberList = response.data.data.linked;
        const activeMemberCount = activeMemberList.filter(
          (data: any) => data.status !== 0
        ).length;
        const unlinkedMemberCount = activeMemberList.length - activeMemberCount;

        this.setState({
          showLoader: false,
          activeMemberList: response.data.data.linked,
          activeMemberListCopy: response.data.data.linked,
          pendingMemberList: response.data.data.pending,
          activeMemberCount,
          unlinkedMemberCount,
        });
      } else {
        toast.error(response.data.messages[0], {
          autoClose: 3000,
        });
      }
    });
  };

  updateMemberListStatus = (uuid: string, area?: string) => {
    let data = [];
    if (area && area === 'deleted') {
      data = this.state.activeMemberList.filter((data: any) => {
        return data.uuid !== uuid;
      });
    } else {
      data = this.state.activeMemberList.map((data: any) => {
        if (data.uuid === uuid) {
          data.status = data.status === 0 ? 1 : 0;
        }
        return data;
      });
    }
    this.setState({
      activeMemberList: data,
    });
  };

  removePendingMember = (pendingInviteUuid: string) => {
    const pendingMemberList = this.state.pendingMemberList.filter((data: any) => {
      return data.uuid !== pendingInviteUuid;
    });

    this.setState({
      pendingMemberList,
    });
  };

  componentDidMount() {
    document.title = `Linked Subcontractors ${appConfig.DOCUMENT_TITLE_POSTFIX}`;

    // fetch members list;
    this.fetchMemberList();
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevState.activeMemberList !== this.state.activeMemberList &&
      this.state.activeMemberList.length > 0
    ) {
      const activeMemberList = this.state.activeMemberList.filter((data: any) => {
        return data.status !== 0;
      });

      const unlinkedMemberList = this.state.activeMemberList.filter((data: any) => {
        return data.status !== 1;
      });

      this.setState({
        activeMemberCount: activeMemberList.length,
        unlinkedMemberCount: unlinkedMemberList.length,
      });
    }
  }

  render() {
    return (
      <>
        <Breadcrumb selectedArea="linkedSubcontractors" />
        <Container fluid={true} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col sm={12} md={11} lg={10} className="col-centered">
              {this.state.showLoader ? (
                <Loader />
              ) : this.state.activeMemberListCopy.length > 0 ||
                this.state.pendingMemberList.length > 0 ? (
                <MemberList
                  activeMemberCount={this.state.activeMemberCount}
                  unlinkedMemberCount={this.state.unlinkedMemberCount}
                  memberList={this.state.activeMemberList}
                  pendingMemberList={this.state.pendingMemberList}
                  updateMemberListStatus={this.updateMemberListStatus}
                  removePendingMember={this.removePendingMember}
                  reloadData={this.fetchMemberList}
                />
              ) : (
                <BlankScreen />
              )}
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default CorporateSubcontractors;
