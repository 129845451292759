import React from 'react';
import { withRouter, RouteComponentProps, Link as RouterLink } from 'react-router-dom';

import { Nav, NavItem } from 'reactstrap';
import { Link, animateScroll as scroll } from 'react-scroll';

interface MobileMenuProps extends RouteComponentProps {}

interface MobileMenuState {
  color: string;
  isOpen: boolean;
  dropdownOpen: boolean;
  setDropdownOpen: boolean;
}

class MobileMenu extends React.Component<MobileMenuProps, MobileMenuState> {
  constructor(props: MobileMenuProps) {
    super(props);
    this.state = {
      isOpen: false,
      dropdownOpen: false,
      setDropdownOpen: false,
      color: '',
    };
  }

  private navsidebarToggle = React.createRef<HTMLButtonElement>();

  toggle = () => {
    if (this.state.isOpen) {
      this.setState({
        color: 'transparent',
      });
    } else {
      this.setState({
        color: 'white',
      });
    }
  };
  scrollToTop = () => {
    scroll.scrollToTop();
  };

  closeNavSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
    let element: any = document.getElementById('jsCloseSidebar');
    element.classList.remove('toggled');
  };

  render() {
    return (
      <>
        <div className="widget__menuArea">
          <div className="widget__menuArea__content">
            <div className="widget__menuArea__content--body">
              <div className="widget__menuArea__content--body--scroll">
                <nav className="responsive-navigation">
                  <Nav className="responsive-navigation__list">
                    <NavItem className="responsive-navigation__item">
                      <Link
                        to="Features"
                        spy={true}
                        smooth={true}
                        offset={-10}
                        duration={2000}
                        className="nav-link"
                        activeClass="is--active"
                        onClick={() => this.closeNavSidebar()}
                      >
                        Features
                      </Link>
                    </NavItem>
                    <NavItem className="responsive-navigation__item">
                      <Link
                        to="AboutUs"
                        spy={true}
                        smooth={true}
                        offset={-40}
                        duration={2500}
                        className="nav-link"
                        activeClass="is--active"
                        onClick={() => this.closeNavSidebar()}
                      >
                        About us
                      </Link>
                    </NavItem>
                    <NavItem className="responsive-navigation__item">
                      <Link
                        to="Pricing"
                        spy={true}
                        smooth={true}
                        offset={-30}
                        duration={3000}
                        className="nav-link"
                        activeClass="is--active"
                        onClick={() => this.closeNavSidebar()}
                      >
                        Pricing
                      </Link>
                    </NavItem>
                    <NavItem className="responsive-navigation__item">
                      <RouterLink
                        to="/auth/login"
                        onClick={this.closeNavSidebar}
                        className="nav-link"
                      >
                        Login
                      </RouterLink>
                    </NavItem>
                    <NavItem className="responsive-navigation__item">
                      <Link
                        to="Pricing"
                        spy={true}
                        smooth={true}
                        offset={-30}
                        duration={500}
                        onClick={this.closeNavSidebar}
                        className="nav-link button"
                      >
                        Try For Free
                      </Link>
                    </NavItem>
                  </Nav>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(MobileMenu);
