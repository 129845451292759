import React from 'react';
import { Modal, ModalHeader, ModalBody, Table } from 'reactstrap';

// Application imports;
import NoDataMsg from '../../common/profile-preview/NoDataMsg';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import { documentStatusCode } from '../../../helpers/Constant';

interface Props {
  showLoader: boolean;
  isOpen: boolean;
  toggle: Function;
  attachedRoleList: any;
}

interface State {}

class AttachedRoleModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle()}
        className="modal--exlarge modal--exlarge-md"
        data-bg="grey"
        backdrop={true}
      >
        <ModalHeader
          className="start u-border--btm0 plr--45 plr--45-sm"
          toggle={() => this.props.toggle()}
        >
          <span className="fs--30 fs--30-sm">Attached Roles</span>
        </ModalHeader>
        <ModalBody className="plr--45 plr--45-sm" style={{ paddingTop: '10px' }}>
          <p>You can see a list of all the roles this document is applied to.</p>
          <div className="u-flexed fullwidth u-mb--0">
            {this.props.showLoader ? (
              <Loader />
            ) : this.props.attachedRoleList === null ? (
              <NoDataMsg area="attachedRole" />
            ) : (
              <div className="table-responsive u-mb--0">
                <Table className="table-bordered u-mb--0">
                  <thead>
                    <tr>
                      <th>Company name</th>
                      <th>Role</th>
                      <th>Role Requirement</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.attachedRoleList.map((data: any, key: number) => (
                      <tr key={key}>
                        <td>
                          <div className="u-flexed u-align-center">
                            <div>{data.company_name}</div>
                          </div>
                        </td>
                        <td>
                          <div className="u-flexed u-align-center">
                            <div>{data.role_title}</div>
                          </div>
                        </td>
                        <td>
                          <div className="u-flexed u-align-center">
                            {data.role_document_title}
                          </div>
                        </td>
                        <td
                          className={`${
                            data.document_status === documentStatusCode.rejected
                              ? `status status--danger`
                              : data.document_status ===
                                documentStatusCode.awaitingApproval
                              ? `status status--info`
                              : ``
                          }`}
                        >
                          <div className="u-flexed u-align-center">
                            <div>{data.document_status_text}</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </div>
        </ModalBody>
      </Modal>
    );
  }
}

export default AttachedRoleModal;
