import Api from './Api';

export default class Account extends Api {
  static getLinkedAccounts = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/account-link/linked-accounts', postData);
  };

  static requestLinkUnlink = async (
    accountUuid: string,
    userAccountUuid: string,
    unlink: boolean
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_uuid: userAccountUuid,
      unlink: unlink,
    };

    return Api.postRequest('/account-link/request-link-unlink', postData);
  };

  static cancelPendingCorporateInvite = async (
    accountUuid: string,
    pendingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pendingInviteUuid,
    };

    return Api.postRequest('/account-link/cancel-corporate-invite', postData);
  };

  static acceptPendingCorporateInvite = async (
    accountUuid: string,
    pendingInviteUuid: string,
    documentList: Array<any>
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pendingInviteUuid,
      document_list: documentList,
    };

    return Api.postRequest('/account-link/accept-corporate-invite', postData);
  };

  static subcontractorAcceptPendingCorporateInvite = async (
    accountUuid: string,
    pendingInviteUuid: string
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      pending_invite_uuid: pendingInviteUuid,
    };

    return Api.postRequest(
      '/account-link/subcontractor-accept-corporate-invite',
      postData
    );
  };

  static updateAttachedRoleDocuments = async (
    accountUuid: string,
    userAccountUuid: string,
    documentData: any
  ): Promise<any> => {
    const postData = {
      uuid: accountUuid,
      user_account_uuid: userAccountUuid,
      document_list: documentData,
    };

    return Api.postRequest(
      '/account-link/update-account-attached-role-documents',
      postData
    );
  };

  static getActivityLog = async (accountUuid: string): Promise<any> => {
    const postData = {
      uuid: accountUuid,
    };

    return Api.postRequest('/account/get-activity-logs', postData);
  };
}
