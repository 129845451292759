import React from 'react';
import { withRouter, RouteComponentProps, Link } from 'react-router-dom';

// Application Imports;
import LocalStorage from '../../../../helpers/LocalStorage';

// Import Icons
import Avatar from '../../../../assets/images/No-Image.jpg';

interface Props extends RouteComponentProps {
  data: any;
  isTypeLink: boolean;
}

interface State {}

class LinkUnlinkMember extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const hasCorporateAccount = LocalStorage.hasCorporateAccount();
    const { data, isTypeLink } = this.props;
    return (
      <>
        <img
          className="avatar size--18"
          src={
            data.userProfilePic
              ? data.userProfilePic
              : data.activityByProfileImageUrl
              ? data.activityByProfileImageUrl
              : Avatar
          }
          alt={data.userFirstName + ' ' + data.userLastName}
        />
        {hasCorporateAccount ? (
          <p className="u-mr--5">
            {data.userFirstName} {data.userLastName}
          </p>
        ) : (
          <>
            <Link to={`/account/${data.activityByUuid}`}>{data.activityByName}</Link>
            &nbsp;
          </>
        )}
        {isTypeLink ? 'linked' : 'unlinked'}&nbsp;
        <Link to={`/account/${data.activityForUuid}`}>{data.activityForName}</Link>
      </>
    );
  }
}

export default withRouter(LinkUnlinkMember);
