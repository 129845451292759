import React from 'react';

// Application imports;
import AppContext from '../../../components/AppContext';
import ProjectBlankScreen from './ProjectBlankScreen';
import Loader from '../../../layout/includes/ContentLoader/Loader';
import Project from '../../../api/Project';
import ProjectListMarkup from './ProjectListMarkup';

// Static imports;
import NoComplianceAlertGraphic from '../../../assets/images/No-Compliance-Alerts.jpg';
import NoApprovalsGraphic from '../../../assets/images/No-Approvals.svg';

// Import CSS
import './team-overview.scss';
import '../team/team-member-landing.scss';

interface Props {}

interface State {
  showLoader: boolean;
  approvedProjectList: Array<any>;
  expiringProjectList: Array<any>;
  projectListBlankScreenShow: boolean;
}

class ProjectColumnView extends React.PureComponent<Props, State> {
  static contextType = AppContext;
  constructor(props: Props) {
    super(props);
    this.state = {
      showLoader: true,
      projectListBlankScreenShow: true,
      approvedProjectList: [],
      expiringProjectList: [],
    };
  }

  getProjectList = (projectIds: any, projectList: any) => {
    let projectListArray: any = [];
    const projectDataArray = Object.entries(projectList);
    for (let [key, value] of projectDataArray) {
      for (let i = 0; i < projectIds.length; i++) {
        if (projectIds[i] === key) {
          projectListArray.push(value);
          /**
           * @TODO Uncommemnt if want unique record of each user;
           */
          // break;
        }
      }
    }

    return projectListArray;
  };

  fetchProjectSummary = () => {
    const { activeAccountUUID } = this.context;

    // request to server;
    Project.getProjectSummary(activeAccountUUID).then((response) => {
      if (response.data.success === 1) {
        this.setState({
          showLoader: false,
          approvedProjectList: this.getProjectList(
            response.data.data.okProjectsUuids,
            response.data.data.project_list
          ),
          expiringProjectList: this.getProjectList(
            response.data.data.alertProjectsUuids,
            response.data.data.project_list
          ),
          projectListBlankScreenShow:
            Object.keys(response.data.data.project_list).length > 0 ? false : true,
        });
      }
    });
  };

  componentDidMount() {
    // fetch kambean view data;
    this.fetchProjectSummary();
  }

  render() {
    return (
      <>
        {this.state.showLoader ? (
          <div className="u-ml--20 text-center">
            <Loader />
          </div>
        ) : !this.state.projectListBlankScreenShow ? (
          <div className="board--wrapper">
            <div className="board--header columnCount--02">
              <div className="board--header__coloumn">
                <div className="coloumn--title">
                  Non Compliant{' '}
                  <span className="count">({this.state.expiringProjectList.length})</span>
                </div>
              </div>
              <div className="board--header__coloumn">
                <div className="coloumn--title">
                  Compliant{' '}
                  <span className="count">({this.state.approvedProjectList.length})</span>
                </div>
              </div>
            </div>

            <div className="board--canvas columnCount--02">
              <div className="mobile--board--header__coloumn">
                <div className="coloumn--title">
                  Non Compliant{' '}
                  <span className="count">({this.state.expiringProjectList.length})</span>
                </div>
              </div>
              {this.state.expiringProjectList.length > 0 ? (
                <ProjectListMarkup
                  projectList={this.state.expiringProjectList}
                  area="nonComplaint"
                />
              ) : (
                <div className="board--canvas__coloumn">
                  <div className="board--canvas__widget">
                    <div className="board--canvas__widget__content">
                      <div className="board--canvas__widget__content--body">
                        <div className="board--canvas__widget__content--body--scroll">
                          <div className="text-center w250">
                            <div className="Graphic235">
                              <img
                                src={NoComplianceAlertGraphic}
                                alt="No Compliance Alert"
                              />
                            </div>
                            <h4 className="fs--15 u-bold">No Non-Compliant Project</h4>
                            <p className="fs--13 dark-gray">
                              All Projects are up to date with their compliance
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="mobile--board--header__coloumn">
                <div className="coloumn--title">
                  Compliant{' '}
                  <span className="count">({this.state.approvedProjectList.length})</span>
                </div>
              </div>
              {this.state.approvedProjectList.length > 0 ? (
                <ProjectListMarkup
                  projectList={this.state.approvedProjectList}
                  area="complaint"
                />
              ) : (
                <div className="board--canvas__coloumn">
                  <div className="board--canvas__widget">
                    <div className="board--canvas__widget__content">
                      <div className="board--canvas__widget__content--body">
                        <div className="board--canvas__widget__content--body--scroll">
                          <div className="text-center w250">
                            <div className="Graphic235">
                              <img src={NoApprovalsGraphic} alt="No Approvals" />
                            </div>
                            <h4 className="fs--15 u-bold">No Project</h4>
                            <p className="fs--13 dark-gray">
                              Pour yourself a coffee, you have no project to review
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        ) : (
          <ProjectBlankScreen area="allProject" />
        )}
      </>
    );
  }
}

export default ProjectColumnView;
