import axiosInstance from '../utils/AppAxios';

export default class Api {
  static postRequest = async (url: string, postData: any = {}): Promise<any> => {
    const response = await axiosInstance.post(url, postData);
    return response;
  };

  static getRequest = async (url: string): Promise<any> => {
    const response = await axiosInstance.get(url);
    return response;
  };
}
