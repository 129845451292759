import React from 'react';
import { Container, Row, Col } from 'reactstrap';

// Import Icons
import InviteGraphic from '../../../assets/images/Invite-Team-Members.jpg';

interface Props {}

interface State {}

class BlankScreen extends React.PureComponent<Props, State> {
  render() {
    return (
      <>
        <Container fluid={false} className="u-pb--50 u-pb--50-sm">
          <Row>
            <Col xs={12} md={6} lg={5} className="col-centered">
              <div className="invite--section invite--section-sm text-center">
                <div className="invite--graphic invite--graphic-sm u-mb--60 u-mb--60-sm">
                  <img
                    src={InviteGraphic}
                    alt="Invite Team Members"
                    className="img-fluid"
                  />
                </div>
                <div className="page-head page-head-sm center column noborder">
                  <p className="text-center fs--13 dark-gray">
                    Your account is not linked to any corporate account as a subcontractor.
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default BlankScreen;
